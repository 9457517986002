import React, { useState } from 'react';

import * as Styled from '../modal-default.styled';

import { useCreateSimplifiedRealFromAnon } from '@/common/hooks/use-anon';
import { ActivateAnonInformationBlockComponent } from '@/common/components/anon/components';
import { SlideType } from '@/common/types/modal.type';
import { AnonymitySelectButton } from '../../ui/anonymity-select-button/anonymity-select-button.component';
import { FormType } from '@/common/types/anon.types';
import { VerifyEmailAndCreadAnonButton } from '../../ui/verify-email-button/verify-email-button.component';
import { AnonymityToEveryoneAndUsForm } from '../activate-anon-modal/forms/anonymity-to-everyone-and-us.form';
import { ConfirmFormActions } from '../../ui/confirm-form-actions/confirm-form-actions';

interface IActivateRealModalProps {
  open: boolean;
  closeHandler: () => void;
}

export const ActivateRealModal = ({ open, closeHandler }: IActivateRealModalProps) => {
  // states
  const [activeForm, setActiveForm] = useState<FormType | null>(null);
  const [interacted, setInteracted] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const { mutateAsync: createSimplifiedRealFromAnon, isLoading } = useCreateSimplifiedRealFromAnon();

  // functions
  const onCreateSimplifiedRealClick = () => {
    if (isLoading) return;
    createSimplifiedRealFromAnon();
  };

  const handleButtonClick = (formType: FormType) => {
    if (formType === activeForm) {
      setActiveForm(null);
      setInteracted(false);
    } else {
      setActiveForm(formType);
      setInteracted(true);
      if (formType === FormType.Everyone) {
        onCreateSimplifiedRealClick();
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  };

  const setDefault = () => {
    setIsMessageSent(false);
  };

  const onClose = () => {
    setTimeout(() => {
      setActiveForm(null);
      setInteracted(false);
      setDefault();
    }, 300);
    closeHandler();
  };

  const onBack = () => {
    setActiveForm(null);
  };

  return (
    <Styled.StyledModal
      open={open}
      closeHandler={onClose}
      isCenter
      slideType={SlideType.BOTTOM}
      openTimeout={800}
      closeTimeout={600}
      hideChildBackground
      hideChildeShadow
      hideBackdrop
      hideBorder
      childTopPostion={0}
      isUserModeSwitchModal
    >
      {activeForm === null ? (
        <Styled.ModalWrapper>
          <ActivateAnonInformationBlockComponent isReal handleClose={onClose} />
          <Styled.ModalDivider formType={FormType.Everyone || FormType.EveryoneAndUs} />
          <Styled.AnonymityActionsBlockContainer>
            <Styled.AnonymityActionsOptions>
              <Styled.AnonymityActionsOption>Option 1</Styled.AnonymityActionsOption>
              <Styled.AnonymityActionsOptionText>
                Only Villan could identify the owner of an Anonymous Account
              </Styled.AnonymityActionsOptionText>
            </Styled.AnonymityActionsOptions>
            <AnonymitySelectButton
              title="anonymity to other users"
              active={activeForm === FormType.Everyone || activeForm === null}
              onClick={() => {
                handleButtonClick(FormType.Everyone);
                if (activeForm !== FormType.Everyone) setDefault();
              }}
              formType={FormType.Everyone}
              interacted={interacted}
            />
          </Styled.AnonymityActionsBlockContainer>
          <Styled.ModalDivider formType={FormType.Everyone || FormType.EveryoneAndUs} />
          <Styled.AnonymityActionsBlockContainer>
            <Styled.AnonymityActionsOptions>
              <Styled.AnonymityActionsOption>Option 2</Styled.AnonymityActionsOption>
              <Styled.AnonymityActionsOptionText>
                Villan can never identify the owner of an Anonymous Account
              </Styled.AnonymityActionsOptionText>
            </Styled.AnonymityActionsOptions>
            <AnonymitySelectButton
              title="anonymity to other users and Villan"
              active={activeForm === FormType.EveryoneAndUs || activeForm === null}
              onClick={() => {
                handleButtonClick(FormType.EveryoneAndUs);
                if (activeForm !== FormType.EveryoneAndUs) setDefault();
              }}
              formType={FormType.EveryoneAndUs}
              interacted={interacted}
            />
            <Styled.AnonymityActionsRequireText>you will need to provide a new email address</Styled.AnonymityActionsRequireText>
          </Styled.AnonymityActionsBlockContainer>
        </Styled.ModalWrapper>
      ) : (
        <>
          {activeForm === FormType.EveryoneAndUs && (
            <AnonymityToEveryoneAndUsForm
              onClose={onClose}
              formType={FormType.EveryoneAndUs}
              isReal
              setIsUsernameConfirmed={(_) => {}}
              setUsernameToCheck={(_) => {}}
              setIsUsernameReserved={(_) => {}}
              linkClicked={linkClicked}
              resetLinkClicked={() => setLinkClicked(false)}
              onResetUserEmail={() => {
                setLinkClicked(true);
                setIsMessageSent(false);
                setIsFormValid(false);
              }}
              usernameToCheck=""
              setIsFormValid={setIsFormValid}
              setIsMessageSent={setIsMessageSent}
              isMessageSent={isMessageSent}
              isFormRealValid={isFormValid}
              onBack={onBack}
            />
          )}
        </>
      )}
    </Styled.StyledModal>
  );
};
