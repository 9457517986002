import React from 'react';

import WarningIcon from '@styles/icons/warning-icon.webp';
import * as Styled from './reserved-username-input.styled';

import { OtpInputComponent } from '../ui';
import { Error } from '../ui/common-styles';
import { useCofirmUsernameReservedCode } from '@/common/hooks';

interface IReservedUsernameInputComponentProps {
  username: string;
  setIsUsernameConfirmed: (isConfirmed: boolean) => void;
  onResetUsername: any;
}

export const ReservedUsernameInputComponent = ({
  username,
  setIsUsernameConfirmed,
  onResetUsername
}: IReservedUsernameInputComponentProps) => {
  const {
    data: usernameReservedCodeConfirmStatus,
    mutateAsync: confirmUsernameReservedCode,
    isLoading: confirmUsernameReservedCodeLoading,
    isError: usernameReservedCodeConfirmIsError,
    reset: sendConfirmUsernameReservedCodeReset
  } = useCofirmUsernameReservedCode();

  const onFinish = (code: string) => {
    confirmUsernameReservedCode({ username, code });
  };

  React.useEffect(() => {
    if (usernameReservedCodeConfirmStatus) {
      setIsUsernameConfirmed(true);
    } else {
      setIsUsernameConfirmed(false);
    }
  }, [usernameReservedCodeConfirmStatus]);

  React.useEffect(
    () => () => {
      sendConfirmUsernameReservedCodeReset();
    },
    []
  );

  return (
    <Styled.ReservedUsernameInputWrapper>
      <Styled.FormContainer>
        <Styled.FormContainerInfo>
          <div>
            <Styled.WarninIcon src={WarningIcon} alt="Warning Icon" width={14} height={14} />
            <p>This username is reserved</p>
          </div>
          <span>
                   Message @Villan on Twitter for a code to unlock this username
            <br /> If this username doesn’t belong to you on Twitter, please select another
          </span>
        </Styled.FormContainerInfo>
        <Styled.CodeOtpContainer>
        <Styled.CodeOtpInputWrapper>
          <OtpInputComponent inModal disabled={confirmUsernameReservedCodeLoading} reservedUsernameInput onFinish={onFinish} />
        </Styled.CodeOtpInputWrapper>
        {usernameReservedCodeConfirmIsError && (
           <Error $isAnonModal $isConnectModal>
            Incorrect code
         </Error>
        )}
        </Styled.CodeOtpContainer>
      </Styled.FormContainer>
    </Styled.ReservedUsernameInputWrapper>
  );
};
