import { IPost } from './post.type';
import { ISentiment } from './question.type';

export enum EPollQuartal {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  HY = 'HY',
  FY = 'FY'
}

export enum EUnits {
  NONE = 'NONE',
  PERCENTAGES = 'PERCENTAGES',
  THOUSANDS = 'THOUSANDS',
  MILLIONS = 'MILLIONS',
  BILLIONS = 'BILLIONS'
}

export const UnitLabelResolver = {
  units: {
    [EUnits.NONE]: 'none',
    [EUnits.PERCENTAGES]: '%',
    [EUnits.THOUSANDS]: 'thousand',
    [EUnits.MILLIONS]: 'million',
    [EUnits.BILLIONS]: 'billion'
  }
};

export const unitOptions: { label: string; value: string }[] = Object.entries(UnitLabelResolver.units).map(([value, label]) => ({
  label,
  value
}));

export interface IPollProfileResponse {
  aligned: ISentiment[];
  variantDisagreed: ISentiment[];
  variantAgreed: ISentiment[];
  awaitingForResult: ISentiment[];
  isHidden: boolean;
  isQuestionDoesntExist?: boolean;
  outputTextForHidden?: string;
}

export interface IPollFilterPayload {
  page: number;
  limit: number;
  search?: string;
  periods?: number[];
  excludePeriods?: number[];
  regions?: string[];
  excludeRegions?: string[];
  sectors?: string[];
  excludeSectors?: string[];
  categories?: string[];
  excludeCategories?: string[];
}

export interface IOracle {
  id: string;
  isImageHidden: boolean;
  imageUrl: string;
}

export interface IPoll {
  id: string;
  isVisible: boolean;
  text: string;
  year: number;
  quartal: EPollQuartal;
  security: { name: string; id: string };
  unit: EUnits;
  midPoint: number;
  ownAnswerPosition: number;
  oracles?: IOracle[];
  oraclesCount?: number;
  totalIntervalCount?: number;
  endAt: Date;
  createdAt: Date;
  updatedAt: Date;
  finalConsensus: number;
  averageAnswer: number;
  totalAnswers: number;
}

export interface IPollPayload {
  isVisible: boolean;
  text: string;
  year: number;
  unit: string;
  quartal: EPollQuartal;
  security: string;
  endAt?: Date;
  answer: number | null;
  profileId?: string;
}

export interface IAddPollAnswerPayload {
  pollId: string;
  answer: number;
}

export interface IPollSwings extends IPost {}

export interface IPollsList {
  polls: IPollSwings[];
  maxPage: number;
}
