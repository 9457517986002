import React, { Dispatch, useRef, SetStateAction, ChangeEvent, useState } from 'react';
import Close from '@styles/icons/reply-repost-close.svg';
import AttachPhoto from '@styles/icons/reply-repost-attach-photo.webp';
import Reply from '@styles/icons/reply-post-icon.webp';
import ReplyIdeaModal from '@styles/icons/reply-send-icon.webp';
import Quote from '@styles/icons/reply-repost-quote.svg';
import * as Styled from './reply-repost.styled';
import { Loader } from '@/common/components/loader';
import { getBase64 } from '@/common/components/onboard/helpers';
import { COLORS } from '@/theme';
import { POST } from '@/common/consts';
import { useCheckedBasicPostInfo } from '@/common/hooks';
import { NewPostBasicInfoComponent } from '../post-basic-info';
import { pasteBuffer } from '@/common/utils/copy-past-in-input';
import { ImagePreviewComponent } from '../image-preview';
import { LinkPreviewForNewPost } from '../link-preview-for-new-post';
import { handleDrop } from '@/common/utils/on-drop-in-input';
import { OnDropErrorEnum } from '@/common/types/profile.type';
import { ModalErrorOnDragAndDropComponent } from '@/common/components/modal-error-on-drag-and-drop';
import { ISubPost } from '@/common/types/post.type';
import { RepliedPostPreview } from '../replied-post-preview/replied-post-preview.component';

export enum PostInputType {
  REPLY = 'REPLY',
  REPOST = 'REPOST'
}

interface IReplyRepostComponentProps {
  placeholder: string;
  text: string;
  image: ArrayBuffer | string | null;
  repliedPost?: ISubPost;
  setText: Dispatch<SetStateAction<string>>;
  setImage: Dispatch<SetStateAction<ArrayBuffer | string | null>>;
  closeHandler: () => void;
  createHandler: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inputType: PostInputType;
  isLoading?: boolean;
  isIdeaModal?: boolean;
  className?: string;
}

export const ReplyRepostComponent = ({
  placeholder,
  text,
  image,
  repliedPost,
  setText,
  setImage,
  closeHandler,
  createHandler,
  onKeyDown,
  inputType,
  isLoading,
  className,
  isIdeaModal
}: IReplyRepostComponentProps) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [isMoreThanMaxLength, setIsMoreThanMaxLength] = useState(false);
  const { info } = useCheckedBasicPostInfo();
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);

  const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img = await getBase64(e.target.files[0]);
      setImage(img);
      e.target.value = '';
    }
  };

  const onPast = (e: React.ClipboardEvent<HTMLDivElement>) => {
    pasteBuffer(e, setImage);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e, setImage, setOnDropError);
  };
  const onCreateClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    createHandler();
  };

  return (
    <Styled.ReplyRepostWrapper
      isIdeaModal={isIdeaModal}
      className={className}
      onDrop={(e) => {
        e.preventDefault();
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      {inputType === PostInputType.REPLY && !!info && (
        <NewPostBasicInfoComponent imageUrl={info.imageUrl} fullName={info.fullName} vertical={info.vertical} role={info.role} />
      )}
      {repliedPost && <RepliedPostPreview post={repliedPost} />}
      <Styled.ReplyRepostContainer>
        <Styled.InputArea
          isInModal={false}
          setIsMoreThanMaxLength={setIsMoreThanMaxLength}
          maxLength={POST.MAX_LENGTH}
          placeholder={placeholder}
          value={text}
          onChange={(value) => setText(value)}
          onPaste={onPast}
          onDrop={onDrop}
          onKeyDown={onKeyDown}
          autoFocus
        />

        <LinkPreviewForNewPost text={text} />

        {image && (
          <ImagePreviewComponent
            img={typeof image === 'string' ? image : URL.createObjectURL(new Blob([image]))}
            deleteHandler={() => setImage(null)}
          />
        )}
        <Styled.ButtonsContainer>
          <Styled.RightButtonsContainer>
            {inputType === PostInputType.REPLY && (
              <Styled.ActionButton onClick={onCreateClick} disabled={isLoading || isMoreThanMaxLength}>
                {isLoading ? (
                  <Loader height="16" width="16" borderSize="2" color={COLORS.black} />
                ) : (
                  <Styled.ActionButtonImage src={isIdeaModal ? ReplyIdeaModal : Reply} loading="eager" isReply alt="reply" />
                )}
              </Styled.ActionButton>
            )}
            <Styled.ActionButton>
              <input ref={uploadInputRef} accept="image/*" onChange={uploadPhoto} style={{ display: 'none' }} type="file" />
              <Styled.ActionButtonImage
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                src={AttachPhoto}
                alt="attach"
                loading="eager"
              />
            </Styled.ActionButton>
            {inputType === PostInputType.REPOST && text && (
              <Styled.ActionButton onClick={createHandler} disabled={isLoading}>
                {isLoading ? <Loader /> : <Styled.ActionButtonImage src={Quote} loading="eager" alt="repost" />}
              </Styled.ActionButton>
            )}
          </Styled.RightButtonsContainer>
          <Styled.ActionButton onClick={closeHandler}>
            <Styled.ActionButtonImage src={Close} alt="close" loading="eager" isClose />
          </Styled.ActionButton>
        </Styled.ButtonsContainer>
      </Styled.ReplyRepostContainer>
    </Styled.ReplyRepostWrapper>
  );
};
