import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import * as Styled from './anonymity.form.styled';
import { ActionType, IFormData, IReservetionProps } from '@/common/types/anon.types';
import { useCheckReservetion, useCreateAnonUser, useCreateRealFromAnon, useEmailVerifyConfirm, useEmailVerifySend } from '@/common/hooks';
import { OtpInputComponent } from '../../../ui';
import { everyoneAndUsFormSchema, everyoneAndUsRealFormSchema } from './schemas/anonymity-forms-validate-shemas';
import { Error } from '../../../ui/common-styles';
import { Stepper } from '@/common/components/onboard-new/components/stepper';
import { ConfirmFormActions } from '../../../ui/confirm-form-actions/confirm-form-actions';
import { ReservedUsernameInputComponent } from '../../../reserved-username-input/reserved-username-input.component';
import { VerticalRoleModalComponent } from '@/common/components/onboard-new/components/vertical-role-modal';
import { ActivateAnonInformationBlockComponent } from '../../../information-blocks/anon-information-block/anon-information-block.component';
import { Loader } from '@/common/components/loader';
import { ModalWrapper } from '../../modal-default.styled';

interface IErrors {
  email?: string;
  username?: string;
  password?: string;
}

export const AnonymityToEveryoneAndUsForm = ({
  isReal,
  onClose,
  isMessageSent,
  setIsFormValid,
  setIsUsernameReserved,
  setIsUsernameConfirmed,
  isUsernameConfirmed,
  setUsernameToCheck,
  isFormValid,
  setIsMessageSent,
  linkClicked,
  resetLinkClicked,
  onResetUserEmail,
  usernameToCheck,
  handleLinkClick,
  isUsernameReserved,
  isFormRealValid,
  formType,
  onBack
}: {
  isReal?: boolean;
  onBack: any;
  formType?: any;
  isFormRealValid?: boolean;
  usernameToCheck: string;
  onClose: any;
  isUsernameReserved?: boolean;
  isUsernameConfirmed?: boolean;
  handleLinkClick?: (valid: boolean) => void;
  setIsFormValid: (valid: boolean) => void;
  isFormValid?: boolean;
  isMessageSent: boolean;
  setIsMessageSent?: (sent: boolean) => void;
  linkClicked?: boolean;
  resetLinkClicked?: () => void;
  onResetUserEmail?: () => void;
} & IReservetionProps) => {
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    username: '',
    password: ''
  });

  const defaultEmployer: any = {
    companies: [],
    createdAt: '',
    description: '',
    employer: '',
    endDate: '',
    id: '',
    isCompaniesSuccess: false,
    isDescriptionFilled: false,
    isDescriptionSuccess: false,
    isLocationSuccess: false,
    isMain: true,
    isPrevious: '',
    isPulled: false,
    isRegionSuccess: false,
    isSectorSuccess: false,
    isStartDateSuccess: false,
    isSubSectorSuccess: false,
    isVerticalSuccess: false,
    location: '',
    locationId: '',
    locationRegion: '',
    locationRegionId: '',
    profileId: '',
    regions: [],
    role: '',
    sectors: [],
    startDate: '',
    subSector: '',
    subSectorId: '',
    subSectors: [],
    updatedAt: '',
    vertical: ''
  };

  const schema = isReal ? everyoneAndUsRealFormSchema : everyoneAndUsFormSchema;
  const useremailInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<IErrors>({});
  const [activeStep, setActiveStep] = useState(1);
  const [employer, setEmployer] = useState(defaultEmployer);
  const [current, setIsCurrent] = useState<boolean>(false);
  const [activeCallback, setActiveCallback] = useState<(() => void) | null>(null);
  const [otpFinishError, setOtpFinishError] = useState<boolean>(false);
  const [isIndustry, setIsIndustry] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createAnonVertical, setCreateAnonVertical] = useState<string>('');
  const [subSectorId, setSubSectorId] = useState<string | null>(null);
  const [isCableTelecoms, setIsCableTelecoms] = useState<boolean>(false);

  const { mutateAsync: createAnonUser } = useCreateAnonUser();
  const { mutateAsync: emailCharactersConfirmSend } = useEmailVerifyConfirm();
  const { mutateAsync: createRealFromAnon } = useCreateRealFromAnon();

  useEffect(() => {
    if (linkClicked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: ''
      }));
      if (resetLinkClicked) resetLinkClicked();
      if (useremailInputRef.current) {
        useremailInputRef.current.focus();
      }
    }
  }, [linkClicked, resetLinkClicked]);

  if (!isReal) {
    useCheckReservetion({
      setIsUsernameReserved,
      setIsUsernameConfirmed,
      setUsernameToCheck,
      username: formData.username,
      usernameError: errors.username
    });
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsernameToCheck(value);
      setIsUsernameConfirmed(false);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const singleFieldError = schema.extract(name).validate(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: singleFieldError.error ? singleFieldError.error.details[0].message : undefined
    }));
  };

  const validateForm = () => {
    const filledFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, val]) => val !== '' && ((isReal && key !== 'username') || !isReal))
    );
    const { error } = schema.validate(filledFormData, { abortEarly: false });
    setIsFormValid(!error && isIndustry);
    if (isReal) {
      setIsFormValid(!error);
    }
  };

  useEffect(() => {
    const filledFields = Object.entries(formData).filter(
      ([key, value]) => value !== '' && (key !== 'confirmPassword' || formData.password === value)
    ).length;
    setActiveStep(filledFields + 1);
  }, [formData]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined
    }));
  };

  const { data: isEmailSend, mutateAsync: emailCharactersVerifySend } = useEmailVerifySend((error) => {
    if ((error as any)?.response?.data?.exceptionMessage === 'Email address is already in use') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'This email address is already in use'
      }));
      setIsFormValid(false);
    }
  });

  const sendEmailWithVerificationCode = () => {
    if (!errors.email) {
      emailCharactersVerifySend({ email: formData.email });
    }
  };

  useEffect(() => {
    if (!isEmailSend) return;
    if (setIsMessageSent) {
      setIsMessageSent(true);
    }
    setActiveCallback(null);
  }, [isEmailSend]);

  useEffect(() => {
    validateForm();
    setActiveCallback(() => () => sendEmailWithVerificationCode());
  }, [formData, isIndustry]);

  const handleOtpFinish = (code: string) => {
    setIsLoading(true);
    emailCharactersConfirmSend({ email: formData.email.trim(), code })
      .then(() => {
        setOtpFinishError(false);
        setIsLoading(true);

        if (isReal) {
          return createRealFromAnon({ email: formData.email, keyword: formData.password });
        }
        return createAnonUser({ ...formData, keyword: formData.password, vertical: createAnonVertical, subSectorId });
      })
      .then(() => {
        onClose();
        setIsLoading(false);
      })
      .catch(() => {
        setOtpFinishError(true);
      });
  };

  const steps = [
    {
      value: 1,
      lineHeight: '75px'
    },
    {
      value: 2,
      lineHeight: '75px'
    },
    {
      value: 3,
      lineHeight: '75px'
    },
    {
      value: '✓'
    }
  ];

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const currentElement = e.target as HTMLInputElement;
      const inputs = Array.from(document.querySelectorAll('input'));
      const currentIndex = inputs.indexOf(currentElement);
      const nextIndex = currentIndex + 1;

      if (nextIndex < inputs.length) {
        const nextElement = inputs[nextIndex] as HTMLInputElement;
        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };

  useEffect(() => {
    validateForm();
    setActiveCallback(() => () => sendEmailWithVerificationCode());
  }, [formData, isIndustry]);

  const otpInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMessageSent && otpInputRef.current) {
      otpInputRef.current.focus();
    }
  }, [isMessageSent]);

  const filteredSteps = isReal ? [steps[0], steps[3]] : steps;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ModalWrapper formType={formType}>
          <ActivateAnonInformationBlockComponent isReal={isReal} everyoneAndUs={formType} handleClose={onClose} />
          <Styled.ModalDivider />
          <Styled.AnonymityFormContainer>
            <Stepper steps={filteredSteps} step={activeStep} />
            <Styled.AnonymityForm>
              {!isReal && (
                <Styled.AnonymityDataSection>
                  <Styled.AnonymityDataTitle>
                    Username
                    <Styled.AnonymityDataSubtitles>
                      <Styled.AnonymityDataSubtitle>5-25 characters</Styled.AnonymityDataSubtitle>
                      <Styled.AnonymityDataSubtitle>Use numbers, letters, dashes, but no spaces</Styled.AnonymityDataSubtitle>
                    </Styled.AnonymityDataSubtitles>
                  </Styled.AnonymityDataTitle>
                  <Styled.AnonymityValidateInput>
                    <Styled.AnonymityDataInput
                      value={formData.username}
                      name="username"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      type="text"
                      placeholder="Type here ..."
                    />
                    {errors.username && <Styled.ErrorMessage>{errors.username}</Styled.ErrorMessage>}
                  </Styled.AnonymityValidateInput>
                </Styled.AnonymityDataSection>
              )}
              <Styled.AnonymityDataSection>
                <Styled.AnonymityDataTitle>
                  <p>Password</p>
                  <Styled.AnonymityDataSubtitles>
                    <Styled.AnonymityDataSubtitle>8-20 characters</Styled.AnonymityDataSubtitle>
                    <Styled.AnonymityDataSubtitle>Use numbers, letters, specials ( ! , £ , $ , # )</Styled.AnonymityDataSubtitle>
                  </Styled.AnonymityDataSubtitles>
                </Styled.AnonymityDataTitle>
                <Styled.AnonymityValidateInput>
                  <Styled.AnonymityDataInputPassword
                    type="password"
                    name="password"
                    value={formData.password}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    placeholder="Type here ..."
                  />
                  {errors.password && <Styled.ErrorMessage>{errors.password}</Styled.ErrorMessage>}
                </Styled.AnonymityValidateInput>
              </Styled.AnonymityDataSection>
              <Styled.AnonymityDataSection>
                <Styled.AnonymityDataEmailTitle>
                  Email address
                  <Styled.AnonymityDataSubtitle>
                    Must be different from your {isReal ? 'Anonymous Account' : 'Real Account'} email
                  </Styled.AnonymityDataSubtitle>
                </Styled.AnonymityDataEmailTitle>
                <Styled.AnonymityValidateInput>
                  <Styled.AnonymityDataInput
                    type="email"
                    name="email"
                    value={formData.email}
                    ref={useremailInputRef}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Type here ..."
                  />
                  {errors.email && <Styled.ErrorMessage>{errors.email}</Styled.ErrorMessage>}
                </Styled.AnonymityValidateInput>
              </Styled.AnonymityDataSection>
              {!isReal && (
                <Styled.AnonymityDataIndustrySection>
                  <Styled.AnonymityDataTitle>Select your industry</Styled.AnonymityDataTitle>
                  <Styled.IndustryDataSection isCableTelecoms={isCableTelecoms}>
                    <VerticalRoleModalComponent
                      employer={employer}
                      isAnon={false}
                      setIsCableTelecoms={setIsCableTelecoms}
                      setEmployer={setEmployer}
                      setIsCurrent={setIsCurrent}
                      setCreateAnonVertical={setCreateAnonVertical}
                      setSubSectorId={setSubSectorId}
                      setIsIndustry={setIsIndustry}
                      isCreateAnonVertical
                    />
                  </Styled.IndustryDataSection>
                </Styled.AnonymityDataIndustrySection>
              )}
              {isMessageSent ? (
                <Styled.OtpInputWrapper>
                  <Styled.AnonymityDataTitle>{isReal ? 'Verify email' : 'Verify email to complete account'}</Styled.AnonymityDataTitle>
                  <OtpInputComponent onFinish={handleOtpFinish} inModal inputRef={otpInputRef} />
                  {otpFinishError && (
                    <Error $isAnonModal $isConnectModal>
                      Incorrect code — try again or{' '}
                      <a
                        onClick={() => {
                          setOtpFinishError(false);
                          if (onResetUserEmail) onResetUserEmail();
                        }}
                      >
                        click here to submit another email address{' '}
                      </a>
                    </Error>
                  )}
                </Styled.OtpInputWrapper>
              ) : (
                <>
                  {isUsernameReserved && !isUsernameConfirmed && (
                    <ReservedUsernameInputComponent
                      username={usernameToCheck}
                      setIsUsernameConfirmed={setIsUsernameConfirmed}
                      onResetUsername={handleLinkClick}
                    />
                  )}
                  <ConfirmFormActions
                    isActive={isReal ? (isFormRealValid ?? false) : (isFormValid ?? false) && (isUsernameConfirmed ?? false)}
                    activeCallback={activeCallback ?? (() => {})}
                    onBack={onBack}
                    actionType={ActionType.CONFIRM}
                  />
                </>
              )}
            </Styled.AnonymityForm>
          </Styled.AnonymityFormContainer>
        </ModalWrapper>
      )}
    </>
  );
};
