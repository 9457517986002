import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '../modal';
import { COLORS, FONTS, SPACES } from '@/theme';

export const ModalWrapper = styled(ModalComponent)``;

export const ChildWrapper = styled.div`
  padding: 100px 10px 200px 10px;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
`;

export const Child = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 22px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  backdrop-filter: blur(1.5px);
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: flex-end;
  align-items: center;
`;

export const KeywordInputWrapper = styled.div`
  position: relative;
  width: 351px;
  height: 48px;
  border-radius: 15px;
  border: 1px solid ${COLORS.borderColorForReplyTile};
`;

export const AnonIcon = styled(Image)`
  position: absolute;
  top: 14px;
  left: 14px;
  width: 18px;
  height: 18px;
`;

export const SubmitKeywordButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background: rgba(121, 0, 164, 0.8);
  height: 36px;
  width: 36px;
  padding: 11px;
  &:disabled {
    background: rgba(121, 0, 164, 0.2);
  }
`;

export const SubmitIcon = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const KeywordInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 15px;
  background: rgba(253, 253, 253, 0.75);
  padding: 16px 56px 16px 41px;
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.m};
  letter-spacing: 2.1px;
  &::placeholder {
    letter-spacing: ${SPACES.defaultLetterSpacing};
    color: ${COLORS.viewBookmarkNewText};
    opacity: 1;
    letter-spacing: 0.42px;
  }
  :focus-visible {
    outline: 0;
  }
`;

export const ReminderWrapper = styled.p`
  padding: 8px 10px;
  text-align: center;
  color: ${COLORS.switchToAnonReminderTextColor};
  text-align: center;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 16px;
  border-radius: 15px;
  background: rgba(120, 120, 120, 0.05);
  backdrop-filter: blur(7.5px);
  width: 100%;
`;

export const ErrorMessageAndResetButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 14px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.s};
  & > p {
    color: #df0000;
  }

  & > button {
    border-radius: 15px;
    background: rgba(0, 22, 216, 0.1);
    font-family: ${FONTS.FAMILIES.robotoFlex};
    backdrop-filter: blur(7.5px);
    padding: 8px 14px;
    display: flex;
    gap: 10px;
    cursor: pointer;
    border: none;
    & > p {
      font-size: ${FONTS.SIZES.s};
      line-height: ${FONTS.SIZES.s};
      color: #0016d8;
    }
  }
`;

export const ResetButtonIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ResetKeywordForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0 0 14px;
  justify-content: start;
  align-items: start;
  width: 100%;
  & > h5 {
    color: #7900a4;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.xxl};
  }
`;

export const EnterAndVerifyEmailWrapper = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.xxl};
  max-width: 332px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  outline: none;
  & > span {
    color: #3a3a3a;
  }
  & > input {
    color: ${COLORS.black};
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.xxl};
    letter-spacing: 0.42px;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${COLORS.black};
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.m};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.medium};
      line-height: ${FONTS.SIZES.xxl};
      letter-spacing: 0.42px;
      opacity: 1;
    }
  }
  & > button {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #7900a4;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.m};
    border: none;
    background-color: transparent;
    width: fit-content;
    cursor: pointer;
    &:disabled {
      color: rgba(121, 0, 164, 0.2);
    }
  }

  & > p {
    padding-top: 10px;
    color: #df0000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.xxl};
  }
`;

export const CodeInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > p {
    color: #3a3a3a;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.xxl};
  }
  & > button {
    width: 287px;
    border: none;
    background-color: transparent;
    text-align: start;
    color: #df0000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.xxl};
    cursor: pointer;
  }
`;

export const KeywordAndErrorWrapper = styled.div<{ isHasValue: boolean }>`
  display: flex;
  gap: 10px;
  & > input {
    font-family: ${FONTS.FAMILIES.robotoFlex};
    color: ${COLORS.black};
    width: 100%;
    ${({ isHasValue }) => isHasValue && 'width: 199px;'}
    border: none;
    outline: none;
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.xxl};
    letter-spacing: 2.1px;
    background-color: transparent;
    &::placeholder {
      letter-spacing: 0.42px;
      color: ${COLORS.black};
      opacity: 1;
    }
    :focus-visible {
      outline: 0;
    }
  }
  & > span {
    color: #df0000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: 12px;
    padding: 5px 0 3px 0;
  }
`;

export const NewKeywordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  & > div {
    & > h5 {
      color: #3a3a3a;
      font-size: ${FONTS.SIZES.m};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.normal};
      line-height: ${FONTS.SIZES.xxl};
    }
    & > p {
      padding: 5px 0px 3px 0px;
      color: #5a5a5a;
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.light};
      line-height: ${FONTS.SIZES.s};
    }
  }
  & > button {
    font-family: ${FONTS.FAMILIES.robotoFlex};
    color: #7900a4;
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    padding: 10px 0px;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.m};
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: none;
    background-color: transparent;
    &:disabled {
      color: rgba(121, 0, 164, 0.2);
    }
  }
  & > p {
    color: #df0000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.xxl};
  }
`;
