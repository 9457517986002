import { month } from '../components/employer/employer.const';

export const getRangeOfMonthsStart = (dateStart?: Date | string | null, dateEnd?: Date | string | null, saveYearsStart?: number | null) => {
  if (!dateStart) {
    return month;
  }

  const dateStartLocal = typeof dateStart === 'string' ? new Date(dateStart) : dateStart;
  const dateCurrent = new Date();
  if (dateStartLocal && dateStartLocal.getFullYear() === dateCurrent.getFullYear() && !dateEnd) {
    return month.filter((_, index) => index <= dateCurrent?.getMonth());
  }

  const dateEndLocal = typeof dateEnd === 'string' ? new Date(dateEnd) : dateEnd;

  if (dateEndLocal && dateStartLocal && dateStartLocal.getFullYear() === dateEndLocal.getFullYear()) {
    return month.filter((_, index) => index <= dateEndLocal?.getMonth() && index <= dateEndLocal.getMonth());
  }

  if (dateEndLocal && dateStartLocal && dateStartLocal.getFullYear() === dateEndLocal.getFullYear()) {
    return month.filter((_, index) => index <= dateEndLocal?.getMonth());
  }

  if (dateStartLocal && dateStartLocal.getFullYear() === dateCurrent.getFullYear()) {
    return month.filter((_, index) => index <= dateCurrent?.getMonth());
  }

  return month;
};
