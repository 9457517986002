import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ReturnBack from '@styles/icons/return-back-on-delete-post.webp';
import Trash from '@styles/icons/trash.webp';
import DeleteFirst from '@styles/icons/delete-post-first.webp';
import DeleteSecond from '@styles/icons/delete-post-second.webp';
import QuotePostMark from '@styles/icons/quote-post-tile.webp';
import { UseMutateAsyncFunction } from 'react-query';
import { ClickAwayListener } from '@mui/material';
import { ISubPost, PostType } from '@/common/types/post.type';
import { NewPostBasicInfoComponent } from './components/post-basic-info';
import { PostStatsComponent } from './components/post-stats';
import { QuestionResultComponent } from '../question-result';
import { QuestionVoteComponent } from '../question-vote';
import { useUpdatePost } from '@/common/hooks';
import { PostIdeaComponent } from './components/post-idea';
import { DisplayMemoSectionComponent } from './components/display-memo-section';
import * as Styled from './post.styled';
import { PublishModalComponent } from '../publish-memo/components/image-modal/images-modal.component';
import { RepliedPostPreview } from './components/replied-post-preview';
import { NewsfeedPublish } from '../newsfeed/components/newsfeed-publish';
import { DisplayReviewComponent } from './components/display-review';
import { ReviewLinkPreview } from './components/display-review/review-link-preview.component';
import { PollResultComponent, PollVoteComponent } from '../poll/components';
import { PostInsightComponent } from './components/post-insight';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { SourceMenuComponent } from './components/dropdown-menu';
import { CaseType, IMemo, IMemoSection } from '@/common/types/memo.type';

interface IPostStatus {
  isInOwnTread: boolean;
  isTreadOpen?: boolean;
  isPublish?: boolean;
  isInModal?: boolean;
  isReply?: boolean;
  isRepost?: boolean;
  isQuote?: boolean;
  isNotDisaplay?: boolean;
  isLast?: boolean;
  isInSearch?: boolean;
  isOwnReply?: boolean;
  isConversation?: boolean;
  isHidePostStats?: boolean;
}

interface IPostComponent {
  post: ISubPost;
  isAnon?: boolean;
  isHome?: boolean;
  isHidePostStats?: boolean;
  isAnotherUserProfile?: boolean;
  repliedPost?: ISubPost;
  postStatus: IPostStatus;
  userProfileId?: string;
  searchText?: string;
  className?: string;
  openPostInModal?: () => void;
  onClickByPost?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickByRepliedPost?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  deletePost?: UseMutateAsyncFunction<unknown, unknown, string, unknown>;
  updateQuestionInModal?: (post: ISubPost) => void;
  quotePostHandler?: (postId: string) => void;
  closeModal?: () => void;
  postType?: PostType;
  pollRefetch?: () => void;
  highlight?: string;
  isIdeaModal?: boolean;
  isDefaultStats?: boolean;
  memosSection?: any;
  parentPost?: ISubPost;
}

export const PostComponent = ({
  post,
  isAnon,
  isHome,
  memosSection,
  isIdeaModal,
  isAnotherUserProfile,
  repliedPost,
  postType,
  postStatus,
  isHidePostStats,
  userProfileId,
  searchText,
  className,
  openPostInModal,
  onClickByPost,
  onClickByRepliedPost,
  updateQuestionInModal,
  openReply,
  deletePost,
  quotePostHandler,
  closeModal,
  pollRefetch,
  highlight,
  isDefaultStats,
  parentPost
}: IPostComponent) => {
  // eslint-disable-next-line no-spaced-func
  const [anchorEl, setAnchorEl] = React.useState<null | { element: HTMLElement; onClose: () => void }>(null);
  const {
    id: postId,
    imageUrl,
    profileId,
    deletedAt: notValidDeletedAt,
    vertical,
    isHidden,
    createdAt,
    isImageHidden,
    text,
    idea,
    question,
    memo,
    review,
    memoSection,
    linkPreviews,
    isOriginalPostFaded,
    newsFeed,
    isPrivate,
    questionResult,
    pollResult,
    poll,
    insight,
    isOwnPost: isOwnPostCondition
  } = post;
  const { fullName, imageUrl: profileImageUrl } = post.profile ?? {
    imageUrl: newsFeed?.newsSource.imgUrl,
    fullName: newsFeed?.newsSource.fullName
  };
  const route = useRouter();
  const isPublishPage = route.pathname.includes(ROUTER_KEYS.MEMO_PUBLISH);
  const { role } = newsFeed ? { role: 'RSS Feed' } : post;
  const date = useMemo(() => (typeof createdAt === 'string' ? new Date(createdAt) : createdAt), [createdAt]);

  const deletedAt = useMemo(
    () => (typeof notValidDeletedAt === 'string' ? new Date(notValidDeletedAt) : notValidDeletedAt),
    [notValidDeletedAt]
  );

  const isOwnPost = useMemo(() => isOwnPostCondition || profileId === userProfileId, [userProfileId, post]);

  const { isPublish, isReply, isRepost, isQuote, isTreadOpen, isNotDisaplay, isInSearch, isInModal, isOwnReply } = postStatus;

  const [modalImages, setModalImages] = useState<string[]>(imageUrl ? [imageUrl] : []);
  const [selectedModalImage, setCurrentImage] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const [replyOpen, setReplyOpen] = useState(false);
  const [onDelete, setOnDelete] = useState<undefined | boolean>(undefined);
  const myRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: UpdateAsync } = useUpdatePost(userProfileId ?? '');

  const { asPath } = useRouter();

  const hash = useMemo(() => asPath.split('#b').at(1), [asPath]);

  useEffect(() => {
    if (hash && hash === postId && !isRepost && !isQuote) {
      myRef.current?.scrollIntoView();
    }
  }, [hash]);

  const hashInTreads = useMemo(() => asPath.split('#u').at(1), [asPath]);

  useEffect(() => {
    if (hashInTreads && hashInTreads === postId) {
      myRef.current?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (replyOpen && !isTreadOpen) {
      setReplyOpen(false);
    }
  }, [isTreadOpen]);

  useEffect(() => {
    if (isTreadOpen) {
      setReplyOpen(true);
    }
  }, [isTreadOpen]);

  const onDeleteClick = () => {
    if (!onDelete) {
      setOnDelete(true);
      return;
    }
    if (deletePost) {
      deletePost(postId);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (isReply || isQuote) && ((!deletedAt && onClickByPost && onClickByPost(e)) || (onClickByRepliedPost && onClickByRepliedPost(e)));

  const onClickByImage = (e: React.MouseEvent<HTMLImageElement, MouseEvent> | undefined) => {
    e?.stopPropagation();
    setModalImages(imageUrl ? [imageUrl] : []);
    setCurrentImage(0);
    setOpen(true);
  };

  const onClickByPostText = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => !isReply && onClickByPost && onClickByPost(e);

  const handleClickThreeDots = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, onClose: () => void) => {
    setAnchorEl({ element: event.currentTarget, onClose });
  };

  const onThreeDotsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, onClose: () => void) => {
    handleClickThreeDots(event, onClose);
  };

  const updatePost = () => {
    UpdateAsync(postId);
  };

  const isQuestion = question && !isReply && !isHidden && (!isPrivate || isOwnPost);
  const isDebateResult = isQuestion && (!!question.ownAnswer || question.isHidden) && question.isResult && !question.isNotEnoughAnswers;
  const nowDate = new Date();
  const questionEndAt = new Date(question?.endAt ?? '');
  const isQuestionTimeNotOver = isQuestion && question.endAt && questionEndAt > nowDate && !question.isResult;
  const isNotEnouthAnswerForResult = isQuestion && question.isResult && question.isNotEnoughAnswers;
  const isResultInProcessing = isQuestion && !!question.endAt && questionEndAt < nowDate && !question.isResult;

  const isPoll = poll && !isReply && (!isPrivate || isOwnPost);
  const isPollResult = (isPoll && !!poll.ownAnswer && !poll.isNotEnoughAnswers) || (isPoll && new Date(poll.endAt) < new Date());
  const pollEndAt = new Date(poll?.endAt ?? '');
  // const isPollTimeNotOver = isPoll && poll.endAt && pollEndAt > nowDate && (!poll.ownAnswer || poll.totalAnswers < 2);
  const isNotEnouthAnswerForPollResult = isPoll && pollEndAt > nowDate && poll.totalAnswers < 2 && poll.isNotEnoughAnswers;
  const isPollResultInProcessing = isPoll && false;
  const isMemoPost = (!!memo || !!memoSection) && isPublish;
  const isSummary = post?.memoSection?.type === CaseType.BASE_CASE;
  return (
    <>
      <PublishModalComponent
        open={open}
        transitionExited={() => setModalImages([])}
        closeHandler={() => setOpen(false)}
        imgUrls={modalImages}
        current={selectedModalImage}
        setCurrentImage={setCurrentImage}
      />
      <Styled.QuotePostWrapper
        isIdeaModal={isIdeaModal}
        isQuote={isQuote}
        isPublish={isPublish || isPublishPage}
        isInModal={isInModal}
        isMemoPost={isMemoPost}
      >
        {isQuote && <Styled.QuotePostIcon src={QuotePostMark} alt="Quote Post" />}
        <Styled.PostWrapper
          onClick={(e) => onClick(e)}
          ref={myRef}
          isNotDisaplay={isNotDisaplay}
          isReply={isReply}
          isOwnReply={isOwnReply}
          isQuote={isQuote}
          className={className}
          isPublish={isPublish || isPublishPage}
          isClickable={!!onClickByPost && (isReply || isQuote || isInSearch)}
          onDrop={(e) => {
            e.preventDefault();
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          {deletedAt && (
            <>
              {isQuote && (
                <NewPostBasicInfoComponent
                  isAnon={isAnon}
                  closeModal={closeModal}
                  fullName={fullName}
                  role={role ?? ''}
                  vertical={vertical ?? ''}
                  isQuote
                  isHidden={!!isHidden}
                  deletedAt={deletedAt}
                  isMemoAnon={memoSection?.memo?.isAnon}
                />
              )}
              <Styled.DeletedPostWrapper>
                {!isQuote && (
                  <Styled.TrashWrapper>
                    <Styled.Trash src={Trash} alt="Trash" />
                  </Styled.TrashWrapper>
                )}
                <Styled.DeletedPostText isAnon={isAnon} onClick={(e) => !isQuote && onClickByPostText(e)} isQuote={isQuote}>
                  {text}
                </Styled.DeletedPostText>
              </Styled.DeletedPostWrapper>
            </>
          )}
          {!deletedAt && (
            <ClickAwayListener onClickAway={() => typeof onDelete !== 'undefined' && setOnDelete(undefined)}>
              <Styled.PostBodyWrapper isQuote={isQuote}>
                <Styled.DeleteBlock ondelete={onDelete} isInModal={isInModal} isAnon={!!isAnon}>
                  <Styled.DeleteButtonWrapper>
                    <Styled.DeleteButton src={ReturnBack} alt="Back" onClick={() => setOnDelete(undefined)} />
                    <Styled.DeleteButton src={onDelete ? DeleteSecond : DeleteFirst} alt="Delete" onClick={onDeleteClick} />
                  </Styled.DeleteButtonWrapper>
                </Styled.DeleteBlock>
                <Styled.PostBodyWrapperWithoutDelete
                  isOwnReply={isOwnReply}
                  ondelete={onDelete}
                  isQuote={isQuote}
                  isInsight={Boolean(insight)}
                >
                  {!isPublish && (
                    <>
                      {!(isHome && isDebateResult) &&
                        ((!isQuestionTimeNotOver && !isNotEnouthAnswerForResult && !isResultInProcessing) || isQuote) &&
                        (!poll || isQuote) &&
                        (!isQuestion || isQuote) && (
                          <NewPostBasicInfoComponent
                            post={post.originalPostId && !post.profileId && parentPost ? parentPost : post}
                            isAnon={isAnon}
                            fullName={fullName}
                            imageUrl={profileImageUrl}
                            role={role ?? ''}
                            vertical={vertical ?? ''}
                            profileId={profileId}
                            isImageHidden={isImageHidden}
                            isHidden={!!isHidden}
                            isOriginalPostFaded={isOriginalPostFaded}
                            isQuote={isQuote}
                            date={isQuote && (!isPrivate || isOwnPost) ? date : undefined}
                            closeModal={closeModal}
                            deletedAt={deletedAt}
                            isMemoAnon={memoSection?.memo?.isAnon}
                          />
                        )}
                      <Styled.PostTextWrapper isOwnReply={isOwnReply} onClick={isSummary ? undefined : onClickByPostText} isQuote={isQuote}>
                        {repliedPost && (!isPrivate || isOwnPost) && (
                          <Styled.RepliedPostWrapper>
                            <RepliedPostPreview post={repliedPost} />
                          </Styled.RepliedPostWrapper>
                        )}
                        {isHidden && (!isPrivate || isOwnPost) && !poll && <Styled.HideTextBlock>{text}</Styled.HideTextBlock>}
                        {isPrivate && !isOwnPost && <Styled.PostText isAnon={isAnon}>This post is private</Styled.PostText>}
                        {!newsFeed &&
                          !insight &&
                          !idea &&
                          !question &&
                          !memo &&
                          !review &&
                          !poll &&
                          !memoSection &&
                          !isHidden &&
                          (!isPrivate || isOwnPost) &&
                          !!text.length && (
                            <Styled.PostText
                              isAnon={isAnon}
                              searchText={searchText}
                              isQuote={isQuote}
                              isOriginalPostFaded={isOriginalPostFaded}
                            >
                              {text}
                            </Styled.PostText>
                          )}
                        {newsFeed &&
                          (isQuote ? (
                            <Styled.PostText
                              isAnon={isAnon}
                              searchText={searchText}
                              isQuote={isQuote}
                              isOriginalPostFaded={isOriginalPostFaded}
                            >
                              {text}
                            </Styled.PostText>
                          ) : (
                            <NewsfeedPublish
                              description={newsFeed.description}
                              title={newsFeed.title}
                              imgUrl={newsFeed.imgUrl}
                              link={newsFeed.link}
                              sourceImgUrl={newsFeed.newsSource.imgUrl}
                              tags={newsFeed.tags}
                            />
                          ))}
                        {review && !isHidden && (!isPrivate || isOwnPost) && (
                          <DisplayReviewComponent isAnon={isAnon} isQuote={isQuote} review={review} />
                        )}
                        {imageUrl && !idea && !isReply && (!isPrivate || isOwnPost) && (
                          <Styled.PostImage src={imageUrl} onClick={onClickByImage} postType={postType} />
                        )}
                        {/* {memo && !isHidden && (!isPrivate || isOwnPost) && <DisplayMemoComponent memo={memo} isQuote={isQuote} />} */}
                        {memoSection && !isHidden && (!isPrivate || isOwnPost) && (
                          <DisplayMemoSectionComponent memoSection={memoSection as IMemoSection} isQuote={isQuote} />
                        )}
                        {idea && !isReply && !isHidden && (!isPrivate || isOwnPost) && (
                          <PostIdeaComponent
                            isAnon={isAnon}
                            postText={post.text}
                            text={idea.text}
                            horizon={idea.horizon}
                            reasons={idea.reasons ?? []}
                            priority={idea.priority}
                            isPostTile
                            isQuotePost={isQuote}
                          />
                        )}
                        {insight && !isReply && !isHidden && (!isPrivate || isOwnPost) && <PostInsightComponent {...insight} />}
                        {imageUrl && !!idea && !isReply && (!isPrivate || isOwnPost) && (
                          <Styled.PostImage src={imageUrl} onClick={onClickByImage} postType={postType} />
                        )}
                        {(isQuestionTimeNotOver || isNotEnouthAnswerForResult || isResultInProcessing) && (
                          <QuestionVoteComponent
                            isPost
                            isRepost={isRepost}
                            ownerId={question.ownerProfileId}
                            isAnon={!!isAnon}
                            isOwner={userProfileId === question.ownerProfileId}
                            allowChange={question.isCanChange}
                            isNotEnouthAnswerForResult={isNotEnouthAnswerForResult}
                            isResultInProcessing={isResultInProcessing}
                            isAnotherUserProfile={isAnotherUserProfile}
                            userProfileId={userProfileId ?? undefined}
                            answer={question.ownAnswer}
                            cb={updatePost}
                            openPostInModal={openPostInModal}
                            updateQuestionInModal={updateQuestionInModal}
                            ownerFullName={question.ownerFullName}
                            questionId={question.id}
                            text={question.text}
                            isQuote={isQuote}
                            questionEndAt={question.endAt}
                            followersCount={question.followerAnswerCount}
                            shouldShowVoteOwner={{
                              ownerName: question.answeredUserFullName?.split(' ')?.[0] || fullName.split(' ')[0] || fullName
                            }}
                            isOnPost
                          />
                        )}
                        {isDebateResult && (
                          <QuestionResultComponent
                            questionId={question.id}
                            ownerId={question.ownerProfileId}
                            isAnon={!!isAnon}
                            text={question.text}
                            allAnswer={question.averageAnswer}
                            userAnswer={question.user.answer}
                            keyDebateResult={questionResult}
                            ownerFullName={question.ownerFullName}
                            isHidden={question.isHidden}
                            isCurrentUserPost={profileId === userProfileId}
                            isQuote={isQuote}
                            userName={question.user?.profile?.fullName?.split(',')[0] || question.user?.username?.split(',')[0] || ''}
                          />
                        )}

                        {isPoll && !isPollResult && (
                          <PollVoteComponent
                            isPost
                            highlight={highlight}
                            isRepost={isRepost}
                            ownerId={poll.ownerProfileId}
                            isAnon={!!isAnon}
                            isOwner={userProfileId === poll.ownerProfileId}
                            allowChange={poll.isCanChange}
                            isNotEnouthAnswerForResult={isNotEnouthAnswerForPollResult}
                            isResultInProcessing={isPollResultInProcessing}
                            isAnotherUserProfile={isAnotherUserProfile}
                            userProfileId={userProfileId ?? undefined}
                            answer={poll.ownAnswer}
                            poll={poll}
                            cb={pollRefetch}
                            openPostInModal={openPostInModal}
                            updateQuestionInModal={updateQuestionInModal}
                            ownerFullName={poll.ownerFullName}
                            pollId={poll.id}
                            text={poll.text}
                            isQuote={isQuote}
                            questionEndAt={poll.endAt as unknown as string}
                            followersCount={poll.followerAnswerCount}
                            shouldShowVoteOwner={{
                              ownerName: poll.answeredUserFullName?.split(' ')?.[0] || fullName.split(' ')[0] || fullName
                            }}
                            isOnPost
                          />
                        )}
                        {isPollResult && (
                          <PollResultComponent
                            cb={pollRefetch}
                            isOwner={userProfileId === poll.ownerProfileId}
                            userProfileId={userProfileId ?? undefined}
                            openPostInModal={openPostInModal}
                            updateQuestionInModal={updateQuestionInModal}
                            highlight={highlight}
                            pollId={poll.id}
                            allowChange={poll.isCanChange}
                            poll={poll}
                            ownerId={poll.ownerProfileId}
                            isAnon={!!isAnon}
                            text={poll.text}
                            allAnswer={poll.averageAnswer}
                            userAnswer={poll.user.answer}
                            pollResult={pollResult}
                            ownerFullName={poll.ownerFullName}
                            isHidden={poll.isHidden}
                            isCurrentUserPost={profileId === userProfileId}
                            isQuote={isQuote}
                            userName={poll.user?.profile?.fullName?.split(',')[0] || poll.user?.username?.split(',')[0] || ''}
                          />
                        )}

                        {!!linkPreviews.length &&
                          (!isPrivate || isOwnPost) &&
                          linkPreviews.map((it, index) => <ReviewLinkPreview data={it} key={`link-preview-${postId}-${index}`} />)}
                      </Styled.PostTextWrapper>
                    </>
                  )}
                </Styled.PostBodyWrapperWithoutDelete>
              </Styled.PostBodyWrapper>
            </ClickAwayListener>
          )}
          {!isReply &&
            !isHidePostStats &&
            !isQuote &&
            !deletedAt &&
            (!isAnotherUserProfile || !isQuestionTimeNotOver) &&
            !isNotEnouthAnswerForResult &&
            !isResultInProcessing && (
              <>
                <PostStatsComponent
                  isAnon={isAnon}
                  isReply={!!post.originalPostId}
                  postId={postId}
                  date={isPrivate && !isOwnPost ? undefined : date}
                  optionHandler={onThreeDotsClick}
                  quotePostHandler={quotePostHandler}
                  profileId={userProfileId ?? undefined}
                  isPublish={isPublish}
                  isIdeaModal={isIdeaModal}
                  replyHandler={(e) => (!isPrivate || isOwnPost) && openReply && openReply(e)}
                  replyCountHandler={onClickByPostText}
                  isBottonsDisabled={isPrivate && !isOwnPost}
                  memoSection={memoSection}
                  memosSection={memosSection}
                  isMemoPost={isMemoPost}
                  isDefaultStats={isDefaultStats}
                  isInModal={isInModal}
                />
                <SourceMenuComponent
                  anchorEl={anchorEl}
                  postId={postId}
                  originalPostId={post.originalPostId}
                  setAnchorEl={setAnchorEl}
                  onDelete={
                    isOwnPost && deletePost && !isQuote && (!isPublishPage || !!post.originalPostId) ? () => deletePost(postId) : undefined
                  }
                />
              </>
            )}
        </Styled.PostWrapper>
      </Styled.QuotePostWrapper>
    </>
  );
};
