import React, { useMemo } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { IStatistic, ISubPost, PostType } from '@/common/types/post.type';
import { PostComponent } from '@/common/components/post';

interface IProps {
  post: ISubPost;
  isAnon?: boolean;
  isAnotherUserProfile?: boolean;
  userProfileId?: string | undefined;
  onClickByPost: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  updateQuestionInModal?: (post: ISubPost) => void;
  statistic?: IStatistic;
  deletePost?: UseMutateAsyncFunction<unknown, unknown, string, unknown>;
  isPublish?: boolean;
  isHome?: boolean;
  isTreadOpen?: boolean;
  isInModal?: boolean;
  isIdeaModal?: boolean;
  isHidePostStats?: boolean;
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  openPostInModal?: () => void;
  memosSection?: any;
  isDefaultStats?: boolean;
}

export const BasicPostComponent = ({
  post,
  isAnon,
  memosSection,
  isAnotherUserProfile,
  isHidePostStats,
  userProfileId,
  onClickByPost,
  openReply,
  updateQuestionInModal,
  statistic,
  deletePost,
  isTreadOpen,
  isInModal,
  isIdeaModal,
  quotePostHandler,
  closeModal,
  openPostInModal,
  isPublish,
  isHome,
  isDefaultStats
}: IProps) => {
  const isLast = useMemo(() => (statistic?.replyCount || post.replyCount || 0) === 0, [statistic, post.replyCount]);

  return (
    <PostComponent
      isHome={isHome}
      isAnon={isAnon}
      isDefaultStats={isDefaultStats}
      openPostInModal={openPostInModal}
      updateQuestionInModal={updateQuestionInModal}
      isHidePostStats={isHidePostStats}
      isAnotherUserProfile={isAnotherUserProfile}
      postStatus={{ isInOwnTread: true, isLast, isTreadOpen, isPublish, isInModal }}
      post={post}
      deletePost={deletePost}
      key={post.id}
      isIdeaModal={isIdeaModal}
      openReply={openReply}
      onClickByPost={onClickByPost}
      quotePostHandler={quotePostHandler}
      closeModal={closeModal}
      userProfileId={userProfileId}
      postType={PostType.Regular}
      memosSection={memosSection}
    />
  );
};
