import { EnhancedWithAuthHttpService } from '@/common/services/http-auth.service';
import { axiosInstance } from '@/common/services/axios';
import { HttpService } from '@/common/services/http.service';
import {
  ICompaniesUniqueRes,
  ICompanySearchFilter,
  IMarketDataFromApi,
  IOnboardingSubSectors,
  IRegion,
  ISector,
  ISelectedCompany,
  ISubSector,
  ISubSectorFilter
} from '@/common/types/coverage.type';

class CoverageService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getSectors() {
    return this.get<ISector[]>('sector');
  }

  async getUniqueSectors() {
    return this.get<ISector[]>('sector/unique');
  }

  async getSubSectors(id: string) {
    return this.get<ISubSector[]>(`sector/${id}/sub-sectors`);
  }

  async getSubSectorsBySectors(ids: string[]) {
    if (ids.length >= 1) {
      return this.post<ISubSector[], ISubSectorFilter>('sub-sector/by-sectors', { ids });
    }
  }

  async getSubSectorsBySectorsOnboarding(ids: string[]) {
    if (ids.length >= 1) {
      return this.post<IOnboardingSubSectors, ISubSectorFilter>('sub-sector/onboarding-by-sectors-ids', { ids });
    }
  }

  async getSubSectorsBySectorsUnique(ids: string[]) {
    if (ids.length >= 1) {
      return this.post<ISubSector[], ISubSectorFilter>('sub-sector/by-sectors/unique', { ids });
    }
  }

  async getRegions() {
    return this.get<IRegion[]>('region');
  }

  async getRegionsOnMemo() {
    return this.get<IRegion[]>('region/memo');
  }

  async getCompaniesBySymbol(filter: ICompanySearchFilter) {
    return this.get<ISelectedCompany[]>(`yh-company?symbol=${filter.symbol}&count=12`);
  }

  async getCompaniesCoverageBySymbolUnique(filter: ICompanySearchFilter) {
    return this.get<ICompaniesUniqueRes>(`yh-company/coverage/unique?symbol=${filter.symbol}&count=12`);
  }

  async getCompaniesBySymbolUnique(filter: ICompanySearchFilter) {
    return this.get<ISelectedCompany[]>(`yh-company/unique?symbol=${filter.symbol}&count=12`);
  }

  async getCompanyMarketDataByTicker(ticker?: string): Promise<IMarketDataFromApi> {
    return this.get<any>(`yh-company/quote?ticker=${ticker}`);
  }

  async getCompaniesByTickersEditProfile(tickers: string[]) {
    const query = tickers.map((ticker) => `id=${ticker}`).join('&');
    return this.get<ISelectedCompany[]>(`yh-company/tickers-edit-profile?${query}`);
  }

  async savePrivateCompany(name: string) {
    await this.post('company/save', { name });
  }
}

export const coverageService = new CoverageService();
