import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { IBookmark, IOptions, IPagedReturn, IPostBookmark, IPostSetResult } from '../types/bookmark.types';

class BookmarkService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getBookmarks(options: IOptions) {
    const optionsUrl = new URLSearchParams();
    optionsUrl.append('page', options.page ? options.page.toString() : '1');
    optionsUrl.append('limit', options.limit ? options.limit.toString() : '20');
    return this.get<IPagedReturn>(`bookmark/paged?${optionsUrl}`);
  }

  async setBookmark(body: IBookmark) {
    return this.post('bookmark', body);
  }

  async setPostBookmark(body: IPostBookmark) {
    return this.post<IPostSetResult, IPostBookmark>('bookmark/posts', body);
  }

  async removeBookmark(body: IBookmark) {
    const query = new URLSearchParams();
    if (body.postId) query.append('postId', body.postId);
    if (body.messageId) query.append('messageId', body.messageId.toString());
    if (body.channelUrl) query.append('channelUrl', body.channelUrl);
    return this.delete(`bookmark?${query}`);
  }
}

export const bookmarkService = new BookmarkService();
