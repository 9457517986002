import React, { useMemo, useState } from 'react';
import AnonIcon from '@styles/icons/swich-to-anon-model-input-keyword-icon.webp';
import Submit from '@styles/icons/switch-to-anon-modal-keyword-input-submit-icon.webp';
import ResetButtonIcon from '@styles/icons/switch-to-anon-modal-reset-button-arrow.webp';
import VerifyButtonArrowDisabled from '@styles/icons/switch-to-anon-verify-button-arrow-disabled.webp';
import VerifyButtonArrow from '@styles/icons/switch-to-anon-verify-button-arrow.webp';
import Image from 'next/image';
import * as Styled from './switch-to-anon-modal.styled';
import { SlideType } from '@/common/types/modal.type';
import { EMAIL_MATCH } from '../auth';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { useAnonSignIn, useResetAnonKeyword, useVerifyAnonEmailConfirm, useVerifyAnonEmailSend } from '@/common/hooks';

interface ISwitchToAnonModalProps {
  open: boolean;
  closeHandler: () => void;
}

export const SwitchToAnonModalComponent = ({ open, closeHandler }: ISwitchToAnonModalProps) => {
  const [keyword, setKeyword] = useState('');
  const [email, setEmail] = useState('');
  const [isHasError, setIsHasError] = useState(false);
  const [isClickOnResetButton, setIsClickOnResetButton] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isShowCodeInput, setIsShowCodeInput] = useState(false);
  const [isVerifyEmailCodeSendError, setIsVerifyEmailCodeSendError] = useState(false);
  const [firstKeyword, setFirstKeyword] = useState('');
  const [secondKeyword, setSecondKeyword] = useState('');

  const close = () => {
    closeHandler();
    setTimeout(() => {
      setIsClickOnResetButton(false);
      setIsHasError(false);
      setKeyword('');
      setEmail('');
      setIsEmailDisabled(false);
      setIsShowCodeInput(false);
      setFirstKeyword('');
      setSecondKeyword('');
    }, 700);
  };

  const { mutateAsync: anonSignIn } = useAnonSignIn(
    () => {
      close();
    },
    () => setIsHasError(true)
  );

  const isAnonSignInButtonDisabled = useMemo(() => keyword.length < 8 || keyword.length > 20, [keyword]);

  const onClickByAnonSignInButton = () => {
    if (isAnonSignInButtonDisabled) return;
    anonSignIn(keyword);
  };

  const onVerifyEmailCodeSend = () => {
    setIsEmailDisabled(true);
    setIsShowCodeInput(true);
  };

  const { mutateAsync: verifyAnonEmailSend, isLoading: isVerifyEmailSending } = useVerifyAnonEmailSend(onVerifyEmailCodeSend, () =>
    setIsEmailError(true)
  );

  const onVerifyEmailConfirmed = () => {
    setIsShowCodeInput(false);
  };

  const { mutateAsync: verifyAnonEmailCodeSend, isLoading: isVerifyEmailCodeCheching } = useVerifyAnonEmailConfirm(
    onVerifyEmailConfirmed,
    () => setIsVerifyEmailCodeSendError(true)
  );

  const isVerifyDisabled = useMemo(() => !email.length || !email.match(EMAIL_MATCH) || isVerifyEmailSending, [email, isVerifyEmailSending]);

  const onClickByVerifyButton = () => {
    if (isVerifyDisabled) return;
    verifyAnonEmailSend({ email });
  };

  const onClickByErrorMessageAfterCode = () => {
    setIsEmailDisabled(false);
    setIsShowCodeInput(false);
    setIsVerifyEmailCodeSendError(false);
  };
  const keywordMatch = /^[a-zA-Z0-9!£$#]{8,20}$/;
  const isFirstKeywordMatch = useMemo(() => firstKeyword.match(keywordMatch), [firstKeyword]);
  const isKeywordMatch = useMemo(
    () => firstKeyword.match(keywordMatch) && secondKeyword.match(keywordMatch),
    [firstKeyword, secondKeyword]
  );

  const { mutateAsync: resetAnonKeyword, isLoading: isResetingAnonKeyword } = useResetAnonKeyword(
    () => {
      close();
    },
    () => {}
  );

  const isConfirmNewKeywordDisabled = useMemo(
    () => !isKeywordMatch || firstKeyword !== secondKeyword || isResetingAnonKeyword,
    [firstKeyword, secondKeyword, isKeywordMatch, isResetingAnonKeyword]
  );

  const onClickByConfirmResetKeyword = () => {
    if (isConfirmNewKeywordDisabled) return;
    resetAnonKeyword({ email, keyword: firstKeyword, keywordConfirm: secondKeyword });
  };

  return (
    <Styled.ModalWrapper
      open={open}
      closeHandler={close}
      isCenter
      slideType={SlideType.BOTTOM}
      openTimeout={800}
      closeTimeout={600}
      hideChildBackground
      hideChildeShadow
      hideBorder
      childTopPostion={0}
    >
      <Styled.ChildWrapper>
        <Styled.Child>
          <Styled.KeywordInputWrapper>
            <Styled.AnonIcon src={AnonIcon} alt="Anon Icon" />
            <Styled.KeywordInput
              placeholder="enter your password"
              type="password"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              // autoFocus
              id="keyword"
              onKeyDown={(e) => e.key === 'Enter' && onClickByAnonSignInButton()}
            />
            <Styled.SubmitKeywordButton disabled={isAnonSignInButtonDisabled} onClick={onClickByAnonSignInButton} type="button">
              <Styled.SubmitIcon src={Submit} alt="Submit Icon" />
            </Styled.SubmitKeywordButton>
          </Styled.KeywordInputWrapper>
          {!isHasError && !isClickOnResetButton && (
            <Styled.ReminderWrapper>
              Save your password to your password manager to instantly <br /> switch from your real account to your anon account
            </Styled.ReminderWrapper>
          )}
          {isHasError && !isClickOnResetButton && (
            <Styled.ErrorMessageAndResetButtonWrapper>
              <p>Incorrect password</p>
              <button type="button" onClick={() => setIsClickOnResetButton(true)}>
                <p>Reset password via email</p>
                <Styled.ResetButtonIcon src={ResetButtonIcon} alt="Arrow" />
              </button>
            </Styled.ErrorMessageAndResetButtonWrapper>
          )}
          {isHasError && isClickOnResetButton && (
            <Styled.ResetKeywordForm>
              <h5>Reset your password</h5>
              <Styled.EnterAndVerifyEmailWrapper>
                <span>Enter your Anonymous Account Email Address</span>
                <input
                  type="email"
                  value={email}
                  autoComplete="username"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (isEmailError) setIsEmailError(false);
                  }}
                  placeholder="type email address here"
                  disabled={isEmailDisabled}
                  onKeyDown={(e) => e.key === 'Enter' && onClickByVerifyButton()}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
                {!isEmailDisabled && !isEmailError && (
                  <button type="button" disabled={isVerifyDisabled} onClick={onClickByVerifyButton}>
                    <span>verify email</span>
                    <Image
                      src={isVerifyDisabled ? VerifyButtonArrowDisabled : VerifyButtonArrow}
                      width={10}
                      height={10}
                      alt="Verify Email"
                    />
                  </button>
                )}
                {isEmailError && <p>The email address you entered is not registered</p>}
              </Styled.EnterAndVerifyEmailWrapper>
              {isEmailDisabled && isShowCodeInput && (
                <Styled.CodeInputWrapper>
                  <p>Enter the code you receive to the above email address</p>
                  <CodeInputComponent disabled={isVerifyEmailCodeCheching} onFinish={(code) => verifyAnonEmailCodeSend({ email, code })} />
                  {isVerifyEmailCodeSendError && (
                    <button type="button" onClick={onClickByErrorMessageAfterCode}>
                      The code you entered is incorrect: try again or <br />
                      click here to submit another email address
                    </button>
                  )}
                </Styled.CodeInputWrapper>
              )}
              {isEmailDisabled && !isShowCodeInput && (
                <Styled.NewKeywordWrapper>
                  <div>
                    <h5>Create a New Password</h5>
                    <p>8 - 20 characters</p>
                    <p>Allowed: numbers, letters, certain specials ( ! , £ , $ , # )</p>
                  </div>
                  <Styled.KeywordAndErrorWrapper isHasValue={!!firstKeyword}>
                    <input
                      placeholder="type new password here"
                      type="password"
                      value={firstKeyword}
                      onChange={(e) => setFirstKeyword(e.target.value)}
                      autoComplete="new-password"
                      onKeyDown={(e) => e.key === 'Enter' && onClickByConfirmResetKeyword()}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                    {!!firstKeyword && !isFirstKeywordMatch && <span>Invalid entry</span>}
                  </Styled.KeywordAndErrorWrapper>

                  <Styled.KeywordAndErrorWrapper isHasValue={!!secondKeyword}>
                    <input
                      placeholder="type new password again to confirm"
                      type="password"
                      value={secondKeyword}
                      onKeyDown={(e) => e.key === 'Enter' && onClickByConfirmResetKeyword()}
                      onChange={(e) => setSecondKeyword(e.target.value)}
                    />
                    {!!secondKeyword && firstKeyword !== secondKeyword && <span>Passwords do not match</span>}
                  </Styled.KeywordAndErrorWrapper>
                  <button disabled={isConfirmNewKeywordDisabled} onClick={onClickByConfirmResetKeyword} type="button">
                    <span>confirm new password</span>
                    <Image
                      src={isConfirmNewKeywordDisabled ? VerifyButtonArrowDisabled : VerifyButtonArrow}
                      width={10}
                      height={10}
                      alt="confirm new password"
                    />
                  </button>
                  {/* {isKeywordMatch && firstKeyword !== secondKeyword && <p>Keywords do not match</p>} */}
                </Styled.NewKeywordWrapper>
              )}
            </Styled.ResetKeywordForm>
          )}
        </Styled.Child>
      </Styled.ChildWrapper>
    </Styled.ModalWrapper>
  );
};
