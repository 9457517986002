import { IMentionOption } from '../types/post.type';
import { ISearchInModalOption } from '../types/search.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class SearchAlgoliaService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async searchInModal(searchString: string) {
    return this.get<ISearchInModalOption>(`algolia/search-in-modal?query=${searchString}`);
  }

  async mentionSearch(searchString: string) {
    return this.get<IMentionOption[]>('algolia/mention-search', { params: { query: searchString } });
  }
}

export const searchAlgoliaService = new SearchAlgoliaService();
