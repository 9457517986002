import React from 'react';
import Image from 'next/image';
import OvalDotsLine from '@styles/icons/oval-dots-line.svg';
import OvalDotsDisableLine from '@styles/icons/oval-dots-line-disable.svg';
import OvalDotsDisableDarkerLine from '@/styles/icons/oval-dots-line-disable.svg';
import * as Styled from './stepper.styled';
import CheckMark from '@/styles/icons/stepper-checkmark.webp';
import DottedLine from '@/styles/icons/stepper-doted-line.webp';
import DottedDisableLine from '@/styles/icons/disable-dotted-line.webp';
import DottedDisableDarkerLine from '@/styles/icons/dotted-stepper-darker-line.webp';
import DecorDot from '@/styles/icons/memo-post-dot.webp';
import DecorProgressDot from '@/styles/icons/stepper-decor-dot.webp';
import DecorProgressDisabledDot from '@/styles/icons/stepper-decor-disable-dot.webp';
import DecorProgressDisabledLightDot from '@/styles/icons/ligtht-decor-point.webp';

interface StepElement {
  value: string | number;
  lineHeight?: string;
}

interface IProps {
  step: number;
  steps: StepElement[];
  isDotted?: boolean;
  variant?: 'oval' | 'dotted';
}

export const Stepper = ({ step, steps, isDotted, variant }: IProps) => {
  const isOval = variant === 'oval';

  return (
    <Styled.Container>
      {steps.map((el, i) => {
        const isActive = i < step;

        return (
          <React.Fragment key={i}>
            {isDotted && isActive ? (
              <Image src={DecorProgressDot} width={20} height={20} alt="active-icon" />
            ) : isDotted && !isActive ? (
              <Image src={DecorProgressDisabledDot} width={20} height={20} alt="disabled-icon" />
            ) : (
              <Styled.Point isActive={isActive} isFirst={i === 0} isDotted={isDotted ?? false}>
                {el.value === '✓' ? (
                  <Image src={CheckMark} width={10} height={10} alt="checkmark" />
                ) : (
                  <Styled.Text>{el.value}</Styled.Text>
                )}
              </Styled.Point>
            )}
            {i !== steps.length - 1 &&
              (isDotted ? (
                isActive && step > i + 1 ? (
                  <Styled.DottedLine width={2} height={78} src={isOval ? OvalDotsLine : DottedLine} alt="dotted-line" />
                ) : (
                  <Styled.DottedLine
                    width={2}
                    height={75}
                    src={isOval ? OvalDotsLine : DottedDisableDarkerLine}
                    alt="dotted-disable-line"
                  />
                )
              ) : (
                <Styled.Line isActive={i < step - 1} height={el.lineHeight || '80px'} />
              ))}
          </React.Fragment>
        );
      })}
      {isDotted && (
        <>
          <Image src={isOval ? OvalDotsDisableLine : DottedDisableLine} width={2} height={65} alt="dotted-disable-line" />
          <Image src={DecorProgressDisabledLightDot} width={20} height={20} alt="decor-dot" />
        </>
      )}
    </Styled.Container>
  );
};
