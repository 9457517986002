import { ILocation, ILocationRegion } from '../types/current-employer.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class CurrentEmployerService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getRegions() {
    return this.get<ILocationRegion[]>('location/region');
  }

  async getLocations() {
    return this.get<ILocation[]>('location');
  }
}

export const currentEmployerService = new CurrentEmployerService();
