import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import * as Styled from './mention-text.styled';
import { ConvertFromMentionTypeToAlgoliaType, MentionType } from '@/common/types/post.type';
import { HighlightedText } from '@/common/components/search-page/components/other-element-result';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IMentionTextComponentProps {
  children: ReactNode;
  isAnon?: boolean;
  searchText?: string;
  isQuote?: boolean;
  isFaded?: boolean;
  isCheckLinks?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const MentionTextComponent = ({
  children,
  isAnon,
  searchText,
  isQuote,
  isFaded,
  isCheckLinks,
  onClick,
  className
}: IMentionTextComponentProps) => {
  const { push } = useRouter();
  const mentionClick = (text: string, type: MentionType, value: string) => {
    if (type === MentionType.USER) {
      push({
        pathname: ROUTER_KEYS.PROFILE,
        query: {
          id: value
        }
      });
      return;
    }
    const algoliaType = ConvertFromMentionTypeToAlgoliaType[type];
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        type: algoliaType,
        value: text.slice(1),
        clickedId: value,
        clickedValue: text.slice(1),
        isClickOnMention: true
      }
    });
  };

  const findLinks = (subText: string) => {
    const linkPattern = /(https?:\/\/[^\s]+)/g;
    const linksJSX = [];
    let lastIndex = 0;
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = linkPattern.exec(subText)) !== null) {
      const [link] = match;
      const jsxElement = (
        <Styled.Link target="_blank" href={link} key={`${link}-${lastIndex}`}>
          {link}
        </Styled.Link>
      );

      linksJSX.push(<HighlightedText text={subText.slice(lastIndex, match.index)} searchText={searchText} key={`text5-${lastIndex}`} />);
      linksJSX.push(jsxElement);

      lastIndex = linkPattern.lastIndex;
    }

    linksJSX.push(<HighlightedText text={subText?.slice(lastIndex)} searchText={searchText} key={`text6-${lastIndex}`} />);

    return linksJSX;
  };

  const getText = () => {
    const text = children as string;
    const pattern = /!\[(.*?)\{(.*?)\|(.*?)\}\]/g;
    const mentionsJSX = [];
    let lastIndex = 0;
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = pattern.exec(text)) !== null) {
      const [, mentionText, mentionType, mentionValue] = match;
      const mentionTextWithoutTrigger = mentionText.slice(1);
      const jsxElement = (
        <Styled.Mention
          isAnon={isAnon}
          isQuote={isQuote}
          isFaded={isFaded}
          onClick={(e) => {
            e.stopPropagation();
            mentionClick(mentionText, mentionType as MentionType, mentionValue);
          }}
          key={`${mentionText}-${lastIndex}`}
        >
          <HighlightedText text={mentionTextWithoutTrigger} searchText={searchText} key={`text1-${lastIndex}`} />
        </Styled.Mention>
      );

      const subText = text.slice(lastIndex, match.index);

      mentionsJSX.push(
        isCheckLinks ? findLinks(subText) : <HighlightedText text={subText} searchText={searchText} key={`text2-${lastIndex}`} />
      );
      mentionsJSX.push(jsxElement);

      lastIndex = pattern.lastIndex;
    }
    mentionsJSX.push(
      isCheckLinks ? (
        findLinks(text?.slice(lastIndex))
      ) : (
        <HighlightedText text={text?.slice(lastIndex)} searchText={searchText} key={`text3-${lastIndex}`} />
      )
    );

    return mentionsJSX;
  };

  return (
    <Styled.MentionTextContainer isAnon={isAnon} onClick={onClick} className={className}>
      {getText()}
    </Styled.MentionTextContainer>
  );
};
