import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const LinkPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  cursor: pointer;
  position: relative;
  min-height: 100px;
`;

export const LinkPreviewImage = styled.img`
  width: 100%;
  border-radius: 10px;
  position: relative;
`;

export const EmptyImage = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background: ${COLORS.emptyImageInLinkPreviewBackground};
`;

export const LinkPreviewTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 7px;
  background: rgba(242, 242, 242, 0.75);
  backdrop-filter: blur(2.5px);
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

export const LinkPreviewTitle = styled.div`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.l};
  width: 100%;
  word-wrap: break-word;
`;

export const LinkPreviewDomain = styled.div`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  line-height: ${FONTS.SIZES.xxl};
`;
