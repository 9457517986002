import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { SIZES, WEIGHTS } from '@/theme/fonts.const';
import { FormType } from '@/common/types/anon.types';
import Image from 'next/image';

export const SelectButton = styled.button<{ active: boolean, formType: FormType }>`
  width: ${(props) => props.formType === FormType.Everyone ? '384px' : '378px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  font-size: ${SIZES.m};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  border: none;
  line-height: 20px;
  font-weight: ${WEIGHTS.medium};
  color: ${(props) => (props.active ? COLORS.darkerBlue : COLORS.disabledDarkerBlue)};
  background-color: ${(props) => (props.active ? COLORS.lightBlue : COLORS.disabledlightBlue)};
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const ArrowIcon = styled(Image)`
    width: 14px;
    height: 14px;
`
export const ArrowIconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
`