import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { v4 } from 'uuid';
import AddReplyIconBlack from '@styles/icons/add-reply-black.svg';
import AddReplyIconGrey from '@styles/icons/add-reply-grey.svg';
import AttachPhoto from '@styles/icons/reply-repost-attach-photo.webp';
import { SectionWrapperComponent } from './components';
import { NewPostReplyComponent } from '@/common/components/new-musing-modal/new-post-reply.component';
import { PostButtonComponent } from '@/common/components/modal/components/post-button';
import { useAuthProfile, useCheckedBasicPostInfo, useCreateReview, useDebounce } from '@/common/hooks';
import { INewPostReply } from '@/common/components/new-musing-modal/new-musing-modal.types';
import { IReviewCreatePayload, ReviewMediaTypes } from '@/common/types/review.type';
import StarIcon from '@/styles/icons/rating-star.png';
import EmptyStarIcon from '@/styles/icons/rating-star-empty.png';
import NormalPostIcon from '@/styles/icons/normal-post.png';
import PrivatePostIcon from '@/styles/icons/private-post.png';
import * as Styled from './review.styled';
import { useReviewLinkPreview } from '@/common/hooks/use-link-preview';
import { ILinkPreview } from '@/common/types/post.type';
import { OnDropErrorEnum } from '@/common/types/profile.type';
import { ModalErrorOnDragAndDropComponent } from '@/common/components/modal-error-on-drag-and-drop';
import { getBase64 } from '@/common/components/onboard/helpers';
import { ImagePreviewComponent } from '@/common/components/post/components/image-preview';
import { pasteBuffer } from '@/common/utils/copy-past-in-input';
import { handleDrop } from '@/common/utils/on-drop-in-input';
import { POST } from '@/common/consts';

interface IProps {
  open: boolean;
  closeHandler: () => void;
  setIsScrollDisabled: (it: boolean) => void;
}

export const ReviewComponent = ({ closeHandler, setIsScrollDisabled }: IProps) => {
  const { data: profileId } = useAuthProfile();
  const { info } = useCheckedBasicPostInfo();
  const [mediaType, setMediaType] = useState<ReviewMediaTypes | null>(null);
  const [link, setMediaLink] = useState<string>('');
  const [rating, setRating] = useState<number | null>(null);
  const [recommended, setRecommended] = useState<null | boolean>(null);
  const [review, setReviewText] = useState<string>('');
  const [replies, setReplies] = useState<INewPostReply[]>([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isMoreThanMaxLength, setIsMoreThanMaxLength] = useState(false);
  const [isPostDisabled, setIsPostDisabled] = useState(true);
  const [isReplieDisabled, setIsReplieDisabled] = useState(true);
  const [image, setImage] = useState<ArrayBuffer | string | null>(null);
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);
  const [step, setStep] = useState(1);
  const [invalidError, setInvalidError] = useState(false);
  const [apiError, setAPIError] = useState(false);
  const [noTitleScrapped, setNoTitleScrapped] = useState(false);
  const [linksWithLinkPreview, setLinkWithLinkPreview] = useState<{ link: string; preview: undefined | null | ILinkPreview } | null>(null);
  const deboundedLink = useDebounce<string>(link, 500);

  const setNewLinkPreview = (data: ILinkPreview | null, url: string) => {
    setLinkWithLinkPreview({ link: url, preview: data });
  };

  const setAPIErrorPreview = (newValue: boolean) => {
    setAPIError(newValue);
  };

  const { mutateAsync } = useReviewLinkPreview(setNewLinkPreview, setAPIErrorPreview, setNoTitleScrapped);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: createReview, isLoading, isSuccess, reset } = useCreateReview(profileId ?? undefined);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#000'
    },
    '& .MuiRating-iconHover': {
      color: '#000',
      fontSize: '22px'
    },
    '& .MuiRating-decimal': { transform: 'scale(1)' }
  });

  const handleRatingChange = (event: React.ChangeEvent<{}>, newRating: number | null) => {
    if (!newRating) return;
    setRating(newRating);
  };

  const handleMediaTypeCilck = (it: string) => {
    if (it === mediaType) return;
    setMediaType(it as ReviewMediaTypes);
  };

  const addReplyClick = () => {
    setReplies([...replies, { id: v4(), text: '', image: null }]);
  };

  const isLink = (text: string) => {
    if (!text?.trim()?.length) return false;
    const linkRegex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    return linkRegex.test(text);
  };

  const getPlaceHolder = () => {
    const defPlaceHolder = 'Paste here URL from';
    switch (mediaType) {
      case ReviewMediaTypes.BOOK:
        return `${defPlaceHolder} Amazon or Goodreads`;
      case ReviewMediaTypes.PODCAST:
        return `${defPlaceHolder} Apple, Spotify, Overcast, or Google`;
      case ReviewMediaTypes.MOVIE:
        return `${defPlaceHolder} IMDb, Rotten Tomatoes, Amazon, Apple, Netflix, Max, etc`;
      case ReviewMediaTypes.TV:
        return `${defPlaceHolder} IMDb, Rotten Tomatoes, Amazon, Apple, Netflix, Max, etc`;
      case ReviewMediaTypes.ALBUM:
        return `${defPlaceHolder} Apple, Spotify, YouTube, SoundCloud, etc`;
      default:
        return defPlaceHolder;
    }
  };

  const handleRecomendedClick = (newValue: boolean) => {
    if (newValue === recommended) {
      setRecommended(null);
    } else setRecommended(newValue);
  };

  const createClick = async (createData: IReviewCreatePayload) => {
    createReview(createData);
  };

  const createPost = () => {
    if (isPostDisabled || isLoading) return;
    createClick({
      image: image ?? null,
      mediaType: mediaType!,
      link,
      isPrivate,
      ...(!!review?.trim()?.length && { review }),
      ...(recommended !== null && { recommended }),
      ...(!!rating && { rating }),
      replies
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      createPost();
    }
  };

  const resetFields = () => {
    reset();
    setMediaType(null);
    setMediaLink('');
    setRating(null);
    setRecommended(null);
    setReviewText('');
    setReplies([]);
    setIsPrivate(false);
  };

  const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img = await getBase64(e.target.files[0]);
      setImage(img);
      e.target.value = '';
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    pasteBuffer(e, setImage);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e, setImage, setOnDropError);
  };

  useEffect(() => {
    if (!invalidError && !!deboundedLink.length && !isLink(deboundedLink)) {
      setLinkWithLinkPreview(null);
      setInvalidError(true);
      setAPIError(false);
    }
    if (!deboundedLink || !isLink(deboundedLink)) return;
    setLinkWithLinkPreview({ link: deboundedLink, preview: undefined });
  }, [deboundedLink]);

  useEffect(() => {
    if (invalidError) {
      setInvalidError(false);
    }
    if (!link && apiError) {
      setAPIError(false);
    }
    if (!link && noTitleScrapped) {
      setNoTitleScrapped(false);
    }
  }, [link]);

  useEffect(() => {
    if ((linksWithLinkPreview && linksWithLinkPreview.link === deboundedLink && !!linksWithLinkPreview.preview) || !linksWithLinkPreview) {
      return;
    }
    mutateAsync(linksWithLinkPreview.link);
  }, [linksWithLinkPreview]);

  useEffect(() => {
    if (isSuccess) {
      closeHandler();
      resetFields();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (mediaType && isLink(link) && replies.every((reply) => reply.text || reply.image) && !isMoreThanMaxLength) {
      setIsPostDisabled(false);
    } else {
      setIsPostDisabled(true);
    }
  }, [mediaType, link, replies, isMoreThanMaxLength]);

  useEffect(() => {
    if ((!!review?.trim()?.length || image) && isReplieDisabled) {
      setIsReplieDisabled(false);
    } else if (!review?.trim()?.length && !image && !isReplieDisabled) {
      setIsReplieDisabled(true);
    }
  }, [review, image]);

  useEffect(() => {
    if (step === 1 && mediaType) {
      setStep(2);
    }
  }, [mediaType]);

  return (
    <div>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      <Styled.Container>
        <Styled.TitleContainer>
          <Styled.ModalTitle>New review</Styled.ModalTitle>
        </Styled.TitleContainer>
        <Styled.MediaTypeContainer>
          <Styled.MediaTypeTitle>Media type</Styled.MediaTypeTitle>
          {Object.keys(ReviewMediaTypes).map((it) => (
            <Styled.MediaTypeButton key={it} isSelected={mediaType === it} onClick={() => handleMediaTypeCilck(it)}>
              {it.toLowerCase().split('_').join(' ')}
            </Styled.MediaTypeButton>
          ))}
        </Styled.MediaTypeContainer>
        {step > 1 && (
          <Styled.MainInfoContainer>
            <Styled.MainInputsContainer>
              <SectionWrapperComponent sectionTitle="Link">
                <Styled.MediaInput value={link} type="text" onChange={(e) => setMediaLink(e.target.value)} placeholder={getPlaceHolder()} />
                {invalidError && <Styled.LinkError>This link is invalid</Styled.LinkError>}
                {apiError && !invalidError && <Styled.LinkError>API error</Styled.LinkError>}
                {!apiError && noTitleScrapped && <Styled.LinkError>Title not scrapped</Styled.LinkError>}
              </SectionWrapperComponent>
              <SectionWrapperComponent sectionTitle="Rating" optional>
                <Stack spacing={1}>
                  <StyledRating
                    sx={{
                      fontSize: '22px',
                      gap: '3px'
                    }}
                    name="half-rating"
                    defaultValue={0}
                    value={rating}
                    onChange={handleRatingChange}
                    precision={0.5}
                    icon={<Image src={StarIcon} alt="filled-star" width={22} height={22} />}
                    emptyIcon={<Image src={EmptyStarIcon} alt="empty-star" width={22} height={22} />}
                  />
                </Stack>
              </SectionWrapperComponent>
              <SectionWrapperComponent sectionTitle="Recommend" optional>
                <Styled.RecommendedButtonsWrapper>
                  <Styled.RecommendedButton
                    isYes
                    isNoSelected={!recommended && recommended !== null}
                    isYesSelected={!!recommended}
                    onClick={() => handleRecomendedClick(true)}
                  >
                    yes
                  </Styled.RecommendedButton>
                  <Styled.RecommendedButton
                    isNo
                    isNoSelected={!recommended && recommended !== null}
                    isYesSelected={!!recommended}
                    onClick={() => handleRecomendedClick(false)}
                  >
                    no
                  </Styled.RecommendedButton>
                </Styled.RecommendedButtonsWrapper>
              </SectionWrapperComponent>
              <SectionWrapperComponent sectionTitle="Review" optional>
                <Styled.InputWithImage>
                  <Styled.ReviewInput
                    isInModal
                    maxLength={POST.MAX_LENGTH}
                    value={review}
                    onChange={(value) => setReviewText(value)}
                    onPaste={onPaste}
                    placeholder="Type here"
                    onShowDropdown={() => setIsScrollDisabled?.(true)}
                    onHideDropdown={() => setIsScrollDisabled?.(false)}
                    onDrop={onDrop}
                  />
                  {image && (
                    <ImagePreviewComponent
                      img={typeof image === 'string' ? image : URL.createObjectURL(new Blob([image]))}
                      deleteHandler={() => setImage(null)}
                    />
                  )}
                  <Styled.PostActionsContainer>
                    <Styled.AttachImage
                      src={AttachPhoto}
                      alt="attach"
                      onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    />
                    <input ref={uploadInputRef} accept="image/*" onChange={uploadPhoto} style={{ display: 'none' }} type="file" />
                    {/* <Styled.CloseImage src={replies.length ? CloseGrey : CloseBlack} alt="attach" onClick={closeHandler} /> */}
                  </Styled.PostActionsContainer>
                </Styled.InputWithImage>
              </SectionWrapperComponent>
            </Styled.MainInputsContainer>
          </Styled.MainInfoContainer>
        )}
      </Styled.Container>
      {!!info && !!replies?.length && (
        <Styled.RepliesContainer>
          {replies.map((reply, index) => (
            <NewPostReplyComponent
              setIsMoreThanMaxLength={setIsMoreThanMaxLength}
              reply={reply}
              setReplies={setReplies}
              setIsScrollDisabled={setIsScrollDisabled}
              fullName={info.fullName}
              profileId={info.id}
              imageUrl={info.imageUrl}
              role={info.role}
              vertical={info.vertical}
              key={reply.id}
              onKeyDown={() => {}}
              isLast={index === replies.length - 1}
            />
          ))}
        </Styled.RepliesContainer>
      )}
      {step > 1 && (
        <Styled.ActionsContainer noReplies={!replies.length} haveReplies={!!replies.length}>
          <PostButtonComponent
            isDisabled={isPostDisabled}
            color="default"
            text={replies.length ? 'post all' : 'post'}
            isLoading={isLoading}
            onClick={() =>
              createClick({
                image: image ?? null,
                mediaType: mediaType!,
                link,
                isPrivate,
                ...(!!review?.trim()?.length && { review }),
                ...(recommended !== null && { recommended }),
                ...(!!rating && { rating }),
                replies
              })
            }
            borderRadius={8}
          />
          <Styled.AddReplyButton disabled={isReplieDisabled || isPostDisabled} onClick={addReplyClick} isFirst={!replies.length}>
            <Styled.AddReplyButtonImage src={isReplieDisabled || isPostDisabled ? AddReplyIconGrey : AddReplyIconBlack} alt="send" />
            {replies.length ? 'add reply' : 'add reply to create thread'}
          </Styled.AddReplyButton>
          <Styled.PrivatePostButton onClick={() => setIsPrivate((prev) => !prev)} isPrivate={isPrivate}>
            <Styled.PrivatePostButtonImage priority src={isPrivate ? PrivatePostIcon : NormalPostIcon} alt="private-status" />
            {isPrivate ? 'only you' : 'normal'}
          </Styled.PrivatePostButton>
        </Styled.ActionsContainer>
      )}
    </div>
  );
};
