import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { bookmarkService } from '../services';
import { EBookmarkType, IMessagePostResponse, IPostBookmark, TMessageResponse } from '../types/bookmark.types';
import { setPostStatistics } from '../utils/post-statistics';
import { IFilters, NewsfeedNavigation } from '../types/newsfeed.type';
import { useGetAllowedFilters } from './use-newsfeeds';
import { IPost } from '../types/post.type';
import { updatePostQuestion } from '../utils/mutate-posts';

export const useAllUserBookmarks = (profileId?: string) => {
  const { mutate: getAllowedFilters } = useGetAllowedFilters();
  const queryClient = useQueryClient();
  const { data: bookmarks } = useQuery([QUERY_KEYS.GET_USER_BOOKMARKS, profileId], () =>
    queryClient.getQueryData<(TMessageResponse | IMessagePostResponse)[]>([QUERY_KEYS.GET_USER_BOOKMARKS, profileId])
  );
  return {
    allBookmarks: bookmarks,
    setAllBookmarks: (data: (TMessageResponse | IMessagePostResponse)[], newProfileId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId], data);
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]);
    },
    updateQuestionInBookmark: (data: IPost, newProfileId?: string) => {
      const oldBooks =
        queryClient.getQueryData<(TMessageResponse | IMessagePostResponse)[]>([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]) ??
        [];
      const newBooks = updatePostQuestion(oldBooks as IPost[], data);
      queryClient.setQueryData([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId], newBooks);
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]);
    },
    setNewBookmarks: (data: (TMessageResponse | IMessagePostResponse)[], newProfileId?: string, isBefore?: boolean) => {
      const oldBooks = queryClient.getQueryData<(TMessageResponse | IMessagePostResponse)[]>([
        QUERY_KEYS.GET_USER_BOOKMARKS,
        profileId ?? newProfileId
      ]);
      const filterOldBooks = (oldBooks ?? []).filter((bookmark) => {
        if (bookmark.type === EBookmarkType.MESSAGE) {
          return !data.find((message) => {
            if (message.type === EBookmarkType.MESSAGE) {
              return message.messageId === bookmark.messageId;
            }
          });
        }
        return !data.find((post) => {
          if (post.type === EBookmarkType.POST) {
            return post.id === bookmark.id;
          }
        });
      });

      const newBooks = [...(isBefore ? data : []), ...(filterOldBooks ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId], newBooks);
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]);
    },
    deleteBookmark: (data: TMessageResponse | IMessagePostResponse, newProfileId?: string) => {
      const postsData = queryClient.getQueryData<(TMessageResponse | IMessagePostResponse)[]>([
        QUERY_KEYS.GET_USER_BOOKMARKS,
        profileId ?? newProfileId
      ]);
      const message = data.type === EBookmarkType.MESSAGE ? data : null;
      const post = data.type === EBookmarkType.POST ? data : null;
      const updatedPosts = postsData?.filter((bookmark) => {
        if (bookmark.type === EBookmarkType.MESSAGE && message) {
          return bookmark.messageId !== message.messageId;
        }
        if (bookmark.type === EBookmarkType.POST && post) {
          return bookmark.id !== post.id;
        }
        return true;
      });

      queryClient.setQueryData([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]);
    },
    deletePostBookmark: ({
      postId,
      newProfileId,
      activeTab,
      filter
    }: {
      postId: string;
      newProfileId?: string;
      activeTab?: NewsfeedNavigation;
      filter?: IFilters;
    }) => {
      const postsData = queryClient.getQueryData<(TMessageResponse | IMessagePostResponse)[]>([
        QUERY_KEYS.GET_USER_BOOKMARKS,
        profileId ?? newProfileId
      ]);
      const updatedPosts = postsData?.filter((bookmark) => {
        if (bookmark.type === EBookmarkType.POST) {
          return bookmark.id !== postId;
        }
        return true;
      });

      queryClient.setQueryData([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_BOOKMARKS, profileId ?? newProfileId]);
      if (activeTab === NewsfeedNavigation.SAVED && filter) {
        queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS]);
        getAllowedFilters({ filter, activeTab });
      }
    }
  };
};

const BOOKMARKS_LIMIT = 10;

export const useFetchBookmarks = (profileId: string) => {
  const [pageParam, setPageParam] = useState(0);
  const queryClient = useQueryClient();
  const { setAllBookmarks, setNewBookmarks } = useAllUserBookmarks(profileId);
  const {
    isLoading,
    mutateAsync,
    data: fetchedData
  } = useMutation(
    [profileId, QUERY_KEYS.GET_USER_BOOKMARKS, BOOKMARKS_LIMIT],
    () => bookmarkService.getBookmarks({ page: pageParam + 1, limit: BOOKMARKS_LIMIT }),
    {
      onSuccess: (data) => {
        if (!data) return;
        const { bookmarks: bookmarksData } = data;
        if (pageParam === 0) {
          setAllBookmarks(bookmarksData);
        }
        setNewBookmarks(bookmarksData, profileId);
        bookmarksData.forEach((bookmark) => {
          if (bookmark.type === EBookmarkType.POST) {
            setPostStatistics(bookmark, queryClient);
            if (bookmark.post) {
              setPostStatistics(bookmark.post, queryClient);
            }
          }
        });
      }
    }
  );

  return {
    hasNextPage: pageParam < (fetchedData?.maxPage ?? 0) - 1,
    isFetchingNextPage: pageParam !== 0 && isLoading,
    isLoading: pageParam === 0 && isLoading,
    fetchNextPage: async () => {
      await setPageParam((prev) => prev + 1);
      await mutateAsync();
    },
    refetch: async () => {
      await setAllBookmarks([]);
      await setPageParam(0);
      await mutateAsync();
    }
  };
};

export const usePostBookmark = (activeTab?: NewsfeedNavigation, filter?: IFilters) => {
  const queryClient = useQueryClient();
  const { deletePostBookmark, setNewBookmarks } = useAllUserBookmarks();
  return useMutation({
    mutationFn: (data: IPostBookmark) => bookmarkService.setPostBookmark(data),
    onSuccess: (data) => {
      if (!data) return;
      if (!data.data.isBookmarked) {
        deletePostBookmark({ postId: data.data.id, newProfileId: data.profileId, activeTab, filter });
      }
      if (data.data.isBookmarked && data.post) {
        setNewBookmarks([{ ...data.post, type: EBookmarkType.POST }], data.profileId, true);
      }
      setPostStatistics(data.data, queryClient);
    }
  });
};
