import React, { ReactNode } from 'react';
import * as Styled from './select-button.styled';

interface ISelectButtonComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  selected?: boolean;
  className?: string;
}

export const SelectButtonComponent = ({ children, selected, className, ...otherProps }: ISelectButtonComponentProps) => (
  <Styled.SelectButton selected={selected} className={className} {...otherProps}>
    {children}
  </Styled.SelectButton>
);
