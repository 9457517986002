import Image from 'next/image';
import styled from 'styled-components';

export const UploadImgsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background: rgba(236, 236, 236, 0.5);
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
`;

export const UploadImgContainerForX = styled.div`
  display: flex;
  position: relative;
`;

export const UploadImg = styled.img`
  max-width: 120px;
  max-height: 120px;
  left: 0;
  bottom: -12px;
  object-fit: cover;
  border-radius: 5px;
`;

export const DeleteImgContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  z-index: 2;
`;

export const DeleteImg = styled(Image)`
  cursor: pointer;
  width: 5px;
  height: 5px;
`;
