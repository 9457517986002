import React, { useEffect, useState } from 'react';
import Plus from '@styles/icons/submit-idea-plus.webp';
import { useRouter } from 'next/router';
import SummarizeIcon from '@styles/icons/summaraz-memo-icon.webp';
import { Stack } from '@mui/material';
import Image from 'next/image';
import toast from 'react-hot-toast';
import CreateMemoManual from '@/styles/icons/write-it.webp';
import MemoUpload from '@/styles/icons/memo-upload.webp';
import CloseIcon from '@/styles/icons/cross-in-circle.webp';
import { SelectionType } from '@/common/types/memo.type';
import * as Styled from './memo-first-section.styled';
import { FlexComponent } from '@/common/components/flex';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { UploadDocumentComponent } from '../submit-new-idea-form/components/memo-optional-details/memo-document.component';

interface IMemoFirstSection {
  isOpenOptions: boolean;
  setIsProcessing: any;
  setIsOpenOptions: (isOpen: boolean) => void;
  setIsSectionHidden: (isHidden: boolean) => void;
  handleFileChange: (file: File | undefined) => void;
  handleFileNameChange: (fileName: string) => void;
  selectedType?: undefined | SelectionType;
  setSelectedType: (arg: undefined | SelectionType) => void;
}

export const MemoFirstSection = ({
  isOpenOptions,
  setIsOpenOptions,
  setIsProcessing,
  handleFileNameChange,
  handleFileChange,
  setIsSectionHidden,
  selectedType,
  setSelectedType
}: IMemoFirstSection) => {
  const router = useRouter();
  const [isFileAtaching, setIsFileAtaching] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [isFileSending, setIsFileSending] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const onClickByNewMemo = () => {
    localStorage.removeItem('memoId');
    router.push(ROUTER_KEYS.MEMO_ADD);
  };

  const onClose = () => {
    setIsOpenOptions(false);
    setSelectedType(undefined);
    setFile(undefined);
    setFileName('');
    setIsFileAtaching(false);
  };

  const saveFileToIndexedDB = (fileData: File): Promise<void> =>
    new Promise((resolve, reject) => {
      const request = indexedDB.open('FileStorage', 1);

      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'fileName' });
        }
      };

      request.onsuccess = async () => {
        try {
          const arrayBuffer = await fileData.arrayBuffer();
          const db = request.result;
          const transaction = db.transaction('files', 'readwrite');
          const store = transaction.objectStore('files');
          store.put({ fileName: fileData.name, file: arrayBuffer, fileType: fileData.type });
          transaction.oncomplete = () => resolve();
          transaction.onerror = (event) => reject(event);
        } catch (err) {
          toast.error(err?.toString() ?? '');
        }
      };

      request.onerror = (event) => reject(event);
    });

  const onClickBySummarizeButton = async () => {
    if (selectedType === SelectionType.DOCUMENT_SECTION && fileName && file) {
      // setIsFileSending(true);
      try {
        await saveFileToIndexedDB(file);
        setIsFileSending(false);
      } catch (err) {
        toast.error(`Error: ${err?.toString()}`);
        setIsFileSending(false);
      }
      localStorage.setItem('fileName', fileName);
      localStorage.setItem('isFileAtaching', JSON.stringify(isFileAtaching));
      router.push(ROUTER_KEYS.MEMO_ADD_UPLOADED);
    }
  };

  useEffect(() => {
    if (isFileAtaching) {
      handleFileChange(file);
      handleFileNameChange(fileName);
    }
  }, [file, fileName, isFileAtaching, handleFileChange, handleFileNameChange]);

  return (
    <>
      {!isOpenOptions && (
        <Styled.Button isClosed type="button" onClick={() => setIsOpenOptions(true)} full isOpenOptions={isOpenOptions}>
          <Styled.ImagesContainer>
            <Styled.ImagesPlus src={Plus} alt="submit idea" />
          </Styled.ImagesContainer>
          Submit idea
        </Styled.Button>
      )}
      <Styled.Hidden src={Plus} alt="Hidden icon" />
      <Styled.Hidden src={MemoUpload} alt="Hidden icon" />
      <Styled.Hidden src={CreateMemoManual} alt="Hidden icon" />
      <Styled.Hidden src={SummarizeIcon} alt="Hidden icon" />
      {isOpenOptions && (
        <Styled.ContainerButtons>
          <FlexComponent gap="10px" width="100%">
            <Styled.Button width={136} type="button" inActive isOpenOptions={isOpenOptions}>
              <Styled.ImagesContainer>
                <Styled.ImagesPlus src={Plus} alt="submit idea" />
              </Styled.ImagesContainer>
              Submit idea
            </Styled.Button>
            <Styled.Button
              width={171}
              type="button"
              onClick={() => setSelectedType(SelectionType.DOCUMENT_SECTION)}
              isOpenOptions={isOpenOptions}
            >
              <Styled.ImagesContainer>
                <Styled.Images src={MemoUpload} alt="Create Memo Manual Icon" />
              </Styled.ImagesContainer>
              upload document
            </Styled.Button>
            <Styled.Button
              width={134}
              type="button"
              inActive={selectedType === SelectionType.DOCUMENT_SECTION}
              onClick={onClickByNewMemo}
              isOpenOptions={isOpenOptions}
            >
              <Styled.ImagesContainer>
                <Styled.Images src={CreateMemoManual} alt="Create Memo Manual Icon" />
              </Styled.ImagesContainer>
              write it now
            </Styled.Button>
          </FlexComponent>
          <Styled.CloseIcon onClick={onClose} src={CloseIcon} alt="close" />
        </Styled.ContainerButtons>
      )}
      {selectedType === SelectionType.DOCUMENT_SECTION && (
        <Styled.ContainerForAllSection>
          <Styled.UploadActions>
            <Styled.UploadsSummarizedText>
              Uploads are summarized with AI to provide a succinct read, which you can edit
            </Styled.UploadsSummarizedText>
            <Stack gap="4px">
              <UploadDocumentComponent
                isCurrent
                setDocumentName={setFileName}
                documentName={fileName}
                setDocumentUrl={(name: string) => {}}
                setFile={setFile}
                isFileForProcessing
              />
              <Styled.FileAtachingWrapper>
                <button type="button" onClick={() => setIsFileAtaching((prev) => !prev)}>
                  {isFileAtaching && <div />}
                </button>
                <span>attach this document to your published idea for others to view</span>
              </Styled.FileAtachingWrapper>
            </Stack>
          </Styled.UploadActions>
          <Styled.SummarizeButton
            onClick={onClickBySummarizeButton}
            disabled={(selectedType === SelectionType.DOCUMENT_SECTION && !fileName) || isFileSending}
          >
            <Image style={{ margin: '3px' }} src={SummarizeIcon} width={16} height={16} alt="Summarize Memo Button Icon" />
            Summarize with OpenAI’s GPT-4o
          </Styled.SummarizeButton>
        </Styled.ContainerForAllSection>
      )}
    </>
  );
};
