import { IFilters } from '../types/newsfeed.type';

export const getNewsfeedFilters = (currentFilter?: IFilters) => {
  if (!currentFilter) {
    return {
      include: [],
      exclude: [],
      includeTime: [],
      excludeTime: []
    };
  }
  const { source, regions, topics, 'job verticals': jobVerticals, sectors: investmentSectors, time } = currentFilter;

  const include: string[] = [];
  const exclude: string[] = [];
  const includeTime: string[] = [];
  const excludeTime: string[] = [];

  if (source?.length) {
    source.filter((s) => s.include).forEach((s) => include.push(s.id));
    source.filter((s) => !s.include).forEach((s) => exclude.push(s.id));
  }
  if (regions?.length) {
    regions.filter((r) => r.include).forEach((r) => include.push(r.id));
    regions.filter((r) => !r.include).forEach((r) => exclude.push(r.id));
  }
  if (topics?.length) {
    topics.filter((t) => t.include).forEach((t) => include.push(t.id));
    topics.filter((t) => !t.include).forEach((t) => exclude.push(t.id));
  }
  if (jobVerticals?.length) {
    jobVerticals.filter((jv) => jv.include).forEach((jv) => include.push(jv.id));
    jobVerticals.filter((jv) => !jv.include).forEach((jv) => exclude.push(jv.id));
  }
  if (investmentSectors?.length) {
    investmentSectors.filter((is) => is.include).forEach((is) => include.push(is.id));
    investmentSectors.filter((is) => !is.include).forEach((is) => exclude.push(is.id));
  }

  if (time?.length) {
    time.filter((t) => t.include).forEach((t) => includeTime.push(t.id));
    time.filter((t) => !t.include).forEach((t) => excludeTime.push(t.id));
  }

  return {
    include,
    exclude,
    includeTime,
    excludeTime
  };
};
