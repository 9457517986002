import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const Header = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: space-between;
`;
export const Description = styled.p`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled(Description)`
  font-weight: 400;
`;

export const NotificationMainTitle = styled.p`
  color: #525252;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
`;

export const NotificationSecuritiesBox = styled.p`
  margin-top: 6px;
`;

export const NotificationFundTitle = styled.p`
  color: #525252;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;

export const NotificationTitle = styled.p`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
`;

export const Username = styled.p`
  color: #9b9b9b;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.08;
`;

export const Link = styled.a`
  display: flex;
  gap: 5px;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  color: ${COLORS.darkerBlue};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.57;
  white-space: nowrap;
`;

export const Arrow = styled.img`
  width: 8px;
  height: 8px;
`;

export const EmptySecurity = styled.p`
  padding: 4.5px 6px;
  border-radius: 6px;
  border: 1px rgba(227, 231, 255, 0);
  background: #f9f9f9;
  color: #858585;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  height: 22px;
  width: 131px;
`;

export const OthersText = styled.p<{ ml?: number }>`
  color: #0018f1;
  margin-left: ${({ ml }) => (ml ? `-${ml}px` : 0)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  padding-top: 6.5px;
  padding-bottom: 2.5px;
`;
