import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS, FONTS } from '@/theme';
import { UserImageComponent } from '@/common/components/user-image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: fit-content;
  align-items: center;
`;

export const ImageWrapper = styled(UserImageComponent as AnyStyledComponent)<{ isReply?: boolean }>`
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  ${({ isReply }) => isReply && 'opacity: 0.7;'}
`;

export const InfoWrapper = styled.div<{ isReply?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 30px;
  width: fit-content;
  ${({ isReply }) => isReply && 'opacity: 0.5;'}
`;

export const InfoRow = styled.div<{ isMinMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  row-gap: 4px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.s};
  font-weight: ${WEIGHTS.medium};
  line-height: ${SIZES.s};
  color: ${COLORS.black};
  ${({ isMinMargin }) => isMinMargin && 'margin-top: 4px;'}
`;

export const NameWrapper = styled.div`
  cursor: pointer;
`;

export const DateWrapper = styled.div`
  font-size: ${SIZES.xs};
  line-height: ${SIZES.xs};
  font-weight: ${WEIGHTS.light};
  color: ${COLORS.voteNeutralText};
  display: flex;
  gap: 5px;
`;

export const RoleAndSectorsWrapper = styled.div<{ isNewDesign?: boolean }>`
  font-size: ${SIZES.s};
  font-weight: ${WEIGHTS.light};
  line-height: ${SIZES.s};
  color: ${COLORS.sectorsInPostTile};
  font-size: ${SIZES.s};
  display: flex;
  column-gap: 5px;
  width: fit-content;

  ${({ isNewDesign }) =>
    isNewDesign &&
    `font-family: ${FONTS.FAMILIES.robotoFlex}; 
    font-weight: ${FONTS.WEIGHTS.medium}; 
    color: ${COLORS.colorForRoleAndSectorsOnSearchPageMemobers};`}
`;

export const RoleAndSectorDot = styled.div<{ isNewDesign?: boolean }>`
  color: #ebebeb;
  padding: 0 1px;
  ${({ isNewDesign }) =>
    isNewDesign &&
    `color: #ababab;
    font-weight: ${FONTS.WEIGHTS.medium}; `}
`;

export const DotsImage = styled(Image as AnyStyledComponent)`
  cursor: pointer;
  height: 12px;
`;
