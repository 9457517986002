import styled from 'styled-components';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { boolean } from 'joi';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { FlexComponent } from '@/common/components/flex';

export const IdeaModalWrapper = styled(ModalComponent)`
  position: relative;
  min-width: 600px;
  max-width: 600px;
  overflow: hidden;
  min-height: calc(100vh - 30px);
  padding: 30px;
  padding-bottom: 0;
  padding-top: 35px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;

  flex-direction: column;
  color: #000;
  border-radius: 20px !important;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
  z-index: 15 !important;
  overflow-y: auto;
`;

export const DirectionText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  height: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 15px;
`;

export const CompanyText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 375px;
`;

export const RiskXText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const RiskText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1.2px;
`;

export const PriceTargetText = styled('p')`
  color: #2d2d2d;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;

export const PriceTargetPercentsText = styled('p')`
  color: #5e5e5e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;

export const SubSectorText = styled('p')`
  color: #939393;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const DotText = styled('p')`
  font-family: ${FAMILIES.robotoFlex};
  color: #ccc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const ThesisTitle = styled('p')`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const RatingTitle = styled(ThesisTitle)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const WrittenByText = styled('p')`
  color: #5e5e5e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const ThesisText = styled('p')`
  margin-top: 10px;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  /* max-height: 602px; */
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.3px;
  white-space: pre-wrap;
`;

export const Row = styled(FlexComponent)`
  gap: 10px;
`;

export const OptionalTitle = styled('p')`
  color: #434343;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  min-width: 92px;
`;

export const OptionalText = styled('p')`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const PublishText = styled('p')`
  padding-top: 5px;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 12px;
`;

export const EmptyTextValue = styled('p')`
  color: #e1e1e1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const BottomContainer = styled('div')<{ withoutPagination?: boolean }>`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ withoutPagination }) => (withoutPagination ? '90px' : '163px')};
  padding-top: 20px;
  padding-bottom: 25px;
  margin-top: auto;
  align-items: center;
  gap: 14px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: inset 0px 0px 10px 10px rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2.5px);
`;

export const ActionsBox = styled('div')<{ marginTop?: string }>`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || '4px'};
`;

export const FlexComponentTest = styled(FlexComponent)`
  height: 17px !important;
`;

export const OptionsButton = styled('button')<{ minWidth?: string; marginLeft?: string }>`
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 18px;
  font-family: ${FAMILIES.robotoFlex};
  color: #0018f1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 44px;
  line-height: 14px;
  border-radius: 15px;
  border: 1px solid #b6bcff;
  background: #f3f4ff;
  min-width: ${({ minWidth }) => minWidth || '165px'};
  max-width: 365px;
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  white-space: nowrap;

  cursor: pointer;
`;

export const AddReplyButtonButton = styled(motion.button)<{ minWidth?: string; marginLeft?: string }>`
  display: flex;

  align-items: center;
  gap: 10px;
  font-family: ${FAMILIES.robotoFlex};
  color: #0018f1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  height: 30px;
  border-radius: 20px;
  border: none;
  background: transparent;
  min-width: ${({ minWidth }) => minWidth || '165px'};
  max-width: 365px;
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  white-space: nowrap;

  cursor: pointer;
`;

export const Divider = styled('div')<{ minWidth?: string; marginLeft?: string }>`
  min-height: 1px;
  width: 540px;
  background: #f1f1f1;
  margin-top: 25px;
`;

export const ReplyTitle = styled(motion.p)`
  color: #2e2e2e;
  margin-top: 20px;
  margin-bottom: 17px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const ControlButton = styled('button')<{ justifyContent?: string }>`
  display: flex;
  min-width: 263px;
  padding: 20px 15px;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: 8px;
  border: none;
  border-radius: 20px;
  background: #f3f4ff;
  color: #0018f1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  cursor: pointer;

  :disabled {
    background: rgba(243, 244, 255, 0.5);
    color: rgba(0, 24, 241, 0.2);
    cursor: default;
  }
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

export const CloseButtonImage = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const ArrowIcon = styled(Image)<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;

  ${({ isDisabled }) =>
    isDisabled &&
    `
   opacity: 0.2;
  `};
`;

export const ArrowIconSmall = styled(Image)`
  width: 10px;
  height: 10px;
`;

export const StarIcon = styled(Image)`
  position: absolute;
  left: -19px;
  bottom: 3.5px;
  width: 14px;
  height: 14px;
`;
