import { useMutation, useQueryClient } from 'react-query';
import { profileService } from '@services';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { QUERY_KEYS, ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { ICurrentStatus, ICurrentStatusPayload, IPreviousEndeavoursPayload } from '@/common/types/profile.type';
import { useUpdateSetupStep } from '@/common/hooks/use-update-setup-step';
import { IAnswersPayload } from '../types/question.type';
import { getMonthDifference } from '../utils/month-diff';
import { setIsAnonUser, setIsNonFinanceUser } from './use-user';

export const useCurrentStatus = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.CURRENT_STATUS],
    mutationFn: () => profileService.getCurrentStatus()
  });

export const usePreviousEndeavours = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.PREVIOUS_ENDEAVOURS],
    mutationFn: () => profileService.getPreviousEndeavours()
  });

export const useAnswers = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.SENTIMENT],
    mutationFn: (data: IAnswersPayload) => profileService.getAnswers(data)
  });

export const useSetCurrentStatus = (
  setCurrentStatus: React.Dispatch<React.SetStateAction<Partial<ICurrentStatus> | undefined>>,
  isNonFinanceManagment: boolean
) => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  return useMutation({
    mutationKey: [QUERY_KEYS.CURRENT_STATUS],
    mutationFn: (currentStatus: ICurrentStatusPayload) => profileService.setCurrentStatus(currentStatus),
    onSuccess: (data) => {
      if (isNonFinanceManagment) {
        updateSetupStep(7);
      }
      if (data?.currentEmployer) {
        setCurrentStatus((prev) => ({
          ...prev,
          currentEmployer: data.currentEmployer,
          sectors: [...(prev?.sectors ?? []), ...(data.sectors ?? [])],
          regions: [...(prev?.regions ?? []), ...(data.regions ?? [])]
        }));
      }
    }
  });
};

export const useUpdateBio = () => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  return useMutation({
    mutationKey: [QUERY_KEYS.CURRENT_STATUS],
    mutationFn: (bio: string) => profileService.updateBio(bio),
    onSuccess: () => {
      updateSetupStep(2);
    }
  });
};

export const useUpdateFullname = () => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  return useMutation({
    mutationKey: [QUERY_KEYS.CURRENT_STATUS],
    mutationFn: (fullname: string) => profileService.updateFullname(fullname),
    onSuccess: () => {
      updateSetupStep(2);
    }
  });
};

export const useSetPreviousEndeavours = () => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  return useMutation({
    mutationKey: [QUERY_KEYS.PREVIOUS_ENDEAVOURS],
    mutationFn: (previousEndeavours: IPreviousEndeavoursPayload) => profileService.setPreviousEndeavours(previousEndeavours),
    onSuccess: (_, variables) => {
      if (!variables.isBack) {
        updateSetupStep(3);
      }
    }
  });
};

export const useSetAnswers = () => {
  const queryClient = useQueryClient();
  const setNewIsAnonUser = setIsAnonUser();
  const setNewIsNonFinanceUser = setIsNonFinanceUser();
  const { push } = useRouter();
  const monthsUntilEndOfYear = useMemo(() => {
    const today = new Date();
    const endOfYear = new Date(today.getFullYear(), 11, 31);
    return getMonthDifference(today, endOfYear);
  }, []);

  return useMutation({
    mutationKey: [QUERY_KEYS.SENTIMENT],
    mutationFn: (payload: ICurrentStatusPayload) => profileService.setAnswers(monthsUntilEndOfYear, payload),
    onSuccess: async (data) => {
      if (!data) return;
      setNewIsAnonUser(data.isAnon);
      setNewIsNonFinanceUser(data.isNonFinance);
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
      queryClient.setQueriesData([QUERY_KEYS.USER], data);
      await push(ROUTER_KEYS.PROFILE);
    }
  });
};
export const useSaveAnswers = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.SENTIMENT, 'save'],
    mutationFn: ({ answers }: { answers: string; isBack?: boolean }) => profileService.saveAnswers(answers)
  });
