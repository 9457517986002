import { IInvitationCreateItem, IInvitationResponse } from '../types/invitation.types';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class InvitationService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getInvitationInfo() {
    return this.get<IInvitationResponse>('invitation');
  }

  async createInvitations(data: IInvitationCreateItem[]) {
    return this.post<IInvitationResponse, { invitation: IInvitationCreateItem[] }>('invitation', { invitation: data });
  }
}

export const invitationService = new InvitationService();
