import { IVertical } from '@/common/types/current-employer.type';

export const month: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const roles1: string[] = ['Portfolio Manager', 'Senior Analyst', 'Analyst', 'Trader', 'Private', 'Intern', 'Other'];

export const roles2: string[] = ['Sector Head', 'Senior Analyst', 'Analyst', 'Associate', 'Intern', 'Other'];

export const roles3: string[] = ['Partner', 'Principal', 'Associate', 'Analyst', 'Intern', 'Other'];

export const roles4: string[] = ['Managing Director', 'Executive Director', 'Vice President', 'Associate', 'Analyst', 'Intern', 'Other'];

export const roles5: string[] = ['Other'];

export const verticals: IVertical[][] = [
  [
    { value: 'Equity Long / Short', roles: roles1 },
    { value: 'Equity Long Only', roles: roles1 },
    { value: 'Equity Short Only', roles: roles1 },
    { value: 'Equity Research', roles: roles2 },
    { value: 'Equity Sales / Trading', roles: roles4 }
  ],
  [
    { value: 'Venture Capital', roles: roles3 },
    { value: 'Private Equity', roles: roles3 },
    { value: 'Infrastructure', roles: roles3 },
    { value: 'Real Estate', roles: roles3 },
    { value: 'Agriculture', roles: roles3 }
  ],
  [
    { value: 'IG Credit', roles: roles1 },
    { value: 'HY Credit', roles: roles1 },
    { value: 'Distressed Debt', roles: roles1 }
  ],
  [
    { value: 'Multi-Strategy', roles: roles1 },
    { value: 'Special Situations', roles: roles1 }
  ],
  [
    { value: 'Macro', roles: roles1 },
    { value: 'Commodities', roles: roles1 },
    { value: 'Crypto', roles: roles1 }
  ],
  [
    { value: 'Family Office', roles: roles1 },
    { value: 'Allocator', roles: roles3 }
  ],
  [
    { value: 'Investment Banking', roles: roles4 },
    { value: 'Consulting', roles: roles4 }
  ],
  [{ value: 'Financial Journalist', roles: roles5 }],
  [{ value: 'Other', roles: roles5 }]
];
