import { IPost } from './post.type';

export interface ISearchObject {
  id: string;
  text: string;
  ticker?: string;
}

export interface ISearchInModalOption {
  [key: string]: IHitStats & { hits: ISearchObject[] };
}

export interface ISearchFilter {
  isClicked: boolean;
  searchString?: string;
  typeFilter?: string[];
}

export interface IClickedValueFilter {
  type: string;
  query: string;
  queryId: string;
  isClickOnMention: boolean;
}

export interface IClickedSeeMore {
  type?: string;
  queryId?: string;

  query: string;
  index: string;
  page: number;
  hitsPerPage?: number;
}

export enum SearchOutputType {
  POST = 'posts',
  USERS = 'users',
  POLLS = 'polls',
  OTHER = 'other'
}

export interface IPostInSearch extends IPost {
  type: SearchOutputType.POST | SearchOutputType.POLLS;
}

export interface IProfileResult {
  id: string;
  imageUrl?: string;
  fullName: string;
  role: string;
  vertical: string;
  isImageHidden: boolean;
}

export interface IProfileOutput extends IProfileResult {
  type: SearchOutputType.USERS;
}

export interface IOtherElementResult {
  id: string;
  text: string;
  ticker?: string;
}

export interface IOtherElementOutput extends IOtherElementResult {
  type: SearchOutputType.OTHER;
}

export interface ISearchResult {
  [key: string]: IPost[] | IProfileResult[] | IOtherElementResult[];
}

export interface IHitStats {
  page: number;
  nbHits: number;
  hitsPerPage: number;
}

export interface IPagedResponse extends IHitStats {
  index: string;
  hits: (IPostInSearch | IProfileOutput | IOtherElementOutput)[];
}

export interface IUserSearchResponse {
  users: {
    page: number;
    nbHits: number;
    hitsPerPage: number;
    hits: IProfileOutput[];
    index: string;
  };
}

export interface ISearchOutput {
  [key: string]: IHitStats & {
    hits: (IPostInSearch | IProfileOutput | IOtherElementOutput)[];
  };
}

export interface IClickedValue {
  id: string;
  value: string;
  ticker?: string;
}

export interface IClickedProps {
  valueData: string;
  type: string;
  clickedId: string;
  clickedValue: string;
  clickedTicker?: string;
}
