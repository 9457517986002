import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import DecorIcon from '@styles/icons/add-post-moda-decor-icon.webp';
import Image from 'next/image';
import * as Styled from './create-modal.styled';
import { CreateModalType } from './modal-type.const';
import { CoverageComponent, DebateComponent, PostComponent, ReviewComponent, InsightComponent } from './components';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';
import OtherPlus from '@/styles/icons/plus-create-modal.png';

import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { PollComponent } from './components/poll/poll.component';

interface IProps {
  closeHandler: () => void;
  open: boolean;
  modalType: CreateModalType;
}

const ModalTypes = [
  { name: 'post', type: CreateModalType.POST },
  { name: 'poll', type: CreateModalType.POLL },
  { name: 'debate', type: CreateModalType.DEBATE },
  { name: 'coverage', type: CreateModalType.COVERAGE },
  { name: 'insight', type: CreateModalType.INSIGHT },
  { name: 'review', type: CreateModalType.REVIEW }
];

export const CreateModalComponent = ({ closeHandler, open, modalType }: IProps) => {
  const client = useQueryClient();
  const [type, setType] = useState(modalType);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);

  const onSelectItemClick = (newType: CreateModalType) => {
    if (type === newType) return;
    setType(newType);
  };

  const onShowMoreClick = () => {
    setShowAllItems(true);
  };

  useEffect(() => {
    if (open) {
      setType(modalType);
      client.setQueryData(QUERY_KEYS.HIDE_RIGHT_TOOLBAR, true);
    } else {
      setIsScrollDisabled(false);
      client.setQueryData(QUERY_KEYS.HIDE_RIGHT_TOOLBAR, false);
    }
    client.invalidateQueries([QUERY_KEYS.GET_HIDE_RIGHT_TOOLBAR]);
  }, [open]);

  const modalTypesLength = ModalTypes.length;

  useEffect(() => {
    if (!open) {
      setShowAllItems(false);
    }
  }, [open]);

  return (
    <Styled.CreateModalWrapper
      open={open}
      closeHandler={closeHandler}
      childTopPostion={15}
      isScrollDisabled={isScrollDisabled}
      isCreateModal
    >
      <Styled.HeaderModalContainer>
        <Styled.HeaderModalTabs>
          <Image src={DecorIcon} width={20} height={20} alt="decor-icon" />
          <Styled.SelectModalContainer>
            {ModalTypes.slice(0, showAllItems ? modalTypesLength : modalTypesLength - 2).map((it) => (
              <Styled.SelectItem key={it.name} isSelected={it.type === type} onClick={() => onSelectItemClick(it.type)}>
                {it.name}
              </Styled.SelectItem>
            ))}
            {!showAllItems && (
              <Styled.SelectItem other onClick={onShowMoreClick}>
                other <Image src={OtherPlus} width={10} height={10} alt="others" />
              </Styled.SelectItem>
            )}
          </Styled.SelectModalContainer>
        </Styled.HeaderModalTabs>
        <Styled.CloseButton onClick={closeHandler}>
          <Styled.CloseButtonImage src={CloseIcon} alt="close-icon" />
        </Styled.CloseButton>
      </Styled.HeaderModalContainer>

      {type === CreateModalType.POST && <PostComponent setIsScrollDisabled={setIsScrollDisabled} open={open} closeHandler={closeHandler} />}
      {type === CreateModalType.DEBATE && <DebateComponent open={open} closeHandler={closeHandler} />}
      {type === CreateModalType.COVERAGE && (
        <CoverageComponent setIsScrollDisabled={setIsScrollDisabled} closeHandler={closeHandler} open={open} idea={null} />
      )}
      {type === CreateModalType.REVIEW && (
        <ReviewComponent setIsScrollDisabled={setIsScrollDisabled} closeHandler={closeHandler} open={open} />
      )}
      {type === CreateModalType.POLL && <PollComponent closeHandler={closeHandler} />}
      {type === CreateModalType.INSIGHT && <InsightComponent closeHandler={closeHandler} />}
    </Styled.CreateModalWrapper>
  );
};
