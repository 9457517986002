import Joi from 'joi';
import { EMAIL_MATCH } from '@/common/components/auth';

export const everyoneAndUsRealFormSchema = Joi.object().keys({
  email: Joi.string().regex(EMAIL_MATCH).required().messages({
    'string.pattern.base': 'Invalid entry',
    'any.required': 'Email is required',
    'string.empty': 'Invalid entry'
  }),
  password: Joi.string()
    .min(8)
    .max(20)
    .regex(/^[a-zA-Z0-9!£$#]+$/)
    .required()
    .messages({
      'string.min': 'Invalid entry',
      'string.max': 'Invalid entry',
      'string.pattern.base': 'Invalid entry',
      'string.empty': 'Invalid entry'
    })
});

export const everyoneAndUsFormSchema = everyoneAndUsRealFormSchema.keys({
  username: Joi.string()
    .min(5)
    .max(25)
    .regex(/^[a-zA-Z0-9-]+$/)
    .required()
    .messages({
      'string.min': 'Invalid entry',
      'string.max': 'Invalid entry',
      'string.pattern.base': 'Invalid entry',
      'string.empty': 'Invalid entry'
    })
});

export const toEveryoneFromSchema = Joi.object({
  username: Joi.string()
    .min(5)
    .max(25)
    .regex(/^[a-zA-Z0-9-]+$/)
    .required()
    .messages({
      'string.min': 'Invalid entry',
      'string.max': 'Invalid entry',
      'string.pattern.base': 'Invalid entry',
      'string.empty': 'Invalid entry'
    })
});
