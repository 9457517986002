import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import React from 'react';
import { anonService } from '../services/anon.service';
import {
  ICheckReservedUsernamePayload,
  ICreateAnonUserPayload,
  ICreateSimplifiedAnonUserPayload,
  IEmailVerifyConfirmPayload,
  IEmailVerifySendPayload,
  ILinkAnonToRealPayload,
  IReservedUsernameCodeConfirmPayload,
  IReservetionProps
} from '../types/anon.types';
import { QUERY_KEYS, ROUTER_KEYS } from '../consts/app-keys.const';
import { useNotifications } from './use-notification';
import { setIsAnonUser } from './use-user';
import { useAuth } from './use-auth';

export const useEmailVerifySend = (onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: IEmailVerifySendPayload) => {
      try {
        const response = await anonService.emailVerifySend(data);
        return response;
      } catch (error) {
        if (onError) {
          onError(error);
        }
        throw error;
      }
    },
    onError
  });

export const useEmailVerifyConfirm = (onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: IEmailVerifyConfirmPayload) => {
      const response = await anonService.emailVerifyConfirm(data);
      return response;
    },
    onError
  });

export const useCreateAnonUser = (onSuccess?: () => void) => {
  const { push, reload } = useRouter();
  const queryClient = useQueryClient();
  const changeAnonStatus = setIsAnonUser();
  return useMutation({
    mutationFn: async (data: ICreateAnonUserPayload) => {
      const response = await anonService.createAnonUser(data);
      if (response?.accessToken) {
        queryClient.clear();
        changeAnonStatus(response.isAnon);
        await push(ROUTER_KEYS.HOME);
        reload();
        queryClient.setQueryData([QUERY_KEYS.USER], response);
      }
    },
    onSuccess
  });
};

export const useCreateSimplifiedAnonUser = (onSuccess?: () => void) => {
  const { push, reload } = useRouter();
  const queryClient = useQueryClient();
  const changeAnonStatus = setIsAnonUser();
  return useMutation({
    mutationFn: async (data: ICreateSimplifiedAnonUserPayload) => {
      const response = await anonService.createSimplifiedAnonUser(data);
      if (response?.accessToken) {
        queryClient.clear();
        changeAnonStatus(response.isAnon);
        await push(ROUTER_KEYS.HOME);
        reload();
        queryClient.setQueryData([QUERY_KEYS.USER], response);
      }
    },
    onSuccess
  });
};

export const useFinishAnonOnboarding = () => {
  const { push, reload } = useRouter();
  return useMutation({
    mutationFn: async () => {
      const response = await anonService.finishAnonOnboarding();
      if (response) {
        await push(ROUTER_KEYS.HOME);
        reload();
      }
    }
  });
};

export const useFinishAnonPull = () => {
  const { push } = useRouter();
  return useMutation({
    mutationFn: async () => {
      const response = await anonService.finishAnonPull();
      if (response) {
        localStorage.setItem('anonVerify', 'true');
        await push(ROUTER_KEYS.ONBOARDING);
      }
    }
  });
};

export const useCreateRealFromAnon = () => {
  const queryClient = useQueryClient();
  const { push, reload } = useRouter();
  const changeAnonStatus = setIsAnonUser();
  return useMutation({
    mutationFn: async (data: Omit<ICreateAnonUserPayload, 'username' | 'vertical' | 'subSectorId'>) => {
      const response = await anonService.useCreateRealFromAnon(data);
      if (response?.accessToken) {
        queryClient.clear();
        changeAnonStatus(response.isAnon);
        await push(ROUTER_KEYS.ONBOARDING_WELCOME);
        reload();
        queryClient.setQueryData([QUERY_KEYS.USER], response);
      }
    }
  });
};

export const useCreateSimplifiedRealFromAnon = () => {
  const queryClient = useQueryClient();
  const { push, reload } = useRouter();
  const changeAnonStatus = setIsAnonUser();

  return useMutation({
    mutationFn: async () => {
      const response = await anonService.useCreateSimplifiedRealFromAnon();
      if (response?.accessToken) {
        queryClient.clear();
        changeAnonStatus(response.isAnon);
        await push(ROUTER_KEYS.ONBOARDING_WELCOME);
        reload();
        queryClient.setQueryData([QUERY_KEYS.USER], response);
      }
    }
  });
};

export const useCheckUsernameReserved = () =>
  useMutation({
    mutationFn: async (data: ICheckReservedUsernamePayload) => anonService.checkReservedUsername(data)
  });

export const useCheckReservetion = ({
  setIsUsernameReserved,
  setIsUsernameConfirmed,
  username,
  usernameError
}: IReservetionProps & { username: string; usernameError: string | undefined }) => {
  const {
    data: usernameReservedCheckStatus,
    mutateAsync: checkReservedUsername,
    reset: checkReservedUsernameReset
  } = useCheckUsernameReserved();

  React.useEffect(() => {
    const usernameCheckDelayTimer = setTimeout(() => {
      if (usernameError) return;
      checkReservedUsername({ username });
    }, 500);
    return () => {
      checkReservedUsernameReset();
      clearTimeout(usernameCheckDelayTimer);
    };
  }, [username]);

  React.useEffect(() => {
    if (usernameReservedCheckStatus) {
      setIsUsernameReserved(true);
      setIsUsernameConfirmed(false);
    } else {
      setIsUsernameConfirmed(true);
      setIsUsernameReserved(false);
    }
  }, [usernameReservedCheckStatus]);
};

export const useCofirmUsernameReservedCode = () =>
  useMutation({
    mutationFn: async (data: IReservedUsernameCodeConfirmPayload) => anonService.reservedUsernameCodeConfirm(data)
  });

export const useAnonVerifySend = (onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: IEmailVerifySendPayload) => {
      const response = await anonService.anonVerifySend(data);
      return response;
    },
    onError
  });

export const useAnonVerifyConfirm = (onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: IEmailVerifyConfirmPayload) => {
      const response = await anonService.anonVerifyConfirm(data);
      return response;
    },
    onError
  });

export const useAnonLinkReal = (onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: ILinkAnonToRealPayload) => {
      const response = await anonService.linkAnonToReal(data);
      return response;
    },
    onError
  });
