import React, { KeyboardEvent, ReactElement, useEffect, useRef } from 'react';
import Image from 'next/image';
import * as Styled from './search-input.styled';
import SearchIcon from '@/styles/icons/idea-search-icon.webp';
import CmdIcon from '@/styles/icons/input-cmd.webp';
import CmdIconAnon from '@/styles/icons/input-cmd-anon.png';
import { useDetectOS } from '@/common/hooks';
import { FlexComponent } from '@/common/components/flex';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  mb?: number;
  withSoonText?: boolean;
  isAnon?: boolean;
}

export const SearchInputComponent = ({ mb, withSoonText, placeholder, value, onChange, isAnon }: IProps): ReactElement => {
  const os = useDetectOS();
  const isWindows = os === 'Windows';
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent | globalThis.KeyboardEvent): void => {
    const cmdOrCtrlPressed = isWindows ? event.ctrlKey : event.metaKey;

    if (cmdOrCtrlPressed && event.key.toLowerCase() === 'k') {
      event.preventDefault();
      inputRef.current?.focus();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onChange(value);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isWindows]);

  return (
    <Styled.SearchWrapper isAnon={isAnon} mb={mb}>
      <Styled.IdeaSearchInputImgContainer isAnon={isAnon}>
        <Image src={SearchIcon} alt="search" width={14} height={14} />
      </Styled.IdeaSearchInputImgContainer>
      <Styled.SearchInput
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => onChange(e.target.value)}
      />
      {withSoonText && <Styled.SoonText>(coming soon)</Styled.SoonText>}
      <FlexComponent gap="2px" alignItems="center" paddingRight="4px">
        {!isWindows && <Styled.CmdIcon src={isAnon ? CmdIconAnon : CmdIcon} alt="cmd" />}
        <FlexComponent gap="1px">
          {isWindows && <Styled.KeyText isAnon={isAnon}>ctrl</Styled.KeyText>}
          <Styled.KeyText isAnon={isAnon}>+</Styled.KeyText>
          <Styled.KeyText isAnon={isAnon}>K</Styled.KeyText>
        </FlexComponent>
      </FlexComponent>
    </Styled.SearchWrapper>
  );
};
