import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { StackProps } from '@mui/material';
import { FlexComponent } from '@/common/components/flex';
import * as Styled from './unit-select.styled';
import { unitOptions, EUnits } from '@/common/types/poll.types';

interface IProps {
  selectedUnit: EUnits | string;
  setSelectedUnit: Dispatch<SetStateAction<EUnits | string>>;
  mt?: StackProps['mt'];
}

export const UnitSelectComponent = ({ selectedUnit, setSelectedUnit, mt }: IProps): ReactElement => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>, unit: EUnits | string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSelectedUnit(unit);
    }
  };

  return (
    <FlexComponent gap="5px" mt={mt} alignItems="center">
      <Styled.UnitText>Unit:</Styled.UnitText>
      {unitOptions.map((item) => (
        <Styled.Item
          key={item.value}
          onClick={() => setSelectedUnit(item.value)}
          onKeyDown={(event) => handleKeyDown(event, item.value)}
          isActive={selectedUnit === item.value}
          tabIndex={0}
        >
          {item.label}
        </Styled.Item>
      ))}
    </FlexComponent>
  );
};
