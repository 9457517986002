import styled from 'styled-components';
import { FONTS } from '@/theme';

export const ErrorWrapper = styled.div`
  width: 100vw;
  /* max-width: 1200px; */
  height: 140px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const ErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
  padding: 15px 20px;
  border-radius: 15px;
  background: rgba(223, 0, 0, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(163, 163, 163, 0.25);
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  color: #df0000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  line-height: ${FONTS.SIZES.s};
  & > p {
    font-weight: ${FONTS.WEIGHTS.medium};
  }
  & > span {
    font-weight: ${FONTS.WEIGHTS.light};
  }
`;
