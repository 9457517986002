import React from 'react';
import * as Styled from '../information-block-defalut.styled';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';

interface IProps {
  isReal?: boolean;
  everyoneAndUs?: string;
  everyone?: string;
  handleClose?: () => void;
}

export const ActivateAnonInformationBlockComponent: React.FC<IProps> = ({ isReal, everyoneAndUs, everyone, handleClose }) => (
  <Styled.InformationBlockWrapper formType={everyoneAndUs || everyone}>
    <Styled.InformationBlockHeader>
      <Styled.InformationBlockTitle>{isReal ? 'Create your Real Account' : 'Create your Anonymous Account'}</Styled.InformationBlockTitle>
      <Styled.CloseIcon src={CloseIcon} alt="close-icon" onClick={handleClose} />
    </Styled.InformationBlockHeader>
    {everyoneAndUs ? (
      <Styled.AnonymityActionsOptions>
        <Styled.AnonymityActionsOption>Option 2</Styled.AnonymityActionsOption>
        <Styled.AnonymityActionsOptionText>Villan can never identify the owner of an Anonymous Account</Styled.AnonymityActionsOptionText>
      </Styled.AnonymityActionsOptions>
    ) : everyone ? (
      <Styled.AnonymityActionsOptions>
        <Styled.AnonymityActionsOption>Option 1</Styled.AnonymityActionsOption>
        <Styled.AnonymityActionsOptionText>Only Villan could identify the owner of an Anonymous Account</Styled.AnonymityActionsOptionText>
      </Styled.AnonymityActionsOptions>
    ) : (
      <Styled.InformationBlockList>
        <Styled.InformationBlockListItem>
         {!isReal && `Engage in ways that may not be comfortable with your real identity`} 
        </Styled.InformationBlockListItem>
        <Styled.InformationBlockListItem>Your Anonymous Account will not share data with your Real Account</Styled.InformationBlockListItem>
      </Styled.InformationBlockList>
    )}
  </Styled.InformationBlockWrapper>
);
