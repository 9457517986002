import React from 'react';
import { useRouter } from 'next/router';
import * as Styled from './other-element-result.styled';

interface IProp {
  text: string;
  itemType?: string;
  onClick: () => void;
  ticker?: string;
  searchText?: string;
}

export const HighlightedText = ({ text, searchText }: { text: string; searchText?: string }) => {
  const { pathname } = useRouter();
  if (!searchText) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${searchText})`, 'gi'));

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          className={
            part.toLowerCase() === searchText.toLowerCase() ? (pathname === '/messages' ? 'highlightedMessage' : 'highlighted') : ''
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const OtherElementResult = ({ text, onClick, ticker, searchText, itemType }: IProp) => (
  <Styled.Wrapper onClick={onClick}>
    <Styled.TextWrapper>
      <span>
        <HighlightedText text={text} searchText={searchText} />
      </span>
      {!!ticker && <Styled.TickerWrapper>{itemType === 'privates' ? 'private' : ticker}</Styled.TickerWrapper>}
    </Styled.TextWrapper>
  </Styled.Wrapper>
);
