import styled from 'styled-components';
import { FONTS } from '@/theme';

export const PublishDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const YearRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

export const PublishDateWrapperDot = styled.p`
  color: #969696;
  width: 10px;
  height: 14px;
  text-align: center;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

export const Item = styled.button<{ isDisabled: boolean; isActive: boolean }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: ${({ isActive, isDisabled }) => (isDisabled ? '#F6F6F6' : isActive ? '#EFF1FF' : '#F6F6F6')};
  color: ${({ isActive, isDisabled }) => (isDisabled ? '#969696' : isActive ? '#0016D8' : '#969696')};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
`;
