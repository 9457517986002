import { getBase64 } from '../components/onboard/helpers';
import { OnDropErrorEnum } from '../types/profile.type';
import { dataURLtoFile } from './data-url-to-file';

export const handleDrop = (
  event: React.DragEvent<HTMLDivElement>,
  setItem: (item: string | ArrayBuffer | null) => void,
  setError: (item: null | OnDropErrorEnum) => void
) => {
  event.preventDefault();

  const { files } = event.dataTransfer;
  const URL = event.dataTransfer.getData('URL');
  const images: File[] = [];
  for (let i = 0; i < files.length; i += 1) {
    images.push(files[i]);
  }
  if (URL && URL.length > 0 && images.length === 0) {
    const file = dataURLtoFile(URL, 'file');
    if (file) {
      images.push(file);
    } else {
      setError(OnDropErrorEnum.INCORECT_TYPE);
    }
  }
  if (images[0]) {
    const file = images[0];
    if (!file) return;
    if (
      file.type !== 'image/jpg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/svg' &&
      file.type !== 'image/avif' &&
      file.type !== 'image/bmp' &&
      file.type !== 'image/webp'
    ) {
      setError(OnDropErrorEnum.INCORECT_TYPE);
      return;
    }
    if (file.size > 40 * 1024 * 1024) {
      setError(OnDropErrorEnum.MAX_SIZE);
      return;
    }

    const imageBase64 = getBase64(file);
    imageBase64.then((result) => {
      setItem(result);
    });
  }
};
