import { IPostStatResponseItem, IUpdateReaction } from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class ClapService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async clap(postId: string) {
    return this.post<IUpdateReaction, { postId: string }>('clap', { postId });
  }

  async getPostClaps(postId: string, take: number, page: number) {
    return this.get<IPostStatResponseItem>(`clap/post-claps/${postId}?take=${take ?? 10}&page=${page}`);
  }
}

export const clapService = new ClapService();
