import styled from 'styled-components';
import { COLORS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const Wrapper = styled.div`
  width: 454px;
  padding: 0 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  font-weight: ${WEIGHTS.light};
  border-radius: 10px 10px 0px 10px;
`;

export const Description = styled.div`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-weight: ${WEIGHTS.light};
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS.headTextColorInModal};
  margin-top: 10px;
`;

export const Row = styled.div`
  flex-wrap: wrap;
  max-width: 600px;
  color: #4b4b4b;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 14px;
  gap: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const Item = styled.button`
  white-space: nowrap;
  cursor: pointer;
  border: none;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #efefef;

  background: #fff;
`;

export const ItemText = styled.span`
  // max-width: 175px;
  // white-space: nowrap;
  color: #3f3f3f;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 14px;
`;

export const Input = styled.input<{ variant?: string }>`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 20px;

  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background: #fbfbfb;

  padding: 10px;
  width: 400px;

  ::placeholder {
    color: #000;
    opacity: 1;
  }

  :focus-visible {
    outline: 0;
  }

  ${({ variant }) =>
    variant === 'small' &&
    `
    height: 34px;
    display: flex;
    width: 315px;
    padding: 7px 12px;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;
    width: 312px;
  `}

  ${({ variant }) =>
    variant === 'poll-modal' &&
    `
    display: flex;
width: 505px;
height:40px;
padding: 10px;
align-items: center;
gap: 7px;
  ::placeholder {
    color: #969696;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  }
  `}
`;

export const Dropdown = styled.div<{ variant?: string }>`
  width: max-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4px;
  z-index: 10000;
  background-color: #fdfdfd;
  gap: 5px;
  padding: 5px;
  left: 0px;
  border: 1px solid #f8f8f8;
  border-radius: 10px;

  ${({ variant }) =>
    variant === 'small' &&
    `
    width: 325px;
    left: -6.5px;
    background-color: #ffffff;
  `}
`;

export const DropdownItem = styled.button<{ variant?: string; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: fit-content;
  text-align: start;
  padding: 5px 12px;
  background-color: #fafafa;
  border: none;
  height: 30px;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.light};
  font-size: 14px;
  color: #3f3f3f;
  border-radius: 5px;
  line-height: 20px;

  ${({ variant }) =>
    variant === 'small' &&
    `
  width: 100%;
  max-width: 325px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`;
