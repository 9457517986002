import styled, { css } from 'styled-components';

export const BoxAroundSection = styled.div<{ isAnon?: boolean }>`
  border-radius: 10px;
  background: #fff;

  padding: 15px 20px 25px;

  width: 100%;
  ${({ isAnon }) =>
    isAnon &&
    css`
      width: 100%;
      padding: 15px 20px 25px;
    `}
`;

export const BoxAroundProfileSection = styled.div<{ isAnon?: boolean }>`
  width: 100%;
  ${({ isAnon }) =>
    isAnon &&
    css`
      width: calc(100% - 2px);
      border: 1px solid #9d21ff;
      padding: 15px 30px 25px;
    `}
`;

export const LeftBoxAroundSection = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 18px 20px 30px;
`;

export const RightBoxAroundSection = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 18px 30px 25px;
`;

export const HeaderTitleUnderlineWrapper = styled.div<{ isAnon?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: fit-content;
  padding: 0 0 4.5px 0;
  margin-bottom: 4px;
  border-bottom: solid 1px #f6f6f6;
  ${({ isAnon }) => isAnon && 'border-bottom: solid 1px #4F4F4F;'}
`;
