import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQueryPollSentimentSwings } from '@/common/hooks';
import { PostRowComponent } from '@/common/components/profile/components/post-row';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import * as Styled from './poll-list.style';
import { IPollFilterPayload } from '@/common/types/poll.types';

interface IProp {
  setRefetch: React.Dispatch<React.SetStateAction<(() => {}) | undefined>>;
  quotePostHandler: (newPostId: string) => void;
  openReplyModal: (newPostId: string, searchId: string, isReplyOpen?: boolean) => void;
  filters: Omit<IPollFilterPayload, 'limit' | 'page'>;
  userProfileId?: string;
  isLoading?: boolean;
}

export const PollListComponent: React.FC<IProp> = ({
  setRefetch,
  quotePostHandler,
  openReplyModal,
  filters,
  userProfileId,
  isLoading: isLoadingFilters
}) => {
  const { polls, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage, refetch, isRefetching } = useQueryPollSentimentSwings(filters);

  const { ref: memoLoadingInViewRef, inView: isMemoLoadingInView } = useInView({
    threshold: 0.1
  });

  useEffect(() => {
    setRefetch(refetch);
  }, []);

  useEffect(() => {
    if (!isMemoLoadingInView) return;
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [isMemoLoadingInView]);

  return (
    <>
      <Styled.ListWrapper>
        {isRefetching || isLoading ? (
          <LoaderWrapper heightForMinus={238}>
            <Loader />
          </LoaderWrapper>
        ) : (
          polls.map((item, index) => {
            if (item?.deletedAt && !item?.tread?.replies?.length) {
              return null;
            }

            return (
              <PostRowComponent
                isHome
                quotePostHandler={quotePostHandler}
                key={`post-row-${item.id}`}
                userProfileId={userProfileId}
                post={item}
                openReplyModal={openReplyModal}
                isLast={!hasNextPage && index === polls.length - 1}
              />
            );
          })
        )}
      </Styled.ListWrapper>
      {!polls.length && !isLoading && !isRefetching && (
        <Styled.EmptyListText>
          There are no polls matching your filter (see right) <br />
          Use the button above to create one or search the insights page for more content
        </Styled.EmptyListText>
      )}
      <Styled.PaginationIntersection
        ref={memoLoadingInViewRef}
        style={{ display: isFetchingNextPage || isLoading || isRefetching ? 'none' : 'block' }}
      />
    </>
  );
};
