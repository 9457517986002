import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const IndustryDataContainer = styled.div`
    display: flex;
`

export const ModalDivider = styled.div`
    width: 490px;
    height: 1px;
    background: #EEE;
    margin: 25px 0;
`

export const IndustryDataSection = styled.div<{isCableTelecoms?: boolean}>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-width: 450px;
  justify-content: start;
  ${({ isCableTelecoms }) => isCableTelecoms && 'flex-wrap: wrap;'}
`;

export const AnonymityDataTitle = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 20px;

  p {
    padding-right: 3px;
  }
`;

export const AnonymityDataSubtitles = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AnonymityDataSubtitle = styled.div`
  color: #5a5a5a;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.s};
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: ${SIZES.s};
  padding-top: 5px;
  padding-bottom: 3px;
  letter-spacing: 0.1px;
`;

export const AnonymityValidateInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AnonymityForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BaseInput = styled.input`
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 10px;
  border: 1px #f5f5f5;
  background: #fbfbfb;
  padding: 10px;
  color: #000;
  letter-spacing: 0.42px;
  font-size: ${SIZES.m};
  line-height: ${SIZES.xxl};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.medium};

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    letter-spacing: 0.1px;
    font-weight: ${WEIGHTS.light};
    color: #6f6f6f;
  }
`;

export const AnonymityDataInput = styled(BaseInput)``;

export const AnonymityDataInputPassword = styled(BaseInput)`
  letter-spacing: 2.1px;

  &::placeholder {
    letter-spacing: 0.42px;
`;

export const ErrorMessage = styled.div`
  padding: 0 10px;
  color: ${COLORS.anonActiveModalInputErrorText};
  font-size: ${SIZES.s};
  font-family: ${FAMILIES.robotoFlex};
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 12px;
`;
export const EveryoneFormContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const AnonymityFormContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const AnonymityDataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const AnonymityDataIndustrySection = styled(AnonymityDataSection)`
    gap: 8px;
`

export const AnonymityDataEmailTitle = styled(AnonymityDataTitle)`
  gap: 10px;
`;

export const OtpInputWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  div {
    input {
      background-color: #f6f6f6;
    }
  }
`;
