import Image from 'next/image';
import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const CollapsedDisplayWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: start;
  align-items: start;
`;

export const IconWrapper = styled(Image)`
  margin: 3px 8px;
  width: 14px;
  height: 14px;
`;

export const Mention = styled.span`
  width: 100%;
  color: ${COLORS.mention};
  // font-style: italic;
  cursor: pointer;
`;

export const RepliedPostInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const RepliedPostTextWrapper = styled.div<{ isCollapsed: boolean }>`
  margin-top: 4px;
  color: ${COLORS.repliedPostText};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.l};
  word-break: break-all;
  & > button {
    margin-left: 5px;
    cursor: pointer;
    border: none;
    background: transparent;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.s};
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.l};
    color: ${COLORS.repliedPostTextMoreButton};
  }
`;

export const RepliedPostImage = styled.img`
  width: 100%;
  border-radius: 5px;
`;
