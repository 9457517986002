import styled, { AnyStyledComponent } from 'styled-components';
import { Modal } from '@mui/material';
import Image from 'next/image';

export const ModalWrapper = styled(Modal as AnyStyledComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: rgba(238, 238, 238, 0.25);
  width: calc(100vw - 184px);
  max-width: 1000px;
  height: 100vh;
  max-height: 1000px;
  backdrop-filter: blur(2px);
  position: relative;
  aligh-items: center;
  &:focus-visible {
    outline: none;
  }
`;

export const ModalTitle = styled.span`
  z-index: 2;
  backdrop-filter: blur(0px);
  position: absolute;
  top: 42px;
  color: #000;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const ActionButton = styled.button<{ right?: number; left?: number; isDisabled: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: ${({ isDisabled }) => (isDisabled ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.75)')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 20;
`;

export const LeftActionButton = styled(ActionButton as AnyStyledComponent)`
  left: ${({ left }) => left ?? 0}px;
`;

export const RightActionButton = styled(ActionButton as AnyStyledComponent)`
  right: ${({ right }) => right ?? 0}px;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  max-width: 816px;
  max-height: 816px;
  width: 100%;
  height: calc(100vh - 184px);
`;

export const ImageWrapper = styled.img<{ imgDisplay: boolean }>`
  display: ${({ imgDisplay }) => (imgDisplay ? 'block' : 'none')};
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
`;

export const ButtonImage = styled(Image as AnyStyledComponent)`
  width: 14px;
  height: 14px;
`;

export const LeftImage = styled(ButtonImage as AnyStyledComponent)``;

export const RightImage = styled(ButtonImage as AnyStyledComponent)`
  transform: rotate(180deg);
`;
