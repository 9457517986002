import styled from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES, FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import Image from 'next/image';

export const InformationBlockWrapper = styled.div<{formType?: string}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.formType ? '20px' : '15px')};
  padding: ${(props) => props.formType && '0 15px'};
`;

export const CloseIcon = styled(Image)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`

export const InformationBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InformationBlockTitle = styled.div`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 2px 0;
  line-height: 16px;
`;

export const InformationBlockList = styled.ul`
  padding-left: 1px;
`;

export const InformationBlockListItem = styled.li`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  line-height: 24px;
  position: relative;
  padding-left: 11px;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${COLORS.black};
    font-weight: ${WEIGHTS.normal};
  }
`;

export const ConnectoinInformationBlockWrapper = styled.div`
  max-width: 418px;
  background: ${COLORS.anonActiveModalInformationBackground};
  border-radius: ${SIZES.xxl};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 30px 20px 30px;
`;

export const InformationBlockTopic = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    color: ${COLORS.black};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.l};
    font-style: normal;
    font-weight: ${WEIGHTS.semiBold};
    line-height: ${SIZES.l};
    margin-bottom: 10px;
  }
  p {
    color: ${COLORS.black};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.xxxxl};
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  strong {
    font-weight: ${WEIGHTS.semiBold};
  }

  span {
    margin: 0 4px;
    color: ${COLORS.iconColor};
  }
`;

export const AnonymityActionsOptions = styled.div`
 display: flex;
 gap: 10px;
 align-items: center;
`

export const AnonymityActionsOption = styled.div`
    color: #90009C;
    font-family: "Roboto Flex";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    padding-top: 1px;
`

export const AnonymityActionsOptionText = styled.div`
    color: #000;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; 
`
