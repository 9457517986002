import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS } from '@/theme';

export const ImageWrapper = styled.div<{ isGrey?: boolean; isFaded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ isGrey }) => (isGrey ? 'filter: grayscale(100%);' : '')}
  ${({ isFaded }) => (isFaded ? 'opacity: 0.7;' : '')}
`;

const ImageStyle = css<{ borderRadius?: string; border?: string }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  ${({ border }) => (border ? `border: ${border};` : '')}
`;

export const UserImage = styled.img<{ borderRadius?: string; border?: string; isLoading: boolean }>`
  ${ImageStyle}
  display: ${({ isLoading }) => (isLoading ? 'none' : 'static')}
`;

export const EmptyPlaceholder = styled.div<{ borderRadius?: string; border?: string }>`
  background: ${COLORS.black};
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  ${({ border }) => (border ? `border: ${border};` : '')}
`;
export const HiddenImage = styled.div<{ borderRadius?: string; border?: string }>`
  background: ${COLORS.hiddenImageColor};
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  ${({ border }) => (border ? `border: ${border};` : '')}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockImage = styled(Image)<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '15px')};
  height: ${({ height }) => (height ? `${height}px` : '15px')};
`;
