import { useMutation, useQuery, useQueryClient } from 'react-query';
import { searchAlgoliaService } from '../services/search-algolia.service';
import {
  IClickedSeeMore,
  IClickedValueFilter,
  IPagedResponse,
  ISearchFilter,
  ISearchInModalOption,
  ISearchOutput,
  IUserSearchResponse,
  SearchOutputType
} from '../types/search.type';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { searchAlgoliaPageService } from '../services/search-algolia-page.service';
import { AlgoliaIndexes } from '../components/search-page/search-page.const';
import { setPostStatistics } from '../utils/post-statistics';
import { IPost } from '../types/post.type';
import { updatePostQuestion } from '../utils/mutate-posts';

export const useSearchInModal = (filter: ISearchFilter, setSearchResult: (data: ISearchInModalOption | null) => void) =>
  useQuery({
    queryKey: [QUERY_KEYS.SEARCH_IN_MODAL, filter],
    queryFn: () => {
      if (filter.searchString && filter.searchString.length > 0) {
        return searchAlgoliaService.searchInModal(filter.searchString);
      }
      setSearchResult(null);
    },
    onSuccess: (data) => {
      if (!data) return;
      setSearchResult(data);
    }
  });

export const useSearchOnPage = (filter: ISearchFilter, setSearchResult: (data: ISearchOutput | null) => void) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_IN_MODAL, filter],
    queryFn: () => {
      if (filter.isClicked) return;
      if (filter.searchString && filter.searchString.length > 0) {
        return searchAlgoliaPageService.search(filter.searchString);
      }
      setSearchResult(null);
    },
    onSuccess: (data) => {
      if (!data) return;
      setSearchResult(data);

      data[AlgoliaIndexes.POSTS].hits.forEach((post) => {
        setPostStatistics(post as IPost, queryClient);
      });
    }
  });
};

export const useClickOnSearchValue = (setSearchResult: (data: ISearchOutput | null) => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (filter: IClickedValueFilter) => searchAlgoliaPageService.searchClick(filter),
    onSuccess: (data) => {
      if (!data) return;
      setSearchResult(data);
      data[AlgoliaIndexes.POSTS]?.hits.forEach((post) => {
        setPostStatistics(post as IPost, queryClient);
      });
    }
  });
};

export const useClickOnSeeMore = (setSearchResult: (data: IPagedResponse) => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (filter: IClickedSeeMore) => searchAlgoliaPageService.clickSeeMore(filter),
    onSuccess: (data) => {
      if (!data) return;
      setSearchResult(data);
      if (data.index === AlgoliaIndexes.POSTS) {
        data.hits.forEach((post) => {
          setPostStatistics(post as IPost, queryClient);
        });
      }
      if (data.index === AlgoliaIndexes.NEWS) {
        data.hits.forEach((post) => {
          setPostStatistics(post as IPost, queryClient);
        });
      }
    }
  });
};

export const useUsersSearch = ({ hitsPerPage = 100 }: { hitsPerPage?: number }, onSuccess: (data: IUserSearchResponse | void) => void) =>
  useMutation({
    onSuccess,
    mutationFn: (query: string, page: number | undefined = 0) =>
      searchAlgoliaPageService.usersSearch({
        hitsPerPage,
        query,
        page
      })
  });

export const useMentionSearch = () =>
  useMutation({
    mutationFn: (searchString: string) => searchAlgoliaService.mentionSearch(searchString)
  });

export const useSearchState = (): [ISearchOutput | null, (data: ISearchOutput | null) => void] => {
  const queryClient = useQueryClient();
  const { data } = useQuery([QUERY_KEYS.SEARCH_PAGE_STATE], () => queryClient.getQueryData<ISearchOutput>([QUERY_KEYS.SEARCH_PAGE_STATE]));

  const setSearchResult = (newData: ISearchOutput | null) => {
    queryClient.setQueryData([QUERY_KEYS.SEARCH_PAGE_STATE], newData);
  };

  return [data ?? null, setSearchResult];
};

export const useUpdateQuestionAnswerInAllPosts = () => {
  const [state, setState] = useSearchState();
  const updateQuestionsOnSearchResult = (newPost: IPost) => {
    if (state?.[AlgoliaIndexes.POSTS]?.hits?.length) {
      const postState = state[AlgoliaIndexes.POSTS];
      const oldPosts = postState.hits;
      const newPosts = updatePostQuestion(oldPosts as IPost[], newPost);
      setState({
        ...state,
        [AlgoliaIndexes.POSTS]: {
          ...postState,
          hits: newPosts.map((post) => ({ ...post, type: SearchOutputType.POST }))
        }
      });
    }
  };
  return {
    updateQuestionsOnSearchResult
  };
};
