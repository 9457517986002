import { CreateFeedDTO } from '../types/insight.types';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class InsightService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async createFeedByUser(data: CreateFeedDTO) {
    return this.post('insight/create-feed', data);
  }

  async getPdfBase64(key: string) {
    return this.get(`insight/pdf/${key}`);
  }
}

export const insightService = new InsightService();
