import React from 'react';
import * as Styled from './footer.styled';
import { NotificationBar } from '../home-page/components/notification-bar';
import { MessagesBar } from './components/massages-bar/massages-bar.component';
import { IUser } from '@/common/types/user.type';

export const FooterComponent = ({ user, isAnon }: { user?: IUser; isAnon: boolean }) => (
  <MessagesBar user={user} isAnon={isAnon} />
  // <Styled.FooterWrapper isAnon={isAnon}>
  //   <NotificationBar user={user} isAnon={isAnon} />
  //   <MessagesBar user={user} isAnon={isAnon} />
  // </Styled.FooterWrapper>
);
