export const COLORS = {
  black: '#000000',
  blue: '#4D0EB4',
  white: '#ffffff',
  red: '#c61825',
  errorRed: '#DF0000',
  purple: '#5a14cd',
  esmerald: '#12847D',
  gray: '#dcdcdc',
  silver: '#c6c6c6',
  lightGray: '#a9a9a9',
  lightBlue: '#d9e3ff',
  lighterGray: '#a4a4a4',
  disabledGray: '#D1D1D1',
  secondLightGray: '#aaaaaa',
  darkerGray: '#5a5a5a',
  darkerBlue: '#0018F1',
  lighterBlue: '#2C67FF',
  disabledDarkerBlue: 'rgba(0, 24, 241, 0.50)',
  transparentLighterBlue: 'rgba(44, 103, 255, 0.1)',
  transparentDarkerBlue: 'rgba(0, 24, 241, 0.1)',
  dimGray: '#717171',
  graniteGray: '#585858',
  whiteSmoke: '#f4f4f4',
  gray95: '#f2f2f2',
  gray97: '#f7f7f7',
  gainsboroGray: '#e2e2e2',
  transparentDarkerGray: 'rgba(228, 228, 228, 0.4)',
  disabledlightBlue: 'rgba(217, 227, 255, 0.5)',
  semiTransparentWhite: 'rgba(255, 255, 255, 0.6)',
  halfTransparentWhite: 'rgba(255, 255, 255, 0.2)',
  halfTransparentBlack: 'rgba(0, 0, 0, 0.2)',
  paleTransparentBlack: 'rgba(0, 0, 0, 0.1)',
  modalBackdropColor: 'rgba(225, 225, 225, 0.75)',
  defaultBackdropColor: 'rgba(238, 238, 238, 0.9)',
  modalBackdropColorNewMusing: 'rgba(163, 163, 163, 0.6)',
  semiTransparentGray: 'rgba(220, 220, 220, 0.6)',
  authInputBackground: '#f4f4f4',
  authInputTextColor: '#b3b3b3',
  authTypeColor: '#868686',
  onboardBackgroundColor: '#fafafa' /* 'rgba(176, 176, 176, 0.65)' */,
  sentimentItemBackgroundColor: 'rgba(205, 205, 205)',
  borderBottomColor: 'rgba(189, 189, 189, 0.6)',
  uploadButtonBackground: 'rgba(255, 255, 255, 0.1)',
  backgroundGradientFromSelect: 'rgba(228,228,228, 1)',
  backgroundGradientToSelect: 'rgba(228,228,228, 0)',
  backgroundGradientFromMessage: 'rgba(181, 181, 181, 1)',
  opacityZero: 'rgba(0, 0, 0, 0)',
  backgroundGradientToMessage: 'rgba(181, 181, 181, 0)',
  gradientFromSentimentAllUsers: 'rgba(193,193,193, 1)',
  gradientToSentimentAllUsers: 'rgba(202,202,202, 1)',
  gradientFromSentimentCurrentUserBear: 'rgba(119,66,205, 1)',
  gradientToSentimentCurrentUserBear: 'rgba(144,128,168, 1)',
  gradientFromSentimentCurrentUserBull: 'rgba(12,144,136, 1)',
  gradientToSentimentCurrentUserBull: 'rgba(108,148,145, 1)',
  itemTextSelect: '#333333',
  headGroupColor: '#3d3d3d',
  radioBorderColor: '#bfbfbf',
  radioBackgroundColor: '#676767',
  subTextColor: '#8d8d8d',
  selectorColor: '#aaaaaa',
  stepperBackground: '#bcbcbc',
  appBackground: '#FAFAFA' /* '#cacaca' */,
  sidebarBackground: 'rgba(242, 242, 242, 0.4)',
  sidebarNewMusingBackground: 'linear-gradient(108.83deg, rgba(30, 52, 249, 0.3) 0%, rgba(0, 107, 153, 0.3) 100%)',
  colorIconActive: '#000000',
  colorIconNotActive: '#595959',
  postBackground: 'rgba(255, 255, 255, 0.3)',
  postDateTimeColor: '#3e3e3e',
  postActionItemBackgroundFirst: 'rgba(255, 255, 255, 0.2)',
  postActionItemBackgroundSecond: 'rgba(255, 255, 255, 0)',
  postActionItemBackgroundThird: 'rgba(255, 255, 255, 0.1)',
  postStatCount: '#646464',
  postStatCountNew: '#888',
  postStatCountActiveNew: '#0016d9',
  replyRepostTextAreaBackground: '#F7F7F7',
  repostBackgroundColor: 'rgba(255, 255, 255, 0.125)',
  backToPostButtonColor: '#939393',
  postActionItemColor: 'rgba(51, 51, 51, 0.7)',
  postQueryBackgroundColor: 'rgba(224, 224, 224, 1)',
  postDescription: '#979797',
  postBorderRow: 'rgba(189, 189, 189, 0.6)',
  postBorderColumn: 'rgba(189, 189, 189, 0.3)',
  chartEducationFirst: 'rgba(30, 52, 249, 0.2)',
  chartEducationSecond: 'rgba(0, 107, 153, 0.2)',
  chartEmployerFirst: 'rgba(30, 52, 249, 0.4)',
  chartEmployerSecond: 'rgba(0, 107, 153, 0.4)',
  chartCurrentEmployerFirst: 'rgba(30, 52, 249, 0.9)',
  chartCurrentEmployerSecond: 'rgba(0, 107, 153, 0.9)',
  chartNumberColor: 'rgba(0, 40, 184, 0.6)',
  chartColumnFirst: 'rgba(181, 181, 181, 0.02)',
  chartColumnSecond: 'rgba(174, 174, 174, 0.15)',
  chartColumnThird: 'rgba(134, 134, 134, 0.2)',
  chartSubNumberColor: 'rgba(44, 44, 44, 0.25)',
  chartTextColor: 'rgba(13, 45, 156, 1)',
  dividerColor: 'rgba(138, 138, 138, 0.25)',
  chartDescriptionDivider: 'rgba(157, 157, 157, 0.6)',
  musingDescription: '#979797',
  musingBorderRow: 'rgba(189, 189, 189, 0.6)',
  musingBorderColumn: 'rgba(189, 189, 189, 0.3)',
  connectionModalDescription: '#202020',
  connectionModalButtonDefault: '#0029FF',
  connectionModalButtonUnfollow: '#f28181',
  connectionModalButtonConfirm: '#cbcbcb',
  connectionRequestButtonBackground: '#e3eeff',
  connectionRequestButtonColor: '#003fe1',
  backgroundOpacityZero: 'rgba(255, 255, 255, 0)',
  blur: 'rgba(0, 0, 0, 0)',
  locationDividerColor: '#737373',
  postInputPlaceholderColor: '#969696',
  postInputBackgroundColor: 'rgba(255, 255, 255, 0.3)',
  inputBackground: 'rgba(212, 212, 212, 1)',
  viewBookmarkNewText: '#989898',
  sentimentSwingBackground: '#F9F9F9',
  neutralAnswerBackground: '#173d9f',
  voteBearText: '#5A14CD',
  voteBearUnderline: 'rgba(84, 0, 189, 0.6)',
  voteNeutralText: '#757575',
  voteNeutralUnderline: 'rgba(117, 117, 117, 0.5)',
  voteBullText: '#12847D',
  voteBullUnderline: 'rgba(0, 145, 136, 0.6)',
  headTextColorInModal: '#454545',
  degreeItemTextColor: '#474747',
  textColorInStockEntryModal: '#8c8c8c',
  backgoundColorInStockEntryModal: '#f5f5f5',
  headTextColorInSectionOfOnboarding: '#002CC9',
  addAnotherShadowColor: '#dbdbdb',
  addAnotherBackgroundColor: '#ededed',
  headCoveregeGoupColor: '#a2a2a2',
  sectionHomeTitle: '#000',
  sectionSubTitle: '#A39E9E',
  sentimentBearishSubtitleBackground: 'rgba(77, 14, 180, 0.05)',
  sentimentBearishSubtitleText: '#4D0EB4',
  sentimentNeutralSubtitleBackground: 'rgba(0, 0, 0, 0.05)',
  sentimentNeutralSubtitleText: '#000000',
  sentimentBullishSubtitleBackground: 'rgba(0, 146, 137, 0.05)',
  sentimentBullishSubtitleText: '#009289',
  sectorsInPostTile: '#616161',
  iconColor: '#c8c8c8',
  repliedPostText: '#4d4d4d',
  repliedPostTextMoreButton: '#0016d8',
  borderColorForReplyTile: '#eaeaea',
  borderColorForOwnReplyTile: '#f3f2f2',
  quoteBorderDividerFirst: 'rgba(219, 219, 219, 1)',
  quoteBorderDividerSecond: 'rgba(219, 219, 219, 0.2)',
  sentimentSwingLineGradientFrom: 'rgba(90, 20, 205, 1)',
  sentimentSwingLineNewColor: '#0094FE',
  sentimentSwingLineGradientTo: 'rgba(0, 195, 183, 1)',
  trendingBackgroundColor: '#089C93',
  trendingItemColor: '#636363',
  trendingPostTitle: '#C81800',
  trendingPostSubtitle: '#A39E9E',
  firstColorInGouteDividerGradient: '#e8e8e8',
  secondColorInGouteDividerGradient: 'rgba(219, 219, 219, 0.20)',
  borderColorInReplyModal: 'rgba(240, 240, 240, 0.75)',
  dividerColorInVisibilitySettings: '#ececec',
  groupDescriptinColorInVisibilitySettings: '#5f5f5f',
  lastDividerColorInVisibilitySettings: '#9b9b9b',
  visibilitySettingsButton: '#eee',
  visibilitySettingsButtonActive: '#ebebeb',
  visibilitySettingsTextButtonColor: '#808080',
  firstClickOnDeletePostButton: 'rgba(252, 252, 252, 0.60)',
  secondClickOnDeletePostButton: 'rgba(252, 252, 252, 0.85)',
  firstClickOnDeletePostButtonInModal: 'rgba(249, 249, 249, 0.60)',
  secondClickOnDeletePostButtonInModal: 'rgba(249, 249, 249, 0.85)',
  hiddenImageColor: 'rgba(217, 217, 217, 0.10)',
  trashWraperBackground: 'rgba(217, 217, 217, 0.30)',
  searchModalBackgroundColor: 'rgba(240, 240, 240, 0.50)',
  searchModalBoxShadow: '#e9e9e9',
  backgroundColorSeeAllButton: 'rgba(212, 224, 255, 0.50)',
  backgroundColorInFilterButtonValidAndNotActive: 'rgba(212, 224, 255, 0.40)',
  backgroundColorInFilterButtonValidAndActive: 'rgba(212, 224, 255, 0.60)',
  backgroundColorInFilterButtonNotValid: 'rgba(212, 224, 255, 0.10)',
  backgroundColorFilterWrapper: 'rgba(212, 224, 255, 0.80)',
  colorInFilterButtonValidAndNotActive: '#2C67FF',
  colorInFilterButtonValidAndActive: '#003FE1',
  colorInFilterButtonNotValid: '#B9CDFF',
  mention: '#0018F1',
  mentionQuote: 'rgba(0, 24, 241, 0.70)',
  mentionFaded: '#7684FF',
  clickedTypeColorOnSearchPage: '#8f8f8f',
  colorForRoleAndSectorsOnSearchPageMemobers: '#a0a0a0',
  colorNotActiveTextTitleOnSearchPage: '#484848',
  colorHomeSearchText: '#8a8a8a',
  backgroundColorSeeMoreButtonOnSearchPage: '#f5f4f4',
  fullnamePostInfoColor: '#2e2e2e',
  datailePostInfoColor: '#a0a0a0',
  fullNameInRepostTileColor: '#303030',
  searchModalPlaceholderColor: '#989898',
  settingsPageHeaderWrapperBackground: 'rgba(253, 253, 253, 0.01)',
  settingsPageBlockBackground: '#fdfdfd',
  settingsPageBlockShadow: '#f1f1f1',
  gapLineUserModal: '#f0f0f0',
  subUserTextUserModal: '#a0a0a0',
  buttonInactiveUserModalBackground: '#EBEBEB',
  buttonActiveUserModalBackground: 'rgba(0, 24, 241, 0.05)',
  buttonActiveUserModalText: '#0018F1',
  buttonPaginationUserModal: '#0016D9',
  buttonPaginationUserModalBackground: 'rgba(253,253,253,0.8)',
  descriptionColorInBlock: '#515151',
  colorCategory: '#c5c5c5',
  gold: '#bc7100',
  colorMemoTitle: '#454545',
  securityActive: '#F1F1F1',
  colorInactiveText: '#c5c5c5',
  saveButtonBackgroundDisabled: '#f3f3f3',
  saveButtonColorDisabled: '#c9c9c9',
  summaryTextArea: '#7b7b7b',
  colorAfterInputInactiveText: 'rgba(99, 99, 99, 0.50)',
  colorInactiveButtonText: '#DCDCDC',
  thesisTextArea: '#7b7b7b',
  publishInfo: '#515151',
  dotPublish: '#CFCFCF',
  boxShadowMemoPage: '#F1F1F1',
  bcColorContainerMemo: '#ffffff',
  bcButtonCreate: '#FAFAFA',
  btnFirstCreateMemo: '#ccc',
  titleMemoCreate: '#626262',
  bcButtonLong: 'rgba(0, 146, 137, 0.10)',
  bcButtonPurple: 'rgba(77, 14, 180, 0.05)',
  bcButtonLongMain: '#009289',
  colorSummaryBlock: '#434343',
  quantityThemasSpan: '#797979',
  longDirection: '#009289',
  borderFilterActive: '#5786FF',
  borderFilter: 'rgba(186, 205, 255, 0.50)',
  bcFilter: 'rgba(212, 224, 255, 0.40)',
  bcFilterActive: '#D4E0FF',
  colorFilter: '#B9CDFF',
  colorFilterActive: '#2C67FF',
  bcFilterDisabled: 'rgba(212, 224, 255, 0.1)',
  colorFilterDisabled: '#b9cdff',
  bcTitleFilter: '#2C67FF',
  titleAnswer: '#6f6f6f',
  bcDirectionShort: 'rgba(77, 14, 180, 0.05)',
  bcDirectionLong: 'rgba(18, 132, 125, 0.05)',
  themesSpan: 'rgba(0, 90, 172, 0.75)',
  borderDefaultThemas: 'rgba(186, 205, 255, 0.50)',
  borderNotActiveThemas: 'rgba(186, 205, 255, 0.25)',
  colorNotActive: 'rgba(44, 103, 255, 0.50)',
  borderFilterNew: 'rgba(186, 205, 255, 0.50)',
  activeSectionBc: 'rgba(44, 103, 255, 0.10)',
  activeSectionColor: 'rgba(44, 103, 255, 0.60)',
  contIsFilter: 'rgba(0, 90, 172, 0.45)',
  bgSummary: 'rgba(44, 103, 255, 0.10)',
  colorH2IsCurrent: '#A7A7A7',
  notificationResponseSectionBackground: 'rgba(253, 253, 253, 0.90)',
  notificationResponseSectionActiveButtonColor: 'linear-gradient(264deg, rgba(0, 148, 121, 0.1) 0%, rgba(0, 24, 241, 0.1) 100%)',
  notificationResponseSectionInactiveButtonColor: 'rgba(0, 107, 131, 0.60)',
  notificationResponseSectionInactiveDisabledButtonColor:
    'linear-gradient(264deg, rgba(127, 49, 255, 0.30) 0%, rgba(75, 3, 193, 0.30) 36.46%)',
  notificationResponseSectionHiddenButtonColor: 'linear-gradient(264deg, rgba(127, 49, 255, 0.10) 0%, rgba(75, 3, 193, 0.10) 36.46%)',
  notificationAdorationSectionWrapperBackground: 'rgba(253, 253, 253, 0.85)',
  hiddenImageBackgroundColor: 'rgba(217, 217, 217, 0.30)',
  hiddenTextSectionColor: '#3100bc',
  newActiveColorForEngagement: '#D50000',
  notificationDisconnectButton: 'linear-gradient(264deg, rgba(173, 0, 0, 0.90) 0%, rgba(206, 0, 0, 0.90) 100%);',
  notificationOldColor: '#b0b0b0',
  notificationDotColor: '#d4d4d4',
  notificationIconNew: '#0043ef',
  notificationHiddenActionButton: 'linear-gradient(264deg, rgba(0, 148, 121, 0.05) 0%, rgba(0, 24, 241, 0.05) 100%)',
  notificationHiddenActionButtonColor: 'rgba(0, 24, 241, 0.30)',
  notificationOldActionTextColor: '#858585',
  hiddenTextColor: 'rgba(253, 253, 253, 0.8)',
  hiddenTextColorMozilla: 'rgba(253, 253, 253, 0.85)',
  warningColor: '#AB7B00',
  darkWarningColor: '#950000',
  profileUsernameSubInfo: '#9c9c9c',
  profileInfoFirstSeparator: 'rgba(203, 203, 203, 0.20)',
  profileInfoSeparator: 'rgba(236, 236, 236, 1)',
  profileWarningText: '#876100',
  profileWarningBorder: 'rgba(171, 123, 0, 0.3)',
  profileWarningBackground: 'rgba(171, 123, 0, 0.02)',
  profileLockSectionBackground:
    'linear-gradient(90deg, rgba(0, 38, 173, 0.05) 0%, rgba(0, 38, 173, 0.03) 48.49%, rgba(0, 38, 173, 0.04) 100%)',
  profileLockSectionBorder: 'rgba(0, 38, 173, 0.15)',
  profileLockSectionText: '#0026ad',
  emptyNotificationBarTextColor: 'rgba(188, 188, 188, 0.8)',
  newPostButtonColor: '#0029bb',
  rightScrollTextColor: 'rgba(0, 117, 167, 0.50)',
  leftScrollTextColor: '#BBB',
  notificationAdorationBackground: '#fbfbfb',
  notifcationDivider: '#93B1FF',
  notifcationDividerOld: '#E7E7E7',
  noitifcationDeviderAdoration: '#0043EF',
  noitifcationDeviderForResponce: '#0043EF',
  rightBackgroundColor: '#FBFBFB',
  emptyImageInLinkPreviewBackground: '#d9d9d9',
  emptyImageInLinkPreviewBackground20Opacity: '#d9d9d933',
  /*
    anon
  */

  // info
  anonActiveModalInformationBackground: 'rgba(255, 255, 255, 0.80)',

  // button
  anonActiveModalButtonBorder: '1px solid #DDD',
  anonActiveModalButtonBackground: 'rgba(255, 255, 255, 0.50)',

  // email
  anonActiveModalEmailInputBackground: 'rgba(251, 251, 251, 0.80)',
  anonActiveModalEmailTextColorPrimary: '#7900a4',
  anonActiveModalEmailSubTextColorPrimary: '#3a3a3a',
  anonActiveModalEmailSubTextColorSecondary: '#6e0095',
  anonActiveModalEmailSubTextColorGrey: '#494949',

  // input
  anonActiveModalInputErrorText: '#df0000',

  // input button
  anonActiveModalInputButtonBorder: '1px solid rgba(121, 0, 164, 0.2)',
  anonActiveModalInputButtonBackground: 'rgba(121, 0, 164, 0.1)',
  anonActiveModalInputButtonBorderDisabled: '1px solid rgba(121, 0, 164, 0.05) ',
  anonActiveModalInputButtonBackgroundDisabled: 'rgba(121, 0, 164, 0.05)',
  anonActiveModalInputButtonTextColorDisabled: 'rgba(121, 0, 164, 0.20)',
  anonActiveModalInputButtonPrimaryBackground: 'rgba(121, 0, 164, 0.80)',
  anonActiveModalInputButtonPrimaryBackgroundDisabled: 'rgba(121, 0, 164, 0.20)',
  anonActiveModalInputButtonPrimaryTextColorDisabled: 'rgba(255, 255, 255, 0.25)',

  // reserved input

  anonActiveModalReservedInputBackground: 'rgba(121, 0, 164, 0.25)',
  anonActiveModalReservedInputShadow: 'rgba(180, 180, 180, 0.5)',
  anonActiveModalReservedInputOtpBackground: 'rgba(246, 246, 246, 0.30)',

  /*
  onboarding
  */

  onboardingBackground: '#F5F5F5;',
  onboardingAnonFormTitleColor: '#143aff',
  onboardingAnonInputBackroundSecondary: 'rgba(253, 253, 253, 0.5)',
  onboadringAnonInputLastBackground: 'rgba(253, 253, 253, 0.95)',
  onboadringAnonInputLastShadow: '#9ac2ff',
  onboadringAnonInputSubmitButtonText: 'rgba(0, 168, 128, 1)',
  onboadringAnonInputOtpBackground: '#f3f3f3',
  onboardingAnonInputEnterVeronaButtonBackground: 'rgba(0, 168, 128, 0.15)',
  onboardingAnonInputEnterVeronaButtonText: '#00a880',

  switchToAnonReminderTextColor: '#2f2f2f',
  onboardingAnonSeparatorColor: '#ebebeb',
  onboardingAnonInputTitleColor: '#454545',
  onboardingAnonAdditionInfoTextColor: '#616161',

  /*
  real mode
  */

  realActivateModalTextColorPrimary: '#0075a7',
  realActiveModalEmailSubTextColorSecondary: '#0087c1',
  realActiveModalInputButtonTextColorDisabled: 'rgba(0, 117, 167, 0.20)',
  realActiveModalInputButtonBackgroundDisabled: 'rgba(0, 117, 167, 0.05)',
  realActiveModalInputButtonBackground: 'rgba(0, 117, 167, 0.10)',
  realActiveModalInputButtonBorder: '1px solid rgba(0, 117, 167, 0.20)',
  realActiveModalInputButtonBorderDisabled: '1px solid rgba(0, 117, 167, 0.05)',
  realActivateModalInputButtonPrimaryDisabled: 'rgba(255, 255, 255, 0.25)',
  realActivateModalInputButtonPrimaryBackgroundDisabled: 'rgba(0, 117, 167, 0.20)',
  realActivateModalInputButtonPrimaryBorderDisabled: 'rgba(0, 117, 167, 0.20)',
  realActiveModalInputButtonPrimaryBackground: 'rgba(0, 117, 167, 0.80)',
  realActiveModalInputButtonPrimaryBorder: '1px solid rgba(0, 117, 167, 0.80)',

  /*
  link
  */

  linkModalSuccessfulLinkedButtonBackground: 'linear-gradient(90deg, rgba(121, 0, 164, 0.80) 0%, rgba(0, 117, 167, 0.80) 100%);',

  /* login to real from anon */

  loginToRealFromAnonModalBackground: 'rgba(251, 251, 251, 1)',

  /*
  auth
  */

  authWrapperBackground: '#fff',
  authEmailInputBackground: 'rgba(255, 255, 255, 0.8);',
  authEmailInputPlaceholder: 'rgba(177, 177, 177, 1)',
  authEmailInputButtonBackground: 'rgba(241, 241, 241, 0.8)',
  authEmailInputErrorColor: '#AB0000',
  authEmailInputSendColor: 'rgba(0, 152, 115, 1)',
  authEmailInputSendButtonBackground: 'rgba(0, 152, 115, 0.1)',
  authPrivacyAndServicesTextColor: 'rgba(160, 160, 160, 1)',
  authEmailWarningTextColor: 'rgba(157, 157, 157, 1)',
  authEmailInputWarningButtonBackground: 'rgba(152, 137, 0, 0.10)',
  authEmailInputWarningColor: 'rgba(164, 128, 0, 1)',
  authDocumentsHeaderBackground: 'linear-gradient(180deg, rgba(245, 245, 245, 0.95) 60.42%, rgba(245, 245, 245, 0.47) 100%)',
  authDocumentsNavigationTextColor: 'rgba(0, 0, 0, 0.7)',

  /*
  edit profile
  */

  editSubTitleColor: 'rgba(0, 29, 182, 0.7)',
  editSubTitleErrorColor: '#ED0000',

  /*
  edit profile
  */

  newsfeedTags: 'rgba(112, 112, 112, 0.05)',
  newsfeedTagsBorder: 'rgba(168, 168, 168, 0.05)',
  newsfeedDescription: '#878787',
  newsfeedFilterActiveBorder: 'rgba(0, 168, 158, 0.50)',
  newsfeedFilterActiveCategory: '#4A00A8',
  newsfeedActionRed: '#FC0000',
  newsfeedActionGreen: '#00A69C'
};
