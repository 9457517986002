import { getBase64 } from '../components/onboard/helpers';

export const pasteBuffer = (e: React.ClipboardEvent<HTMLDivElement>, setItem: (item: string | ArrayBuffer | null) => void) => {
  e.preventDefault();
  const { items } = e.clipboardData;
  const images = [];
  for (let i = 0; i < items.length; i += 1) {
    const file = items[i].getAsFile();
    if (items[i].kind === 'file' && items[i].type.indexOf('image') !== -1 && file) {
      images.push(file);
    }
  }

  if (images[0] && images[0].type.indexOf('image') !== -1) {
    const file = images[0];
    if (
      file &&
      (file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/svg' ||
        file.type === 'image/avif' ||
        file.type === 'image/bmp' ||
        file.type === 'image/webp') &&
      file.size <= 40 * 1024 * 1024
    ) {
      const imageBase64 = getBase64(file);
      imageBase64.then((result) => {
        setItem(result);
      });
    }
  }

  const text = e.clipboardData.getData('text/plain');

  const filteredText = text.replace(/<[^>]*>/g, '');

  document.execCommand('insertText', false, filteredText);
};
