import React, { useEffect, useRef } from 'react';
import Search from '@styles/icons/search.webp';
import * as Styled from './search.styled';
import { getIsAnonUser } from '@/common/hooks';

interface ISearchComponentProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchComponent = ({ value, onChange }: ISearchComponentProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const isAnon = getIsAnonUser();

  return (
    <Styled.InputWrapper isAnon={isAnon}>
      <Styled.InputImage src={Search} alt="search" />
      <Styled.Input ref={ref} placeholder="search Villan" value={value} onChange={onChangeInput} type="text" autoFocus />
    </Styled.InputWrapper>
  );
};
