import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, delay: number = 500, onSuccess?: () => void) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
      if (onSuccess) {
        onSuccess();
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [JSON.stringify(value), delay]);

  return debouncedValue;
};
