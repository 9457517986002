import styled, { AnyStyledComponent, keyframes } from 'styled-components';
import Image from 'next/image';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS, FONTS } from '@/theme';

export const ContainerFirstCategory = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const TitleMemo = styled.h2`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.l};
  font-weight: ${WEIGHTS.normal};
  line-height: 16px;
  margin-right: 30px;
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 720px;
`;

export const WrapperGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

//  background: ${({ isCurrent, isAllSectionOpen }) =>
//     isAllSectionOpen ? '#FDFDFD' : isCurrent ? COLORS.bcColorContainerMemo : 'transparent'};

export const TitleMiniSection = styled.h2<{ isCurrent: boolean; isAllSectionOpen: boolean }>`
  color: ${({ isCurrent, isAllSectionOpen }) =>
    isAllSectionOpen ? COLORS.degreeItemTextColor : isCurrent ? COLORS.black : COLORS.colorH2IsCurrent};
  font-weight: ${({ isCurrent }) => (isCurrent ? WEIGHTS.semiBold : WEIGHTS.normal)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  line-height: 14px;
`;

export const ContainerTitle = styled.div<{ group?: boolean; active?: number }>`
  border-radius: 15px;
  background: ${({ active }) => (active && active > 0 ? COLORS.activeSectionBc : COLORS.bcTitleFilter)};
  padding: 7px 12px;
  display: block;
  display: flex;
  align-items: center;
  width: ${({ group }) => (group ? '94px' : 'none')};
`;

export const Content = styled.div<{ width?: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  ${({ width }) => !!width && `width: ${width}px`}
`;

export const Title = styled.h3<{ active?: number }>`
  padding: 7px 0;
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-weight: ${WEIGHTS.medium};
  line-height: ${SIZES.m};
`;

export const TitleNormal = styled(Title)`
  font-weight: ${WEIGHTS.normal};
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &::after {
    content: '';

    position: absolute;
    top: -14px;
    right: -36px;
    width: 1px;
    height: 101vh;
    background-color: #efefef;
    z-index: 2;
  }
`;

export const Button = styled.button<{
  inActive?: boolean;
  state?: string;
  full?: boolean;
  active?: boolean;
  isClosed?: boolean;
  isOpenOptions?: boolean;
  width?: number;
}>`
  display: flex;
  cursor: pointer;
  padding: 8px 12px 8px 8px;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #9aa4f9;
  height: 49px;
  background: ${({ isClosed }) => (isClosed ? 'rgba(209, 213, 255, 0.35)' : '#f3f4ff')};
  gap: 8px;
  color: #0018f1;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  opacity: ${({ inActive }) => (inActive ? 0.5 : 1)};
  text-wrap: nowrap;
  &::after {
    content: '';
    position: absolute;
    top: -14px;
    right: -62px;
    width: 1px;
    height: 101vh;
    background-color: #efefef;
    z-index: 2;
    ${({ isOpenOptions }) => isOpenOptions && 'display:none;'};
  }
  ${({ full }) => full && 'width: 100%;'};
  ${({ width }) => width && `width: ${width}px`};
`;

export const ImagesContainer = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(209, 213, 255, 0.5);
`;

export const Images = styled(Image as AnyStyledComponent)`
  width: 16px;
  height: 16px;
`;

export const Hidden = styled(Image as AnyStyledComponent)`
  width: 18px;
  height: 18px;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ImagesPlus = styled(Image as AnyStyledComponent)`
  width: 18px;
  height: 18px;
`;

export const TitleSectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;

export const SectorHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const SectorNumber = styled.span<{ isCurrent: boolean; isAllSectionOpen: boolean }>`
  color: ${({ isCurrent, isAllSectionOpen }) =>
    isAllSectionOpen ? COLORS.black : isCurrent ? COLORS.black : COLORS.visibilitySettingsTextButtonColor};
  font-family: ${FAMILIES.robotoFlex};
  padding-top: 1px;
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 14px;
`;

export const SectorDescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const TitleSector = styled('h3')<{ isCurrent: boolean; isAllSectionOpen: boolean }>`
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.l};
  font-style: normal;
  font-weight: ${WEIGHTS.semiBold};
  line-height: ${SIZES.l};
  letter-spacing: 0.32px;
  color: #000;
`;

export const SectorDescription = styled.span<{ isCurrent: boolean }>`
  color: ${({ isCurrent }) => (isCurrent ? COLORS.darkerGray : '#ACACAC')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 14px;
  padding: 1px 0px;
`;

const flashing = keyframes`
  from { 
    opacity: 1; 
  }
  to { 
    opacity: 0.2; 
  }
`;

export const FlashingText = styled.div`
  color: #696969;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 15px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  animation: 1s linear 0.5s infinite alternate ${flashing};
`;

export const DescriptionContainer = styled.div`
  padding-left: 10px;
`;

export const DescriptionUnderFlashingText = styled.div<{ withBullet?: boolean; lastParagraph?: boolean }>`
  position: relative;
  padding-left: 15px;
  color: #202020;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 15px;
  margin-bottom: ${({ lastParagraph }) => (lastParagraph ? '0' : '5px')};
  margin-top: ${({ lastParagraph }) => (lastParagraph ? '-5px' : '0')};
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  ${({ withBullet }) =>
    withBullet &&
    `
  &::before {
    content: '•'; 
    position: absolute;
    left: 0;
  }
`}
`;

export const CloseIcon = styled(Image as AnyStyledComponent)`
  width: 20px;
  height: 20px;
  margin-right: 35px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 600px;

  border: none;
  outline: none;
  padding: 7px 5px;
  border-radius: 5px;
  color: ${COLORS.black};
  background: transparent;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-weight: ${WEIGHTS.light};
  line-height: 22px;
  &::placeholder {
    opacity: 1;
    color: #696969;
  }
  :focus-visible {
    outline: 0;
  }
`;

export const PublishDateWrapper = styled.div`
  display: flex;
  gap: 10px;
  min-width: calc(100% + 100px);

  h2 {
    color: #000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.m};
  }

  > div {
    width: 625px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const YearRowWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  gap: 3px;
`;

export const MonthAndDayRowWrapper = styled(YearRowWrapper)`
  gap: 2px;
`;

export const PublishDateNextPrevButton = styled.button`
  cursor: pointer;
  padding-block: 0px;
  padding-inline: 0px;
  padding: 7px 9px;
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #fff;
  display: flex;
`;

export const Item = styled.button<{ isActive: boolean; isDisabled: boolean; isDay?: boolean }>`
  display: flex;
  min-width: 32px;
  width: fit-content;
  height: fit-content;
  color: #000;
  border: none;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
  cursor: pointer;
  display: flex;
  padding: 2px 9px;
  ${({ isDay }) => isDay && 'padding: 2px 7px;'}
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #efefef;
  text-align: center;
  background: #fff;
  ${({ isActive }) => isActive && `color: ${COLORS.black};`}
  ${({ isActive }) => isActive && 'border: 1px solid #505050;'}
  ${({ isDisabled }) => isDisabled && 'background: rgba(255, 255, 255, 0.50);'}
  ${({ isDisabled }) => isDisabled && 'color: rgba(0, 0, 0, 0.25);'}
`;

export const ImageCheck = styled(Image as AnyStyledComponent)`
  margin: 4px 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const DateCheckContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
`;

export const DateTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 10px;
`;

export const DateTitleText = styled.p`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 7px 0px;
`;

export const DateTextContainer = styled.div`
  width: 650px;
  display: flex;
  padding-left: 5px;
  align-items: center;
  gap: 3px;
`;

export const DateText = styled.p`
  color: #6b6b6b;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const UploadsSummarizedText = styled.p`
  color: #c85400;
  margin-bottom: 9px;
  margin-top: 32px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const UploadActions = styled.div`
  padding-left: 8px;
`;

export const ErrorMessage = styled.p`
  padding-left: 15px;
  margin-top: 18px;
  color: #df0000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

export const SummarizeButton = styled.button`
  display: flex;
  padding-left: 1px;
  cursor: pointer;
  padding: 9px 8px;
  width: 548px;
  align-items: center;
  border-radius: 15px;
  border: 2px solid #9aa4f9;
  height: 43px;
  background: #f3f4ff;
  gap: 5px;
  color: #0018f1;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  &:disabled {
    opacity: 0.5;
    border: 2px solid rgba(154, 164, 249, 0.5);
  }
`;

export const ContainerForAllSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  border-radius: 10px;
`;

export const FileAtachingWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  & > button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #aaa;
    background: transparent;
    padding: 2px;
    width: 16px;
    height: 16px;
    & > div {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: #005aac;
    }
  }
  & > span {
    color: #000;
    padding: 4px 0;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
  }
`;

export const HeaderWithFilterWrapper = styled.div<{ sidebarWidth: number }>`
  position: fixed;
  width: 675px;
  z-index: 2;
  top: 0;
  left: ${({ sidebarWidth }) => `${sidebarWidth}px`};

  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);

  &::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 1px;
    height: 100vh;
    background-color: #efefef;
    z-index: 2;
  }
`;
