import React, { useEffect, useState } from 'react';
import ArrowLeftIcon from '@styles/icons/modal-back-arrow.png';
import TrashIcon from '@styles/icons/trash-icon.webp';
import TrashIconRed from '@styles/icons/trash-icon-red.webp';
import CloseBlackIcon from '@styles/icons/close-black.webp';
import { IdeaDTO } from '@/common/types';
import * as Styled from './ideas-modal.styled';
import { EnterCompanyComponent } from './enter-company.component';
import { ISelectedCompany } from '@/common/types/coverage.type';
import { IdeaHorizon } from '@/common/types/idea.type';
import { BEAR_ANSWER, BULL_ANSWER, horizonText, ideaReasons, SKIP_ANSWER } from '@/common/consts/idea.const';
import { IdeaVoteComponent } from '@/common/components/idea-vote';
import { useCreateIdea, useDeleteIdea, useUpdateIdea } from '@/common/hooks';
import { MODAL_SLIDE_TIMEOUT } from '@/common/consts';
import { PostButtonComponent } from '@/common/components/modal/components/post-button';
import CloseIcon from '@/styles/icons/close-modal-pointer.png';
import { InputWithImageComponent } from '@/common/components/create-modal/components/input-with-image/input-with-image.component';

interface IIdeasModalComponentProps {
  open: boolean;
  idea: IdeaDTO | null;
  closeHandler: () => void;
  closeReplyModal?: () => void;
}

export const IdeasModalComponent = ({ open, idea, closeHandler, closeReplyModal }: IIdeasModalComponentProps) => {
  const [selectedCompany, setSelectedCompany] = useState<ISelectedCompany | null>(null);
  const [horizon, setHorizon] = useState<IdeaHorizon | null>(null);
  const [answer, setAnswer] = useState<number | null>(null);
  const [reasons, setReasons] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);

  const [viewSelected, setViewSelected] = useState(!!idea?.priority || idea?.priority === 0);

  const { mutate: createIdea, isLoading: createIsLoading, isSuccess: createIsSuccess, reset: createReset } = useCreateIdea();
  const { mutate: updateIdea, isLoading: updateIsLoading, isSuccess: updateIsSuccess, reset: updateReset } = useUpdateIdea();
  const { mutate: deleteIdea, isSuccess: deleteIsSuccess, reset: deleteReset } = useDeleteIdea();

  const resetFields = () => {
    setSelectedCompany(idea ? { id: idea.questionId, name: idea.text } : null);
    setHorizon(idea?.horizon || null);
    setAnswer(idea?.priority || idea?.priority === SKIP_ANSWER ? idea.priority : null);
    setReasons(idea?.reasons || []);
    createReset();
    updateReset();
    deleteReset();
    if (deleteClicked) {
      setDeleteClicked(false);
    }
  };

  useEffect(() => {
    resetFields();
  }, [idea]);

  useEffect(() => {
    setTimeout(() => {
      resetFields();
    }, MODAL_SLIDE_TIMEOUT);
  }, [open]);

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess || deleteIsSuccess) {
      closeHandler();
      if (closeReplyModal) closeReplyModal();
    }
  }, [createIsSuccess, updateIsSuccess, deleteIsSuccess]);

  useEffect(() => {
    if (
      idea &&
      (horizon !== idea.horizon ||
        answer !== idea.priority ||
        reasons.length !== idea.reasons.length ||
        !reasons.every((val) => idea.reasons.includes(val)))
    ) {
      setShowEdit(true);
    } else if (showEdit) {
      setShowEdit(false);
    }
  }, [horizon, answer, reasons]);

  useEffect(() => {
    if (!viewSelected && (!!answer || answer === 0)) {
      setViewSelected(true);
    }
  }, [answer]);

  const handleClickCompany = (company: ISelectedCompany) => () => {
    setSelectedCompany(company);
  };

  const reasonOnClick = (reason: string) => () => {
    setReasons((prev) => {
      if (prev.includes(reason)) {
        return prev.filter((item) => item !== reason);
      }
      return [...prev, reason];
    });
  };

  const deleteClick = () => {
    if (!deleteClicked) {
      setDeleteClicked(true);
      return;
    }

    if (idea) {
      deleteIdea(idea.id);
    }
  };

  const confirmClick = () => {
    if (idea) {
      updateIdea({
        ideaId: idea.id,
        horizonSelect: horizon || IdeaHorizon.SKIP,
        priority: answer,
        reasons
      });
    }
  };

  const postText = idea?.postText;
  const postTextForOutput = postText?.startsWith('Idea ') && (postText?.length ?? 0) > 5 ? postText.slice(5) : '';

  const getStep = (step: number, title: string, active: boolean, optional?: boolean, isNotEdited?: boolean) => (
    <Styled.StepContainer key={step}>
      <Styled.StepTitle isActive={active}>{title}</Styled.StepTitle>
      {!!optional && <Styled.StepText>optional</Styled.StepText>}
      {!!isNotEdited && <Styled.StepIsNotEditable>can not be edited</Styled.StepIsNotEditable>}
      {!!active && (
        <>
          <Styled.StepDot>•</Styled.StepDot>
          <Styled.StepText>{`${step} of 5`}</Styled.StepText>
        </>
      )}
    </Styled.StepContainer>
  );

  return (
    <Styled.IdeasModalWrapper open={open} closeHandler={closeHandler} childTopPostion={15} isCreateModal>
      <div>
        <Styled.CoverageContainer>
          <Styled.TitleContainer>
            <Styled.ModalTitle>{!idea ? 'Add coverage' : 'Edit coverage'}</Styled.ModalTitle>
            {!!idea && (
              <Styled.BackButton onClick={closeHandler}>
                <Styled.BackButtonImage src={ArrowLeftIcon} alt="back" /> back
              </Styled.BackButton>
            )}
            {!idea && (
              <Styled.CloseButton onClick={closeHandler}>
                <Styled.CloseImage src={CloseIcon} alt="close-icon" />
                close
              </Styled.CloseButton>
            )}
          </Styled.TitleContainer>
          <Styled.Section>
            {getStep(1, 'Security', !selectedCompany)}
            {!selectedCompany && <EnterCompanyComponent handleClickCompany={handleClickCompany} />}
            {!!selectedCompany && (
              <Styled.ActiveCompany>
                <span>{selectedCompany.id}</span>
                <span>{selectedCompany.name}</span>
              </Styled.ActiveCompany>
            )}
          </Styled.Section>

          {!!selectedCompany && (
            <Styled.Section>
              {getStep(2, 'Investment time horizon', !horizon)}
              <Styled.TimeHorizonContainer>
                {Object.values(IdeaHorizon)
                  .filter((item) => item !== IdeaHorizon.SKIP)
                  .map((item) => (
                    <Styled.TimeHorizonItem key={item} onClick={() => setHorizon(item)} isDisabled={!!horizon && horizon !== item}>
                      {`${horizonText[item]} months`}
                    </Styled.TimeHorizonItem>
                  ))}
                <Styled.TimeHorizonItem
                  key={IdeaHorizon.SKIP}
                  onClick={() => setHorizon(IdeaHorizon.SKIP)}
                  isDisabled={!!horizon && horizon !== IdeaHorizon.SKIP}
                >
                  {`${horizonText[IdeaHorizon.SKIP]}`}
                </Styled.TimeHorizonItem>
              </Styled.TimeHorizonContainer>
            </Styled.Section>
          )}

          {!!selectedCompany && !!horizon && (
            <Styled.Section>
              {getStep(3, 'Your view', !viewSelected)}
              <IdeaVoteComponent
                borderRadius={5}
                answer={answer}
                allowChange
                answerCallback={(ideaAnswer: number) => {
                  if (answer === ideaAnswer) {
                    setAnswer(null);
                  } else {
                    setAnswer(ideaAnswer);
                  }
                }}
              />
            </Styled.Section>
          )}

          {!!selectedCompany && !!horizon && viewSelected && (
            <>
              <Styled.Section>
                {getStep(4, 'Reasons for your view', !idea, true)}
                <Styled.ReasonsContainer>
                  {ideaReasons.map((item) => (
                    <Styled.ReasonSelectItem
                      selected={reasons.includes(item)}
                      onClick={reasonOnClick(item)}
                      answer={answer || 0}
                      key={item}
                    >
                      {item}
                    </Styled.ReasonSelectItem>
                  ))}
                </Styled.ReasonsContainer>
              </Styled.Section>

              {postTextForOutput && (
                <Styled.Section>
                  {getStep(5, 'Further thoughts', !idea, false, true)}
                  <InputWithImageComponent
                    text={postTextForOutput}
                    image={null}
                    setText={(text: string) => {}}
                    onPaste={() => {}}
                    onDrop={() => {}}
                    uploadPhoto={() => {}}
                    removePhoto={() => {}}
                    isEdit={false}
                  />
                </Styled.Section>
              )}
            </>
          )}
        </Styled.CoverageContainer>

        <Styled.ActionsContainer>
          <PostButtonComponent
            isDisabled={!selectedCompany || !horizon || answer === null}
            color={answer === BEAR_ANSWER ? 'bear' : answer === BULL_ANSWER ? 'bull' : 'default'}
            isLoading={createIsLoading || updateIsLoading}
            onClick={confirmClick}
            borderRadius={8}
          />
          {!!idea && (
            <Styled.DeleteButton onClick={deleteClick} isClicked={deleteClicked}>
              <Styled.DeleteButtonImage src={!deleteClicked ? TrashIcon : TrashIconRed} alt="delete" /> delete coverage{' '}
              {deleteClicked && (
                <Styled.DeleteButtonClose
                  src={CloseBlackIcon}
                  alt="close"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteClicked(false);
                  }}
                />
              )}
            </Styled.DeleteButton>
          )}
        </Styled.ActionsContainer>
      </div>
    </Styled.IdeasModalWrapper>
  );
};
