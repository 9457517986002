// stepper.component.tsx
import React from 'react';
import Image from 'next/image';
import { Stack } from '@mui/material';
import * as Styled from './stepper.styled';
import CheckMark from '@/styles/icons/stepper-checkmark.webp';
import DecorProgressDot from '@/styles/icons/stepper-decor-dot.webp';
import DecorProgressDisabledDotLightGray from '@/styles/icons/stepper-decor-disable-dot-light-gray.png';
import DecorProgressDisabledDot from '@/styles/icons/stepper-decor-disable-dot.webp';
import DecorProgressDotGray from '@/styles/icons/gray-dot-active.png';

interface StepElement {
  value: string | number;
  lineHeight?: string;
  isDisabledDotLightGray?: boolean;
  disabledLineHeight?: string;
}

interface IProps {
  step: number;
  steps: StepElement[];
  isDotted?: boolean;
  allDotsActive?: boolean;
  grayDotStep?: number;
}

export const StepperComponent = ({ step, steps, grayDotStep, isDotted, allDotsActive }: IProps) => (
  <Styled.Container>
    {steps.map((el, i) => {
      const isActive = i < step;
      const isLineActive = i < step - 1;
      const lineHeight = isActive ? el.lineHeight || '80px' : el.disabledLineHeight || '80px';
      const lineHeightPx = parseInt(lineHeight, 10);

      const dotHeight = 2.5;
      const gap = 4;
      const totalPerDot = dotHeight + gap;
      const N = Math.floor((lineHeightPx + gap) / totalPerDot);

      let DotComponent;
      if (i === grayDotStep) {
        DotComponent = <Image src={DecorProgressDotGray} width={20} height={20} alt="gray-dot-icon" />;
      } else if ((isDotted && isActive) || allDotsActive) {
        DotComponent = <Image src={DecorProgressDot} width={20} height={20} alt="active-icon" />;
      } else if (isDotted && !isActive) {
        DotComponent = (
          <Image
            src={el.isDisabledDotLightGray ? DecorProgressDisabledDotLightGray : DecorProgressDisabledDot}
            width={20}
            height={20}
            alt="disabled-icon"
          />
        );
      } else {
        DotComponent = (
          <Styled.Point isActive={isActive} isFirst={i === 0} isDotted={isDotted ?? false}>
            {el.value === '✓' ? <Image src={CheckMark} width={10} height={10} alt="checkmark" /> : <Styled.Text>{el.value}</Styled.Text>}
          </Styled.Point>
        );
      }

      return (
        <React.Fragment key={i}>
          {DotComponent}

          {i !== steps.length - 1 &&
            (isDotted ? (
              <Stack
                gap={`${gap}px`}
                sx={{
                  height: lineHeightPx,
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {Array.from({ length: N }).map((_, index) => (
                  <Styled.OvalDot key={index} />
                ))}
              </Stack>
            ) : (
              <Styled.Line isActive={isLineActive} height={lineHeight} />
            ))}
        </React.Fragment>
      );
    })}
  </Styled.Container>
);
