import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const SelectButton = styled.button<{ selected?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-weight: ${FONTS.WEIGHTS.light};
  border: none;
  padding: 5px;
  border-radius: 3px;
  background: ${({ selected }) => (selected ? COLORS.gainsboroGray : COLORS.gray95)};
  cursor: pointer;
`;
