import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Styled from './ideas-modal.styled';
import { useCompaniesBySearchUnique, useDebounce } from '@/common/hooks';
import { ICompanySearchFilter, ISelectedCompany } from '@/common/types/coverage.type';

type EnterCompanyComponentProps = {
  handleClickCompany: (selectedCompany: ISelectedCompany) => () => void;
};

export const EnterCompanyComponent = ({ handleClickCompany }: EnterCompanyComponentProps) => {
  const [activeCompanies, setActiveCompanies] = useState<ISelectedCompany[] | null>(null);
  const [companyFilterBy, setCompanyFilterBy] = useState('');
  const companyInputRef = useRef<HTMLInputElement | null>(null);

  const debouncedForCompany = useDebounce<ICompanySearchFilter>({ symbol: companyFilterBy }, 500);
  useCompaniesBySearchUnique(debouncedForCompany, setActiveCompanies);

  const onCompanyEnter = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyFilterBy(e.target.value);
  };

  useEffect(() => {
    if (companyInputRef.current) {
      companyInputRef.current.focus();
    }
  }, []);

  return (
    <Styled.EnterCompanyContainer>
      <Styled.CompanyInput
        ref={companyInputRef}
        placeholder="Enter security ticker / name"
        value={companyFilterBy ?? ''}
        onChange={onCompanyEnter}
      />
      {!!activeCompanies && !!activeCompanies.length && (
        <Styled.ActiveCompaniesContainer>
          {activeCompanies
            .filter((it) => it.id && it.name)
            .map((x) => (
              <Styled.ActiveCompany onClick={handleClickCompany(x)} key={x.id}>
                <span>{x.id}</span>
                <span>{x.name}</span>
              </Styled.ActiveCompany>
            ))}
        </Styled.ActiveCompaniesContainer>
      )}
    </Styled.EnterCompanyContainer>
  );
};
