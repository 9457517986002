import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';

export const ModalWrapper = styled(ModalComponent)`
  border-radius: 10px;
`;

export const Wrapper = styled(ModalComponent)`
  width: 574px;
  border-radius: 37px;
  background: ${COLORS.searchModalBackgroundColor};
  backdrop-filter: blur(1.5px);
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SearchResultWrapper = styled.div`
  border-radius: 20px;
  background-color: rgba(253, 253, 253, 0.7);
  backdrop-filter: blur(1.5px);
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 15px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px dashed #ddd;
`;

export const SeeAllResultButton = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 7px;
  gap: 10px;
  border: none;
  border-radius: 3px;
  background-color: ${COLORS.backgroundColorSeeAllButton};
  width: fit-content;

  color: ${COLORS.connectionRequestButtonColor};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.m};

  cursor: pointer;
`;

export const TextWrapper = styled.div`
  margin-left: -5px;
`;

export const SearchIcon = styled(Image)`
  width: 14px;
  height: 14px;
`;
export const ArrowIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;
