import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAuthProfile, useAuth, useDebounce, getIsAnonUser, useGetFilters } from '@/common/hooks';
import * as Styled from './poll-page.styled';
import { quotePostModalWrapper } from '../profile/components/new-quote-modal/new-quote-modal-wrapper.component';
import { ReplyModalWrapper } from '../profile/components/reply-modal';
import { useGetAllPollFilter, useGetAvailablePollFilter } from '@/common/hooks/use-poll';
import { CreateModalComponent } from '../create-modal/create-modal.component';
import { CreateModalType } from '../create-modal/modal-type.const';
import AddPollIcon from '@/styles/icons/create-poll-plus.webp';
import FilterAvailableIcon from '@/styles/icons/poll-filter-available.webp';
import FilterDisabledIcon from '@/styles/icons/poll-filter-disabled.webp';
import FilterSelectedIcon from '@/styles/icons/poll-filter-selected.webp';
import FilterExcludedIcon from '@/styles/icons/poll-filter-excluded.webp';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { SearchInputComponent } from '../memo/components/search-input';
import { FilterType } from '@/common/types/profile.type';
import { PollListComponent } from './components/poll-list';
import { FlexComponent } from '../flex';
import { FilterMoreButtonComponent } from '../memo/components/memo-filters/components/memo-filter-more-button';

const REGIONS_FILTER = [
  { label: 'n. america', value: 'North America' },
  { label: 'europe', value: 'Europe' },
  { label: 'china', value: 'China' },
  { label: 'india', value: 'India' },
  { label: 'japan', value: 'Japan' },
  { label: 's + se asia', value: 'South and Southeast Asia' },
  { label: 'latam', value: 'Latin America' },
  { label: 'australasia', value: 'Australasia' },
  { label: 'korea', value: 'Korea' },
  { label: 'middle east', value: 'Middle East' },
  { label: 'africa', value: 'Africa' }
];

const SECTORS = [
  'consumer',
  'software',
  'internet',
  'media',
  'semis',
  'ai',
  'hardware',
  'healthcare',
  'financials',
  'industrials',
  'materials',
  'energy',
  'r. estate'
];

export const ALL_PERIODS = [2024, 2025, 2026];

const CATEGORIES = ['equity'];

export const PollPageComponent = () => {
  const { isUserLoading } = useAuth();
  const { data: userProfileId } = useAuthProfile();
  const isAnon = getIsAnonUser();
  const [search, setSearch] = useState('');
  const [periods, setPeriods] = useState<{ value: number; exclude: boolean }[]>([]);
  const [regions, setRegions] = useState<{ value: string; exclude: boolean }[]>([]);
  const [sectors, setSectors] = useState<{ value: string; exclude: boolean }[]>([]);
  const [categories, setCategories] = useState<{ value: string; exclude: boolean }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpenSector, setIsOpenSector] = useState(false);
  const [isOpenRegion, setIsOpenRegion] = useState(false);
  const [refetch, setRefetch] = useState<() => {}>();
  const {
    filteredSectors,
    filteredExcludeSectors,
    filteredRegions,
    filteredExcludeRegions,
    filteredPeriods,
    filteredExcludePeriods,
    filteredCategories,
    filteredExcludeCategories
  } = {
    filteredSectors: sectors.filter((it) => !it.exclude).map((it) => it.value),
    filteredExcludeSectors: sectors.filter((it) => it.exclude).map((it) => it.value),
    filteredRegions: regions.filter((it) => !it.exclude).map((it) => it.value),
    filteredExcludeRegions: regions.filter((it) => it.exclude).map((it) => it.value),
    filteredPeriods: periods.filter((it) => !it.exclude).map((it) => it.value),
    filteredExcludePeriods: periods.filter((it) => it.exclude).map((it) => it.value),
    filteredCategories: categories.filter((it) => !it.exclude).map((it) => it.value),
    filteredExcludeCategories: categories.filter((it) => it.exclude).map((it) => it.value)
  };
  const {
    sectors: deboucedSectors,
    excludeSectors: deboucedExcludeSectors,
    regions: deboucedRegions,
    excludeRegions: deboucedExcludeRegions,
    periods: deboucedPeriods,
    excludePeriods: deboucedExcludePeriods,
    categories: deboucedCategories,
    excludeCategories: deboucedExcludeCategories,
    search: debouncedSearch
  } = useDebounce(
    {
      sectors: filteredSectors,
      excludeSectors: filteredExcludeSectors,
      regions: filteredRegions,
      excludeRegions: filteredExcludeRegions,
      periods: filteredPeriods,
      excludePeriods: filteredExcludePeriods,
      categories: filteredCategories,
      excludeCategories: filteredExcludeCategories,
      search
    },
    1000
  );
  const { quotePostHandler, componentToView } = quotePostModalWrapper();
  const { openReplyModal, componentToView: replyModalComponentToView } = ReplyModalWrapper({});
  const leftColumnRef = useRef<null | HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const { data: allFilters, isLoading: isLoadingAllFilters } = useGetAllPollFilter();

  const { data: filters, isLoading: isLoadingFilters, isRefetching: isRefetchingFilter } = useGetFilters(FilterType.POLL);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const {
    data: availableFilters,
    isFetching: isLoadingAvailableFilters,
    refetch: refetchAvailableFilters
  } = useGetAvailablePollFilter({
    regions: deboucedRegions,
    excludeRegions: deboucedExcludeRegions,
    periods: deboucedPeriods,
    excludePeriods: deboucedExcludePeriods,
    sectors: deboucedSectors,
    excludeSectors: deboucedExcludeSectors,
    categories: deboucedCategories,
    excludeCategories: deboucedExcludeCategories,
    search: debouncedSearch
  });
  const [openModal, setOpenModal] = useState(false);

  const addDebateClick = () => {
    setOpenModal(true);
  };

  const allPeriods = useMemo(
    () => (allFilters?.periods && allFilters?.periods?.length > 0 ? allFilters?.periods.sort((a, b) => a - b) : []),
    [allFilters?.periods]
  );

  const availablePeriods = useMemo(
    () => (availableFilters?.periods && availableFilters?.periods?.length > 0 ? availableFilters?.periods.sort((a, b) => a - b) : []),
    [availableFilters?.periods]
  );

  const availableRegions = useMemo(
    () => (availableFilters?.regions && availableFilters?.regions?.length > 0 ? availableFilters?.regions : []),
    [availableFilters?.regions]
  );

  const availableSectors = useMemo(
    () => (availableFilters?.sectors && availableFilters?.sectors?.length > 0 ? availableFilters?.sectors : []),
    [availableFilters?.sectors]
  );

  const availableCategories = useMemo(
    () => (availableFilters?.categories && availableFilters?.categories?.length > 0 ? availableFilters?.categories : []),
    [availableFilters?.categories]
  );

  useEffect(() => {
    if (!filters && !isLoadingFilters && !isRefetchingFilter) {
      setIsLoading(false);
    }
    if (filters && !isLoadingFilters && !isRefetchingFilter) {
      setSectors([
        ...(filters.sectors?.map((item) => ({
          exclude: false,
          value: item
        })) ?? []),
        ...(filters.excludeSectors?.map((item) => ({
          exclude: true,
          value: item
        })) ?? [])
      ]);
      setRegions([
        ...(filters.regions?.map((item) => ({
          exclude: false,
          value: item
        })) ?? []),
        ...(filters.excludeRegions?.map((item) => ({
          exclude: true,
          value: item
        })) ?? [])
      ]);
      setCategories([
        ...(filters.categories?.map((item) => ({
          exclude: false,
          value: item
        })) ?? []),
        ...(filters.excludeCategories?.map((item) => ({
          exclude: false,
          value: item
        })) ?? [])
      ]);
      setPeriods([
        ...(filters.periods?.map((item) => ({ exclude: false, value: Number.parseInt(item, 10) })) ?? []),
        ...(filters.excludePeriods?.map((item) => ({ exclude: true, value: Number.parseInt(item, 10) })) ?? [])
      ]);
      setTimeout(() => setIsLoading(false), 550);
    }
  }, [filters, isLoadingFilters, isRefetchingFilter]);

  const getValidIcon = (isActive: boolean, isExcludeActive: boolean, isDisabled: boolean) =>
    isActive ? FilterSelectedIcon : isExcludeActive ? FilterExcludedIcon : isDisabled ? FilterDisabledIcon : FilterAvailableIcon;

  const prepareSectorButton = (sector: string) => {
    const findSector = sectors.find((item) => item.value === sector);
    const isActive = findSector ? !findSector.exclude : false;
    const isExcludeActive = findSector ? findSector.exclude : false;
    const isDisabled = !availableSectors.includes(sector) && !findSector;
    return (
      <Styled.PollFilterRowItem
        id={`${sector}-poll-sector`}
        isActive={isActive}
        isExcludeActive={isExcludeActive}
        isDisabled={isDisabled}
        onClick={() => {
          if (isLoadingAvailableFilters || isDisabled) return;
          setSectors((prev) => {
            const findSectorInPrev = prev.find((item) => item.value === sector);
            if (!findSectorInPrev) {
              return [...prev, { exclude: false, value: sector }];
            }
            if (findSectorInPrev?.exclude) {
              return prev.filter((item) => item.value !== sector);
            }
            return prev.map((item) => (item.value === sector ? { ...item, exclude: !item.exclude } : { ...item }));
          });
        }}
      >
        {sector}
        <Styled.FilterButtonImage src={getValidIcon(isActive, isExcludeActive, isDisabled)} alt="icon" />
      </Styled.PollFilterRowItem>
    );
  };

  const prepareRegionButton = (region: { label: string; value: string }) => {
    const findRegion = regions.find((item) => item.value === region.value);
    const isActive = findRegion ? !findRegion.exclude : false;
    const isExcludeActive = findRegion ? findRegion.exclude : false;
    const isDisabled = !availableRegions.includes(region.value) && !findRegion;
    return (
      <Styled.PollFilterRowItem
        onClick={() => {
          if (isLoadingAvailableFilters || isDisabled) return;
          setRegions((prev) => {
            const findRegionInPrev = prev.find((item) => item.value === region.value);
            if (!findRegionInPrev) {
              return [...prev, { exclude: false, value: region.value }];
            }
            if (findRegionInPrev?.exclude) {
              return prev.filter((item) => item.value !== region.value);
            }
            return prev.map((item) => (item.value === region.value ? { ...item, exclude: !item.exclude } : { ...item }));
          });
        }}
        isActive={isActive}
        isExcludeActive={isExcludeActive}
        isDisabled={isDisabled}
      >
        {region.label}
        <Styled.FilterButtonImage src={getValidIcon(isActive, isExcludeActive, isDisabled)} alt="icon" />
      </Styled.PollFilterRowItem>
    );
  };

  return (
    <Styled.PollPageWrapper
      contentLeftMargin="56px"
      isLoading={isUserLoading || isLoadingFilters || isRefetchingFilter || isLoading}
      sidebarActive="Polls"
      disableScroll
    >
      {componentToView}
      {replyModalComponentToView}
      {!!userProfileId && !isLoadingAllFilters && !isLoadingFilters ? (
        <AnimatePresence>
          <motion.div
            transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Styled.ColumnsWrapper>
              <Styled.LeftColumn ref={leftColumnRef}>
                <Styled.HeaderContainer>
                  <Styled.AddPollButton onClick={addDebateClick}>
                    <Styled.AddPolIcon src={AddPollIcon} alt="add-poll" />
                    <Styled.AddPolText>Create poll</Styled.AddPolText>
                  </Styled.AddPollButton>
                </Styled.HeaderContainer>
                <CreateModalComponent
                  open={openModal}
                  closeHandler={() => {
                    if (refetch && typeof refetch === 'function') {
                      refetch();
                    }
                    refetchAvailableFilters();
                    setOpenModal(false);
                  }}
                  modalType={CreateModalType.POLL}
                />
                <PollListComponent
                  filters={
                    isMounted
                      ? {
                          sectors: deboucedSectors,
                          excludeSectors: deboucedExcludeSectors,
                          regions: deboucedRegions,
                          excludeRegions: deboucedExcludeRegions,
                          periods: deboucedPeriods,
                          excludePeriods: deboucedExcludePeriods,
                          categories: deboucedCategories,
                          excludeCategories: deboucedExcludeCategories,
                          search: debouncedSearch
                        }
                      : {
                          sectors: filteredSectors,
                          excludeSectors: filteredExcludeSectors,
                          regions: filteredRegions,
                          excludeRegions: filteredExcludeRegions,
                          periods: filteredPeriods,
                          excludePeriods: filteredExcludePeriods,
                          categories: filteredCategories,
                          excludeCategories: filteredExcludeCategories,
                          search
                        }
                  }
                  quotePostHandler={quotePostHandler}
                  openReplyModal={openReplyModal}
                  setRefetch={setRefetch}
                  userProfileId={userProfileId}
                />
              </Styled.LeftColumn>
              <Styled.RightColumn>
                <SearchInputComponent isAnon={isAnon} mb={40} value={search} onChange={setSearch} placeholder="search polls" />
                <Styled.PollFilterWrapper>
                  <FlexComponent gap="10px" alignItems="center">
                    <Styled.PollFilterTitle>Filter the list of polls</Styled.PollFilterTitle>
                    <Styled.PollFilterText>click tab twice to exclude item</Styled.PollFilterText>
                  </FlexComponent>
                  <Styled.PollFilterRowContainer>
                    <Styled.Hidden src={FilterSelectedIcon} alt="icon" />
                    <Styled.Hidden src={FilterDisabledIcon} alt="icon" />
                    <Styled.Hidden src={FilterAvailableIcon} alt="icon" />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Period</Styled.PollFilterRowTitle>
                      {allPeriods &&
                        allPeriods.map((period) => {
                          const findPeriod = periods.find((item) => item.value === period);
                          const isActive = findPeriod ? !findPeriod.exclude : false;
                          const isExcludeActive = findPeriod ? findPeriod.exclude : false;
                          const isDisabled = !availablePeriods.includes(period) && !findPeriod;
                          return (
                            <Styled.PollFilterRowItem
                              key={`period-${period}`}
                              isActive={isActive}
                              isDisabled={isDisabled}
                              isExcludeActive={isExcludeActive}
                              onClick={() => {
                                if (isLoadingAvailableFilters || isDisabled) return;
                                setPeriods((prev) => {
                                  const findPeriodInPrev = prev.find((item) => item.value === period);
                                  if (!findPeriodInPrev) {
                                    return [...prev, { exclude: false, value: period }];
                                  }
                                  if (findPeriodInPrev?.exclude) {
                                    return prev.filter((item) => item.value !== period);
                                  }
                                  return prev.map((item) => (item.value === period ? { ...item, exclude: !item.exclude } : { ...item }));
                                });
                              }}
                            >
                              {period}
                              <Styled.FilterButtonImage src={getValidIcon(isActive, isExcludeActive, isDisabled)} alt="icon" />
                            </Styled.PollFilterRowItem>
                          );
                        })}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />

                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Sector</Styled.PollFilterRowTitle>
                      {SECTORS.slice(0, -2).map((sector) => prepareSectorButton(sector))}
                      <FilterMoreButtonComponent setIsOpen={setIsOpenSector} isOpen={isOpenSector} />
                      {isOpenSector && SECTORS.slice(-2).map((sector) => prepareSectorButton(sector))}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Region</Styled.PollFilterRowTitle>
                      {REGIONS_FILTER.slice(0, 6).map((region) => prepareRegionButton(region))}
                      <FilterMoreButtonComponent setIsOpen={setIsOpenRegion} isOpen={isOpenRegion} />
                      {isOpenRegion && REGIONS_FILTER.slice(6).map((region) => prepareRegionButton(region))}
                    </Styled.PollFilterRow>
                    <Styled.PollFilterLine />
                    <Styled.PollFilterRow>
                      <Styled.PollFilterRowTitle>Category</Styled.PollFilterRowTitle>
                      {CATEGORIES.map((category) => {
                        const findCategory = categories.find((item) => item.value === category);
                        const isActive = findCategory ? !findCategory.exclude : false;
                        const isExcludeActive = findCategory ? findCategory.exclude : false;
                        const isDisabled = !availableCategories.includes(category) && !findCategory;
                        return (
                          <Styled.PollFilterRowItem
                            id={`${category}-poll-categories`}
                            isActive={isActive}
                            isExcludeActive={isExcludeActive}
                            isDisabled={isDisabled}
                            onClick={() => {
                              if (isLoadingAvailableFilters || isDisabled) return;
                              setCategories((prev) => {
                                const findCategoryInPrev = prev.find((item) => item.value === category);
                                if (!findCategoryInPrev) {
                                  return [...prev, { exclude: false, value: category }];
                                }
                                if (findCategoryInPrev?.exclude) {
                                  return prev.filter((item) => item.value !== category);
                                }
                                return prev.map((item) => (item.value === category ? { ...item, exclude: !item.exclude } : { ...item }));
                              });
                            }}
                          >
                            {category}
                            <Styled.FilterButtonImage src={getValidIcon(isActive, isExcludeActive, isDisabled)} alt="icon"
                            />
                          </Styled.PollFilterRowItem>
                        );
                      })}
                    </Styled.PollFilterRow>
                  </Styled.PollFilterRowContainer>
                </Styled.PollFilterWrapper>
              </Styled.RightColumn>
            </Styled.ColumnsWrapper>
          </motion.div>
        </AnimatePresence>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Styled.PollPageWrapper>
  );
};
