import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';
import { SelectButtonComponent } from '@/common/components/select-button';
import { BEAR_ANSWER, BULL_ANSWER } from '@/common/consts/idea.const';
import { PostButtonCommonStyle } from '@/common/components/modal/components/post-button';
import { FAMILIES } from '@/theme/fonts.const';

export const IdeasModalWrapper = styled(ModalComponent)`
  min-width: 600px;
  max-width: 600px;
  min-height: calc(100vh - 30px);
  padding: 35px 50px 18px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  flex-direction: column;
  color: #000;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
`;
export const CoverageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  min-height: 560px;
  height: fit-content;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 40px;
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const CloseImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ActionsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ModalTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 7px;
  height: 12px;
  margin-bottom: 10px;
`;

export const StepTitle = styled.div<{ isActive?: boolean }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: ${({ isActive }) => (isActive ? '#000' : '#8A8A8A')};
`;

export const StepText = styled.div`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #9f9f9f;
`;

export const StepIsNotEditable = styled.div`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #bdbdbd;
`;

export const StepDot = styled.div`
  color: #c4c4c4;
  font-weight: ${FONTS.WEIGHTS.medium};
  display: flex;
  align-items: center;
`;

export const EnterCompanyContainer = styled.div`
  width: 100%;
`;

export const CompanyInput = styled.input`
  border: none;
  background: transparent;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: 20px;
  color: ${COLORS.black};
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #969696;
  }
`;

export const ActiveCompaniesContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4px;
`;

export const ActiveCompany = styled.button`
  padding: 8px 12px;
  border-radius: 5px;
  background: #f2f2f2;
  color: #000;
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  line-height: ${FONTS.SIZES.m};
  border: none;
  cursor: pointer;

  span {
    font-family: ${FONTS.FAMILIES.robotoFlex};
  }

  & > span:first-child {
    margin-right: 9px;
  }
`;

export const TimeHorizonContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const TimeHorizonItem = styled.button<{ isDisabled?: boolean }>`
  border: 0;
  border-radius: 5px;
  background: ${({ isDisabled }) => (isDisabled ? '#F6F6F6' : '#f2f2f2')};
  color: ${({ isDisabled }) => (isDisabled ? '#C2C2C2' : '#000')};
  padding: 8px 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
  font-family: ${FONTS.FAMILIES.robotoFlex};
`;
export const ReasonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ReasonSelectItem = styled(SelectButtonComponent)<{ answer: number; selected: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  padding: 8px 12px;
  border-radius: 5px;
  background: ${({ selected }) => (!selected ? '#f2f2f2' : '#D0D0D0')};
  ${({ answer, selected }) => (answer === BEAR_ANSWER && selected ? 'color: #4D0EB4;' : '')}
  ${({ answer, selected }) => (answer === BEAR_ANSWER && selected ? 'background: rgba(77, 14, 180, 0.10);' : '')}
  ${({ answer, selected }) => (answer === BULL_ANSWER && selected ? 'color: #12847D;' : '')}
  ${({ answer, selected }) => (answer === BULL_ANSWER && selected ? 'background: rgba(0, 146, 137, 0.10);' : '')}
`;

export const BackButton = styled.button`
  ${PostButtonCommonStyle}
  position: absolute;
  top: 30px;
  right: 40px;
  line-height: 12px;
  color: #515151;
  padding: 8px 10px;
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.s};
  background: #eee;
  border-radius: 10px;
`;

export const BackButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const DeleteButton = styled.button<{ isClicked: boolean }>`
  ${PostButtonCommonStyle}
  padding: 10px 12px;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  background: #ededed;
  border-radius: 8px;
  ${({ isClicked }) =>
    isClicked ? 'border: 1px solid rgba(241, 0, 0, 0.20); background: rgba(241, 0, 0, 0.03); color: #F10000; padding: 9px 11px' : ''};
`;

export const DeleteButtonImage = styled(Image)`
  width: 11px;
  height: 14px;
`;

export const DeleteButtonClose = styled(Image)`
  width: 10px;
  height: 10px;
  margin-left: 12px;
`;
