import styled from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';

export const RowWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;
export const ButtonInRow = styled.button`
  border: none;
  border-radius: 3px;
  background-color: ${COLORS.addAnotherBackgroundColor};
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  padding: 3px 7px;

  display: flex;
  gap: 5px;
  align-items: center;

  max-width: 100%;

  cursor: pointer;

  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Icon = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const Arrow = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const TickerText = styled.div`
  color: ${COLORS.sectorsInPostTile};
  font-size: ${FONTS.SIZES.s};
`;

export const RowTitle = styled.div`
  display: inline-flex;
  padding: 6px 7px;
  align-items: start;
  gap: 5px;
  border-radius: 3px;
  background-color: ${COLORS.backgroundColorInFilterButtonValidAndNotActive};

  color: ${COLORS.connectionRequestButtonColor};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.m};
`;
