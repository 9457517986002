import { EnhancedWithAuthHttpService } from '@/common/services/http-auth.service';
import { axiosInstance } from '@/common/services/axios';
import { HttpService } from '@/common/services/http.service';
import {
  FollowedQuery,
  IAddQueryAnswerPayload,
  IQuestion,
  IQuestionPayload,
  IQuestionProfileResponse,
  ISentiment,
  ISentimentSwings
} from '@/common/types/question.type';
import { IPost } from '../types/post.type';

class QuestionService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getSectorQuestions() {
    return this.get<IQuestion[]>('question/sector');
  }

  async getCompaniesQuestions() {
    return this.get<IQuestion[]>('question/company');
  }

  async getQueriesQuestions() {
    return this.get<IQuestion[]>('question/query');
  }

  async getSentimentProfileQuestions(profileId: string) {
    return this.get<IQuestionProfileResponse>(`question/sentiment-profile/${profileId}`);
  }

  async getSentimentHomeQuestions() {
    return this.get<ISentiment[]>('question/sentiment-home');
  }

  async getSentimentSwings() {
    return this.get<ISentimentSwings[]>('question/sentiment-swings');
  }

  async createQuestion(question: IQuestionPayload) {
    return this.post<IPost, IQuestionPayload>('question', question);
  }

  async addQueryAnswer(answer: IAddQueryAnswerPayload) {
    return this.post<{ post: IPost; questionId: string }, IAddQueryAnswerPayload>('question/answer', answer);
  }

  async getFollowedQueries() {
    return this.get<FollowedQuery[]>('question/followed-queries');
  }

  async getQueryAnswer(questionId: string) {
    return this.get<number>(`question/answer/${questionId}`);
  }
}

export const questionService = new QuestionService();
