import { ILinkPreview } from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class LinkPreviewService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getLinkPreview(urlForLinkPreview: string) {
    const data = await this.get<ILinkPreview | null>(`link-preview/?url=${urlForLinkPreview}`);
    return { data, url: urlForLinkPreview };
  }
}

export const linkPreviewService = new LinkPreviewService();
