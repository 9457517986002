const verticalInputToOutput = [
  { input: 'Equity Long / Short', output: 'Long / Short Equity' },
  { input: 'Equity Market Neutral', output: 'Market Neutral Equity' },
  { input: 'Equity Long Only', output: 'Long Only Equity' },
  { input: 'Equity Crossover', output: 'Crossover Equity' },
  { input: 'Equity Short Only', output: 'Short Only Equity' },
  { input: 'Equity Research', output: 'Equity Research' },
  { input: 'Equity Sales / Trading', output: 'Equity Sales / Trading' },
  { input: 'Venture Full Cycle', output: 'Full Cycle VC' },
  { input: 'Venture Early Stage', output: 'Early Stage VC' },
  { input: 'Venture Growth', output: 'Growth VC' },
  { input: 'Venture Crypto', output: 'Crypto VC' },
  { input: 'Private Equity Buyouts', output: 'Private Equity' },
  { input: 'Private Equity Search Fund', output: 'Search Fund' },
  { input: 'Private Equity Rights', output: 'Rights Fund' },
  { input: 'Credit Investment Grade', output: 'Investment Grade Credit' },
  { input: 'Credit HY + Distressed', output: 'HY + Distressed Credit' },
  { input: 'Credit EM', output: 'EM Credit' },
  { input: 'Credit Research', output: 'Credit Research' },
  { input: 'Macro Cross-Asset', output: 'Cross-Asset Macro' },
  { input: 'Macro FX', output: 'FX' },
  { input: 'Macro Fixed Income', output: 'Fixed Income' },
  { input: 'Macro Equities', output: 'Macro Equities' },
  { input: 'Macro Energy', output: 'Energy' },
  { input: 'Macro Metals', output: 'Metals' },
  { input: 'Macro Agriculture', output: 'Agriculture Macro' },
  { input: 'Macro Research', output: 'Macro Research' },
  { input: 'Real Assets Infrastructure', output: 'Infrastructure Fund' },
  { input: 'Real Assets Real Estate', output: 'Real Estate Fund' },
  { input: 'Real Assets Agriculture', output: 'Agriculture Fund' },
  { input: 'Cross-Asset Quant', output: 'Quant' },
  { input: 'Cross-Asset Event-Driven', output: 'Event-Driven' },
  { input: 'Allocation Endowment', output: 'Endowment' },
  { input: 'Allocation Pension Fund', output: 'Pension Fund' },
  { input: 'Allocation Family Office', output: 'Family Office' },
  { input: 'Allocation Private Wealth', output: 'Private Wealth' },
  { input: 'Allocation Insurance', output: 'Insurance Allocator' },
  { input: 'Allocation Fund of Funds', output: 'Fund of Funds' },
  { input: 'Advisory Investment Banking', output: 'Investment Banking' },
  { input: 'Advisory Consulting', output: 'Consulting' },
  { input: 'Publishing Financial Media', output: 'Financial Media' },
  { input: 'Publishing Sole Publisher', output: 'Sole Publisher' }
];

export const findOutputVertical = (inputVertical?: string | null) => {
  if (!inputVertical) {
    return '';
  }
  const findVertical = verticalInputToOutput.find((item) => item.input === inputVertical);
  return findVertical?.output || inputVertical;
};
