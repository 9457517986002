import styled from 'styled-components';
import { FONTS } from '@/theme';
import { UserImageComponent } from '@/common/components/user-image';
import { FAMILIES } from '@/theme/fonts.const';

export const OraclesTitle = styled.p`
  color: #343434;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const UserImageComponentWrapper = styled(UserImageComponent)<{ isAlone: boolean; index?: number }>`
  max-width: 33px;
  min-width: 33px;
  width: 33px;
  max-height: 33px;
  min-height: 33px;
  height: 33px;
  ${({ index }) => index && `z-index: ${index};`}
  ${({ isAlone }) =>
    isAlone &&
    `
    max-width: 33px;
    min-width: 33px;
    width: 33px;
    max-height: 33px;
    min-height: 33px;
    height: 33px;
    `}
  margin-right: -4px;
`;

export const UserImageComponentList = styled.div`
  display: flex;
  margin-left: 8px;
`;

export const OthersText = styled.p`
  margin-left: 7px;
  color: #646464;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;
