import { UserMessage } from '@sendbird/chat/message';

export type TSearchMessageResult = UserMessage & { mentionValues?: { value: string; index: number }[] };

function shortMessages(searchTerm: string, message: string) {
  const words = message.split(' ');

  const indices = words.reduce((acc: number[], word, index) => {
    if (word.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push(index);
    }
    return acc;
  }, []);

  if (indices.length > 0) {
    const index = indices[0];

    const startIndex = Math.max(0, index - 2);
    const endIndex = Math.min(words.length - 1, index + 2);

    const shortenedMessage = words.slice(startIndex, endIndex + 1).join(' ');

    const prefix = startIndex > 0 ? '.. ' : '';
    const suffix = endIndex < words.length - 1 ? ' ..' : '';

    return `${prefix}${shortenedMessage}${suffix}`;
  }

  return message;
}

function findSubstring(mention: string, message: string) {
  const mentionArray = mention.split(' ');
  let currentSubstring = '';
  let longestSubstring = '';

  for (let i = 0; i < mentionArray.length; i += 1) {
    const currentWord = mentionArray[i];
    currentSubstring += (i > 0 ? ' ' : '') + currentWord;

    if (message.includes(currentSubstring)) {
      if (currentSubstring.length > longestSubstring.length) {
        longestSubstring = currentSubstring;
      }
    } else {
      currentSubstring = '';
    }
  }

  return longestSubstring;
}

export const mapMessageSearchResults = (results: UserMessage[], query: string) =>
  results.reduce((acc: any[], r) => {
    if (r.isFileMessage()) return acc;

    const pattern = /!\[(.*?)\{(.*?)\|(.*?)\}\]/g;

    const withMention = pattern.test(r.message);
    pattern.lastIndex = 0;

    if (!withMention) {
      return [
        ...acc,
        {
          ...r,
          message: shortMessages(query, r.message.trim())
        }
      ];
    }
    let match;
    let text = r.message;
    const mentionValues = [];

    // eslint-disable-next-line no-cond-assign
    while ((match = pattern.exec(text)) !== null) {
      const fullMatch = match[0];
      const mentionValue = match[1].slice(1);

      mentionValues.push({ value: mentionValue.trim(), index: match.index });

      text = text.replace(fullMatch, mentionValue);
      pattern.lastIndex = 0;
    }

    if (!text.toLowerCase().includes(query.toLowerCase())) {
      return acc;
    }

    const shortStr = shortMessages(query, text);

    return [
      ...acc,
      {
        ...r,
        message: shortStr,
        mentionValues: mentionValues.map((mv) => ({
          value: findSubstring(mv.value, shortStr),
          index: shortStr.startsWith('..') ? mv.index + 3 : mv.index
        }))
      }
    ];
  }, []);
