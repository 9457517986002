import styled from 'styled-components';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';

export const StyledModal = styled(ModalComponent)``;

export const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  & > div {
    background-color: ${COLORS.searchModalBackgroundColor};
    border-radius: 30px;
    backdrop-filter: blur(3px);
    width: 497px;
    padding: 15px;
  }
  & > p {
    padding: 5px 0px 3px 0px;
    color: #df0000;
    text-align: center;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: 18px;
    width: 100%;
  }
  & > span {
    padding: 5px 0px 3px 0px;
    color: #df0000;
    text-align: center;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.m};
    width: 100%;
  }
  & > button {
    padding: 5px 0px 3px 0px;
    border: none;
    background-color: transparent;
    color: #df0000;
    text-align: center;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.xl};
    width: 100%;
    cursor: pointer;
  }
`;

export const LoginWrapper = styled.div`
  color: ${COLORS.black};
  background-color: ${COLORS.loginToRealFromAnonModalBackground};
  border-radius: 20px;
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: fit-content;
    & > h2 {
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-weight: ${FONTS.WEIGHTS.semiBold};
      font-style: normal;
      font-size: ${FONTS.SIZES.l};
      line-height: ${FONTS.SIZES.l};
      letter-spacing: 0.48px;
      color: #0075a7;
    }
    & > span {
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      line-height: ${FONTS.SIZES.s};
      font-weight: ${FONTS.WEIGHTS.normal};
      font-style: normal;
      color: #0075a7;
    }
    & > h4 {
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.m};
      line-height: ${FONTS.SIZES.xxl};
      font-weight: ${FONTS.WEIGHTS.normal};
      font-style: normal;
      color: #3a3a3a;
    }
    & > input {
      background-color: transparent;
      width: 240px;
      text-align: center;
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.m};
      line-height: ${FONTS.SIZES.xxl};
      font-weight: ${FONTS.WEIGHTS.medium};
      color: ${COLORS.black};
      border: none;
      &::placeholder {
        opacity: 1;
        color: ${COLORS.black};
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const ConfirmButton = styled.button`
  border-radius: 0px 0px 20px 20px;
  border: 1px solid rgba(0, 117, 167, 0.2);
  background: rgba(0, 117, 167, 0.1);
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 100%;
  & > p {
    color: #0075a7;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    line-height: ${FONTS.SIZES.m};
  }
  &:disabled {
    border: 1px solid rgba(0, 117, 167, 0.05);
    background: rgba(0, 117, 167, 0.05);
    & > p {
      color: rgba(0, 117, 167, 0.2);
    }
  }
`;

export const CodeInputWrapper = styled.div<{ isHasMarginBottom: boolean }>`
  padding-top: 5px;
  padding-bottom: 5px;
  ${({ isHasMarginBottom }) => isHasMarginBottom && 'padding-bottom: 15px;'}
  & > div {
    & > p {
      width: 100%;
      text-align: center;
    }
  }
`;
