export enum SentimentType {
  DISAGREED_VARIANT = 'DISAGREED_VARIANT',
  ALIGNED = 'ALIGNED',
  AGREED_VARIANT = 'AGREED_VARIANT',
  AWAITING_FOR_RESULT = 'AWAITING_FOR_RESULT'
}

export const questionResultTitleColor = {
  [SentimentType.DISAGREED_VARIANT]: '#4D0EB4',
  [SentimentType.ALIGNED]: '#7D7D7D',
  [SentimentType.AGREED_VARIANT]: '#009289'
};

export const questionLeftLineEndTextColor = {
  [SentimentType.DISAGREED_VARIANT]: '#5A14CD',
  [SentimentType.ALIGNED]: 'rgba(90, 20, 205, 0.5)',
  [SentimentType.AGREED_VARIANT]: '#5A14CD'
};

export const questionRightLineEndTextColor = {
  [SentimentType.DISAGREED_VARIANT]: '#009289',
  [SentimentType.ALIGNED]: 'rgba(0, 146, 137, 0.5)',
  [SentimentType.AGREED_VARIANT]: '#009289'
};
