import {
  ICurrentStatusPayload,
  IFollowsDataResponse,
  IFollowsResponse,
  IPostBasicInfo,
  IPreviousEndeavoursPayload,
  IPreviousEndeavoursResponse,
  IProfile,
  IEditProfile,
  IUserConnections,
  ProfileVisibilityEnum,
  ProfileVisibilitySettings,
  WrokHistoryResponse,
  IChannelsBasicInfo,
  FilterType,
  ISavedFilters
} from '@/common/types/profile.type';
import { IAnswerResponse, IAnswersPayload } from '@/common/types/question.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { IPostStatResponseItem } from '../types/post.type';
import { IUser } from '../types/user.type';

class ProfileService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getProfile(id: string) {
    return this.get<IProfile>(`profile/${id}`);
  }

  async getConnections({ id, type }: { id: string | undefined; type: string }) {
    return this.get<IUserConnections>(`profile/connections?${type}Id=${id}`);
  }

  async getProfileByUserId(userId?: string) {
    if (userId) {
      return this.get<IProfile>(`profile/profile-by-user/${userId}`);
    }
  }

  async getAuthProfileId() {
    return this.get<string>('profile/auth-profile');
  }

  async getWorkHistory(id: string) {
    return this.get<WrokHistoryResponse>(`profile/work-history/${id}`);
  }

  async getProfileVisibilitySettings() {
    return this.get<ProfileVisibilitySettings>('profile/visibility-settings');
  }

  async updateProfileVisibilitySettings(data: ProfileVisibilitySettings) {
    return this.put<ProfileVisibilitySettings, ProfileVisibilitySettings>('profile/visibility-settings', data);
  }

  async setCurrentStatus(currentStatus: ICurrentStatusPayload) {
    return this.post<ICurrentStatusPayload, ICurrentStatusPayload>('profile/current-status', currentStatus);
  }

  async editProfile(currentStatus: IEditProfile) {
    return this.patch<IUser, IEditProfile>('profile/edit-profile', currentStatus);
  }

  async updateBio(bio: string) {
    return this.put<void, { bio: string }>('profile/bio', { bio });
  }

  async updateFullname(fullname: string) {
    return this.put<void, { fullname: string }>('profile/fullname', { fullname });
  }

  async getCurrentStatus() {
    return this.get<ICurrentStatusPayload>('profile/current-status');
  }

  async setPreviousEndeavours(previousEndeavours: IPreviousEndeavoursPayload) {
    return this.post<void, IPreviousEndeavoursPayload>('profile/previous-endeavours', previousEndeavours);
  }

  async getPreviousEndeavours() {
    return this.get<IPreviousEndeavoursResponse>('profile/previous-endeavours');
  }

  async setAnswers(horizon: number, payload: ICurrentStatusPayload) {
    return this.post<IUser, { horizon: number; payload: ICurrentStatusPayload }>('profile/set-answers', { horizon, payload });
  }

  async saveAnswers(answers: string) {
    return this.put<void, { answers: string }>('profile/sentiment', { answers });
  }

  async getAnswers(data: IAnswersPayload) {
    return this.post<IAnswerResponse, IAnswersPayload>('profile/sentiment', data);
  }

  async pullLinkedinProfile(profileId: string) {
    return this.get(`profile/linkedin?url=${profileId}`);
  }

  async removeEducation(id: string) {
    return this.delete(`profile/education/${id}`);
  }

  async removeEmployer(id: string) {
    return this.delete(`profile/employer/${id}`);
  }

  async removeProfile(id: string) {
    return this.delete(`profile/by-user/${id}`);
  }

  async removeProfileOnPull(id: string) {
    return this.delete(`profile/by-user-on-pull/${id}`);
  }

  async getFollowerById(id: string, isFollower: boolean, profileId: string) {
    return this.get<IFollowsDataResponse>(`profile/follower/${id}?profileId=${profileId}&isFollower=${isFollower}`);
  }

  async getFollowersById(id: string, page: number, take?: number) {
    return this.get<IFollowsResponse>(`profile/followers/${id}?take=${take ?? 20}&page=${page}`);
  }

  async getFollowingById(id: string, page: number, take?: number) {
    return this.get<IFollowsResponse>(`profile/following/${id}?take=${take ?? 20}&page=${page}`);
  }

  async getPermissionsByProfileId(profileId: string) {
    return this.get<ProfileVisibilityEnum[]>(`profile/visibility/${profileId}`);
  }

  async getPostBasicInfo() {
    return this.get<IPostBasicInfo>('profile/basic-post-info');
  }

  async getMutualAssociations(userId: string) {
    return this.get<number>(`profile/mutual-associations/${userId}`);
  }

  async getChannelsBasicInfo(userIds: string[]) {
    const query = new URLSearchParams();
    userIds.forEach((id) => query.append('ids', id));
    return this.get<IChannelsBasicInfo[]>(`profile/basic-channels-info?${query}`);
  }

  async getUserInActionModal(profileId: string) {
    return this.get<IPostStatResponseItem['data'][0]>(`profile/action-info/${profileId}`);
  }

  async getFilters(filterType: FilterType) {
    return this.get<ISavedFilters>(`profile/filters?filterType=${filterType}`);
  }
}

export const profileService = new ProfileService();
