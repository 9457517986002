import { FONTS } from '@/theme';
import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 7px;
`;

export const TitleText = styled.span`
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const Dot = styled.span`
  margin: auto 0;
  color: #c4c4c4;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const DescriptionText = styled.span`
  color: #9f9f9f;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;
