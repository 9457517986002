import Image from 'next/image';
import styled, { css } from 'styled-components';
import { COLORS, FONTS, SPACES } from '@/theme';

export const InputWrapper = styled.div<{ isAnon: boolean }>`
  display: flex;
  align-items: center;
  min-height: 57px;
  border-radius: 20px;
  border: 1px solid ${COLORS.borderColorForReplyTile};
  ${({ isAnon }) =>
    isAnon &&
    css`
      border: 1px solid #9d21ff;
    `}
  background-color: rgba(253, 253, 253, 0.75);
  padding: 18px;
  min-width: 530px;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  background-color: transparent;
  font-size: ${FONTS.SIZES.l};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${SPACES.l};
  margin-left: 9px;
  color: ${COLORS.black};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${COLORS.searchModalPlaceholderColor};
  }
`;

export const InputImage = styled(Image)`
  width: 18px;
  height: 18px;
`;
