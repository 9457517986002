import React from 'react';
import * as Styled from '../likes-claps-praises-modal.styled';

interface IFollowModalButtonProps {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export default function UserConnectionButton({ children, active, disabled, onClick }: IFollowModalButtonProps) {
  return (
    <Styled.UserConnectionButton $disabled={disabled} $active={active ?? false} disabled={!active || disabled} onClick={onClick}>
      {children}
    </Styled.UserConnectionButton>
  );
}
