import React from 'react';
import deleteImage from '@/styles/icons/delete-thesis-image.webp';
import * as Styled from './image-preview.styled';

interface ImagePreviewProps {
  img: string;
  deleteHandler: () => void;
}

export const ImagePreviewComponent = ({ img, deleteHandler }: ImagePreviewProps) => (
  <Styled.UploadImgsContainer>
    <Styled.UploadImgContainerForX>
      <Styled.UploadImg src={img} alt="Preview" />
      <Styled.DeleteImgContainer onClick={deleteHandler}>
        <Styled.DeleteImg src={deleteImage} alt="delete" />
      </Styled.DeleteImgContainer>
    </Styled.UploadImgContainerForX>
  </Styled.UploadImgsContainer>
);
