import { Stack, StackProps } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

type Props = StackProps & {
  children?: ReactNode;
};

export const FlexComponent = ({ children, ...otherProps }: Props): ReactElement => (
  <Stack display="flex" flexDirection="row" {...otherProps}>
    {children}
  </Stack>
);
