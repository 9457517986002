import React from 'react';
import { ILinkPreview } from '@/common/types/post.type';
import * as Styled from './display-review.styled';

interface IProps {
  data: ILinkPreview;
}

export const ReviewLinkPreview = ({ data }: IProps) => {
  const onClick = (e?: any) => {
    e?.stopPropagation();
    window.open(data?.link, '_blank');
  };

  const parseTitle = (title: string) => {
    if (!title) return title;

    const isIncludeDotCom = title.includes('.com');
    if (isIncludeDotCom) title = title.replace('.com', '');
    return title;
  };

  return (
    <Styled.LinkPreviewWrapper onClick={onClick}>
      <Styled.LinkPreviewTextWrapper>
        <Styled.LinkPreviewDomain>{parseTitle(data?.domain)}</Styled.LinkPreviewDomain>
        <Styled.LinkPreviewDescription>{data?.description}</Styled.LinkPreviewDescription>
      </Styled.LinkPreviewTextWrapper>
      {data?.imageUrl ? <Styled.LinkPreviewImage src={data.imageUrl} alt="Preview" /> : <Styled.EmptyImage />}
    </Styled.LinkPreviewWrapper>
  );
};
