import React from 'react';
import Image from 'next/image';
import { Rating, Stack, styled } from '@mui/material';
import { ReviewLinkPreview } from './review-link-preview.component';
import { IReview } from '@/common/types/review.type';
import RecommendedCheckIcon from '@/styles/icons/recommended-review.png';
import RecommendsCrossIcon from '@/styles/icons/recommends-cross.png';
import StarIcon from '@/styles/icons/rating-star.png';
import EmptyStarIcon from '@/styles/icons/rating-star-empty.png';
import * as Styled from './display-review.styled';
import { decodeHTMLEntities } from '@/common/utils/decode-text';

interface IProps {
  review: IReview;
  isAnon?: boolean;
  isQuote?: boolean;
}

export const DisplayReviewComponent = ({ review, isAnon, isQuote }: IProps) => {
  const { linkPreview } = review;
  const mediaType = review.mediaType.toLowerCase().split('_').join(' ');
  const StyledRating = isAnon
    ? styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#000'
        },
        '& .MuiRating-iconHover': {
          color: '#000'
        }
      })
    : styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#000'
        },
        '& .MuiRating-iconHover': {
          color: '#000'
        }
      });

  const isTextShortened = (value: boolean, text: string, max: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const min = max - 20;
    if (!ctx) return value ? false : text;
    const font = 'Roboto Flex';
    const fontSize = 14;
    ctx.font = `${fontSize}px ${font}`;
    let textWidth = ctx.measureText(text).width;

    if (!value && textWidth > max) {
      let truncatedText = text;
      let left = 0;
      let right = text.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedText = text.slice(0, middle);
        textWidth = ctx.measureText(truncatedText).width;

        if (textWidth > min) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }

      truncatedText = `${text.slice(0, right)}...`;
      return truncatedText;
    }
    if (!value && textWidth <= max) {
      return text;
    }

    return textWidth > max;
  };

  const getTextWidth = (text?: string) => {
    if (!text) return 0;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const font = 'Roboto Flex';

    const fontSize = 14;
    if (!ctx) return 0;
    ctx.font = `${fontSize}px ${font}`;
    return ctx.measureText(text).width;
  };

  return (
    <Styled.Container>
      <Styled.Row>
        <Styled.Item>
          <Styled.Title>{mediaType}</Styled.Title>
          <Styled.TitleText isAnon={isAnon}>
            {decodeHTMLEntities(
              isTextShortened(true, linkPreview?.title ?? '', 500 - getTextWidth(mediaType) - 8)
                ? (isTextShortened(false, linkPreview?.title ?? '', 500 - getTextWidth(mediaType) - 8) as string)
                : linkPreview?.title ?? ''
            )}
          </Styled.TitleText>
        </Styled.Item>
      </Styled.Row>
      {(review.rating || review.recommended) && (
        <Styled.Row>
          {review.rating && (
            <Styled.Item>
              <Styled.Title>rating</Styled.Title>
              <Stack spacing={1}>
                <StyledRating
                  sx={{
                    fontSize: '16px',
                    gap: '3px'
                  }}
                  name="half-rating"
                  defaultValue={0}
                  value={review.rating}
                  readOnly
                  precision={0.5}
                  icon={<Image src={StarIcon} alt="filled-star" width={16} height={16} />}
                  emptyIcon={<Image src={EmptyStarIcon} alt="empty-star" width={16} height={16} />}
                />
              </Stack>
            </Styled.Item>
          )}
          {review.recommended && (
            <Styled.Item isCentered>
              <Styled.Title>recommends</Styled.Title>
              <Styled.RecommendedIcon src={RecommendedCheckIcon} alt="recommended" />
            </Styled.Item>
          )}
          {!review.recommended && review.recommended !== undefined && review.recommended !== null && (
            <Styled.Item isCentered>
              <Styled.Title>recommends</Styled.Title>
              <Styled.RecommendedCrossIcon src={RecommendsCrossIcon} alt="not-recommended" />
            </Styled.Item>
          )}
        </Styled.Row>
      )}
      {!isQuote && (
        <>
          {review.review && (
            <Styled.Row>
              <Styled.ReviewText isAnon={isAnon}>{review.review}</Styled.ReviewText>
            </Styled.Row>
          )}
          {linkPreview && <ReviewLinkPreview data={linkPreview} />}
        </>
      )}
    </Styled.Container>
  );
};
