import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { UserImageComponent } from '@/common/components/user-image';

export const MentionWrapper = styled.div`
  position: relative;
`;

export const DropdownItemButton = styled.button<{ isActive?: boolean }>`
  color: #0018f1;
  border: none;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 14px;
  display: flex;
  padding: 10px 7px 8px 7px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 5px;
  background: ${({ isActive }) => (isActive ? 'rgba(0, 24, 241, 0.10)' : '#f4f4f4')};
  width: fit-content;
`;

export const EditorContainer = styled.div<{ isEmpty: boolean }>`
  position: relative;
  line-height: 22px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-style: normal;
  font-size: ${FONTS.SIZES.m};
  color: ${COLORS.black};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  &:focus {
    outline: none;
  }

  &:before {
    color: ${COLORS.postInputPlaceholderColor};
    font-weight: ${FONTS.WEIGHTS.light};
    pointer-events: none;
    opacity: ${({ isEmpty }) => (isEmpty ? '1' : '0')};
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Dropdown = styled.div<{
  top: number;
  left: number;
  customMaxHeight?: number;
  isInModal: boolean;
  width: number;
  isOutOfBottomView?: boolean;
}>`
  position: absolute;
  top: ${({ top, isOutOfBottomView }) => (isOutOfBottomView ? `${top - 350}px` : `${top + 25}px`)};
  left: ${({ left, isInModal, width }) => (isInModal ? `calc(${left + 20}px - ${width}px)` : `${left - 30}px`)};
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
  background: rgba(251, 251, 251, 0.9);
  box-shadow: 0px 0px 4px 0px #ececec;
  backdrop-filter: blur(1.5px);
  z-index: 1000;
  width: max-content;
  max-height: 348px;
  ${({ customMaxHeight }) => (customMaxHeight ? `max-height: ${customMaxHeight}px;` : '')}
  overflow-y: scroll;
`;

export const DropdownItemContainer = styled.div<{ gap?: number | string }>`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : '8px')};
  flex-wrap: nowrap;
  min-width: 400px;
  max-width: 400px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
`;

export const DropdownTypeContainer = styled.div<{ width?: number }>`
  /* min-width: ${({ width }) => `${width ?? 0}px`}; */
  display: flex;
  justify-content: flex-end;
`;

export const DropdownType = styled.div`
  padding: 10px 7px 8px 7px;
  border-radius: 7px;
  min-width: 74px;
  min-height: 30px;
  max-height: 30px;
  background: #f4f4f4;
  color: #595959;
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
`;

export const DropdownItem = styled.div<{ isActive: boolean; isUser: boolean }>`
  background: #f0f0f0;
  cursor: pointer;
  color: #0018f1;
  line-height: ${FONTS.SIZES.m};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.light};
  & > p {
    color: #0018f1;
    line-height: ${FONTS.SIZES.m};
    font-size: ${FONTS.SIZES.m};
    font-weight: ${FONTS.WEIGHTS.light};
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 8px 10px;
  border-radius: 30px;
  max-width: 300px;
  max-height: 30px;
  position: relative;
  ${({ isUser }) => (isUser ? 'padding-right: 2px;' : '')}
  ${({ isActive }) => (isActive ? 'background: rgba(0, 24, 241, 0.10);' : '')}
`;

export const DropdownPhoto = styled(UserImageComponent)`
  height: 24px;
  width: 24px;
`;

export const DropdownSecurityTicker = styled.div`
  font-size: ${FONTS.SIZES.s};
  margin-left: 3px;
  margin-top: 2px;
`;
