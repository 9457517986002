import { HttpService } from './http.service';
import { ISignIn } from '@/common/types/auth.type';
import { axiosInstance } from '@/common/services/axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';

class AuthToAnonService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async anonSignIn(keyword: string) {
    const response = await this.post<ISignIn, { keyword: string }>('auth/anon-sign-in', { keyword });

    return response;
  }
}

export const authToAnonService = new AuthToAnonService();
