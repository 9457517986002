import { HttpService } from './http.service';
import { axiosInstance } from '@/common/services/axios';
import {
  IEmailVerifySendPayload,
  IEmailVerifyConfirmPayload,
  ICreateAnonUserPayload,
  IAnonUser,
  IRealUser,
  ICheckReservedUsernamePayload,
  IReservedUsernameCodeConfirmPayload,
  ILinkAnonToRealPayload,
  ICreateSimplifiedAnonUserPayload
} from '../types/anon.types';
import { EnhancedWithAuthHttpService } from './http-auth.service';

class AnonService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async emailVerifySend(payload: IEmailVerifySendPayload) {
    return this.post<boolean, IEmailVerifySendPayload>('auth/email-verify/send', payload);
  }

  async emailVerifyConfirm(payload: IEmailVerifyConfirmPayload) {
    return this.post<boolean, IEmailVerifyConfirmPayload>('auth/email-verify/confirm', payload);
  }

  async createAnonUser(payload: ICreateAnonUserPayload) {
    const response = await this.post<IAnonUser, ICreateAnonUserPayload>('auth/anon-create', payload);
    return response;
  }

  async createSimplifiedAnonUser(payload: ICreateSimplifiedAnonUserPayload) {
    return this.post<IAnonUser, ICreateSimplifiedAnonUserPayload>('auth/simplified-anon-create', payload);
  }

  async finishAnonOnboarding() {
    return this.post<boolean, any>('auth/finish-anon-oboard', {});
  }

  async finishAnonPull() {
    return this.post<boolean, any>('auth/finish-anon-pull', {});
  }

  async useCreateRealFromAnon(payload: Omit<ICreateAnonUserPayload, 'username' | 'vertical' | 'subSectorId'>) {
    const response = await this.post<IRealUser, Omit<ICreateAnonUserPayload, 'username' | 'vertical' | 'subSectorId'>>(
      'auth/create-real-from-anon',
      payload
    );
    return response;
  }

  async useCreateSimplifiedRealFromAnon() {
    return this.post<IRealUser, undefined>('auth/create-simplified-real-from-anon', undefined);
  }

  async checkReservedUsername(payload: ICheckReservedUsernamePayload) {
    return this.post<boolean, ICheckReservedUsernamePayload>('anon/check-username', payload);
  }

  async reservedUsernameCodeConfirm(payload: IReservedUsernameCodeConfirmPayload) {
    return this.post<boolean, IReservedUsernameCodeConfirmPayload>('anon/reserved-username-code/confirm', payload);
  }

  async anonVerifySend(payload: IEmailVerifySendPayload) {
    return this.post<boolean, IEmailVerifySendPayload>('auth/anon-verify/send', payload);
  }

  async anonVerifyConfirm(payload: IEmailVerifyConfirmPayload) {
    return this.post<boolean, IEmailVerifyConfirmPayload>('auth/anon-verify/confirm', payload);
  }

  async linkAnonToReal(payload: ILinkAnonToRealPayload) {
    return this.post<boolean, ILinkAnonToRealPayload>('anon/link-real-to-anon', payload);
  }
}

export const anonService = new AnonService();
