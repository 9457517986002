import { IClickedSeeMore, IClickedValueFilter, IPagedResponse, ISearchOutput, IUserSearchResponse } from '../types/search.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class SearchAlgoliaPageService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async search(searchString: string, filter?: string[]) {
    if (filter && filter.length > 0) {
      const filterString = filter.reduce((a, b) => (a && a.length > 0 ? `${a},${b}` : `${b}`), '');
      return this.get<ISearchOutput>(`algolia-search?query=${searchString}&filter=${filterString}`);
    }

    return this.get<ISearchOutput>(`algolia-search?query=${searchString}`);
  }

  async searchClick({ type, query, queryId, isClickOnMention }: IClickedValueFilter) {
    return this.get<ISearchOutput>(
      `algolia-search/after-click?query=${query}&clickedQueryId=${queryId}&type=${type}&isClickOnMention=${isClickOnMention}`
    );
  }

  async clickSeeMore({ query, page, hitsPerPage, index, type, queryId }: IClickedSeeMore) {
    if (type && queryId) {
      return this.get<IPagedResponse>(
        `algolia-search/pagination?query=${query}&index=${index}&page=${page}&hitsPerPage=${
          hitsPerPage ?? 20
        }&type=${type}&queryId=${queryId}`
      );
    }

    return this.get<IPagedResponse>(
      `algolia-search/pagination?query=${query}&index=${index}&page=${page}&hitsPerPage=${hitsPerPage ?? 20}`
    );
  }

  async usersSearch({ query, page, hitsPerPage }: Partial<IClickedSeeMore>) {
    return this.get<IUserSearchResponse>(
      `algolia-search/pagination?query=${query}&index=users&page=${page}&hitsPerPage=${hitsPerPage ?? 20}`
    );
  }
}

export const searchAlgoliaPageService = new SearchAlgoliaPageService();
