import React from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { IPost, IStatistic, ISubPost, PostType } from '@/common/types/post.type';
import { PostComponent } from '@/common/components/post';

interface IProps {
  post: IPost;
  isAnon?: boolean;
  parrentPost: ISubPost;
  onClickByQuotePost: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  statistic?: IStatistic;
  userProfileId?: string | undefined;
  onClickByPost: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isTreadOpen?: boolean;
  deletePost?: UseMutateAsyncFunction<unknown, unknown, string, unknown>;
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  isInQuoteModal?: boolean;
  isInModal?: boolean;
  memosSection?: boolean;
}

export const QuotePostComponent = ({
  post,
  isAnon,
  parrentPost,
  onClickByQuotePost,
  statistic,
  userProfileId,
  onClickByPost,
  openReply,
  memosSection,
  isTreadOpen,
  deletePost,
  quotePostHandler,
  closeModal,
  isInQuoteModal,
  isInModal
}: IProps) => {
  const isLast = (statistic?.replyCount || post.replyCount || 0) === 0;
  return (
    <>
      {!isInQuoteModal && !post.deletedAt && (
        <PostComponent
          isAnon={isAnon}
          userProfileId={userProfileId}
          postStatus={{ isInOwnTread: true, isInModal, isQuote: true }}
          post={parrentPost}
          key={parrentPost?.id}
          onClickByPost={onClickByQuotePost}
          quotePostHandler={quotePostHandler}
          openReply={openReply}
          closeModal={closeModal}
          memosSection={memosSection}
        />
      )}
      <PostComponent
        isAnon={isAnon}
        postStatus={{ isInOwnTread: true, isTreadOpen, isLast, isInModal }}
        post={post}
        userProfileId={userProfileId}
        key={post.id}
        deletePost={deletePost}
        onClickByPost={onClickByPost}
        quotePostHandler={quotePostHandler}
        openReply={openReply}
        closeModal={closeModal}
        postType={PostType.Quote}
      />
    </>
  );
};
