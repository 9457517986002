import styled from 'styled-components';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const ModalHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    margin-bottom: 30px;
`
export const ModalHeaderTextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const ModalHeaderText = styled.div`
    color: #000;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`

export const ModalHeaderContent = styled.div`
    display: flex;
    align-items: start;
    gap: 17px;
    margin-bottom: 30px;
`
export const ModalHeaderTitle = styled.div`
    color: #000;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
`

export const AddFundMaterialsFormContainer = styled.div`
    display: flex;
    gap: 15px;
    
`

export const  AddFundMaterialsForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const  AddFundMaterialsDataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const AddFundMaterialsDataTitle = styled.div<{active?: boolean, isActiveOptionTitle?: boolean}>`
  display: flex;
  gap: 7px;
  align-items: center;
  color: ${({ active, isActiveOptionTitle }) => (isActiveOptionTitle ? '#000' : active ? '#008767' : '#C8C8C8')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 20px;

  p {
    padding-right: 3px;
  }

  span {
    color: #C86000;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

`;

export const AddFundMaterialsValidateInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AddFundMaterialsDataSubtitle = styled.div`
    color: #606060;
    font-family: "Roboto Flex";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0.1px;
    padding-bottom: 5px;
`

export const ToggleButton = styled.button<{active: boolean}>`
  background-color: ${props => props.active ? '#EFF1FF' : '#F6F6F6'};
  color: ${props => props.active ? '#0016D8' : '#C2C2C2'};
  border: none;
  display: flex;
    width: 60px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  cursor: pointer;
  border-radius: 5px;

    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px; 
`;

export const ToogleButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const IconContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
`