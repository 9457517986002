import styled, { css } from 'styled-components';
import { FONTS } from '@/theme';

interface IVoteItemProps {
  isChosen?: boolean;
  isDisabled?: boolean;
  allowChange?: boolean;
  isOnboarding?: boolean;
  isOnThink?: boolean;
  borderRadius?: number;
  isCoverageModal?: boolean;
}

export const IdeaVoteWrapper = styled.div<{
  isOnboarding?: boolean;
  isLastItem?: boolean;
  isFirst?: boolean;
  isLatest?: boolean;
  isDisabled?: boolean;
}>`
  ${({ isOnboarding }) => isOnboarding && 'display: flex; gap: 30px; padding: 15px 20px; background: rgba(253, 253, 253, 0.95);'}
  ${({ isFirst }) => isFirst && 'border-radius: 10px 10px 0px 0px;'}
  ${({ isLatest }) => isLatest && 'border-radius: 0px 0px 10px 10px;'}
  ${({ isFirst, isLatest }) => isFirst && isLatest && 'border-radius: 10px 10px 10px 10px;'}
  ${({ isDisabled, isOnboarding }) => !isDisabled && isOnboarding && 'border: 2px solid #FFF; padding: 13px 18px;'}
  ${({ isDisabled, isOnboarding }) => isDisabled && isOnboarding && 'background: rgba(253, 253, 253, 0.50); box-shadow: none;'}
`;

export const IdeaVoteContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
`;

export const IdeaVoteItemsWrapper = styled.div<{ isOnboarding?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  ${({ isOnboarding }) => isOnboarding && 'align-items: center;'}
`;

const IdeaVoteItemStyle = css<IVoteItemProps>`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  width: 75px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #f1f1f1;
  cursor: pointer;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ isDisabled }) => (isDisabled ? 'background: #F8F8F8;' : '')}
  ${({ isDisabled }) => (isDisabled ? 'color: #E3E3E3;' : '')}
  ${({ isDisabled, allowChange }) => (isDisabled && !allowChange ? 'cursor: auto;' : '')}
`;

export const BearVoteItem = styled.button<IVoteItemProps>`
  font-weight: ${(props) => (props.isOnboarding && props.isChosen ? '500 !important' : '')};
  ${IdeaVoteItemStyle}
  border: none;
  ${({ isDisabled }) => (!isDisabled ? 'background: rgba(90, 20, 205, 0.05);' : '')}
  ${({ isDisabled }) => (!isDisabled ? 'color: #5a14cd;' : '')}
  ${({ isOnboarding, isDisabled }) => isOnboarding && !isDisabled && 'border: 1px solid rgba(90, 20, 205, 0.50);'}
  ${({ isChosen, isDisabled }) =>
    isChosen && !isDisabled ? 'background: linear-gradient(90deg, rgba(84, 0, 189, 0.15) 0%, rgba(84, 0, 189, 0.05) 100%);' : ''}
  ${(props) =>
    props.isChosen &&
    props.isOnboarding &&
    'background: linear-gradient(99deg, rgba(147, 82, 255, 0.25) 0%, rgba(138, 79, 235, 0.10) 100%); color: #5A14CD; border: none;'}
    ${(props) =>
    props.isDisabled && props.isOnboarding && 'border: none; background: rgba(90, 20, 205, 0.03); color: rgba(90, 20, 205, 0.20);'}
    
    ${({ isCoverageModal, isChosen, isDisabled }) =>
    isChosen && isCoverageModal && !isDisabled ? 'background: rgba(90, 20, 205, 0.05);' : ''}
`;

export const BullVoteItem = styled.button<IVoteItemProps>`
  font-weight: ${(props) => (props.isOnboarding && props.isChosen ? '500 !important' : '')};
  ${IdeaVoteItemStyle}
  border: none;
  ${({ isDisabled }) => (!isDisabled ? 'background: rgba(18, 132, 125, 0.05);' : '')}
  ${({ isDisabled }) => (!isDisabled ? 'color: #12847d;' : '')}
  ${({ isOnboarding, isDisabled }) => isOnboarding && !isDisabled && 'border: 1px solid rgba(18, 132, 125, 0.50);'}
  ${({ isChosen, isDisabled }) =>
    isChosen && !isDisabled ? 'background: linear-gradient(270deg, rgba(0, 145, 136, 0.15) 0%, rgba(0, 145, 136, 0.05) 100%);' : ''}
  ${(props) =>
    props.isChosen &&
    props.isOnboarding &&
    'background: linear-gradient(261deg, rgba(0, 198, 185, 0.25) 0%, rgba(0, 198, 185, 0.15) 100%); color: #12847D;  border: none;'}
  ${(props) => props.isDisabled && props.isOnboarding && 'background: rgba(18, 132, 125, 0.03); color: rgba(18, 132, 125, 0.20);'}

  ${({ isCoverageModal, isChosen, isDisabled }) =>
    isChosen && isCoverageModal && !isDisabled ? 'background: rgba(18, 132, 125, 0.05);' : ''}
`;

export const NeutralVoteItem = styled.button<IVoteItemProps>`
  font-weight: ${(props) => (props.isOnboarding && props.isChosen ? '500 !important' : '')};
  ${IdeaVoteItemStyle}
  border: none;
  color: #414141;
  ${({ isChosen, isOnboarding, isDisabled }) =>
    isChosen && isOnboarding
      ? 'background: #E6E6E6;'
      : isDisabled
      ? 'background: rgba(241, 241, 241, 0.30); color: rgba(65, 65, 65, 0.20);'
      : 'background: #F1F1F1;'}
  ${({ isChosen, isOnboarding }) => isChosen && !isOnboarding && 'background: #D0D0D0;'}

  ${({ isCoverageModal, isChosen, isDisabled }) => (isChosen && isCoverageModal && !isDisabled ? 'background: #F1F1F1;' : '')}
`;

export const SkipVoteItem = styled.button<IVoteItemProps>`
  font-weight: ${(props) => (props.isOnboarding && props.isChosen ? '500 !important' : '')};
  ${IdeaVoteItemStyle}
  border: none;
  color: #414141;
  ${({ isChosen, isOnboarding, isDisabled }) =>
    isChosen && isOnboarding
      ? 'background: #E6E6E6;'
      : isDisabled
      ? 'background: rgba(241, 241, 241, 0.30); color: rgba(65, 65, 65, 0.20);'
      : 'background: #F1F1F1;'}
  ${({ isChosen, isOnboarding }) => isChosen && !isOnboarding && 'background: #D0D0D0;'}
`;

export const IdeaTitle = styled.div<{ isOnboarding?: boolean }>`
  font-weight: ${FONTS.WEIGHTS.medium};
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  ${({ isOnboarding }) => isOnboarding && 'margin-bottom: 0px; align-items: center;'}
`;

export const IdeaText = styled.div<{
  isOnboarding?: boolean;
  width?: number;
}>`
  font-size: ${FONTS.SIZES.m};
  ${({ width }) => width && `width: ${width}px;`}
  /* ATTENTION HERE */
  ${(props) =>
    props.isOnboarding && 'font-family: Roboto Flex; min-width: 100px; color: #000; font-weight: 300;line-height: 20px; padding: 7px 0px;'}
`;

export const IdeaHorizonText = styled.div<{
  isOnboarding?: boolean;
}>`
  ${(props) =>
    props.isOnboarding &&
    'font-family: Roboto Flex; font-size: 12px; font-style: normal; font-weight: 300; line-height: 20px; padding: 11px 0px 9px 0px;'}
  font-size: ${FONTS.SIZES.s};
  color: #5a5a5a;
`;
