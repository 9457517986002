import React from 'react';
import * as Styled from './email-input.styled';
import { InputContainer, Error } from '../ui/common-styles';
import { InputError } from '../modals/activate-anon-modal/activate-anon-modal.types';

interface IEmailInputComponentProps extends InputError {
  isSpanPurple: boolean;
  disabled: boolean;
  autoFocus?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isRealModal?: boolean;
  isLinkModal?: boolean;
  value?: string;
}

export const EmailInputComponent = ({
  isSpanPurple,
  disabled,
  autoFocus,
  onChange,
  onKeyDown,
  isRealModal,
  isLinkModal,
  error,
  value
}: IEmailInputComponentProps) => (
  <Styled.EmailVerifcationWrapper>
    <Styled.EmailVerificationInfoText $isSpanPurple={isSpanPurple} $isRealModal={isRealModal}>
      {isLinkModal ? (
        <p>Enter your Anonymous Account Email Address</p>
      ) : (
        <p>Enter an Email Address for your {isRealModal ? 'Real' : 'Anonymous'} Account</p>
      )}
      {!isLinkModal && (
        <span>
          {isRealModal
            ? 'This must be different from your anonymous account email address, and can be used to login'
            : 'This must be different from your real account email address, and can be used to login or for password recovery'}
        </span>
      )}
    </Styled.EmailVerificationInfoText>
    <InputContainer>
      <Styled.EmailVerificationInput
        autoFocus={autoFocus}
        placeholder="type here"
        type="email"
        onChange={(e) => {
          onChange(e);
        }}
        onKeyDown={(e) => onKeyDown(e)}
        disabled={disabled}
        value={value}
      />
      {error.isError && <Error>{error.errorText}</Error>}
    </InputContainer>
  </Styled.EmailVerifcationWrapper>
);
