import { IGetNotificationsResponse } from '../types/notification.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class NotificationService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async notifications() {
    return this.get<IGetNotificationsResponse>('notification');
  }

  async oldNotifications(params?: { start: string; end: string }) {
    return this.get<IGetNotificationsResponse>('notification/old', { params });
  }

  async markAllAsRead() {
    return this.post('notification/mark-all-as-read', {});
  }

  subscribe() {
    return this.eventSource('notification/subscribe');
  }
}

export const notificationService = new NotificationService();
