import React, { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import AddMaterialsIcon from '@styles/icons/add-insight-icon.webp';
import { Stack } from '@mui/material';
import * as Styled from './poll.styled';
import Checkmark from '@/styles/icons/ok-checkmark.svg';
import { ISelectedCompany } from '@/common/types/coverage.type';
import { TimePeriodComponent } from './components';
import { useCreatePoll } from '@/common/hooks/use-poll';
import { EPollQuartal, EUnits } from '@/common/types/poll.types';
import { FlexComponent } from '@/common/components/flex';
import { SecuritySelectComponent } from './components/security-select/security-select.component';
import { PostFormActions } from '../insight/components/post-form-actions';
import { UnitSelectComponent } from './components/unit-select/unit-select.component';
import { StepperComponent } from './components/stepper';

interface IProps {
  closeHandler: () => void;
}

const MIN_MAX_PERCENT = {
  min: -999,
  max: 999
};

export const getMaxMinPollAnswer = (selectedUnitValue: EUnits | string) => {
  if (selectedUnitValue === EUnits.PERCENTAGES) {
    return MIN_MAX_PERCENT;
  }
  return {
    min: -9999,
    max: 9999
  };
};

const steps = [
  {
    value: '',
    lineHeight: '75px',
    disabledLineHeight: '75px'
  },
  {
    value: '',
    lineHeight: '97px',
    disabledLineHeight: '30px'
  },
  {
    value: '',
    lineHeight: '65px',
    disabledLineHeight: '30px'
  },
  {
    value: ''
  }
];

export const PollComponent: React.FC<IProps> = ({ closeHandler }) => {
  const [selectedCompany, setSelectedCompany] = useState<ISelectedCompany | null>(null);
  const [kpi, setKpi] = useState<string>('');
  const [selectedUnit, setSelectedUnit] = useState<EUnits | string>('');
  const [kpiConfirmIsDisabled, setKpiConfirmIsDisabled] = useState<boolean>(true);
  const [confirmClicked, setConfirmClicked] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [selectedQuartal, setSelectedQuartal] = useState<EPollQuartal | undefined>(undefined);
  const [step, setStep] = useState<number>(1);
  const [answer, setAnswer] = useState<number | null>(null);
  const { mutateAsync, isLoading, isSuccess } = useCreatePoll();
  const isSubmitDisabled = useMemo(
    () => !selectedCompany || !selectedYear || !selectedQuartal || !kpi || !answer || !selectedUnit,
    [selectedCompany, selectedYear, selectedQuartal, answer, selectedUnit, kpi]
  );
  const inputMinMax = useMemo(() => getMaxMinPollAnswer(selectedUnit), [selectedUnit, getMaxMinPollAnswer]);

  const selectYear = (newYear: number | undefined) => {
    if (newYear === undefined) {
      setSelectedYear(undefined);
      return undefined;
    }

    setSelectedYear(newYear);
    return newYear;
  };

  const handlePostPoll = async () => {
    if (isSubmitDisabled) return;

    await mutateAsync({
      year: selectedYear!,
      quartal: selectedQuartal!,
      security: selectedCompany?.id!,
      answer,
      unit: selectedUnit,
      text: kpi,
      isVisible: true
    });
    closeHandler();
  };

  const handleConfirmKpi = () => {
    if (!confirmClicked) {
      setStep(3);
      setConfirmClicked(true);
    }
  };

  useEffect(() => {
    if (selectedCompany && step < 2) {
      setStep(2);
    }
    if (selectedYear && selectedQuartal) {
      setStep(4);
    }
    if (kpi?.length > 2) {
      setKpiConfirmIsDisabled(false);
    } else {
      setKpiConfirmIsDisabled(true);
    }
  }, [selectedCompany, kpi, selectedYear, selectedQuartal]);

  useEffect(() => {
    if (selectedUnit === EUnits.PERCENTAGES && answer) {
      if (answer < MIN_MAX_PERCENT.min || answer > MIN_MAX_PERCENT.max) {
        setAnswer(null);
      }
    }
  }, [selectedUnit, answer]);

  return (
    <Styled.MainWrapper>
      <Styled.PollContainer>
        <Styled.ModalHeaderContent>
          <Styled.IconContainer>
            <Image src={AddMaterialsIcon} width={12} height={12} alt="add-materials-icon" />
          </Styled.IconContainer>
          <Styled.ModalHeaderTextContent>
            <Styled.ModalHeaderTitle>Create poll</Styled.ModalHeaderTitle>
            <Styled.ModalHeaderText>Poll others on their estimates of key reporting metrics</Styled.ModalHeaderText>
          </Styled.ModalHeaderTextContent>
        </Styled.ModalHeaderContent>
        <FlexComponent gap="15px">
          <StepperComponent isDotted step={step} steps={steps} />
          <Stack gap="30px">
            <Styled.Section>
              <Styled.SectionTitle>Select the company</Styled.SectionTitle>
              <SecuritySelectComponent company={selectedCompany} setCompany={setSelectedCompany} />
            </Styled.Section>
            {step >= 2 ? (
              <Styled.Section>
                <Styled.SectionTitle>KPI: enter the metric</Styled.SectionTitle>
                <Styled.SectionText>
                  e.g. units sold, net adds, gross bookings, ARPU, ARR, YouTube revenue, EBITDA margin, EPS
                </Styled.SectionText>
                <FlexComponent gap="10px" mt="5px">
                  <Styled.PollInput maxLength={70} value={kpi} onChange={(e) => setKpi(e.target.value)} placeholder="Type here .." />
                  <Styled.KpiButton
                    onClick={handleConfirmKpi}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && !kpiConfirmIsDisabled && !confirmClicked) {
                        event.preventDefault();
                        handleConfirmKpi();
                      }
                    }}
                    disabled={kpiConfirmIsDisabled && !confirmClicked}
                  >
                    confirm <Styled.KpiButtonImage src={Checkmark} alt="check" />
                  </Styled.KpiButton>
                </FlexComponent>
              </Styled.Section>
            ) : (
              <Styled.Section>
                <Styled.SectionTitle isDisabled>KPI</Styled.SectionTitle>
              </Styled.Section>
            )}
            {step >= 3 ? (
              <Styled.Section>
                <Styled.SectionTitle>Fiscal Reporting Year + Quarter: select below</Styled.SectionTitle>
                <Styled.Section>
                  <TimePeriodComponent
                    selectedYear={selectedYear}
                    setSelectedYear={selectYear}
                    selectedQuartal={selectedQuartal}
                    setSelectedQuartal={setSelectedQuartal}
                  />
                </Styled.Section>
              </Styled.Section>
            ) : (
              <Styled.Section>
                <Styled.SectionTitle isDisabled>Fiscal Reporting Year + Quarter</Styled.SectionTitle>
              </Styled.Section>
            )}
            {step >= 4 ? (
              <Styled.Section>
                <Styled.SectionTitle>Your Estimate: enter your estimate + select unit</Styled.SectionTitle>
                <Styled.PollInput
                  width={505}
                  min={inputMinMax.min}
                  max={inputMinMax.max}
                  type="number"
                  step="0.001"
                  value={answer || ''}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (value.includes('.')) {
                      const [integerPart, decimalPart] = value.split('.');
                      if (decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                    }
                    const numericValue = Number(value);
                    if (numericValue >= inputMinMax.min && numericValue <= inputMinMax.max) {
                      setAnswer(numericValue);
                    }
                  }}
                  placeholder="Type here .."
                />
                <UnitSelectComponent mt="5px" selectedUnit={selectedUnit} setSelectedUnit={setSelectedUnit} />
              </Styled.Section>
            ) : (
              <Styled.Section>
                <Styled.SectionTitle isDisabled>Your Estimate</Styled.SectionTitle>
              </Styled.Section>
            )}
            <PostFormActions
              title="create poll"
              isDisabled={isLoading || isSuccess}
              isActive={!isSubmitDisabled}
              activeCallback={handlePostPoll}
            />
          </Stack>
        </FlexComponent>
      </Styled.PollContainer>
    </Styled.MainWrapper>
  );
};
