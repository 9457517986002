import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

interface IInputFiledProps {
  $onPage?: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
  $reservedUsernameInput?: boolean;
}

export const ModalInputField = styled.input`
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 10px;
  border: 1px solid #F5F5F5;
  background: #FBFBFB;
  border-radius: 10px;
  letter-spacing: 9.8px;
  padding: 10px;
  color: #000;
  font-size: ${SIZES.m};
  line-height: ${SIZES.xxl};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.medium};

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    font-weight: ${WEIGHTS.light};
    color: #6F6F6F;
    letter-spacing: 0.1px;
  }
`;

export const InputField = styled.input<IInputFiledProps>`
  width: 60px;
  height: 46px;

  border-radius: 3px;
  ${({ $isFirst }) => $isFirst && 'border-radius: 10px 3px 3px 10px;'}
  ${({ $isLast }) => $isLast && 'border-radius: 3px 10px 10px 3px;'}
  
  background: ${({ $onPage, $reservedUsernameInput }) => {
    if ($reservedUsernameInput) {
      return COLORS.anonActiveModalReservedInputOtpBackground;
    }
    if ($onPage) {
      return COLORS.onboadringAnonInputOtpBackground;
    }
    return COLORS.white;
  }};
  text-align: center;

  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.xxl};
  border: none;
  font-weight: ${WEIGHTS.normal};
  line-height: ${SIZES.xxl};
  letter-spacing: 0.6px;
  :focus-visible {
    outline: 0;
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  gap: 4px;
  border-radius: 10px;
`;
