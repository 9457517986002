import { ILinkPreview, IPostPayload } from './post.type';

export interface IReviewCreatePayload {
  link: string;
  mediaType: ReviewMediaTypes;
  review?: string;
  recommended?: boolean;
  rating?: number;
  replies?: IPostPayload[];
  isPrivate: boolean;
  image: string | ArrayBuffer | null;
}

export interface IReview {
  link: string;
  mediaType: ReviewMediaTypes;
  review?: string;
  recommended?: string;
  rating?: number;
  linkPreview: ILinkPreview;
}

export enum ReviewMediaTypes {
  BOOK = 'BOOK',
  PODCAST = 'PODCAST',
  MOVIE = 'MOVIE',
  TV = 'TV',
  ALBUM = 'ALBUM'
}
