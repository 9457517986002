import { IAssociationResponse } from './connection.type';
import { IPost } from './post.type';

export enum NotificationClientType {
  POST_REACTS = 'POST_REACTS',
  REPLY = 'REPLY',
  REPOST = 'REPOST',
  QUOTE_POST = 'QUOTE_POST',
  CONNECTION_REQUEST = 'CONNECTION_REQUEST',
  NEW_FOLLOWER = 'NEW_FOLLOWER',
  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
  MENTION = 'MENTION'
}

export enum NotificationType {
  LIKE = 'LIKE',
  PRAISE = 'PRAISE',
  CLAP = 'CLAP',
  REPLY = 'REPLY',
  REPOST = 'REPOST',
  QUOTE_POST = 'QUOTE_POST',
  CONNECTION_REQUEST = 'CONNECTION_REQUEST',
  NEW_FOLLOWER = 'NEW_FOLLOWER',
  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
  MENTION = 'MENTION'
}

export interface IProfiles {
  id: string;
  imageUrl: string;
  isImageHidden: boolean;
}

export interface INotificationProfile extends IProfiles {
  fullName: string;
  role: string;
  vertical: string;
  isUserProfileLocked: boolean;
  isHidden?: boolean;
}

export interface IProfilesConnection extends INotificationProfile {
  userId: string;
  ownUserId: string;
  isOwnProfileLocked: boolean;
  isAnon: boolean;
}

export interface INotificationResponse {
  id: string[];
  type: NotificationClientType;
  date: string;
  post?: IPost;
  likes?: number;
  likeProfiles?: IProfiles[];
  praises?: number;
  praiseProfiles?: IProfiles[];
  claps?: number;
  clapProfiles?: IProfiles[];
  userProfile?: INotificationProfile;
  count?: number;
  countProfiles?: IProfiles[];
  countPostIds?: string[];
  connection?: IAssociationResponse;
  connectionProfile?: IProfilesConnection;
  answerConnection?: IAssociationResponse;
  hasMention?: boolean;
}

export interface IGetNotificationsResponse {
  forResponse: INotificationResponse[];
  adoration: INotificationResponse[];
  nextDate: string | null;
}

export interface IGetNotifications {
  forResponse: INotificationResponse[];
  adoration: INotificationResponse[];
}

export interface ICreateNotificationEvent {
  id: string;
  createdAt: string;
  type: NotificationType;
  targetProfileId: string;
  sourceProfileId: string;
  sourceProfile: IProfiles;
  hasMention?: boolean;
  postId?: string;
  post?: IPost;
  originalPostId?: string;
  originalPost?: IPost;
  userProfile?: INotificationProfile;
  connection?: IAssociationResponse;
  connectionProfile?: IProfilesConnection;
  answerConnection?: IAssociationResponse;
}

export interface IDeleteNotificationEvent {
  id: string;
  type: NotificationType;
  targetProfileId?: string;
  sourceProfileId?: string;
  isRead: boolean;
}

export interface INotificationEvent {
  create?: ICreateNotificationEvent;
  delete?: IDeleteNotificationEvent;
}

export enum NotificationTypeForHomePageBar {
  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
  MENTION = 'MENTION',
  REPLY = 'REPLY',
  QUOTE_POST = 'QUOTE_POST',
  REPOST = 'REPOST',
  CLAP = 'CLAP',
  LIKE = 'LIKE'
}
