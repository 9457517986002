export const BUTTON_TEXT = {
  CONNECTED: 'connected',
  CONNECT: 'connect',
  MUTUAL_FOLLOWERS: 'mutual followers',
  CONNECTION_SENT: 'connection sent',
  FOLLOW_SENT: 'follow sent',
  FOLLOW_BACK: 'follow back',
  FOLLOWING: 'following',
  FOLLOW: 'follow'
};

export const IMAGE_ALT = {
  PAGINATION_ARROW: 'pagination arrow'
};

export const TAKE_STATS_MODAL = 10;
