import { IdeaHorizon } from '../types';
import { TitleEnum } from '@/common/types/memo.type';

export const horizonText = {
  [IdeaHorizon.SKIP]: 'skip',
  [IdeaHorizon.MONTHS_0_6]: '0 - 6',
  [IdeaHorizon.MONTHS_6_12]: '6 - 12',
  [IdeaHorizon.MONTHS_12_24]: '12 - 24',
  [IdeaHorizon.MONTHS_24_PLUS]: '24+'
};

export const SKIP_ANSWER = 0;
export const BEAR_ANSWER = 50;
export const NEUTRAL_ANSWER = 100;
export const BULL_ANSWER = 150;

export const ideaReasons = [
  'Pricing',
  'Volume',
  'Revenue',
  'COGS',
  'Opex',
  'Margins',
  'Share-based comp',
  'Operating leverage',
  'Share count',
  'Debt',
  'Management',
  'Governance',
  'Capital allocation',
  'Competition',
  'Moat',
  'AI',
  'Customers',
  'Suppliers',
  'Valuation',
  'Yield',
  'Macro',
  'FX',
  'China',
  'Regulation',
  'M&A'
];

export const sectorsOrder = [
  'Consumer',
  'Software',
  'Internet',
  'Media',
  'Semis',
  'AI',
  'Hardware',
  'Healthcare',
  'Financials',
  'Industrials',
  'Materials',
  'Energy',
  'Real Estate'
];

export const PublishIdeasTitles = {
  [TitleEnum.SUMMARY]: 'Thesis Summary',
  [TitleEnum.EXIT_VALUATION]: 'Exit Value: Financial Estimates + Valuation',
  [TitleEnum.RISKS]: 'Risks',
  [TitleEnum.BEAR_CASE]: 'Bear Case',
  [TitleEnum.MOAT]: 'Moat',
  [TitleEnum.BUSINESS_MODEL]: 'Business Model',
  [TitleEnum.STAKEHOLDERS]: 'Stakeholders'
};

export const PublishIdeaTitleOrder: { [key in TitleEnum]: number } = {
  [TitleEnum.SUMMARY]: 0,
  [TitleEnum.BUSINESS_MODEL]: 1,
  [TitleEnum.EXIT_VALUATION]: 2,
  [TitleEnum.MOAT]: 3,
  [TitleEnum.RISKS]: 4,
  [TitleEnum.STAKEHOLDERS]: 5,
  [TitleEnum.BEAR_CASE]: 6
};
