import axios from 'axios';
import { CreateUserSendbird } from '../types/sendbird.type';

class SendbirdService {
  constructor(
    private appId = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID ?? '',
    private appToken = process.env.NEXT_PUBLIC_SENDBIRD_APP_TOKEN ?? ''
  ) {}

  async createUserSendbird(body: CreateUserSendbird) {
    await axios.post(`https://api-${this.appId}.sendbird.com/v3/users`, body, {
      headers: {
        'Api-Token': this.appToken
      }
    });
  }

  async isUserExist(userId: string) {
    const response = await axios.get(`https://api-${this.appId}.sendbird.com/v3/users?user_ids=${userId}`, {
      headers: {
        'Api-Token': this.appToken
      }
    });
    if (response.data.users.length < 1) {
      return false;
    }

    return true;
  }
}

export const sendbirdService = new SendbirdService();
