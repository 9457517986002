import { IGetNewsfeedResponse, IPost, ISetTreadAndPostsProps } from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { setPostStatistics } from '../utils/post-statistics';
import { IFilters, IListTags, ISkipPayload, ITag, NewsfeedNavigation } from '../types/newsfeed.type';
import { getNewsfeedFilters } from '../utils/get-newsfeed-filters';

class NewsfeedService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getAllNewsfeeds(
    { setTread, queryClient, setPosts }: ISetTreadAndPostsProps,
    page: number,
    take: number,
    currentFilter: IFilters,
    activeTab: NewsfeedNavigation
  ) {
    const query = new URLSearchParams();

    const { include, exclude, includeTime, excludeTime } = getNewsfeedFilters(currentFilter);
    query.append('page', page.toString());
    query.append('take', take.toString());
    query.append('activeTab', activeTab);
    const timeZone = new Date().getTimezoneOffset() / 60;
    query.append('timeZone', timeZone.toString());

    include.forEach((id) => query.append('include[]', id));
    exclude.forEach((id) => query.append('exclude[]', id));

    includeTime.forEach((id) => query.append('includeTime[]', id));
    excludeTime.forEach((id) => query.append('excludeTime[]', id));

    const postResponse = await this.get<IGetNewsfeedResponse>(`news-feed?${query}`);

    if (postResponse?.posts?.length) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
    }
    setPosts(postResponse?.posts ?? [], page ?? 1);
    return postResponse;
  }

  async getAllowedFilters(filter: IFilters, activeTab?: NewsfeedNavigation) {
    const query = new URLSearchParams();
    if (activeTab) {
      query.append('activeTab', activeTab);
    }

    const { include, exclude, includeTime, excludeTime } = getNewsfeedFilters(filter);
    const timeZone = new Date().getTimezoneOffset() / 60;
    query.append('timeZone', timeZone.toString());

    include.forEach((id) => query.append('include[]', id));
    exclude.forEach((id) => query.append('exclude[]', id));

    includeTime.forEach((id) => query.append('includeTime[]', id));
    excludeTime.forEach((id) => query.append('excludeTime[]', id));

    return this.get<ITag[]>(`news-feed/admin-filters?${query}`);
  }

  async getListTags() {
    return this.get<IListTags>('news-feed/admin-list-tags');
  }

  async getPreselectedFilters() {
    return this.get<IFilters>('news-feed/preselected-filters');
  }

  async skipNewsfeed(payload: ISkipPayload) {
    return this.post<{ posts: IPost[] }, ISkipPayload>('news-feed/skip', payload);
  }
}

export const newsfeedService = new NewsfeedService();
