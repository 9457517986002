import React, { useEffect, useState } from 'react';
import SixHoursLeft from '@styles/icons/siz-hours-left-to-vote.webp';
import NewDebateIcon from '@styles/icons/new-debate.svg';
import VotedeIcon from '@styles/icons/debate-voted.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as Styled from './question-vote.styled';
import { useAddQueryAnswer } from '@/common/hooks';
import { checkTimeAlmostUp } from '@/common/utils/check-time-almost-up';
import { ISubPost } from '@/common/types/post.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IQuestionVoteComponentProps {
  userProfileId?: string;
  isAnon?: boolean;
  isPost?: boolean;
  isOwner?: boolean;
  isRepost?: boolean;
  isNotEnouthAnswerForResult?: boolean;
  isResultInProcessing?: boolean;
  questionId?: string;
  text?: string;
  answer?: number | null; // 0 - skip, 1-6 - answers, null/undefined - not voted
  answerCallback?: (answer: number) => void;
  updateQuestionInModal?: (post: ISubPost) => void;
  showSkip?: boolean;
  hideLeftSkip?: boolean;
  isQuote?: boolean;
  isOnNotification?: boolean;
  isOnHome?: boolean;
  isOnPost?: boolean;
  allowChange?: boolean;
  hideDebateCapture?: boolean;
  className?: string;
  isOnboarding?: boolean;
  isAnotherUserProfile?: boolean;
  ownerFullName?: string;
  ownerId?: string;
  questionEndAt?: string;
  followersCount?: number;
  onClickByPostText?: () => void;
  openPostInModal?: () => void;
  cb?: () => void;
  voteWidth?: number;
  oneVoteWidth?: number;
  oneVoteHeight?: number;
  isFirst?: boolean;
  isLatest?: boolean;
  isDisabled?: boolean;
  isTwoRows?: boolean;
  isPopular?: boolean;
  shouldShowVoteOwner?: {
    ownerName: string;
  };
  isCurrent?: boolean;
}

export const QuestionVoteComponent = ({
  userProfileId,
  questionId,
  isAnon,
  isPost,
  isOwner,
  isRepost,
  isNotEnouthAnswerForResult,
  isResultInProcessing,
  text,
  answer,
  answerCallback,
  updateQuestionInModal,
  showSkip,
  hideLeftSkip,
  isQuote,
  isOnHome,
  isOnNotification,
  isAnotherUserProfile,
  allowChange,
  hideDebateCapture,
  ownerFullName,
  ownerId,
  questionEndAt,
  followersCount,
  className,
  isOnboarding,
  onClickByPostText,
  openPostInModal,
  cb,
  voteWidth,
  oneVoteWidth,
  oneVoteHeight,
  isDisabled,
  isFirst,
  isLatest,
  isTwoRows,
  shouldShowVoteOwner,
  isOnPost,
  isPopular,
  isCurrent
}: IQuestionVoteComponentProps) => {
  const { push } = useRouter();
  const [isTimeAlmostUp, setIsTimeAlmostUp] = useState(checkTimeAlmostUp(questionEndAt));
  const [currentAnswer, setCurrentAnswer] = useState(answer);
  const { mutateAsync: addQuestionAnswer, isLoading } = useAddQueryAnswer(userProfileId, cb, updateQuestionInModal, true);

  useEffect(() => {
    if (answer) {
      setCurrentAnswer(answer);
    }
  }, [answer]);

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newAnswer: number) => {
    if (isQuote) return;
    event.preventDefault();
    event.stopPropagation();
    if (((!!currentAnswer || currentAnswer === 0) && !allowChange) || isLoading) {
      return;
    }
    let newAnswerToSend = newAnswer;
    if (!!currentAnswer && currentAnswer === newAnswer && isPost) {
      newAnswerToSend = 0;
      if (isOwner) return;
      if (questionId) {
        addQuestionAnswer({ questionId, answer: 0 });
      }
    }
    setCurrentAnswer(newAnswerToSend);
    if (answerCallback) {
      answerCallback(newAnswerToSend);
    } else if (questionId && newAnswerToSend) {
      addQuestionAnswer({ questionId, answer: newAnswerToSend });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>, newAnswer: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      clickHandler(event as any, newAnswer);
    }
  };

  const onClickByText = () => {
    if (onClickByPostText) {
      onClickByPostText();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeAlmostUp(checkTimeAlmostUp(questionEndAt));
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isNotNewDebate = !isNotEnouthAnswerForResult && !isResultInProcessing;

  const onClickByCreator = () => {
    if (!ownerId) return;
    push(`${ROUTER_KEYS.PROFILE}?id=${ownerId}`);
  };

  return (
    <Styled.QuestionVoteWrapper
      isRepost={isRepost}
      className={className}
      isOnboarding={isOnboarding}
      isLatest={isLatest || (!currentAnswer && currentAnswer !== 0)}
      isFirst={isFirst}
      isDisabled={isDisabled || !!currentAnswer || currentAnswer === 0}
      isOnPost={isOnPost}
    >
      {isAnotherUserProfile && isNotNewDebate && (
        <Styled.DebateOnAnotherUserProfileTitle>
          <p>{shouldShowVoteOwner?.ownerName ?? ''} voted on this debate</p>
        </Styled.DebateOnAnotherUserProfileTitle>
      )}
      {isTimeAlmostUp && !answer && !isQuote && !hideDebateCapture && isNotNewDebate && (
        <Styled.DebateAlmostUpWrapper>
          <Image src={SixHoursLeft} alt="Six hours left to vote icon" width={14} height={14} style={{ margin: '3px 7px 3px 0' }} />6 hours
          left to vote <span>•</span> {followersCount ?? 0} members you follow have voted
        </Styled.DebateAlmostUpWrapper>
      )}
      {!!ownerFullName && !hideDebateCapture && (
        <Styled.NewDebateWrapper voted={(isAnotherUserProfile && isNotNewDebate) || isQuote} isClicable={!!ownerId}>
          {!isQuote && (
            <Styled.DebateIcon>
              <Image src={isAnotherUserProfile && isNotNewDebate ? VotedeIcon : NewDebateIcon} alt="Icon" width={18} height={18} />
            </Styled.DebateIcon>
          )}
          <Styled.NewDebateText>New Debate</Styled.NewDebateText>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickByCreator();
            }}
            type="button"
          >
            created by {ownerFullName}
          </button>
        </Styled.NewDebateWrapper>
      )}
      {!!text && (
        <Styled.QuestionTitleContainer voted isOnHome={isOnHome} isOnboarding={isOnboarding || (isAnotherUserProfile && isNotNewDebate)}>
          <Styled.QuestionTextContainer isOnboarding={isOnboarding}>
            <Styled.QuestionText
              isAnon={!!isAnon}
              isClickable={!!onClickByPostText}
              onClick={onClickByText}
              isQuote={isQuote}
              isOnboarding={isOnboarding}
              isOnNotification={isOnNotification}
              isTwoRows={isTwoRows}
              isOnPost={isOnPost}
              isCurrent={isCurrent}
            >
              {text}
            </Styled.QuestionText>
          </Styled.QuestionTextContainer>
        </Styled.QuestionTitleContainer>
      )}

      {(allowChange || !isAnotherUserProfile) && shouldShowVoteOwner && !isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.OwnerText isOnboarding={isOnboarding}>
          Vote on this debate below. Results are only available to those that vote:
        </Styled.OwnerText>
      )}

      {(allowChange || !isAnotherUserProfile) && !isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.VoteWithSkipContainer voteWidth={voteWidth} isOnboarding={isOnboarding}>
          {showSkip && !hideLeftSkip && (
            <Styled.QuestionSkip
              voted={!!currentAnswer || currentAnswer === 0}
              active={currentAnswer === 0}
              onClick={(e) => clickHandler(e, 0)}
              onKeyDown={(event) => handleKeyDown(event, 0)}
              allowChange={!!allowChange}
              isOnboarding={isOnboarding}
              isDisabled={!!currentAnswer && currentAnswer !== 0}
              isLeft
              tabIndex={0}
            >
              skip debate
            </Styled.QuestionSkip>
          )}
          <Styled.VoteContainer isPost={isPost} isOnboarding={isOnboarding}>
            <Styled.LeftVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 1}
              onClick={(e) => clickHandler(e, 1)}
              onKeyDown={(event) => handleKeyDown(event, 1)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 1}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              {isPost ? (
                <p>
                  strong
                  <br />
                  disagree
                </p>
              ) : (
                <>
                  strong
                  <br />
                  disagree
                </>
              )}
            </Styled.LeftVoteBar>
            <Styled.LeftVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 2}
              onClick={(e) => clickHandler(e, 2)}
              onKeyDown={(event) => handleKeyDown(event, 2)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 2}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              <p>
                highly
                <br />
                unlikely
              </p>
            </Styled.LeftVoteBar>
            <Styled.LeftVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 3}
              onClick={(e) => clickHandler(e, 3)}
              onKeyDown={(event) => handleKeyDown(event, 3)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 3}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              <p>
                quite
                <br />
                unlikely
              </p>
            </Styled.LeftVoteBar>

            <Styled.RightVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 4}
              onClick={(e) => clickHandler(e, 4)}
              onKeyDown={(event) => handleKeyDown(event, 4)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 4}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              <p>
                quite
                <br />
                likely
              </p>
            </Styled.RightVoteBar>
            <Styled.RightVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 5}
              onClick={(e) => clickHandler(e, 5)}
              onKeyDown={(event) => handleKeyDown(event, 5)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 5}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              <p>
                highly
                <br />
                likely
              </p>
            </Styled.RightVoteBar>
            <Styled.RightVoteBar
              oneVoteWidth={oneVoteWidth}
              oneVoteHeight={oneVoteHeight}
              isPopular={isPopular}
              isPost={isPost}
              isQuoted={isQuote || isOnNotification}
              isSelected={currentAnswer === 6}
              onClick={(e) => clickHandler(e, 6)}
              onKeyDown={(event) => handleKeyDown(event, 6)}
              isDisabled={(!!currentAnswer || currentAnswer === 0) && currentAnswer !== 6}
              allowChange={allowChange}
              isFirst={isFirst}
              isOnboarding={isOnboarding}
              tabIndex={0}
            >
              <p>
                strong
                <br />
                agree
              </p>
            </Styled.RightVoteBar>
          </Styled.VoteContainer>
          {showSkip && (
            <Styled.QuestionSkip
              voted={!!currentAnswer || currentAnswer === 0}
              active={currentAnswer === 0}
              onClick={(e) => clickHandler(e, 0)}
              onKeyDown={(event) => handleKeyDown(event, 0)}
              allowChange={!!allowChange}
              isOnboarding={isOnboarding}
              isDisabled={!!currentAnswer && currentAnswer !== 0}
              tabIndex={0}
            >
              skip debate
            </Styled.QuestionSkip>
          )}
        </Styled.VoteWithSkipContainer>
      )}

      {(allowChange || !isAnotherUserProfile) && shouldShowVoteOwner && !isQuote && !isAnotherUserProfile && isNotNewDebate && (
        <Styled.KeyDebatesDescription>
          Debate closes 3 days from its submission; you can only change your vote once
        </Styled.KeyDebatesDescription>
      )}

      {isAnotherUserProfile && isNotNewDebate && !answer && (
        <Styled.KeyDebateDescriptionOnAnotherUserProfile>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              if (openPostInModal) {
                openPostInModal();
              }
            }}
          >
            Click here to vote
          </button>
          <span>•</span>
          <p>results are only available to those that vote</p>
        </Styled.KeyDebateDescriptionOnAnotherUserProfile>
      )}

      {isAnotherUserProfile && isNotNewDebate && !!answer && (
        <Styled.KeyDebateDescriptionOnAnotherUserProfileAlreadyVoted>
          You have already voted on this
        </Styled.KeyDebateDescriptionOnAnotherUserProfileAlreadyVoted>
      )}
      {isNotEnouthAnswerForResult && (
        <Styled.KeyDebateDescriptionNotReveald>
          This debate did not reach enough votes and is now closed.
        </Styled.KeyDebateDescriptionNotReveald>
      )}
      {isResultInProcessing && <Styled.KeyDebateDescriptionNotReveald>This debate is closed.</Styled.KeyDebateDescriptionNotReveald>}
    </Styled.QuestionVoteWrapper>
  );
};
