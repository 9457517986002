/* eslint-disable jsx-a11y/no-autofocus */
import React, { useMemo, useState } from 'react';
import Arrow from '@styles/icons/login-real-from-anon-arrow.webp';
import ArrowDisabled from '@styles/icons/login-real-from-anon-arrow-disabled.webp';
import Image from 'next/image';
import * as Styled from './login-real-from-anon-modal.styled';
import { SlideType } from '@/common/types/modal.type';
import { useRealVerifyConfirm, useRealVerifySend } from '@/common/hooks';
import { EMAIL_MATCH } from '@/common/components/auth';
import { VerifcationCodeInputComponent } from '../../verification-code-input/verification-code-input.component';

interface IProps {
  open: boolean;
  closeHandler: () => void;
}

export const LoginRealFromAnon: React.FC<IProps> = ({ open, closeHandler }) => {
  const [email, setEmail] = useState('');
  const [disabledEmailInput, setDisabledEmailInput] = useState(false);
  const [emailHasError, setEmailHasError] = useState(false);
  const [disabledCodeInput, setDisabledCodeInput] = useState(false);
  const [isCodeIncorect, setIsCodeIncorect] = useState(false);

  const close = () => {
    closeHandler();
    setTimeout(() => {
      setEmail('');
      setDisabledEmailInput(false);
      setEmailHasError(false);
      setDisabledCodeInput(false);
      setIsCodeIncorect(false);
    }, 500);
  };

  const { mutateAsync: verifySend, isLoading: verifySending } = useRealVerifySend(
    () => {
      setDisabledEmailInput(true);
    },
    () => setEmailHasError(true)
  );

  const { mutateAsync: verifyCode, isLoading: verifingCode } = useRealVerifyConfirm(
    () => {
      setDisabledCodeInput(true);
      setIsCodeIncorect(false);
    },
    () => {
      setIsCodeIncorect(true);
    }
  );

  const sendVerifyMessage = () => {
    if (verifySending) return;
    verifySend({ email });
  };

  const sendVerifyCode = (code: string) => {
    if (verifingCode) return;
    verifyCode({ email, code });
  };

  const handleVerifyEmailErrorClick = () => {
    setEmail('');
    setDisabledEmailInput(false);
    setEmailHasError(false);
    setDisabledCodeInput(false);
    setIsCodeIncorect(false);
  };

  const onConfirmButtonClick = () => {
    if (!disabledEmailInput) {
      sendVerifyMessage();
    }
  };

  const buttonDisabled = useMemo(() => {
    if (!disabledEmailInput) {
      return !EMAIL_MATCH.test(email);
    }

    return false;
  }, [email]);
  return (
    <Styled.StyledModal open={open} closeHandler={close} slideType={SlideType.BOTTOM} isCenter hideChildBackground hideChildeShadow hideBorder>
      <Styled.ChildWrapper>
        <div>
          <Styled.LoginWrapper>
            <div>
              <h2>Login to your Real Account</h2>
              <span>After this action, seamlessly switch from Anon to Real</span>
            </div>
            <div>
              <h4>Enter your Real Account Email Address</h4>
              <input
                type="email"
                placeholder="type here"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value ?? '');
                  setEmailHasError(false);
                }}
                disabled={disabledEmailInput}
                autoFocus
                onKeyDown={(e) => e.code === 'Enter' && sendVerifyMessage()}
              />
            </div>
            {disabledEmailInput && (
              <Styled.CodeInputWrapper isHasMarginBottom={!disabledCodeInput}>
                <VerifcationCodeInputComponent
                  disabled={disabledCodeInput}
                  onFinish={sendVerifyCode}
                  error={{ errorText: '', isError: false }}
                  clickable
                />
              </Styled.CodeInputWrapper>
            )}
            {!disabledEmailInput && (
              <Styled.ConfirmButton disabled={buttonDisabled} onClick={onConfirmButtonClick}>
                <p>Verify email</p>
                <Image src={buttonDisabled ? ArrowDisabled : Arrow} width={14} height={14} alt="Confirm Arrow" />
              </Styled.ConfirmButton>
            )}
          </Styled.LoginWrapper>
        </div>
        {emailHasError && <p>This email address is not registered</p>}
        {isCodeIncorect && (
          <button type="button" onClick={handleVerifyEmailErrorClick}>
            The code you entered is incorrect:
            <br />
            try again or click here to submit another email address
          </button>
        )}
      </Styled.ChildWrapper>
    </Styled.StyledModal>
  );
};
