import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '../consts/app-keys.const';
import { IPost, ITag } from '../types/post.type';
import { useTreadByPostId } from './use-post';
import { POST_LIMIT } from '../consts';
import { newsfeedService } from '../services';
import { IFilters, IListTags, ISkipPayload, NewsfeedNavigation } from '../types/newsfeed.type';

export const useAllNewsfeeds = (userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { data: posts, isLoading } = useQuery([QUERY_KEYS.NEWSFEEDS, userProfileId], () =>
    queryClient.getQueryData<IPost[]>([QUERY_KEYS.NEWSFEEDS, userProfileId])
  );
  return {
    allPosts: posts,
    isLoading,
    setAllPosts: (data: IPost[]) => {
      queryClient.setQueryData([QUERY_KEYS.NEWSFEEDS, userProfileId], data);
      queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS, userProfileId]);
    },
    setNewPosts: (data: IPost[], newUserProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.NEWSFEEDS, newUserProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.NEWSFEEDS, newUserProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS, newUserProfileId]);
    },
    setUpdatePosts: (data: IPost, newUserProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.NEWSFEEDS, newUserProfileId]);
      const newPosts = (oldPosts ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([QUERY_KEYS.NEWSFEEDS, newUserProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS, newUserProfileId]);
    },
    deletePost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId]);
      const updatedPosts = postsData?.map((post) => {
        if (post.postId === data.id) {
          return {
            ...post,
            post: data
          };
        }
        if (post.id === data.id) {
          return {
            ...post,
            ...data
          };
        }

        return post;
      });

      queryClient.setQueryData([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId]);
    },
    deleteRepost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId]);
      const updatedPosts = postsData?.filter((postData) => postData.id !== data.id);

      queryClient.setQueryData([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.NEWSFEEDS, userProfileId ?? newProfileId]);
    }
  };
};

export const useFetchNewsfeeds = (currentFilter: IFilters, activeTab: NewsfeedNavigation, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useAllNewsfeeds(userProfileId);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isRefetching, isLoading, refetch } = useInfiniteQuery(
    [QUERY_KEYS.NEWSFEEDS, POST_LIMIT, userProfileId, activeTab],
    ({ pageParam = 0 }) =>
      newsfeedService.getAllNewsfeeds({ setTread, queryClient, setPosts }, pageParam + 1, POST_LIMIT, currentFilter, activeTab),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isRefetching,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch
  };
};

export const useGetTags = () => {
  const client = useQueryClient();
  const sourceQuery = useMutation({
    mutationKey: [QUERY_KEYS.GET_LIST_TAGS],
    mutationFn: () => newsfeedService.getListTags(),
    onSuccess: (data) => {
      if (!data) return;
      client.setQueryData(QUERY_KEYS.LIST_TAGS, data);
      client.invalidateQueries([QUERY_KEYS.LIST_TAGS]);
    }
  });
  return sourceQuery;
};

export const useGetTagListData = () => {
  const client = useQueryClient();

  return useQuery({
    queryKey: [QUERY_KEYS.LIST_TAGS],
    queryFn: () => client.getQueryData(QUERY_KEYS.LIST_TAGS) as IListTags
  });
};

export const useGetAllowedFilters = () => {
  const client = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS.GET_NEWS_ALLOWED_FILTERS],
    mutationFn: ({ filter, activeTab }: { filter: IFilters; activeTab?: NewsfeedNavigation }) =>
      newsfeedService.getAllowedFilters(filter, activeTab),
    onSuccess: (data) => {
      if (!data) return;
      client.setQueryData(QUERY_KEYS.GET_NEWS_ALLOWED_FILTERS_DATA, data);
      client.invalidateQueries([QUERY_KEYS.GET_NEWS_ALLOWED_FILTERS_DATA]);
    }
  });
};

export const useGetAllowedFiltersData = () => {
  const client = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_NEWS_ALLOWED_FILTERS_DATA],
    queryFn: () => client.getQueryData(QUERY_KEYS.GET_NEWS_ALLOWED_FILTERS_DATA) as ITag[]
  });
};

export const usePreselectedFilters = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.SET_CURRENT_FILTER],
    mutationFn: async () => newsfeedService.getPreselectedFilters()
  });

export const useSkipNewsfeed = () =>
  useMutation({
    mutationFn: (data: ISkipPayload) => newsfeedService.skipNewsfeed(data)
  });
