import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import * as Styled from './memo-filter-more-button.styled';
import Plus from '@/styles/icons/plus-blue.svg';
import PlusDisabled from '@/styles/icons/plus-disabled.svg';
import Minus from '@/styles/icons/minus-blue.svg';

interface IProps {
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const FilterMoreButtonComponent = ({ isOpen, setIsOpen, isDisabled }: IProps): ReactElement => (
  <Styled.MoreButton disabled={isDisabled} onClick={() => setIsOpen((prev) => !prev)}>
    {isOpen ? 'less' : 'more'}
    <Styled.MoreButtonIcon src={isOpen ? Minus : isDisabled ? PlusDisabled : Plus} alt={isOpen ? 'Minus' : 'Plus'} />
  </Styled.MoreButton>
);
