import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { MentionType } from '@sendbird/chat/message';
import * as Styled from './poll-text.styled';
import { ConvertFromMentionTypeToAlgoliaType, IPollPosts } from '@/common/types/post.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IPollVoteComponentProps {
  poll?: IPollPosts;
  highlight?: string;
  isQuote?: boolean;
}

export const PollTextComponent = ({ poll, highlight, isQuote }: IPollVoteComponentProps) => {
  const { push } = useRouter();

  const securityClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const algoliaType = ConvertFromMentionTypeToAlgoliaType.SECURITY;

    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        type: algoliaType,
        value: poll?.security.name,
        clickedId: poll?.security.id,
        clickedValue: poll?.security.name,
        clickedTicker: poll?.security.id
      }
    });
  };

  const titleHighlightObject = useMemo(() => {
    if (!poll?.text || highlight === undefined || highlight === '') return { titleBeforeHighlight: poll?.text, highlightedTitle: '', titleAfterHighlight: '' };
    const highlightIndex = poll.text.toLowerCase().indexOf(highlight.toLowerCase());
    if (highlightIndex === -1) return { titleBeforeHighlight: poll.text, highlightedTitle: '', titleAfterHighlight: '' };
    return {
      titleBeforeHighlight: poll.text.slice(0, highlightIndex),
      highlightedTitle: poll.text.slice(highlightIndex, highlightIndex + highlight.length),
      titleAfterHighlight: poll.text.slice(highlightIndex + highlight.length)
    };
  }, [poll?.text, highlight]);

  const securityHighlightObject = useMemo(() => {
    if (!poll?.security?.name || highlight === undefined || highlight === '') return { titleBeforeHighlight: poll?.security?.name, highlightedTitle: '', titleAfterHighlight: '' };
    const text = poll.security.name;
    const highlightIndex = text.toLowerCase().indexOf(highlight.toLowerCase());
    if (highlightIndex === -1) return { titleBeforeHighlight: text, highlightedTitle: '', titleAfterHighlight: '' };
    return {
      titleBeforeHighlight: text.slice(0, highlightIndex),
      highlightedTitle: text.slice(highlightIndex, highlightIndex + highlight.length),
      titleAfterHighlight: text.slice(highlightIndex + highlight.length)
    };
  }, [poll?.security?.name, highlight]);

  return (
    <Styled.SwingsTitle isQuote={isQuote}>
      <Styled.SwingsSubtitle onClick={securityClick} isQuote={isQuote}>
        {securityHighlightObject.titleBeforeHighlight}
        <Styled.HighlightedTitle>{securityHighlightObject.highlightedTitle}</Styled.HighlightedTitle>
        {securityHighlightObject.titleAfterHighlight}
      </Styled.SwingsSubtitle>{' '}
      {titleHighlightObject.titleBeforeHighlight}
      <Styled.HighlightedTitle>{titleHighlightObject.highlightedTitle}</Styled.HighlightedTitle>
      {titleHighlightObject.titleAfterHighlight} in {poll!.year} {poll!.quartal}
    </Styled.SwingsTitle>
  );
};
