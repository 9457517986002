import React from 'react';
import Image from 'next/image';
import * as Styled from './close-button.styled';
import CloseIcon from '@/styles/icons/anon-activate-modal-close-button.webp';

interface ICloseButtonProps {
  closeHandler: () => void;
}

export const CloseButton = ({ closeHandler }: ICloseButtonProps) => (
  <Styled.CloseButton onClick={closeHandler}>
    close
    <Image src={CloseIcon} width={10} height={10} alt="Close modal" />
  </Styled.CloseButton>
);
