import { IGetMe } from '@/common/types/auth.type';
import { EnhancedWithAuthHttpService } from '@/common/services/http-auth.service';
import { axiosInstance } from '@/common/services/axios';
import { HttpService } from '@/common/services/http.service';
import { IUser } from '@/common/types/user.type';

// fix
class UserService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  // async me({ onSuccess, onError }: { onSuccess: (me?: IGetMe) => void; onError: () => void }) {
  async me() {
    // try {
    const response = await this.get<IGetMe>('auth/me');
    // onSuccess(response ?? undefined);
    return response;
    // } catch (err) {
    //   onError();
    // }
  }

  async getInvitationLink(baseUrl: string) {
    const response = await this.post<{ link: string }, { baseUrl: string }>('auth/invitation-link', { baseUrl });
    return response?.link;
  }

  async updateSetupStep(setupStep: number) {
    const response = await this.patch<IUser, { setupStep: number }>('auth/me', { setupStep });
    return response;
  }
}

export const userService = new UserService();
