import { QueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { IPost, IStatistic, ISubPost } from '../types/post.type';

export const pushToUpdatedStatistics = (updatedStatistics: IStatistic[], post: IPost | ISubPost | IStatistic) => {
  updatedStatistics.push({
    id: post.id,
    replyCount: post.replyCount,
    repostCount: post.repostCount,
    bookmarkCount: post.bookmarkCount,
    likeCount: post.likeCount,
    praiseCount: post.praiseCount,
    clapCount: post.clapCount,
    ownReplyCount: post.ownReplyCount,
    isLiked: post.isLiked,
    isBookmarked: post.isBookmarked,
    isClaped: post.isClaped,
    isPraised: post.isPraised,
    isReposted: post.isReposted,
    isOwnPost: post.isOwnPost,
    isReplied: post.isReplied,
    isQuotePosted: post.isQuotePosted,
    quotePostCount: post.quotePostCount
  });
};

export const setPostStatistics = async (post: IPost | ISubPost | IStatistic, queryClient: QueryClient) => {
  await queryClient.setQueryData([QUERY_KEYS.STATISTIC, post.id], post);
  await queryClient.invalidateQueries([QUERY_KEYS.STATISTIC, post.id]);
};

export const updateAdorationPost = (post: IPost | ISubPost | IStatistic, queryClient: QueryClient, profileId?: string) => {
  if (!profileId) return;
  const data = queryClient.getQueryData<IPost[]>([QUERY_KEYS.ADORATIONS, profileId]);
  if (!data) return;
  if (!post.isClaped && !post.isLiked && !post.isReposted) {
    queryClient.setQueryData(
      [QUERY_KEYS.ADORATIONS, profileId],
      data.filter((e) => e.id !== post.id)
    );
    return;
  }
  const isNew = !data.filter((it) => it.id === post.id).length;

  if (isNew) {
    queryClient.setQueryData([QUERY_KEYS.ADORATIONS, profileId], [post, ...data]);
  }
};

export const formatNumber = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  }
  if (num < 10000) {
    const rounded = (num / 1000).toFixed(1);
    return `${rounded}K`;
  }
  if (num < 1000000) {
    const rounded = Math.floor(num / 1000);
    return `${rounded}K`;
  }
  const rounded = (num / 1000000).toFixed(1);
  return `${rounded}M`;
};
