import styled from 'styled-components';
import Image from 'next/image';
import { FAMILIES } from '@/theme/fonts.const';

export const ChipLabel = styled.div`
  height: 40px;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #eff1ff;
  color: #0016d8;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

export const CloseIcon = styled(Image)`
  cursor: pointer;
  width: 8px;
  height: 8px;
`;
