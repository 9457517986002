import styled from 'styled-components';
import { COLORS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const CloseButton = styled.button`
  max-width: 88px;
  max-height: 34px;
  margin: 0px 164px;
  cursor: pointer;

  border-radius: ${SIZES.xs};
  border: ${COLORS.anonActiveModalButtonBorder};
  background: ${COLORS.white};
  padding: 10px 15px;

  display: flex;
  gap: 15px;
  align-items: center;

  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  line-height: ${SIZES.m};
`;
