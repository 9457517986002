import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { insightService } from '../services/insight.service';
import { CreateFeedDTO } from '../types/insight.types';
import { useAllHomePosts, useAllProfilePosts, useTreadByPostId } from './use-post';
import { useAuthProfile } from './use-profile';
import { setPostStatistics } from '../utils/post-statistics';
import { QUERY_KEYS, ROUTER_KEYS } from '../consts/app-keys.const';

export const useCreateFeedByUser = () => {
  const { asPath } = useRouter();
  const { data: profileId = undefined } = useAuthProfile();
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts(profileId);
  const { setNewPosts: setNewPostsOnHomeFollowing } = useAllHomePosts(false, profileId);
  const { setNewPosts: setNewPostsOnHome } = useAllHomePosts(true, profileId);
  const { setTread } = useTreadByPostId();

  return useMutation({
    mutationFn: (data: CreateFeedDTO) => insightService.createFeedByUser(data),
    onError: (error) => {
      console.error('Error creating feed:', error);
    },
    onSuccess: (data: any) => {
      if (!data || asPath === ROUTER_KEYS.PROFILE) return;
      setTread(data.tread, data.id);
      data.tread.replies.forEach((it: any) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);
      setNewPosts([data], data.profileId, true);
      setNewPostsOnHome([data], data.profileId, true);
      setNewPostsOnHomeFollowing([data], data.profileId, true);
    }
  });
};

export const usePdfBase64 = (key: string) =>
  useQuery([QUERY_KEYS.GET_PDF_BASE64, key], () => insightService.getPdfBase64(key), {
    enabled: !!key,
    retry: false,
    onError: (error: Error) => {
      console.error('Error fetching PDF base64 data:', error);
    }
  });
