type IgnoreMessage = {
  endpoint: string;
  message: string;
};

export const IGNORE_ERRORS: IgnoreMessage[] = [
  {
    endpoint: '/auth/anon-sign-in',
    message: 'Wrong password'
  },
  {
    endpoint: '/auth/email-characters-signin/send',
    message: 'Not Found'
  },
  {
    endpoint: '/auth/email-characters-signin/confirm',
    message: 'The code is either invalid or has expired'
  },
  {
    endpoint: '/auth/real-verify/confirm',
    message: 'The code is either invalid or has expired'
  },
  {
    endpoint: '/auth/real-verify/send',
    message: 'Real user not found'
  },
  {
    endpoint: '/auth/real-verify/send',
    message: 'Bad Request'
  },
  {
    endpoint: '/auth/email-verify/confirm',
    message: 'The code is either invalid or has expired'
  },
  {
    endpoint: '/auth/email-characters-signin/confirm',
    message: 'The code is either invalid or has expired'
  },
  {
    endpoint: 'auth/email-verify/send',
    message: 'Email adress is already in use'
  }
];
