import React, { useState } from 'react';
import DeletePostIcon from '@styles/icons/delete-post-icon.webp';
import CopiedPostIcon from '@styles/icons/copied-post-icon.webp';
import Image from 'next/image';
import * as Styled from './dropdown-menu.style';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IProps {
  postId: string;
  originalPostId?: string;
  anchorEl: {
    element: HTMLElement;
    onClose: () => void;
  } | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<{
      element: HTMLElement;
      onClose: () => void;
    } | null>
  >;
  onDelete?: () => void;
}

export const SourceMenuComponent: React.FC<IProps> = ({ postId, originalPostId, anchorEl, setAnchorEl, onDelete }) => {
  const open = Boolean(anchorEl);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isCopyClicked, setIsCopyClicked] = useState(false);

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    anchorEl?.onClose();
    setAnchorEl(null);
    setIsCopyClicked(false);
    setIsDeleteClicked(false);
  };

  const onCopyClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsDeleteClicked(false);
    if (typeof window !== 'undefined') {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      if (originalPostId) {
        navigator.clipboard
          .writeText(`${baseUrl}${ROUTER_KEYS.HOME}?postId=${postId}&originalPostId=${originalPostId}`)
          .then(() => setIsCopyClicked(true));
      } else {
        navigator.clipboard.writeText(`${baseUrl}${ROUTER_KEYS.HOME}?postId=${postId}`).then(() => setIsCopyClicked(true));
      }
    }
  };

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsCopyClicked(false);
    setIsDeleteClicked(true);
  };

  const onDeleteHanlder = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!onDelete) return;
    e.stopPropagation();
    onDelete();
  };

  return (
    <Styled.DropdownMenu anchorEl={anchorEl?.element} open={open} onClick={handleClose} onClose={handleClose}>
      <Image
        src={DeletePostIcon}
        width={14}
        height={14}
        style={{
          margin: '3px',
          position: 'absolute',
          top: '0',
          left: '0',
          visibility: 'hidden'
        }}
        alt="delete post icon"
      />
      <button type="button" onClick={onCopyClick}>
        {isCopyClicked ? <span>copied</span> : 'copy url'}
        {isCopyClicked && (
          <Image
            src={CopiedPostIcon}
            priority
            width={14}
            height={14}
            style={{
              margin: '3px'
            }}
            alt="delete post icon"
          />
        )}
      </button>
      {onDelete && (
        <button type="button" onClick={isDeleteClicked ? onDeleteHanlder : onDeleteClick}>
          {isDeleteClicked ? <p>confirm</p> : 'delete'}
          {isDeleteClicked && (
            <Image
              src={DeletePostIcon}
              priority
              width={14}
              height={14}
              style={{
                margin: '3px'
              }}
              alt="delete post icon"
            />
          )}
        </button>
      )}
    </Styled.DropdownMenu>
  );
};
