import styled from 'styled-components';
import { Chip as MuiChip } from '@mui/material';
import { ChipColors, ChipVariants, CustomChipProps } from './chip.types';
import { COLORS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';

const getChipColorStyles = (variant: ChipVariants = 'contained', color: ChipColors = 'default') => {
  const colors = {
    default: {
      background: variant === 'contained' ? `${COLORS.transparentDarkerGray}` : 'transparent',
      border: variant === 'outlined' ? `1px solid ${COLORS.darkerGray}` : 'none',
      text: `${COLORS.darkerGray}`,
      hover: {
        background: variant === 'contained' ? `${COLORS.semiTransparentGray}` : `${COLORS.transparentDarkerGray}`,
        border: variant === 'outlined' ? `1px solid ${COLORS.darkerGray}` : 'none',
        text: `${COLORS.black}`
      },
      focus: {
        background: variant === 'contained' ? `${COLORS.semiTransparentGray}` : `${COLORS.transparentDarkerGray}`,
        border: variant === 'outlined' ? `1px solid ${COLORS.darkerGray}` : 'none',
        text: `${COLORS.black}`
      }
    },
    primary: {
      background: variant === 'contained' ? 'rgba(239, 241, 255, 0.50)' : 'transparent',
      border: variant === 'outlined' ? `1px solid ${COLORS.darkerBlue}` : 'none',
      text: `${COLORS.darkerBlue}`,
      hover: {
        background: variant === 'contained' ? `${COLORS.transparentDarkerBlue}` : `${COLORS.transparentLighterBlue}`,
        border: variant === 'outlined' ? `1px solid ${COLORS.lighterBlue}` : 'none',
        text: `${COLORS.lighterBlue}`
      },
      focus: {
        background: variant === 'contained' ? `${COLORS.transparentDarkerBlue}` : `${COLORS.transparentLighterBlue}`,
        border: variant === 'outlined' ? `1px solid ${COLORS.lighterBlue}` : 'none',
        text: `${COLORS.lighterBlue}`
      }
    }
  };

  return colors[color];
};

export const Chip = styled(MuiChip)<CustomChipProps>`
  &.MuiChip-root {
    font-family: ${FAMILIES.robotoFlex};
    height: 22px;
    background-color: ${({ customVariant = 'contained', color = 'default' }) => getChipColorStyles(customVariant, color).background};
    border-radius: 6px;
    border: ${({ customVariant = 'contained', color = 'default' }) => getChipColorStyles(customVariant, color).border};
    color: ${({ customVariant = 'contained', color = 'default' }) => getChipColorStyles(customVariant, color).text};

    ${({ onClick, customVariant = 'contained', color = 'default' }) =>
      onClick &&
      `
      &:hover {
        background-color: ${getChipColorStyles(customVariant, color).hover.background};
        border: ${getChipColorStyles(customVariant, color).hover.border};
        color: ${getChipColorStyles(customVariant, color).hover.text};
      }
      &:focus {
        background-color: ${getChipColorStyles(customVariant, color).focus.background};
        border: ${getChipColorStyles(customVariant, color).focus.border};
        color: ${getChipColorStyles(customVariant, color).focus.text};
      }
    `}

    & .MuiChip-label {
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 13px;
      padding: 4.5px 6px;
    }

    & svg {
      fill: ${({ customVariant = 'contained', color = 'default' }) => getChipColorStyles(customVariant, color).focus.text};
      width: 14px;
    }
  }
`;
