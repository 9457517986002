import React, { useState, useEffect, useRef, ChangeEvent, DragEvent } from 'react';
import { isURL } from 'validator';
import * as Styled from './input-button.styled';
import { ModalErrorOnDragAndDropComponent } from '@/common/components/modal-error-on-drag-and-drop';
import { OnDropErrorEnum } from '@/common/types/profile.type';

interface InputButtonComponentProps {
  onLinkChange: (value: string, fileName?: string) => void;
  setIsFileFile: (isFile: boolean) => void;
}

export const InputButtonComponent = ({ onLinkChange, setIsFileFile }: InputButtonComponentProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && file.type === 'application/pdf') {
      const base64String = await convertFileToBase64(file);
      setInputValue(file.name);
      onLinkChange(base64String, file.name);
      setIsFileFile(true);
    } else {
      setOnDropError(OnDropErrorEnum.INCORECT_TYPE_MEMO_PDF);
      onLinkChange('', '');
    }
  };

  const handleInputClick = () => {
    document.getElementById('file-upload')?.click();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value.trim();

    if (isURL(newValue, { protocols: ['http', 'https'], require_protocol: false })) {

      if (!newValue.startsWith('http://') && !newValue.startsWith('https://')) {
        newValue = `https://${newValue}`;
      }
      setInputValue(newValue);
      onLinkChange(newValue);
    } else {
      setInputValue('');
      onLinkChange('');
    }
  };
  
  const convertFileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64 = base64String.split(',')[1];
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files?.[0];
    if (file && file.type === 'application/pdf') {
      const base64String = await convertFileToBase64(file);
      setInputValue(file.name);
      onLinkChange(base64String);
      setIsFileFile(true);
    } else {
      setOnDropError(OnDropErrorEnum.INCORECT_TYPE_MEMO_PDF);
      onLinkChange('');
    }
  };

  return (
    <>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      <Styled.InputWrapper onDragOver={handleDragOver} onDrop={handleDrop}>
        <Styled.InputField
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleInputClick}
          placeholder="Paste link&nbsp;&nbsp;/&nbsp;&nbsp;drag-and-drop PDF&nbsp;&nbsp;/&nbsp;&nbsp;click to add PDF"
        />
        <Styled.FileInput type="file" id="file-upload" accept=".pdf" onChange={handleFileChange} />
      </Styled.InputWrapper>
    </>
  );
};
