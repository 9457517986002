import React from 'react';
import * as Styled from './link-preview.styled';
import { ILinkPreview } from '@/common/types/post.type';

interface ILinkPreviewProps {
  data: ILinkPreview;
}

export const LinkPreviewComponent = ({ data }: ILinkPreviewProps) => {
  const onClick = (e?: any) => {
    e?.stopPropagation();
    window.open(data?.link, '_blank');
  };
  return (
    <Styled.LinkPreviewWrapper onClick={onClick}>
      {data?.imageUrl ? <Styled.LinkPreviewImage src={data.imageUrl} alt="Preview" /> : <Styled.EmptyImage />}
      <Styled.LinkPreviewTitleWrapper>
        <Styled.LinkPreviewDomain>{data?.domain}</Styled.LinkPreviewDomain>
        <Styled.LinkPreviewTitle>{data?.title}</Styled.LinkPreviewTitle>
      </Styled.LinkPreviewTitleWrapper>
    </Styled.LinkPreviewWrapper>
  );
};
