import React, { useState } from 'react';
import { useRouter } from 'next/router';
import PrivateUserLogo from '@styles/icons/private-user-logo.webp';
import * as Styled from './user-image.styled';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IUserImageComponentProps {
  imageUrl?: string;
  profileId?: string;
  width?: number;
  height?: number;
  border?: string;
  onClick?: () => void;
  borderRadius?: string;
  className?: string;
  isImageHidden?: boolean;
  isReplyHidden?: boolean;
  isGrey?: boolean;
  isFaded?: boolean;
  isTreanding?: boolean;
}

export const UserImageComponent = ({
  imageUrl,
  profileId,
  onClick,
  borderRadius,
  className,
  isImageHidden,
  isReplyHidden,
  isFaded,
  width,
  height,
  border,
  isGrey,
  isTreanding
}: IUserImageComponentProps) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useRouter();

  const handleImageError = () => {
    setImageError(true);
    setIsLoading(false);
  };

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  const clickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isReplyHidden || (!onClick && !profileId) || isTreanding) return;
    event.stopPropagation();
    if (onClick) {
      onClick();
      return;
    }

    if (profileId) {
      push(`${ROUTER_KEYS.PROFILE}?id=${profileId}`);
    }
  };
  return (
    <Styled.ImageWrapper onClick={clickHandler} className={className} isGrey={isGrey} isFaded={isFaded}>
      {!imageError && imageUrl && !isImageHidden && !isReplyHidden ? (
        <Styled.UserImage
          src={imageUrl}
          alt="User"
          onLoad={handleOnLoad}
          onError={handleImageError}
          borderRadius={borderRadius}
          isLoading={isLoading}
          width={width}
          height={height}
          border={border}
        />
      ) : isImageHidden || isReplyHidden ? (
        <Styled.HiddenImage borderRadius={borderRadius} border={border}>
          <Styled.LockImage src={PrivateUserLogo} alt="private" width={width} height={height} />
        </Styled.HiddenImage>
      ) : (
        <Styled.EmptyPlaceholder borderRadius={borderRadius} border={border} />
      )}
    </Styled.ImageWrapper>
  );
};
