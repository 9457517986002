import { CreateIdeaDTO, IdeaDTO, LatestIdeaDTO, UpdateIdeaDTO } from '@/common/types';
import { IPost } from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class IdeasService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getIdeas(profileId: string) {
    return this.get<{ ideas: IdeaDTO[]; isHidden: boolean; isIdeasDoesntExist?: boolean; outputTextForHidden?: string }>(
      `ideas/${profileId}`
    );
  }

  async getLatestIdeas() {
    return this.get<LatestIdeaDTO[]>('ideas/latest');
  }

  async getIdeaByPostId(postId: string) {
    return this.get<IdeaDTO>(`ideas/by-post-id/${postId}`);
  }

  async createIdea(payload: CreateIdeaDTO) {
    return this.post<{ post: IPost; profileId: string }, CreateIdeaDTO>('ideas', payload);
  }

  async updateIdea(payload: UpdateIdeaDTO) {
    return this.put<{ post: IPost; profileId: string }, UpdateIdeaDTO>('ideas', payload);
  }

  async deleteIdea(ideaId: string) {
    return this.delete<{ deletedPosts: IPost[]; profileId: string }>(`ideas/${ideaId}`);
  }
}

export const ideasService = new IdeasService();
