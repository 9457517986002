export enum JumpTo {
  ABOUT = 'About',
  ROLES = 'Roles',
  SECURITY_COVERAGE = 'Coverage',
  KEY_DEBATES = 'Debates',
  MEMOS = 'Ideas'
}

export type SectionStatusType = {
  [key in JumpTo]?: { isLoading: boolean; isEmpty: boolean };
};
