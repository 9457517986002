import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { ModalComponent } from '@/common/components/modal';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const ReplyModalWithBackButtonWrapper = styled(ModalComponent)`
  position: relative;
  min-width: 600px;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: 95vh; */
  min-height: calc(100vh - 30px);
  padding: 35px 30px 30px 30px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
`;

export const CloseButton = styled.button`
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const CloseImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ModalTitle = styled.div`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.l};
  margin-bottom: 30px;
`;

export const TreadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsStyles = css`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 12px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 8px 10px;
  background-color: ${COLORS.visibilitySettingsButton};
`;

export const ButtonsInModalWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  display: flex;
  gap: 10px;
`;

export const PreviousButton = styled.button`
  ${ButtonsStyles}
  color: #515151;
  border-radius: 10px;
  background: #e3e3e3;
  display: flex;
  gap: 5px;
`;

export const PrevieusButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const DeleteCoverageButton = styled.button<{ isConfirm?: boolean }>`
  ${ButtonsStyles}
  color: #000;

  white-space: nowrap;
  padding: 7px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #e3e3e3;
  gap: 9.5px;
  min-height: 28px;
  max-height: 28px;

  ${({ isConfirm }) =>
    isConfirm &&
    `
border: 1px solid rgba(241, 0, 0, 0.20);
background: rgba(241, 0, 0, 0.03);
  color: #F10000;
  `}
`;

export const DeleteCoverageButtonImage = styled(Image)`
  width: 14px;
  height: 14px;
`;
