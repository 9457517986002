import React from 'react';
import * as Styled from '../information-block-defalut.styled';

export const ConnectRealToAnonInformationBlockComponent = () => (
  <Styled.ConnectoinInformationBlockWrapper>
    <Styled.InformationBlockTopic>
      <h3>One-Time Verification</h3>
      <p>We must perform a one-time verification to link your real account with your anonymous account</p>
      <p>
        We designed Mode Anon so that nobody, <strong>not even us</strong>, can ever identify the owner of any anonymous account
      </p>
      <p>
        The connection between your real account and anonymous account is end-to-end encrypted by <br />
        the password you created earlier
      </p>
      <p>We do not store your password anywhere or at any time, but you can recover it via your email address</p>
      <p>
        Even during this current process of linking your real account and anonymous account, we do not establish any identifiable links
        between the accounts, ensuring complete anonymity at all times
      </p>
      <p>We never log your IP address in either Mode Real or Mode Anon to keep each account clearly isolated</p>
      <p>
        After this verification, you can easily switch <br />
        between accounts via the toolbar
      </p>
    </Styled.InformationBlockTopic>
  </Styled.ConnectoinInformationBlockWrapper>
);
