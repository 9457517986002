import styled from 'styled-components';
import { WEIGHTS } from '@/theme/fonts.const';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  color: white;
  font-family: Roboto Flex;
  font-size: 12px;
  font-style: normal;
  font-weight: ${WEIGHTS.semiBold};
  line-height: 12px;
  white-space: pre-wrap;
`;

export const Point = styled.div<{ isActive: boolean; isDotted: boolean; isFirst: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#80d3bf' : '#EFEFEF')};
  padding: 4px 6.5px;
  border-radius: 7px;
  height: ${({ isDotted, isFirst }) => (isDotted && isFirst ? '17px' : '20px')};
  width: ${({ isDotted, isFirst }) => (isDotted && isFirst ? '18px' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div<{ height: string; isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#80d3bf' : '#EFEFEF')};
  height: ${({ height }) => height};
  width: 2px;
`;

export const DottedLine = styled.div<{ width: number; height: number; isActive: boolean }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ isActive }) =>
    `repeating-linear-gradient(
      to bottom,
      ${isActive ? '#80d3bf' : '#EFEFEF'} 0px,
      ${isActive ? '#80d3bf' : '#EFEFEF'} 2px,
      transparent 2px,
      transparent 6px
    )`};
  border-radius: 50% / 100%;
  background-repeat: repeat-y;
  background-position: center;
`;

export const OvalDot = styled.div`
  width: 2px;
  height: 2.5px;
  background-color: #dbdbdb;
  border-radius: 50% / 100%;
  display: inline-block;
`;
