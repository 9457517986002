export const ERROR_MESSAGES = {
  INVALID_ENTRY: 'Invalid entry',
  WRONG_VERIFY_CODE: 'The code you entered is incorrect: try again or click here to submit another username',
  WRONG_VERIFY_CODE_EMAIL: 'The code you entered is incorrect: try again or click here to submit another email address',

  KEYWORD_DONT_MATCH: 'Passwords do not match',
  NOT_ALL_FIELDS: 'No all fiels filled',
  WRONG_EMAIL: 'This email address is already in use',
  ANON_WRONG_EMAIL: 'This email address is not registered'
};
