import Image from 'next/image';
import React, { useEffect } from 'react';
import * as Styled from './confirm-form-actions.styled';
import Checkmark from '@/styles/icons/ok-checkmark.webp';
import BackIcon from '@/styles/icons/back-icon.webp';
import PressEnterArrow from '@/styles/icons/press-enter-arrow.webp';
import PressEnterArrowDisables from '@/styles/icons/back-icon-disable.webp';
import { ActionType } from '@/common/types/anon.types';

interface IConfirmFormActions {
  isActive: boolean;
  activeCallback: () => void;
  onBack?: () => void;
  actionType?: ActionType;
}

export const ConfirmFormActions = ({ isActive, activeCallback, onBack, actionType }: IConfirmFormActions) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isActive) {
        if (event.key === 'Enter') {
          if (actionType === ActionType.POST && event.ctrlKey) {
            event.preventDefault();
            activeCallback();
          } else if (actionType === ActionType.CONFIRM) {
            event.preventDefault();
            activeCallback();
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive, activeCallback, actionType]);

  const buttonText = actionType === ActionType.POST ? 'Post' : 'Confirm';
  const focusChangeText = actionType === ActionType.POST ? 'press ctrl + enter' : 'press enter';

  return (
    <Styled.AnonymityFormActions>
      <Styled.AnonymityConfirmActions>
        <Styled.AnonymityFormConfirmBtn disabled={!isActive} onClick={activeCallback} actionType={actionType!}>
          {buttonText}
          <Image src={Checkmark} width={14} height={14} alt="checkmark" />
        </Styled.AnonymityFormConfirmBtn>

        <Styled.AnonymityPressEnter isActive={isActive}>
          {focusChangeText}
          <Image
            src={isActive ? PressEnterArrow : PressEnterArrowDisables}
            width={14}
            height={14}
            alt={isActive ? 'arrow' : 'arrow-disabled'}
          />
        </Styled.AnonymityPressEnter>
      </Styled.AnonymityConfirmActions>

      {onBack && (
        <Styled.AnonymityBackActions onClick={onBack}>
          <p>back</p>
          <Image src={BackIcon} width={14} height={14} alt="back-icon" />
        </Styled.AnonymityBackActions>
      )}
    </Styled.AnonymityFormActions>
  );
};
