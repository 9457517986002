import styled, { AnyStyledComponent, css, keyframes } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';

const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export const SidebarContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 12;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background: #f9f9f9;
`;

export const SidebarContainer = styled.div`
  width: 80px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: 75px;
`;

export const RouteText = styled.div<{ isActive: boolean; isAnon: boolean }>`
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ isActive }) => (isActive ? 'black' : '#A0A0A0')};
`;

export const NotificAndMessagesCountContainer = styled.div`
  display: flex;
  padding-top: 6px;
  align-items: center;
  gap: 4px;
`;

export const NotificAndMessagesCount = styled.div`
  color: #d71e1e;
  font-family: 'Roboto Flex';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  padding-top: 1px;
`;

export const SidebarBottomContainer = styled.div`
  width: 169px;
  margin-left: auto;
  padding-left: 30px;
  height: fit-content;
  margin-right: 16px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: start;
  position: relative;
`;

export const SidebarDot = styled.div`
  display: flex;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba(207, 207, 207, 0.4);
`;

const ContainerStyle = css`
  width: 100%;
  height: 100%;
`;

export const RoutesContainer = styled.div`
  ${ContainerStyle}
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
`;

const ButtonStyle = css`
  border: none;
  text-align: center;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.l};
  background: transparent;
  cursor: pointer;
`;

export const LabelItemOnHover = styled.div<{ isAnon?: boolean; isNewPost?: boolean }>`
  position: absolute;
  top: 5px;
  left: 40px;
  border-radius: 10px;
  background: rgba(245, 245, 245, 0.8);
  backdrop-filter: blur(5px);
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;
  padding: 10px;
  color: #616161;
  ${({ isNewPost }) => isNewPost && 'top: 28px;color: #000;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.xxl};
  letter-spacing: 0.64px;

  ${({ isAnon }) =>
    isAnon &&
    css`
      background: rgba(49, 49, 49, 0.8);
      color: #e8e8e8;
    `}
`;

export const RouteButton = styled.button<{ isActive?: boolean; marginBottom?: string }>`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  padding: 15px 0px;
  border: none;
  background-color: inherit;
  position: relative;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
`;

export const NewItemCircle = styled.div`
  width: 6px;
  height: 6px;
  background: #d71e1e;
  position: absolute;
  top: 9px;
  left: -5px;
  border-radius: 50%;
`;

export const RouterDivider = styled.div<{ isAnon?: boolean }>`
  background: inherit;
`;

export const ModeLabel = styled(RouterDivider)<{ isCurrent?: boolean }>`
  ${({ isCurrent, isAnon }) => isAnon && isCurrent && 'color: #9D21FF;'}
  ${({ isCurrent, isAnon }) => isAnon && !isCurrent && 'color: #A0A0A0;'}
  ${({ isCurrent, isAnon }) => !isAnon && isCurrent && 'color: #008FCC;'}
  ${({ isCurrent, isAnon }) => !isAnon && !isCurrent && 'color: #A0A0A0;'}
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

export const LastRouterDivider = styled.div<{ isAnon: boolean }>`
  width: 60px;
  height: 10px;
  background: rgba(244, 244, 244, 0.75);
  ${({ isAnon }) => isAnon && 'background: rgba(49, 49, 49, 0.50);'}
`;

export const NewPostRouteButton = styled.button<{ isAnon: boolean }>`
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin: 18px 0px 30px 0px;
  padding: 10px 20px 10px 0;
  background: none;
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
`;

export const NewPostText = styled.div<{ isAnon: boolean }>`
  color: ${({ isAnon }) => (isAnon ? '#9D21FF' : '#0082BA')};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-top: 2px;
`;

export const Icon = styled(Image as AnyStyledComponent)<{ isActive?: boolean; isAnon: boolean }>`
  width: 20px;
  height: 20px;
`;

export const AnonActiveIcon = styled(Image as AnyStyledComponent)<{ isActive?: boolean; isAnon: boolean }>`
  width: 20px;
  height: 20px;
  margin-top: -15px;
  margin-bottom: -15px;
`;

export const Hidden = styled(Image)`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const NewPostIcon = styled(Image as AnyStyledComponent)`
  width: 20px;
  height: 20px;
`;

export const NewPostIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 41, 187, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewMusingButton = styled.div`
  ${ContainerStyle}
  background: ${COLORS.sidebarNewMusingBackground};
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutButton = styled.div`
  background: ${COLORS.sidebarBackground};
  width: 38px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px -1px #dddddd;
  backdrop-filter: blur(2.5px);
  border-radius: 0px 7px 7px 0px;
`;

export const SlidingMessage = styled.div<{ top: number; left: number }>`
  border-radius: 50px;
  border: 1px solid #e4e4e4;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 6px 6px 30px 0px #ddd;
  backdrop-filter: blur(2.5px);
  padding: 12px 20px;
  display: flex;
  gap: 8px;
  position: absolute;
  align-items: flex-end;
  ${({ top, left }) => `top: ${top + 50}px; left: ${left}px;`}
  white-space: nowrap;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  outline: none;
`;

export const SlidingMessageComingSoon = styled.span`
  color: #007979;
  display: flex;
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  @-moz-document url-prefix() {
    line-height: ${FONTS.SIZES.m} !important;
  }
  align-items: flex-end;
  min-height: 0;
`;

export const SlidingMessageText = styled.span`
  color: #000;
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  align-items: flex-end;
  min-height: 0;
`;

export const ModeWrapper = styled.div<{ isAnon: boolean }>`
  height: fit-content;
  width: 108px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RealButton = styled.button`
  ${ButtonStyle}
  font-weight: ${FONTS.WEIGHTS.light};
  color: #0075a7;
  text-shadow: 2px 2px 4px rgba(0, 127, 167, 0.25);
  width: 84px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IModeSwapProps {
  isAnon?: boolean;
}

export const AnonButton = styled.button<IModeSwapProps>`
  ${ButtonStyle}
  font-weight: ${FONTS.WEIGHTS.light};
  color: #7900a4;
  text-shadow: 2px 2px 4px rgba(121, 0, 164, 0.2);
  width: 79px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnActiveButton = styled.button`
  ${ButtonStyle}
  position: relative;
  font-weight: ${FONTS.WEIGHTS.light};
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 13px;
  ${LabelItemOnHover} {
    top: 0px;
    left: 65px;
  }
  &:hover {
    ${LabelItemOnHover} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;

export const ActivateLabelWrapper = styled.div<{ isFlashing: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  margin-left: 5px;

  & > * {
    color: #a0a0a0;
    text-align: center;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.s};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.m};
    ${({ isFlashing }) =>
      isFlashing
        ? css`
            animation: ${flash} 2s infinite;
          `
        : ''}
  }
`;

export const ActivateCircleWithLineIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  &::before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #a0a0a0;
    position: absolute;
    top: -20px;
  }
`;

export const ModeImg = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const ModeImgWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

export const SidebarLogoStab = styled.div`
  width: 111px;
  height: 50px;
  background: #ededed;
`;
