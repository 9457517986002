export const AlgoliaIndexes = {
  POSTS: 'posts',
  USERS: 'users',
  NEWS: 'news',
  SECTORS: 'sectors',
  SUB_SECTORS: 'sub-sectors',
  REGIONS: 'regions',
  SECURITY: 'security',
  KEY_DEBATES: 'key-debates',
  POLLS: 'polls',
  EMPLOYERS: 'employers',
  PRIVATES: 'privates'
};

export const keyValuePairs = [
  { key: AlgoliaIndexes.POSTS, value: 'Posts + Replies', lessThanTwoValue: 'Posts + Replies' },
  { key: AlgoliaIndexes.USERS, value: 'Members', lessThanTwoValue: 'Member' },
  { key: AlgoliaIndexes.NEWS, value: 'News', lessThanTwoValue: 'News' },
  { key: AlgoliaIndexes.KEY_DEBATES, value: 'Key Debates', lessThanTwoValue: 'Key Debate' },
  { key: AlgoliaIndexes.POLLS, value: 'Polls', lessThanTwoValue: 'Poll' },
  { key: AlgoliaIndexes.SECURITY, value: 'Securities', lessThanTwoValue: 'Security' },
  { key: AlgoliaIndexes.SECTORS, value: 'Sectors', lessThanTwoValue: 'Sector' },
  { key: AlgoliaIndexes.SUB_SECTORS, value: 'Sub-sectors', lessThanTwoValue: 'Sub-sector' },
  { key: AlgoliaIndexes.REGIONS, value: 'Regions', lessThanTwoValue: 'Region' },
  { key: AlgoliaIndexes.EMPLOYERS, value: 'Employers', lessThanTwoValue: 'Employer' },
  { key: AlgoliaIndexes.PRIVATES, value: 'Privates', lessThanTwoValue: 'Privates' }
];
