import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notificationService } from '../services/notification.service';
import {
  ICreateNotificationEvent,
  IGetNotifications,
  INotificationEvent,
  INotificationResponse,
  NotificationClientType,
  NotificationType,
  IDeleteNotificationEvent,
  IGetNotificationsResponse
} from '../types/notification.type';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { PAGINATION_DAYS, PROFILE_LIST_MAX_ITEMS } from '../consts/notifications.const';
import { setCachedConnections } from './use-connection';
import { IAssociationResponse } from '../types/connection.type';

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<IGetNotifications | null>();
  const queryClient = useQueryClient();

  const { data: cachedNewNotifications } = useQuery(QUERY_KEYS.NEW_NOTIFICATIONS, () =>
    queryClient.getQueryData<IGetNotifications>(QUERY_KEYS.NEW_NOTIFICATIONS)
  );

  const handleCreate = async (create: ICreateNotificationEvent) => {
    const newNotifications = queryClient.getQueryData<IGetNotifications>(QUERY_KEYS.NEW_NOTIFICATIONS);
    if (!newNotifications) return;

    const handleCreateNotification = async (
      createEvent: ICreateNotificationEvent,
      isForResponse: boolean,
      type: NotificationClientType
    ) => {
      const notification = (isForResponse ? newNotifications.forResponse : newNotifications.adoration).find(
        (value) => (value.post?.id === createEvent.originalPostId || value.post?.id === createEvent.postId) && value.type === type
      );
      if (notification) {
        // PUSH EVENT TO EXISTING
        notification.id.push(createEvent.id);
        notification.date = createEvent.createdAt;
        const findNotificationProfile = notification.countProfiles?.find((it) => it.id === createEvent.sourceProfile.id);
        if (!findNotificationProfile) {
          notification.count = (notification.count || 0) + 1;
          notification.countProfiles?.push(createEvent.sourceProfile);
        }

        const newArray = [
          notification,
          ...(isForResponse ? newNotifications.forResponse : newNotifications.adoration).filter(
            (item) => item.post?.id !== notification.post?.id || item.type !== type
          )
        ];

        const newData = {
          forResponse: isForResponse ? newArray : newNotifications.forResponse,
          adoration: !isForResponse ? newArray : newNotifications.adoration
        };
        await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, newData);
        // setNotifications(newData);
      } else {
        // ADD NEW NOTIFICATION
        const newNotification: INotificationResponse = {
          id: [createEvent.id],
          type,
          date: createEvent.createdAt,
          hasMention: createEvent.hasMention,
          post:
            type === NotificationClientType.MENTION || type === NotificationClientType.REPLY || type === NotificationClientType.QUOTE_POST
              ? createEvent.post
              : createEvent.originalPost,
          count: 1,
          countProfiles: [createEvent.sourceProfile],
          userProfile: createEvent.userProfile
        };

        const newArray = [newNotification, ...(isForResponse ? newNotifications.forResponse : newNotifications.adoration)];
        await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, {
          forResponse: isForResponse ? newArray : newNotifications.forResponse,
          adoration: !isForResponse ? newArray : newNotifications.adoration
        });
      }

      queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
    };

    const handleCreateNotificationRequest = async (createEvent: ICreateNotificationEvent, type: NotificationClientType) => {
      const newNotification: INotificationResponse = {
        id: [createEvent.id],
        type,
        date: createEvent.createdAt,
        connection: createEvent.connection,
        connectionProfile: createEvent.connectionProfile,
        answerConnection: createEvent.answerConnection,
        count: 1
      };

      const excludeProfileFromConnectionNotifications = (
        notificationsList: INotificationResponse[],
        profileId: string
      ): INotificationResponse[] =>
        notificationsList.filter(
          (value) =>
            !(
              [
                NotificationClientType.CONNECTION_REQUEST,
                NotificationClientType.FOLLOW_REQUEST,
                NotificationClientType.NEW_FOLLOWER
              ].includes(value.type) && value.connectionProfile?.id === profileId
            )
        );

      await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, {
        forResponse: [
          newNotification,
          ...excludeProfileFromConnectionNotifications(newNotifications.forResponse, newNotification.connectionProfile?.id || '')
        ],
        adoration: newNotifications.adoration
      });

      const oldNotifications = queryClient.getQueryData<IGetNotificationsResponse>(QUERY_KEYS.OLD_NOTIFICATIONS);
      queryClient.setQueryData(QUERY_KEYS.OLD_NOTIFICATIONS, {
        ...oldNotifications,
        forResponse: excludeProfileFromConnectionNotifications(
          oldNotifications?.forResponse || [],
          newNotification.connectionProfile?.id || ''
        )
      });

      setCachedConnections(queryClient, {
        connection: createEvent.connection as IAssociationResponse,
        targetConnection: createEvent.answerConnection as IAssociationResponse
      });

      queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
    };

    // REACTS TO POST
    if ([NotificationType.CLAP, NotificationType.LIKE].includes(create.type)) {
      const notification = newNotifications.forResponse.find(
        (value) => value.post?.id === create.post?.id && value.type === NotificationClientType.POST_REACTS
      );
      if (notification) {
        // PUSH EVENT TO EXISTING
        notification.id.push(create.id);
        notification.date = create.createdAt;
        if (create.type === NotificationType.LIKE && !notification.likeProfiles?.includes(create.sourceProfile)) {
          notification.likes = (notification.likes || 0) + 1;
          if ((notification.likeProfiles?.length || 0) < PROFILE_LIST_MAX_ITEMS) {
            notification.likeProfiles?.push(create.sourceProfile);
          }
        } else if (create.type === NotificationType.CLAP && !notification.clapProfiles?.includes(create.sourceProfile)) {
          notification.claps = (notification.claps || 0) + 1;
          notification.clapProfiles?.push(create.sourceProfile);
        }
        const newData = {
          adoration: newNotifications.adoration,
          forResponse: [
            notification,
            ...newNotifications.forResponse.filter(
              (item) => item.post?.id !== notification.post?.id || item.type !== NotificationClientType.POST_REACTS
            )
          ]
        };
        await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, newData);
        queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
        // setNotifications(newData);
      } else {
        // ADD NEW REACTS NOTIFICATION
        const newNotification: INotificationResponse = {
          id: [create.id],
          type: NotificationClientType.POST_REACTS,
          date: create.createdAt,
          post: create.post,
          likes: create.type === NotificationType.LIKE ? 1 : 0,
          likeProfiles: create.type === NotificationType.LIKE ? [create.sourceProfile] : [],
          claps: create.type === NotificationType.CLAP ? 1 : 0,
          clapProfiles: create.type === NotificationType.CLAP ? [create.sourceProfile] : []
          // praises: create.type === NotificationType.PRAISE ? 1 : 0,
          // praiseProfiles: create.type === NotificationType.PRAISE ? [create.sourceProfile] : []
        };
        await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, {
          adoration: newNotifications.adoration,
          forResponse: [newNotification, ...newNotifications.forResponse]
        });
        queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
      }
    } else if (create.type === NotificationType.REPOST) {
      handleCreateNotification(create, true, NotificationClientType.REPOST);
    } else if (create.type === NotificationType.REPLY) {
      handleCreateNotification(create, true, NotificationClientType.REPLY);
    } else if (create.type === NotificationType.QUOTE_POST) {
      handleCreateNotification(create, true, NotificationClientType.QUOTE_POST);
    } else if (create.type === NotificationType.MENTION) {
      handleCreateNotification(create, true, NotificationClientType.MENTION);
    } else if (create.type === NotificationType.CONNECTION_REQUEST) {
      handleCreateNotificationRequest(create, NotificationClientType.CONNECTION_REQUEST);
    } else if (create.type === NotificationType.NEW_FOLLOWER) {
      handleCreateNotificationRequest(create, NotificationClientType.NEW_FOLLOWER);
    } else if (create.type === NotificationType.FOLLOW_REQUEST) {
      handleCreateNotificationRequest(create, NotificationClientType.FOLLOW_REQUEST);
    }
  };

  const handleDelete = async (deleteEvent: IDeleteNotificationEvent) => {
    const notificationsData = !deleteEvent.isRead
      ? queryClient.getQueryData<IGetNotifications>(QUERY_KEYS.NEW_NOTIFICATIONS)
      : queryClient.getQueryData<IGetNotifications>(QUERY_KEYS.OLD_NOTIFICATIONS);
    if (!notificationsData) return;
    if ([NotificationType.CLAP, NotificationType.LIKE, NotificationType.PRAISE].includes(deleteEvent.type)) {
      const notification = notificationsData.forResponse.find(
        (item) => item.type === NotificationClientType.POST_REACTS && item.id.findIndex((id) => id === deleteEvent.id) !== -1
      );
      if (!notification) return;
      if (deleteEvent.type === NotificationType.LIKE && !!notification?.likes && notification.likeProfiles) {
        notification.likes -= 1;
        notification.likeProfiles = notification.likeProfiles.filter((item) => item.id !== deleteEvent.sourceProfileId);
        if (!notification.likeProfiles.length) {
          notification.likes = 0;
        }
      }

      if (deleteEvent.type === NotificationType.CLAP && !!notification?.claps && notification.clapProfiles) {
        notification.claps -= 1;
        notification.clapProfiles = notification.clapProfiles.filter((item) => item.id !== deleteEvent.sourceProfileId);
        if (!notification.clapProfiles.length) {
          notification.claps = 0;
        }
      }

      const index = notificationsData.forResponse.findIndex((item) => item.post?.id === notification.post?.id);
      if (index === -1) return;

      const newData = {
        adoration: notificationsData.adoration,
        forResponse: [
          ...(index ? notificationsData.forResponse.slice(0, index) : []),
          ...((notification?.likes || 0) + (notification?.claps || 0) > 0 ? [notification] : []),
          ...notificationsData.forResponse.slice(index + 1)
        ]
      };
      await queryClient.setQueryData(!deleteEvent.isRead ? QUERY_KEYS.NEW_NOTIFICATIONS : QUERY_KEYS.OLD_NOTIFICATIONS, newData);
    } else if (
      [NotificationType.CONNECTION_REQUEST, NotificationType.FOLLOW_REQUEST, NotificationType.NEW_FOLLOWER].includes(deleteEvent.type)
    ) {
      const notification = notificationsData.forResponse.find((item) => {
        const [id] = item.id;
        return id === deleteEvent.id;
      });
      if (!notification) return;
      const newData = {
        forResponse: notificationsData.forResponse.filter((item) => {
          const [currentId] = item.id;
          const [deleteId] = notification.id;
          return currentId !== deleteId;
        }),
        adoration: notificationsData.adoration
      };
      await queryClient.setQueriesData(!deleteEvent.isRead ? QUERY_KEYS.NEW_NOTIFICATIONS : QUERY_KEYS.OLD_NOTIFICATIONS, newData);
      if (!deleteEvent.isRead) {
        setNotifications(newData);
      }
    } else if ([NotificationType.REPOST].includes(deleteEvent.type)) {
      const notification = notificationsData.forResponse.find(
        (item) => item.type === NotificationClientType.REPOST && item.id.findIndex((id) => id === deleteEvent.id) !== -1
      );
      if (!notification) return;
      if (deleteEvent.type === NotificationType.REPOST && !!notification?.countProfiles) {
        notification.countProfiles = notification.countProfiles.filter((item) => item.id !== deleteEvent.sourceProfileId);
      }

      const index = notificationsData.forResponse.findIndex((item) => item.post?.id === notification.post?.id);
      if (index === -1) return;

      const newData = {
        adoration: notificationsData.adoration,
        forResponse: [
          ...notificationsData.forResponse.slice(0, index),
          ...((notification?.countProfiles?.length || 0) > 0 ? [notification] : []),
          ...notificationsData.forResponse.slice(index + 1)
        ]
      };
      await queryClient.setQueryData(!deleteEvent.isRead ? QUERY_KEYS.NEW_NOTIFICATIONS : QUERY_KEYS.OLD_NOTIFICATIONS, newData);
    } else if ([NotificationType.QUOTE_POST, NotificationType.REPLY, NotificationType.MENTION].includes(deleteEvent.type)) {
      const notification = notificationsData.forResponse.find(
        (item) =>
          (item.type === NotificationClientType.QUOTE_POST ||
            item.type === NotificationClientType.REPLY ||
            item.type === NotificationClientType.MENTION) &&
          item.id.findIndex((id) => id === deleteEvent.id) !== -1
      );
      if (!notification) return;

      const index = notificationsData.forResponse.findIndex((item) => item.id === notification.id);
      if (index === -1) return;
      const newData = {
        forResponse: [
          ...(index === 0 ? [] : notificationsData.forResponse.slice(0, index)),
          ...notificationsData.forResponse.slice(index + 1)
        ],
        adoration: notificationsData.adoration
      };

      await queryClient.setQueryData(!deleteEvent.isRead ? QUERY_KEYS.NEW_NOTIFICATIONS : QUERY_KEYS.OLD_NOTIFICATIONS, newData);
    }
    if (!deleteEvent.isRead) {
      queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
    }
  };

  const handleNotificationMessage = (event: { data: string }) => {
    const { create, delete: deleteEvent }: INotificationEvent = JSON.parse(event.data);
    if (create) {
      handleCreate(create);
    }

    if (deleteEvent) {
      handleDelete(deleteEvent);
    }
  };

  let eventSource: EventSource;

  const { isLoading, refetch } = useQuery('new_notifications_temp', async () => {
    const responseData = await notificationService.notifications();
    await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, responseData);
    queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);

    if (responseData) {
      responseData.forResponse.forEach((item) => {
        if (
          [NotificationClientType.CONNECTION_REQUEST, NotificationClientType.FOLLOW_REQUEST, NotificationClientType.NEW_FOLLOWER].includes(
            item.type
          )
        ) {
          setCachedConnections(queryClient, {
            connection: item.connection as IAssociationResponse,
            targetConnection: item.answerConnection as IAssociationResponse
          });
        }
      });
    }
    eventSource = notificationService.subscribe();
    eventSource.addEventListener('message', handleNotificationMessage);
    return responseData;
  });

  const refetchNotifications = () => {
    eventSource?.close();
    refetch();
  };

  useEffect(() => {
    setNotifications(cachedNewNotifications ? { ...cachedNewNotifications } : null);
  }, [cachedNewNotifications]);

  return { notifications, isLoading, refetchNotifications };
};

export const useOldNotifications = (start?: string, end?: string) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.OLD_NOTIFICATIONS], () => notificationService.oldNotifications(start && end ? { start, end } : undefined), {
    onSuccess: (data) => {
      if (data) {
        data.forResponse.forEach((item) => {
          if (
            [
              NotificationClientType.CONNECTION_REQUEST,
              NotificationClientType.FOLLOW_REQUEST,
              NotificationClientType.NEW_FOLLOWER
            ].includes(item.type)
          ) {
            setCachedConnections(queryClient, {
              connection: item.connection as IAssociationResponse,
              targetConnection: item.answerConnection as IAssociationResponse
            });
          }
        });
      }
    }
  });
};

export const useMutateOldNotifications = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { start: string; end: string }) => notificationService.oldNotifications(data),
    onSuccess: (data) => {
      if (onSuccess) onSuccess();
      if (data) {
        data.forResponse.forEach((item) => {
          if (
            [
              NotificationClientType.CONNECTION_REQUEST,
              NotificationClientType.FOLLOW_REQUEST,
              NotificationClientType.NEW_FOLLOWER
            ].includes(item.type)
          ) {
            setCachedConnections(queryClient, {
              connection: item.connection as IAssociationResponse,
              targetConnection: item.answerConnection as IAssociationResponse
            });
          }
        });
      }
    }
  });
};

export const useMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => notificationService.markAllAsRead(),
    onSuccess: async () => {
      await queryClient.setQueryData(QUERY_KEYS.NEW_NOTIFICATIONS, {
        forResponse: [],
        adoration: []
      });
      queryClient.invalidateQueries([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar']);
    }
  });
};

export const usePaginatedOldNotifications = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [dates, setDates] = useState<{ start: string; end: string }>();
  const [hasNext, setHasNext] = useState(true);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [nextDate, setNextDate] = useState<string>();

  const [oldNotifications, setOldNotifications] = useState<IGetNotifications | null>(null);
  const { data: oldNotificationsData, mutate: fetchNotification, isLoading } = useMutateOldNotifications(() => setIsFirstLoad(false));

  useEffect(() => {
    if (dates) return;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - PAGINATION_DAYS);
    const endDate = new Date();
    setDates({ start: startDate.toISOString(), end: endDate.toISOString() });
  }, []);

  useEffect(() => {
    if (!dates) return;
    fetchNotification(dates);
  }, [dates]);

  const nextNotifications = async () => {
    if (!dates || isLoading || !hasNext || isNextLoading || isFirstLoad) return;
    await setIsNextLoading(true);
    if (nextDate) {
      const startDate = new Date(nextDate);
      startDate.setDate(startDate.getDate() - PAGINATION_DAYS);
      await setDates({ start: startDate.toISOString(), end: nextDate });
    } else {
      const startDate = new Date(dates.start);
      startDate.setDate(startDate.getDate() - PAGINATION_DAYS);
      await setDates({ start: startDate.toISOString(), end: dates.start });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsNextLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    setOldNotifications((prev) => ({
      forResponse: [...(prev?.forResponse || []), ...(oldNotificationsData?.forResponse || [])],
      adoration: []
    }));

    if (oldNotificationsData?.nextDate === null) {
      setHasNext(false);
    }

    setNextDate(oldNotificationsData?.nextDate || '');
  }, [oldNotificationsData]);

  const reset = () => {
    setIsFirstLoad(true);
    setDates(undefined);
    setNextDate(undefined);
    setIsNextLoading(false);
    setHasNext(true);
  };

  return { oldNotifications, nextNotifications, reset, isLoading: isLoading && !isNextLoading, isNextLoading };
};

export const useNotificationsOnHome = () => {
  const queryClient = useQueryClient();
  return useQuery([QUERY_KEYS.NEW_NOTIFICATIONS, 'bar'], () => {
    const data = queryClient.getQueryData<IGetNotifications>(QUERY_KEYS.NEW_NOTIFICATIONS);
    return [...(data?.adoration ?? []), ...(data?.forResponse ?? [])];
  });
};
