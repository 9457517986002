import styled from 'styled-components';
import { WEIGHTS } from '@/theme/fonts.const';

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputField = styled.input`
  cursor: pointer;
  color: #000;
 font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 505px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #fbfbfb;

  display: flex;
  flex-direction: column;
 
  padding: 10px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    letter-spacing: 0.1px;
    font-weight: ${WEIGHTS.light};
    color: #969696;;
  }
`;

export const FileInput = styled.input`
  display: none;
`;
