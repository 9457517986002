import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;

  font-family: ${FONTS.FAMILIES.robotoFlex};
  text-align: center;
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
  width: 100%;
`;

export const TextWrapper = styled.div`
  padding: 3px 7px;
  border-radius: 5px;
  background-color: ${COLORS.backgoundColorInStockEntryModal};
  max-width: 100%;

  display: flex;
  gap: 6px;

  color: ${COLORS.black};
  display: flex;
  align-items: center;

  & .highlighted {
    font-weight: ${FONTS.WEIGHTS.medium};
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TickerWrapper = styled.div`
  font-size: ${FONTS.SIZES.s};
  color: ${COLORS.sectorsInPostTile};
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: -1px;
`;
