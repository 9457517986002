import { IPost, IStatistic } from './post.type';

export interface IBookmark {
  postId?: string;
  messageId?: number;
  channelUrl?: string;
}

export interface IPostBookmark {
  postId: string;
}

export interface IPostSetResult {
  post: IPost;
  data: IStatistic;
  profileId: string;
}

export interface IOptions {
  page: number;
  limit: number;
}

export enum EBookmarkType {
  MESSAGE = 'message',
  POST = 'post'
}

export interface IPagedReturn {
  bookmarks: (TMessageResponse | IMessagePostResponse)[];
  maxPage: number;
}

export interface IMessagePostResponse extends IPost {
  type: EBookmarkType.POST;
}

export type TMessageResponse = {
  messageId: number;
  message: string;
  reactions: TMessageReaction[];
  file: TMessageFile;
  sender: TMessageSender;
  channelUrl: string;
  displayUserInfo?: BasicInfo;
  interlocutor?: BasicInfo;
  type: EBookmarkType.MESSAGE;
};

type TMessageSender = {
  userId: string;
  profileUrl: string;
  nickname: string;
  role: string;
  isActive: boolean;
};

type BasicInfo = {
  role: string;
  vertical: string;
  isImageHidden: boolean;
  fullName: string;
  id: string;
  imageUrl: string;
  userId: string;
};

type TMessageFile = {
  name: string;
  url: string;
  type: string;
  data: string;
  size: number;
};

type TMessageReaction = {
  key: string;
  user_ids: string[];
  updated_at: number;
};
