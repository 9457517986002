import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as Styled from './tag-list.styled';
import { Chip } from '@/common/components/chip';
import { OthersText } from '../../../post-insight/post-insight.styled';

interface IProps {
  listTitle?: string;
  feedId?: string;
  tagsList: string[];
  isOpen?: boolean;
  toggleShowAll?: () => void;
}

export const TagListComponent = ({ listTitle, tagsList, feedId, isOpen, toggleShowAll }: IProps): ReactElement => {
  const listBlockRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<string[]>(tagsList);
  const [remainingWidth, setRemainingWidth] = useState<number>(0);

  useEffect(() => {
    if (listBlockRef.current) {
      const containerWidth = listBlockRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleTags: string[] = [];

      const chips = listBlockRef.current.children;
      for (let i = 0; i < chips.length; i += 1) {
        const chip = chips[i] as HTMLElement;
        const chipWidth = chip.offsetWidth + 5;

        if (totalWidth + chipWidth > containerWidth) {
          break;
        }

        totalWidth += chipWidth;
        newVisibleTags.push(tagsList[i]);
      }

      setVisibleTags(newVisibleTags);
      setRemainingWidth(containerWidth - totalWidth);
    }
  }, [tagsList, isOpen]);


  const showOthersText = visibleTags.length < tagsList.length;
  const othersText = isOpen ? '— show fewer' : `+ ${tagsList.length - visibleTags.length} others`;

  return (
    <Styled.Container>
      {listTitle && <Styled.Title>{listTitle}</Styled.Title>}
      {isOpen ? (
        <>
          {tagsList.map((tag) => (
            <Chip label={tag} color="primary" key={`${feedId}-${tag}`} />
          ))}
          {showOthersText && (
            <OthersText onClick={toggleShowAll} style={{ cursor: 'pointer' }}>
              {othersText}
            </OthersText>
          )}
        </>
      ) : (
        <>
          <Styled.ListBlock ref={listBlockRef}>
            {visibleTags.map((tag) => (
              <Chip label={tag} color="primary" key={`${feedId}-${tag}`} />
            ))}
          </Styled.ListBlock>
          {showOthersText && (
            <OthersText ml={remainingWidth} onClick={toggleShowAll} style={{ cursor: 'pointer' }}>
              {othersText}
            </OthersText>
          )}
        </>
      )}
    </Styled.Container>
  );
};
