import React, { useEffect } from 'react';
import { Slide } from '@mui/material';
import { MODAL_SLIDE_TIMEOUT } from '@/common/consts';
import ModalImage from '@/styles/icons/publish-modal-image.webp';
import ModalImageDisabled from '@/styles/icons/publish-modal-image-disabled.webp';
import * as Styled from './images-modal.styled';

interface IPublishModal {
  open: boolean;
  closeHandler: () => void;
  imgUrls: string[];
  current: number;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
  transitionExited: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PublishModalComponent = ({ open, closeHandler, imgUrls, current, setCurrentImage, transitionExited }: IPublishModal) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && current > 0) {
        setCurrentImage((prev) => prev - 1);
      } else if (event.key === 'ArrowRight' && current < imgUrls.length - 1) {
        setCurrentImage((prev) => prev + 1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [current, imgUrls.length, setCurrentImage]);

  return (
    <Styled.ModalWrapper
      open={open}
      onClose={(e: any) => {
        e?.stopPropagation();
        closeHandler();
      }}
      onTransitionExited={transitionExited}
      componentsProps={{ backdrop: { style: { backgroundColor: 'rgba(238, 238, 238, 0.8)' } } }}
    >
      <Slide direction="up" in={open} timeout={MODAL_SLIDE_TIMEOUT}>
        <Styled.ModalContainer>
          <Styled.LeftActionButton
            left={26}
            isDisabled={current === 0}
            disabled={current === 0}
            onClick={() => setCurrentImage((prev) => prev - 1)}
          >
            {current === 0 && <Styled.LeftImage src={ModalImageDisabled} alt="left-disabled" />}
            {current > 0 && <Styled.LeftImage src={ModalImage} alt="left" />}
          </Styled.LeftActionButton>
          <Styled.ModalTitle>
            {current + 1} of {imgUrls.length}
          </Styled.ModalTitle>
          <Styled.ImageContainer>
            {imgUrls.map((img, index) => (
              <Styled.ImageWrapper key={img} src={img} alt={img} imgDisplay={current === index} />
            ))}
          </Styled.ImageContainer>
          <Styled.RightActionButton
            right={26}
            isDisabled={current === imgUrls.length - 1}
            disabled={current === imgUrls.length - 1}
            onClick={() => setCurrentImage((prev) => prev + 1)}
          >
            {current === imgUrls.length - 1 && <Styled.RightImage src={ModalImageDisabled} alt="right-disabled" />}
            {current < imgUrls.length - 1 && <Styled.RightImage src={ModalImage} alt="right" />}
          </Styled.RightActionButton>
        </Styled.ModalContainer>
      </Slide>
    </Styled.ModalWrapper>
  );
};
