import { useMutation } from 'react-query';

import { profileService } from '@services';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { useUpdateSetupStep } from '@/common/hooks/use-update-setup-step';

export const useLinkedinProfile = () => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();

  return useMutation({
    mutationKey: [QUERY_KEYS.LINKEDIN_PROFILE],
    mutationFn: (profileId: string) => profileService.pullLinkedinProfile(profileId),
    onSuccess: () => {
      localStorage.setItem('linkedInPullTried', 'true');
      localStorage.setItem('linkedInPull', 'true');
      updateSetupStep(2);
    },
    onError: () => {
      localStorage.setItem('linkedInPullTried', 'true');
      localStorage.setItem('linkedInPull', 'false');
      updateSetupStep(2);
    }
  });
};
