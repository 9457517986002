// eslint-disable-next-line import/no-cycle
import { IStatistic } from './post.type';
import { IProfile } from './profile.type';

export enum Question {
  COMPANY = 'COMPANY',
  SECTOR = 'SECTOR',
  QUERY = 'QUERY',
  SUBSECTOR = 'SUBSECTOR',
  FILTER = 'FILTER'
}

export interface IQuestionProfileResponse {
  aligned: ISentiment[];
  variantDisagreed: ISentiment[];
  variantAgreed: ISentiment[];
  awaitingForResult: ISentiment[];
  isHidden: boolean;
  isQuestionDoesntExist?: boolean;
  outputTextForHidden?: string;
}

export interface IQuestion {
  id: string;
  text: string;
  isVisible: boolean;
  type: Question;
  sectorId: string | null;
  companyId: string | null;
  createdAt: Date;
  updatedAt: Date;
  averageAnswer: number;
  totalAnswers: number;
}

export interface IQuestionPayload {
  text: string;
  isVisible: boolean;
  type: Question;
  answer: number;
  sectorId?: string;
  companyId?: string;
  profileId?: string;
}

export interface IAddQueryAnswerPayload {
  questionId: string;
  answer: number;
}

export interface IAnswerResponse {
  companyAnswers?: Record<string, number | null>;
  sectorAnswers?: Record<string, number | null>;
  subSectorAnswers?: Record<string, number | null>;
  queryAnswers?: Record<string, number | null>;
}

export interface ISentiment {
  id: string;
  user: IProfile;
  text: string;
  updatedDate: string;
  post?: IStatistic;
  postId: string;
  postProfileId?: string;
  averageUsers: number;
  answer: number;
}

export interface IAnswersPayload {
  sectorQuestion: string[];
  companyQuestion: string[];
  queryQuestion: string[];
}

export type FollowedQuery = {
  id: string;
  text: string;
  profileImage: string;
  profileId: string;
};

export enum SentimentType {
  QUERY = 'QUERY',
  IDEA = 'IDEA',
  POLL = 'POLL'
}

export interface ISentimentSwings {
  id: number;
  sentimentType: SentimentType;
  text: string;
  startDate: string;
  endDate: string;
  answers: number[];
}
