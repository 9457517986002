import React from 'react';
import * as Styled from './anonymity-select-button.styled';
import ArrowRight from '@/styles/icons/switch-to-anon-modal-reset-button-right-arrow.webp';
import { FormType } from '@/common/types/anon.types';

interface IAnonymitySelectButton {
  title: string;
  active: boolean;
  onClick: () => void;
  interacted: boolean;
  formType: FormType;
}

export const AnonymitySelectButton = ({ title, active, onClick, formType }: IAnonymitySelectButton) => (
  <Styled.SelectButton onClick={onClick} active={active} formType={formType}>
    {title}
    <Styled.ArrowIconWrapper>
    <Styled.ArrowIcon src={ArrowRight} alt="arrow-right" />
    </Styled.ArrowIconWrapper>  
  </Styled.SelectButton>
);
