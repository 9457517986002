import React, { useEffect, useState } from 'react';
import * as Styled from './code-input-landing.styled';

interface ICodeInputComponentProps {
  onFinish?: (code: string) => void;
  reset?: boolean;
  isAuth?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  width?: number;
}

export const CodeInputLandingComponent = ({
  onFinish,
  width,
  placeholder,
  isAuth,
  disabled,
  className,
  reset
}: ICodeInputComponentProps) => {
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    if (reset) {
      setCode('');
    }
  }, [reset]);

  useEffect(() => {
    if (onFinish && code.length === 6) {
      onFinish(code);
    }
  }, [code]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedCode = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    setCode(formattedCode);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData
      .getData('text')
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setCode(pastedText);
  };

  return (
    <Styled.Container className={className} isAuth={isAuth}>
      <Styled.InputField
        width={width}
        placeholder={placeholder}
        type="text"
        maxLength={6}
        value={code}
        onChange={handleChange}
        onPaste={handlePaste}
        disabled={!!disabled}
        isAuth={isAuth}
        autoFocus={reset}
      />
    </Styled.Container>
  );
};
