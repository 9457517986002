export const initOptionalState = {
 disclaimer: '',
  link: '',
  documentUrl: '',
  documentName: '', 
  author: ''
};

export const initMemoState = {
  category: 'Equity',
  direction: '',
  sectorId: '',
  regionId: '',
  subSectorId: '',
  timeHorizon: '',
  security: '',
  researchProgress: '',
  isFirstStepClicked: false,
  isProcessed: false,
  backDateToggle: false,
  priceTarget: 0,
};

export const initSummaryState = {
  summaryText: '',
  themes: []
};

export const initProcessState = {
  isBaseProcessed: true,
  isSummaryProcessed: true,
  isFinancialMetricsProcessed: true,
  isExitValuationProcessed: true,
  isRisksProcessed: true,
  isBusinessQualityProcessed: true,
  isStakeholdersProcessed: true,
  isAboutTheCompanyProcessed: true,
  isBearCaseProcessed: true,
  isBullCaseProcessed: true
}

export const initMemoValuation = {
  revenue: -1,
  ebitda: -1,
  eps: -1,
  fcfeps: -1
};

export const initMemoReturns = {
  baseReturn: -1100,
  bullReturn: -1100,
  bearReturn: -1100,
  baseMethod: '',
  bullMethod: '',
  bearMethod: ''
};

export const initSelectButtons = {
  About: [],
  Oportunity: [],
  Quality: [],
  Growth: [],
  Stewardship: [],
  Valuation: [],
  Risks: [],
  'Pre-Mortem': []
};
