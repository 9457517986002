import styled, { css } from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';
import { NewPostBasicInfoComponent } from '@/common/components/post/components/post-basic-info';
import { MentionInputComponent } from '@/common/components/post/components/mention-input';

export const PostButtonCommonStyle = css`
  cursor: pointer;
  border: none;
  padding: 7px 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:disabled {
    cursor: auto;
    background: rgba(237, 237, 237, 0.5);
    color: #ccc;
  }
`;

export const TextAndImageWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PostActionsContainer = styled.div<{ haveReplie?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ haveReplie }) => haveReplie && 'border-bottom: 1px solid #e2e2e2;'}
  width: 100%;
`;

export const AttachImage = styled(Image)`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const CloseAttachImage = styled(Image)`
  cursor: pointer;
  align-self: center;
  width: 10px;
  height: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const RepliesContainer = styled.div`
  padding-left: 30px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MediaTypeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

export const MediaTypeTitle = styled.span`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  padding: 7px 3px 7px 0px;
  align-items: center;
  gap: 8px;
`;

export const MediaTypeButton = styled.button<{ isSelected?: boolean }>`
  border: none;
  user-select: none;
  cursor: pointer;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  padding: 7px 15px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: ${({ isSelected }) => (isSelected ? 'rgba(0, 22, 216, 0.08)' : 'rgba(240, 240, 240, 0.50)')};
  color: ${({ isSelected }) => (isSelected ? '#0016D8' : '#616161')};
`;

export const ModalTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
`;

export const ActionsContainer = styled.div<{ noReplies?: boolean; haveReplies?: boolean }>`
  display: flex;
  gap: 10px;
  ${({ noReplies }) => noReplies && 'margin-top: 15px;'}
  ${({ haveReplies }) => haveReplies && 'margin-top: 30px;'}
  margin-top: 30px;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;

export const MediaInput = styled.input`
  border: none;
  outline: none;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &::placeholder {
    opacity: 1;
    color: #969696;
  }
  width: 100%;
  background: transparent;
`;

export const LinkError = styled.span`
  color: #df0000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const RecommendedButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

export const InputWithImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const RecommendedButton = styled.button<{ isYes?: boolean; isNo?: boolean; isYesSelected?: boolean; isNoSelected?: boolean }>`
  cursor: pointer;
  border: none;
  user-select: none;
  display: flex;
  width: 60px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  ${({ isYes, isYesSelected, isNoSelected }) =>
    isYes && !isYesSelected && !isNoSelected && 'background: rgba(18, 132, 125, 0.05); color: #12847D;'}
  ${({ isNo, isYesSelected, isNoSelected }) =>
    isNo && !isYesSelected && !isNoSelected && 'background: rgba(90, 20, 205, 0.05); color: #5A14CD;'}
  ${({ isYesSelected, isNoSelected, isYes, isNo }) =>
    ((isYes && isNoSelected) || (isNo && isYesSelected)) && 'background: rgba(97, 97, 97, 0.05); color: #6c6c6c;'}
  ${({ isYes, isNoSelected, isYesSelected }) =>
    isYes && !isNoSelected && isYesSelected && 'background: rgba(18, 132, 125, 0.15); color: #12847D; font-weight: 500;'};
  ${({ isNo, isNoSelected, isYesSelected }) =>
    isNo && !isYesSelected && isNoSelected && 'background: rgba(84, 0, 189, 0.10); color: #5A14CD; font-weight: 500;'};
`;

export const PostBasicInfo = styled(NewPostBasicInfoComponent)`
  margin-bottom: 8px;
`;

export const MainInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
`;

export const ReviewInput = styled(MentionInputComponent)`
  width: 100%;
`;

export const AddReplyButton = styled.button<{ isFirst: boolean }>`
  ${PostButtonCommonStyle}
  height: 34px;
  padding: 7px 11px;
  font-weight: ${FONTS.WEIGHTS.medium};
  background: #f0f0f0;
  border: 1px solid #e1e1e1;
  color: #000;
  justify-content: center;
  border-radius: 8px;
  &:disabled {
    padding: 7px 12px;
    border: none;
  }
`;

export const AddReplyButtonImage = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const PrivatePostButton = styled.button<{ isPrivate: boolean }>`
  border: none;
  cursor: pointer;
  display: flex;
  padding: 7px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ isPrivate }) => (isPrivate ? '#7900A4' : '#0086a4')};
  background: ${({ isPrivate }) => (isPrivate ? 'rgba(121, 0, 164, 0.10)' : 'rgba(0, 134, 164, 0.1)')};
`;

export const PrivatePostButtonImage = styled(Image)`
  display: flex;
  padding: 3px;
  width: 20px;
  height: 20px;
`;
