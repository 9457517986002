import { useMutation } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { linkPreviewService } from '../services';
import { ILinkPreview } from '../types/post.type';

export const useLinkPreview = (setNewLinkPreview: (data: null | ILinkPreview, url: string) => void) =>
  useMutation([QUERY_KEYS.LINK_PREVIEW], (url: string) => linkPreviewService.getLinkPreview(url), {
    onSuccess: (data) => {
      if (data.data === null) {
        setNewLinkPreview(data.data, data.url);
      }
      if (!data.data) return;
      setNewLinkPreview(data.data, data.url);
    }
  });

export const useReviewLinkPreview = (
  setNewLinkPreview: (data: null | ILinkPreview, url: string) => void,
  setAPIErrorPreview: (newValue: boolean) => void,
  setNoTitleScrapped: (value: boolean) => void
) =>
  useMutation([QUERY_KEYS.REVIEW_LINK_PREVIEW], (url: string) => linkPreviewService.getLinkPreview(url), {
    onSuccess: (data) => {
      if (data.data === null) {
        setNewLinkPreview(data.data, data.url);
        setAPIErrorPreview(true);
      }
      if (!data.data) {
        setAPIErrorPreview(true);
        return;
      }
      setNewLinkPreview(data.data, data.url);
      setAPIErrorPreview(false);
      setNoTitleScrapped(!data.data.title?.length);
    }
  });
