import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { UserImageComponent } from '@/common/components/user-image';

export const ReplyButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: none;
  width: fit-content;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  max-width: 430px;
  border-left: 2px solid ${COLORS.borderColorForReplyTile};
`;

export const ReplyDivider = styled.div<{
  isFirstMemoReply?: boolean;
  isFirst?: boolean;
  isAnon?: boolean;
  isMemoPost?: boolean;
  isIdeaModal?: boolean;
  isPublish?: boolean;
  isIdeaPage?: boolean;
  isInModal?: boolean;
  isMemoPostHomeOrProfile?: boolean;
  dividerWidth?: number;
  isIdeaHomePage?: boolean;
}>`
  margin-left: auto;
  margin-top: ${({ isMemoPost }) => (isMemoPost ? '20px' : '18px;')};
  margin-bottom: 18px;
  height: 1px;
  background: ${({ isMemoPost }) => (isMemoPost ? '#F0F0F0;' : 'linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 94%, #fff 94.05%);')};
  ${({ isAnon }) => isAnon && 'background-color: #353535;'};

  width: ${({ isMemoPost }) => (isMemoPost ? '540px' : '540px')};
  ${({ isPublish }) => isPublish && 'width: 650px;'};

  ${({ isIdeaModal, isPublish }) => isIdeaModal && isPublish && 'width: 500px;'};
  ${({ isIdeaModal }) => isIdeaModal && 'width: 510px;'};

  ${({ isFirstMemoReply }) => isFirstMemoReply && 'display: none;'};
  overflow: hidden;

  ${({ isIdeaPage }) => isIdeaPage && 'width: 540px; margin-left: 37px'};
  ${({ isIdeaHomePage }) => isIdeaHomePage && 'width: 540px;'};
  ${({ isMemoPostHomeOrProfile }) => isMemoPostHomeOrProfile && 'width: 500px;'}
  ${({ dividerWidth }) => dividerWidth && `width: ${dividerWidth}px`};
`;

export const ReplyButtonText = styled.div`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.s};
  line-height: ${SIZES.s};
  color: ${COLORS.black};
  letter-spacing: 0.6px;
`;

export const ReplyButtonIcon = styled(Image as AnyStyledComponent)`
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconSecond = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconLeft = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
`;

export const ConversationWrapper = styled.div<{ marginTop?: number; isInModal?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ isInModal }) => isInModal && 'margin-left: 40px;'} // margin-left: 40px;
`;

export const ShowMoreWrapper = styled.button<{ noPadding?: boolean }>`
  background-color: transparent;
  border: none;
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '30px')};
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  min-height: 34px;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
      color: #0016d9;
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.medium};
      line-height: ${FONTS.SIZES.xxxl};
      letter-spacing: 0.6px;
      white-space: nowrap;
    }
  }
`;

export const ProfileIconsList = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  & > div {
    margin-left: -4px;
  }
`;

export const ProfileItem = styled(UserImageComponent)`
  width: 34px;
  min-width: 34px;
  max-width: 34px;
  height: 34px;
  min-height: 34px;
  max-height: 34px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 34px;
  max-height: 34px;
`;
