import { useQuery, useQueryClient } from 'react-query';
import { coverageService } from '@services';
import { useCallback } from 'react';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { ICompanySearchFilter, IMarketDataFromApi, ISelectedCompany } from '@/common/types/coverage.type';

export const useCompanies = (): [ISelectedCompany[] | undefined, (data: ISelectedCompany[] | null) => void] => {
  const queryClient = useQueryClient();
  const companies = queryClient.getQueryData<ISelectedCompany[]>(QUERY_KEYS.COMPANIES);

  const setCompanies = useCallback((data: ISelectedCompany[] | null) => {
    queryClient.setQueryData(QUERY_KEYS.COMPANIES, data);
  }, []);

  return [companies, setCompanies];
};

export const useCompaniesBySearch = (filter: ICompanySearchFilter, setCompanies: (data: ISelectedCompany[] | null) => void) =>
  useQuery(
    [QUERY_KEYS.COMPANIES, filter],
    () => {
      if (filter?.symbol && filter?.symbol?.length > 0) {
        return coverageService.getCompaniesBySymbol(filter);
      }
      setCompanies(null);
    },
    {
      onSuccess: (data) => {
        if (!data) return;
        const error = data[0]?.name as unknown as { name: string };
        if (error?.name) return;
        setCompanies(data?.filter((item) => item?.id && item?.name));
      }
    }
  );

export const useCompaniesBySearchUnique = (filter: ICompanySearchFilter, setCompanies: (data: ISelectedCompany[] | null) => void) =>
  useQuery(
    [QUERY_KEYS.COMPANIES, filter],
    () => {
      if (filter?.symbol && filter?.symbol?.length > 0) {
        return coverageService.getCompaniesBySymbolUnique(filter);
      }
      setCompanies(null);
    },
    {
      onSuccess: (data) => {
        if (!data) return;
        setCompanies(data);
      }
    }
  );

export const useCompaniesCoverageBySearchUnique = (
  filter: ICompanySearchFilter,
  setCompanies: (data: ISelectedCompany[] | null) => void,
  setIsQueryUsed?: (value: boolean) => void
) =>
  useQuery(
    [QUERY_KEYS.COMPANIES, filter],
    () => {
      if (filter?.symbol && filter?.symbol?.length > 0) {
        return coverageService.getCompaniesCoverageBySymbolUnique(filter);
      }
      setCompanies(null);
    },
    {
      onSuccess: (data) => {
        if (!data) return;
        setCompanies(data.companies);
        setIsQueryUsed?.(data.isQueryUsedAsCompany);
      }
    }
  );

export const useCompanyMarketDataByTicker = (ticker?: string) =>
  useQuery<IMarketDataFromApi>([QUERY_KEYS.COMPANY_MARKET_DATA, ticker], () => coverageService.getCompanyMarketDataByTicker(ticker), {
    enabled: !!ticker
  });

export const useCompaniesEditProfile = (tickers: string[], isMain: boolean, roleIndex: number) =>
  useQuery([QUERY_KEYS.COMPANIES, tickers, isMain, roleIndex], () => coverageService.getCompaniesByTickersEditProfile(tickers));
