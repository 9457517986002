export const getFormatedNumber = (value?: number): string => {
  const billion = 1000000000;
  if (typeof value === 'undefined') return '0';
  if (value > billion) {
    return `${Math.round(value / billion)} billion`;
  }
  const million = 1000000;
  if (value > million) {
    return `${Math.round(value / million)} million`;
  }
  return `${value}`;
};

export const getShortenedFormattedNumber = (value?: number): string => {
  const billion = 1000000000;
  if (typeof value === 'undefined') return '0';
  if (value > billion) {
    return `${Math.round(value / billion)}b`;
  }
  const million = 1000000;
  if (value > million) {
    return `${Math.round(value / million)}m`;
  }
  return `${value}`;
};
