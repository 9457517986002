import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Styled from './otp-input.styled';

interface ICodeInputComponentProps {
  onPage?: boolean;
  onFinish?: (code: string) => void;
  disabled?: boolean;
  className?: string;
  inModal?: boolean;
  reservedUsernameInput?: boolean;
  inputRef?: any;
  setOtpFinishError?: Dispatch<SetStateAction<string | null>>;
}

export const OtpInputComponent = ({ onPage, onFinish, disabled, className, inModal, reservedUsernameInput, inputRef }: ICodeInputComponentProps) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [currentFocus, setCurrentFocus] = useState(0);
  const [textInput, setTextInput] = useState('');

  useEffect(() => {
    if (!inModal) {
      const firstInput = document.getElementById('code-input-0');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [inModal]);

  useEffect(() => {
    if (onFinish) {
      if (inModal && textInput && textInput.length === 6) {
        onFinish(textInput);
      } else {
        if (code.every((val) => !!val)) {
          onFinish(code.join(''));
        }
      }
    }
  }, [code, textInput, inModal]);

  const formatInput = (text: string) => text?.toUpperCase().replace(/[^A-Z0-9]/g, '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value: inputValue } = e.target;
    let value = formatInput(inputValue);

    if (inModal) {
      setTextInput(value);
    } else {
      const otpValue = inputValue.length > 1 ? value[value.length - 1] : value;
      const newCode = [...code];
      newCode[index] = otpValue;
      setCode(newCode);

      if (!otpValue && index > 0) {
        const previousInput = document.getElementById(`code-input-${index - 1}`);
        if (previousInput) {
          previousInput.focus();
        }
      } else if (otpValue && index < 5) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (inModal) {
        if (!code[currentFocus]) {
          const previousInput = document.getElementById(`code-input-${currentFocus - 1}`);
          if (previousInput) {
            e.preventDefault();
            previousInput.focus();
            const newCode = [...code];
            newCode[currentFocus - 1] = '';
            setCode(newCode);
          }
        }
      } else {
        setTextInput((prev) => prev.slice(0, -1));
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const pastedText = formatInput(e.clipboardData.getData('text').trim());

    if (!inModal && pastedText) {
      const newCode = [...code].map((val, i) => {
        if (i >= index && i < index + pastedText.length) {
          const pastedTextIndex = i - index;
          return pastedText[pastedTextIndex];
        }
        return val;
      });
      setCode(newCode);
    } else if (inModal && pastedText) {
      setTextInput(pastedText);
    }
  };

  return (
    <>
      {inModal ? (
        <Styled.ModalInputField
          autoComplete="off"
          type="text"
          ref={inputRef}
          maxLength={code.length}
          value={textInput}
          onChange={(e) => handleChange(e, 0)}
          onPaste={(e) => handlePaste(e, 0)}
          onKeyDown={handleKeyDown}
          onFocus={() => setCurrentFocus(0)}
          placeholder="enter the code you receive"
        />
      ) : (
        <Styled.OtpInputContainer className={className} aria-autocomplete="none">
          {code.map((digit, index) => (
            <Styled.InputField
              $isFirst={index === 0}
              $isLast={index === code.length - 1}
              $onPage={onPage}
              $reservedUsernameInput={reservedUsernameInput}
              key={index}
              id={`code-input-${index}`}
              autoComplete="off"
              type="text"
              maxLength={2}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              onKeyDown={handleKeyDown}
              onFocus={() => setCurrentFocus(index)}
              disabled={!!disabled}
            />
          ))}
        </Styled.OtpInputContainer>
      )}
    </>
  );
};
