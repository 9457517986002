import React, { ReactElement } from 'react';
import { FlexComponent } from '@/common/components/flex';
import { OptionalText, OptionalTitle } from './optional-title.styled';

interface IProps {
  title?: string;
}

export const OptionalTitleComponent = ({ title }: IProps): ReactElement => (
  <FlexComponent gap="7px">
    <OptionalTitle>{title}</OptionalTitle>
    <OptionalText>optional</OptionalText>
  </FlexComponent>
);
