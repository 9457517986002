import { ICreateWaitlistItem, ICreateWaitlistItemResponse } from '../types/waitlist.types';
import { axiosInstance } from './axios';
import { HttpService } from './http.service';

class WaitlistService extends HttpService {
  constructor() {
    super(axiosInstance);
  }

  create(data: ICreateWaitlistItem) {
    return this.post<ICreateWaitlistItemResponse, ICreateWaitlistItem>('waitlist', data);
  }
}

export const waitlistService = new WaitlistService();
