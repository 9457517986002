import { useMutation, useQuery, useQueryClient } from 'react-query';
import { coverageService } from '@services';
import { useCallback } from 'react';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { IOnboardingSubSectors, ISector, ISubSector } from '@/common/types/coverage.type';

export const useUpdateSubSectors = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.SUB_SECTORS],
    mutationFn: (activeSector: ISector) => coverageService.getSubSectors(activeSector?.id),
    onSuccess: (data) => {
      if (!data) return;
      const subSectors = queryClient.getQueryData<ISubSector[]>(QUERY_KEYS.SUB_SECTORS);
      const updatedSubSectors = subSectors ? [...subSectors, ...data] : data;
      queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, updatedSubSectors);
    }
  });
};

export const useSubSectors = (): [ISubSector[] | undefined, (data: ISubSector[] | null) => void] => {
  const queryClient = useQueryClient();
  const subSectors = queryClient.getQueryData<ISubSector[]>(QUERY_KEYS.SUB_SECTORS);

  const setSubSectors = useCallback((data: ISubSector[] | null) => {
    queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, data);
  }, []);

  return [subSectors, setSubSectors];
};

export const useSubSectorsBySectors = (ids: string[]) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.SUB_SECTORS], () => coverageService.getSubSectorsBySectors(ids), {
    onSuccess: (data) => {
      if (!data) return;
      queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, data);
    }
  });
};

export const useSubSectorsBySector = () =>
  useMutation([QUERY_KEYS.SUB_SECTORS], (id: string) => coverageService.getSubSectorsBySectors([id]));

export const useSubSectorsBySectorsOnboarding = (ids: string[]) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.SUB_SECTORS], () => coverageService.getSubSectorsBySectorsOnboarding(ids), {
    onSuccess: (data) => {
      if (!data) return;
      queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, data);
      return data;
    }
  });
};

const needAll = ['Residential', 'Commercial'];

export const useSubSectorsOnboarding = (): [IOnboardingSubSectors | undefined, (data: IOnboardingSubSectors | null) => void] => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<IOnboardingSubSectors>(QUERY_KEYS.SUB_SECTORS);

  const setSubSectors = useCallback((dataToUpdate: IOnboardingSubSectors | null) => {
    queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, dataToUpdate);
  }, []);

  if (data && data?.groupedSubSectors) {
    data.groupedSubSectors = data?.groupedSubSectors.map((gropedSubSector) => {
      let result = [...gropedSubSector].sort((a, b) => a.name.localeCompare(b.name));

      if (gropedSubSector[0]?.sector?.name && needAll.includes(gropedSubSector[0].sector?.name)) {
        result = [{ name: 'All', id: 'all' }, ...result];
      }

      return result;
    });
  }

  return [data, setSubSectors];
};

export const useUpdateSubSectorsOnboarding = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.SUB_SECTORS],
    mutationFn: (activeSector: ISector) => coverageService.getSubSectorsBySectorsOnboarding([activeSector?.id]),
    onSuccess: (data) => {
      if (!data) return;
      const { subSectors: dataSubSectors, groupedSubSectors: dataGroupedSubSectors } = data;
      const { subSectors, groupedSubSectors } = queryClient.getQueryData<IOnboardingSubSectors>(QUERY_KEYS.SUB_SECTORS) ?? {
        subSectors: []
      };
      const updatedSubSectors =
        subSectors && groupedSubSectors
          ? { subSectors: [...subSectors, ...dataSubSectors], groupedSubSectors: [...groupedSubSectors, ...dataGroupedSubSectors] }
          : data;

      queryClient.setQueryData(QUERY_KEYS.SUB_SECTORS, updatedSubSectors);
    }
  });
};

export const useSubSectorsEditProfile = (ids: string[], roleIndex: number, isMain: boolean) =>
  useQuery([QUERY_KEYS.SUB_SECTORS, roleIndex, isMain], () => coverageService.getSubSectorsBySectorsOnboarding(ids));
