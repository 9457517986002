import { useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';

export const useGetModalOpen = () => {
  const client = useQueryClient();
  return useQuery({
    queryKey: [QUERY_KEYS.GET_HIDE_RIGHT_TOOLBAR],
    queryFn: () => client.getQueryData(QUERY_KEYS.HIDE_RIGHT_TOOLBAR) as boolean
  });
};
