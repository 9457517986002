import React, { useEffect, useMemo, useState } from 'react';
import Search from '@styles/icons/blue-search.webp';
import BlueArrow from '@styles/icons/blue-right-arrow.webp';
import { useRouter } from 'next/router';
import * as Styled from './search-modal.styled';
import { SearchComponent } from '../search/search.component';
import { useSearchInModal } from '@/common/hooks/use-algolia';
import { keyValuePairs } from '../../search-page.const';
import { RowElementComponent } from '../row-element-modal';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useDebounce } from '@/common/hooks';
import { IClickedProps, ISearchFilter, ISearchInModalOption } from '@/common/types/search.type';
import { SlideType } from '@/common/types/modal.type';

interface IProps {
  open: boolean;
  closeHandler: () => void;
}

export const SearchModal = ({ open, closeHandler }: IProps) => {
  const [value, setValue] = useState('');
  const [refetchFirst, setRefetchFirst] = useState(true);

  const [searchResult, setSearchResult] = useState<ISearchInModalOption | null>(null);

  const debouncedForSearchInModal = useDebounce<ISearchFilter>({ isClicked: false, searchString: value }, 300);
  const { refetch: refetchSearch } = useSearchInModal(debouncedForSearchInModal, setSearchResult);
  const { push } = useRouter();
  const onClickByClose = () => {
    setValue('');
    closeHandler();
  };

  useEffect(() => {
    if (refetchFirst) {
      refetchSearch();
    }
    setRefetchFirst(false);
  }, [debouncedForSearchInModal]);

  const onClickBySeeAllResult = (valueData: string, keyData?: string) => {
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,

      query: {
        value: valueData,
        filter: keyData
      }
    });
    onClickByClose();
  };

  const onClickBySearchedResult = ({ valueData, type, clickedId, clickedValue, clickedTicker }: IClickedProps) => {
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        value: valueData,
        type,
        clickedId,
        clickedValue,
        clickedTicker,
        filter: undefined
      }
    });
    onClickByClose();
  };

  const onClickByMember = (profileId: string) => {
    push({
      pathname: ROUTER_KEYS.PROFILE,
      query: {
        id: profileId
      }
    });

    onClickByClose();
  };

  const allResultCount = useMemo(() => {
    if (searchResult && Object.keys(searchResult).length > 0) {
      return Object.keys(searchResult).reduce((prev, it) => ((searchResult[it]?.nbHits ?? 0) + Number(prev)).toString(), '');
    }
    return 0;
  }, [searchResult]);

  return (
    <Styled.Wrapper
      open={open}
      closeHandler={onClickByClose}
      hideChildBackground
      hideChildeShadow
      hideBorder
      isTopLeft
      slideType={SlideType.BOTTOM}
      childTopPostion={100}
    >
      <SearchComponent value={value} onChange={(str) => setValue(str)} />
      {!!searchResult && (
        <Styled.SearchResultWrapper>
          <Styled.SeeAllResultButton onClick={() => onClickBySeeAllResult(value)}>
            <Styled.SearchIcon src={Search} alt="Search" />
            <Styled.TextWrapper>{allResultCount}</Styled.TextWrapper>
            <Styled.TextWrapper>see all results</Styled.TextWrapper>
            <Styled.ArrowIcon src={BlueArrow} alt="Arrow" />
          </Styled.SeeAllResultButton>
          {keyValuePairs.map(
            ({ key, value: valueToKey }, index) =>
              searchResult[key] &&
              searchResult[key].nbHits > 0 && (
                <>
                  <Styled.Divider />
                  <RowElementComponent
                    keyData={key}
                    index={index}
                    value={valueToKey.toLowerCase()}
                    nbHits={searchResult[key].nbHits}
                    elements={searchResult[key].hits}
                    onClick={(data) => onClickBySearchedResult({ valueData: value, ...data })}
                    onClickBySeeMore={(keyData?: string) => onClickBySeeAllResult(value, keyData)}
                    onClickByMember={onClickByMember}
                  />
                </>
              )
          )}
        </Styled.SearchResultWrapper>
      )}
    </Styled.Wrapper>
  );
};
