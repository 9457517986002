import { ILikePayload, IProfileByGroups } from '../types/like.type';
import { IPostStatResponseItem, IUpdateReaction } from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class LikeService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async like(data: ILikePayload) {
    return this.post<IUpdateReaction, ILikePayload>('like', data);
  }

  async getProfileByGroups(data: ILikePayload) {
    return this.get<IProfileByGroups>(`like/${data.postId}`);
  }

  async getPostLikes(postId: string, take: number, page: number) {
    return this.get<IPostStatResponseItem>(`like/post-likes/${postId}?take=${take}&page=${page}`);
  }
}

export const likeService = new LikeService();
