import styled from 'styled-components';
import Image from 'next/image';
import { FAMILIES } from '@/theme/fonts.const';

export const MoreButton = styled.button`
  display: flex;
  padding: 7px 0px 7px 4px;
  align-items: center;
  gap: 3px;
  color: #2c67ff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    color: #b7cbff;
  }
`;

export const MoreButtonIcon = styled(Image)`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
`;
