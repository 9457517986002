import React from 'react';
import Link from 'next/link';

import * as Styled from './newsfeed-publish.styled';
import { ITag } from '@/common/types/post.type';

interface IProps {
  description?: string | null;
  title: string;
  imgUrl?: string | null;
  link: string;
  sourceImgUrl?: string | null;
  tags?: ITag[];
}

export const NewsfeedPublish: React.FC<IProps> = ({ description, title, imgUrl, link, sourceImgUrl, tags }) => {
  const isTextShortened = (value: boolean, text: string, max: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const min = max - 20;
    if (!ctx) return value ? false : text;
    const font = 'Roboto Flex';
    const fontSize = 14;
    ctx.font = `${fontSize}px ${font}`;
    let textWidth = ctx.measureText(text).width;

    if (!value && textWidth > max) {
      let truncatedText = text;
      let left = 0;
      let right = text.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedText = text.slice(0, middle);
        textWidth = ctx.measureText(truncatedText).width;

        if (textWidth > min) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }

      truncatedText = `${text.slice(0, right)}...`;
      return truncatedText;
    }
    if (!value && textWidth <= max) {
      return text;
    }

    return textWidth > max;
  };

  function truncateText(text: string, maxLines: number, maxWidthPx: number) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;

    const font = 'Roboto Flex';
    const fontSize = 14;
    context.font = `${fontSize}px ${font}`;

    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    for (const word of words) {
      const currentLineWidth = context.measureText(currentLine + (currentLine === '' ? '' : ' ') + word).width;

      if (currentLineWidth <= maxWidthPx) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        lines.push(currentLine);
        if (lines.length > maxLines) break;
        currentLine = word;
      }
    }

    if (currentLine !== '') {
      lines.push(currentLine);
    }

    const truncatedText = lines.join(' ');

    if (lines.length > maxLines) {
      const newLines = lines.slice(0, maxLines);
      const checkText = newLines[1];
      let textWidth = context.measureText(checkText).width;
      const min = maxWidthPx - 20;

      let truncatedTextLast = checkText;
      let left = 0;
      let right = checkText.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedTextLast = checkText.slice(0, middle);
        textWidth = context.measureText(truncatedTextLast).width;

        if (textWidth > min) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }

      truncatedTextLast = `${lines[0]} ${checkText.slice(0, right)}...`;
      return truncatedTextLast;
    }

    return truncatedText;
  }

  const filteredTags = tags?.filter((it) => it.type !== 'source' && it.fullName !== 'US Election 2024') ?? [];

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.InfoWrapper>
          <Link
            href={link}
            target="_blank"
            rel="noopener"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Styled.Title>{truncateText(title, 2, 400)}</Styled.Title>
          </Link>
          <Styled.Description>{!description ? 'Article preview unavailable' : truncateText(description, 2, 400)}</Styled.Description>
        </Styled.InfoWrapper>
        {imgUrl ? (
          <Styled.Image src={imgUrl} />
        ) : (
          <Styled.Screensaver>
            {sourceImgUrl && <Styled.SourceIcon src={sourceImgUrl} alt="source icon" width="34" height="34" />}
          </Styled.Screensaver>
        )}
      </Styled.Wrapper>
      {!!filteredTags?.length && (
        <Styled.TagsWrapper>
          {filteredTags.map((item) => (
            <p key={item.id}>{item.fullName.toLowerCase()}</p>
          ))}
        </Styled.TagsWrapper>
      )}
    </Styled.Container>
  );
};
