/* eslint-disable import/no-cycle */
import { Degree, IEducation } from '@/common/types/education.type';
import { ISector, ISelectedCompany, ISubSector } from './coverage.type';
import { IAssociationResponse } from './connection.type';

export enum Visibility {
  FOLLOWERS = 'FOLLOWERS',
  CONNECTIONS = 'CONNECTIONS',
  ALL_USERS = 'ALL_USERS'
}

export interface ICurrentState {
  about: {
    isValid: boolean;
    isUpdated: boolean;
  };
  employers: {
    isValid: boolean;
    isUpdated: boolean;
  };
  previous: {
    isValid: boolean;
    isUpdated: boolean;
  };
  education: {
    isValid: boolean;
    isUpdated: boolean;
  };
}

export interface IBasicInfo {
  fullName: string;
  image: string | ArrayBuffer | null;
  imageUrl?: string;
  imageVisibility: Visibility;
  isPulled?: boolean;
  isBioFilled?: boolean;
  bio: string | null;
  isBioSuccess: boolean;
}

export interface CurrentEmployer {
  id?: string;
  employer: string;
  role: string;
  startDate: string | Date | null;
  locationId: string;
  locationRegionId: string;
  isPulled?: boolean;
  isDescriptionFilled?: boolean;
  description?: string | null;
  companies: string[];
  regions: string[];
  sectors: string[];
  subSectors: string[];
  isDescriptionSuccess: boolean;
  isLocationSuccess: boolean;
  isStartDateSuccess: boolean;
  isMain: boolean;
}

export interface Employer {
  id?: string;
  employer: string;
  role: string;
  startDate: string | Date;
  locationId: string;
  locationRegionId: string;
  isPulled?: boolean;
  isDescriptionFilled?: boolean;
  description?: string | null;
  companies: ISelectedCompany[];
  regions: string[];
  sectors: string[];
  subSectors: string[];
  isDescriptionSuccess: boolean;
  isLocationSuccess: boolean;
  isStartDateSuccess: boolean;
  isMain: boolean;
  vertical: string | null;
}

export interface ICurrentEmployer extends CurrentEmployer {
  vertical: string | null;
  subSector?: ISubSector;
  location?: ILocation;
  locationRegion?: ILocationRegion;
  sectorId?: string;
}

export interface ICurrentEmployerPayload {
  id?: string;
  employer?: string;
  role?: string;
  vertical?: string;
  startDate?: Date | string;
  locationId?: string;
  locationRegionId?: string;
}

export interface IOtherCurrentEmployer extends ICurrentEmployer {
  subSectorId: string | null | undefined;
}

export interface ICurrentStatusBase extends ICoverage {
  basicInfo: Partial<IBasicInfo>;
  otherCurrentEmployers?: IOtherCurrentEmployer[];
}

export interface ICurrentStatus extends ICurrentStatusBase {
  currentEmployer: Partial<ICurrentEmployer>;
}

export interface ICurrentStatusPayload extends ICurrentStatusBase {
  currentEmployer: ICurrentEmployerPayload;
}

export interface IEditProfile {
  basicInfo?: {
    bio?: string | null;
    fullName?: string;
    image?: string | null;
  };
  employer?: IEmployer[];
  education?: Partial<IEducation>[];
}

export interface IFormerCoverage {
  sectors: string[];
  subSectors?: string[];
  regions: string[];
}

export interface ICoverage extends IFormerCoverage {
  companies: ISelectedCompany[];
  privateCompanies: string[];
}

export interface IFormerEmployer extends ICurrentEmployer {
  endDate: string | Date | null;
}

export interface IPreviousEndeavoursPayload extends IFormerCoverage {
  isBack?: boolean;
  formerEmployer: IFormerEmployer[];
  education?: IEducation[];
}

export interface IPreviousEndeavoursResponse extends IPreviousEndeavoursPayload {
  currentEmployer: ICurrentEmployer;
}

export interface IProfileBasicInfo {
  id?: string;
  fullName: string;
  imageUrl?: string;
  imageVisibility: string;
  bio?: string;
}

export interface IProfile extends IProfileBasicInfo {
  userId: string;
  linkedInFollowerCount?: number;
  twitterFollowerCount?: number;
  currentEmployer: IEmployerHistoryResponse;
  whoCanFollow?: boolean;
  employer?: IEmployer[];
  isAnon?: boolean;
  isImageHidden?: boolean;
}

export enum OnDropErrorEnum {
  INCORECT_TYPE = 1,
  MAX_SIZE = 2,
  MAX_SIZE_MEMO = 3,
  MAX_SIZE_EDIT_PAGE = 4,
  MAX_SIZE_MEMO_PDF = 5,
  INCORECT_TYPE_MEMO_PDF = 6
}

export const OnDropErrorMessages = {
  [OnDropErrorEnum.INCORECT_TYPE]: {
    title: 'Incorrect file format',
    description: 'Please only upload files ending in: jpg, png, jpeg, svg, avif, bmp, webp'
  },
  [OnDropErrorEnum.INCORECT_TYPE_MEMO_PDF]: {
    title: 'Incorrect file format',
    description: 'Please only upload files ending in: pdf'
  },
  [OnDropErrorEnum.MAX_SIZE_MEMO_PDF]: { title: 'File size too large', description: 'Please only upload files smaller than 20 MB' },
  [OnDropErrorEnum.MAX_SIZE]: { title: 'File size too large', description: 'Please only upload files smaller than 40 MB' },
  [OnDropErrorEnum.MAX_SIZE_MEMO]: { title: 'File size too large', description: 'Please only upload files smaller than 8 MB' },
  [OnDropErrorEnum.MAX_SIZE_EDIT_PAGE]: { title: 'File size too large', description: 'Please only upload files smaller than 5 MB' }
};

export interface IUserConnections {
  follows: number;
  followers: number;
  connection: IAssociationResponse;
  userConnection: IAssociationResponse;
}

export enum ChartStateEnum {
  EDUCATION = 'education',
  EMPLOYER = 'employer'
}

export interface ILocation {
  id: string;
  name: string;
  nameCode: string;
  stateCode: string | null;
  stateName: string | null;
}

export interface ILocationRegion {
  id: string;
  name: string;
}

export interface IEducationResponse extends IEducation {
  state: ChartStateEnum.EDUCATION;
}

export interface IEmployer extends CurrentEmployer {
  state: ChartStateEnum.EMPLOYER;
  vertical: string | null;
  sectorId?: string;
  subSector: (ISubSector & { sector?: ISector }) | null;
  location: ILocation;
  locationRegion: ILocationRegion;
  endDate: string | Date | null;
  subSectorId: string | null;
  isNew?: boolean;
  isPrevious?: boolean;
}

export interface IFollowsResponseOld {
  id: string;
  fullName: string;
  imageUrl: string;
  imageVisibility: string;
  follower: boolean;
  following: boolean;
  connected: boolean;
  private: boolean;
  isImageHidden: boolean;
}

export interface IFollowsDataResponse {
  id: string;
  fullName: string;
  imageUrl: string;
  imageVisibility: string;
  follower: boolean;
  following: boolean;
  connected: boolean;
  private: boolean;
  isImageHidden: boolean;
  userId: string;
  role?: string;
  vertical?: string;
  connectionRequestSent: boolean;
  followingRequestSent: boolean;
  followingRequestIgnored: boolean;
  connectionRequestIgnored: boolean;
  isAnon: boolean;
}

export interface IFollowsResponse {
  count: string | number;
  data: IFollowsDataResponse[];
  metaData: {
    lastCursor: string;
    nextPage: boolean;
  };
}

export interface IPostBasicInfo {
  id: string;
  userId: string;
  imageUrl?: string;
  fullName: string;
  vertical: string;
  role: string;
}

export interface IChannelsBasicInfo extends IPostBasicInfo {
  isImageHidden: boolean;
}

export enum WorkHistoryType {
  EDUCATION = 'education',
  EMPLOYER = 'employer'
}

export interface INameIdObject {
  id: string;
  name: string;
}
export interface IEmployerHistoryResponse {
  id: string;
  type: WorkHistoryType.EMPLOYER;
  employer: string;
  startDate: string;
  endDate?: string;
  isMain: boolean;
  location: ILocation;
  locationRegion: ILocationRegion;
  vertical: string;
  role?: string;
  subSector?: ISubSector;
  description?: string;
  regions: INameIdObject[];
  sectors: INameIdObject[];
  subSectors: INameIdObject[];
  companies: INameIdObject[];
  privateCompanies: string[];
  isPrevious?: boolean;
}

export interface IEducationHistoryResponse {
  id: string;
  type: WorkHistoryType.EDUCATION;
  startDate: string;
  endDate?: string;
  description?: string;
  university: string;
  degree: Degree;
  major?: string;
  grade?: string;
  location: ILocation;
  locationRegion: ILocationRegion;
  isPrevious?: boolean;
}

export type UnfilledFields = Partial<Record<keyof (IEducationHistoryResponse & IEmployerHistoryResponse) | 'subVertical', boolean>>;

export interface WrokHistoryResponse {
  currentRoles: IEmployerHistoryResponse[];
  previousRoles: (IEducationHistoryResponse | IEmployerHistoryResponse)[];
  isHidden: boolean;
  isDoesntHaveCurrentRole?: boolean;
  isDoesntHavePreviuseRole?: boolean;
  outputTextForHidden?: string;
}

export enum CoverageType {
  SECTORS = 'sector',
  SUB_SECTORS = 'sub-sector',
  REGIONS = 'regional',
  COMPANIES = 'security',
  PRIVATES = 'privates'
}

export enum VisibilitySettings {
  SURNAME = 'Surname',
  PHOTO = 'Photo',
  EXPERIENCE = 'Experience',
  SECURITY_COVERAGE = 'Security Coverage',
  KEY_DEBATES = 'Key Debates',
  FEED = 'Feed'
}

export enum ProfileVisibilityEnum {
  SURNAME = 'SURNAME',
  PHOTO = 'PHOTO',
  EXPERIENCE = 'EXPERIENCE',
  SECURITY_COVERAGE = 'SECURITY_COVERAGE',
  KEY_DEBATES = 'KEY_DEBATES',
  FEED = 'FEED'
}

export enum LoginMethodEnum {
  CODE = 'CODE',
  LINK = 'LINK',
  PASSWORD = 'PASSWORD'
}

export const VisibilitySettingsOutput: Record<ProfileVisibilityEnum, string> = {
  [ProfileVisibilityEnum.SURNAME]: 'Surname',
  [ProfileVisibilityEnum.PHOTO]: 'Profile Photo',
  [ProfileVisibilityEnum.EXPERIENCE]: 'Work History',
  [ProfileVisibilityEnum.SECURITY_COVERAGE]: 'Security Coverage',
  [ProfileVisibilityEnum.KEY_DEBATES]: 'Key Debates',
  [ProfileVisibilityEnum.FEED]: 'Feed + Posts'
};

export interface ProfileVisibilitySettings {
  profileVisibilityForUnassociatedMembers: ProfileVisibilityEnum[];
  profileVisibilityForFollower: ProfileVisibilityEnum[];
  whoCanFollow: boolean;
  unassociatedMembersIsSeeYourReply: boolean;
  loginMethod: LoginMethodEnum;
  email: string;
  isPasswordSeted: boolean;
}

export enum FilterType {
  HOME = 'home',
  IDEA = 'idea',
  POLL = 'poll'
}

export interface ISavedFilters {
  sectors?: string[];
  regions?: string[];
  periods?: string[];
  categories?: string[];
  excludeSectors?: string[];
  excludeRegions?: string[];
  excludePeriods?: string[];
  excludeCategories?: string[];
  includeSectorId?: string[];
  excludeSectorId?: string[];
  includeTopics?: string[];
  excludeTopics?: string[];
  includeSource?: string[];
  excludeSource?: string[];
}
