import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
// eslint-disable-next-line import/no-cycle

interface ITitleOD {
  isDetails?: boolean;
}

export const WrapperOptionalDetails = styled.div<{ isCurrent?: boolean; isAllSectionOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 30px;
  border-radius: ${({ isAllSectionOpen }) => (isAllSectionOpen ? '0px 0px 15px 15px' : '15px')};
  background: ${({ isCurrent }) => (isCurrent ? COLORS.bcColorContainerMemo : 'transparent')};
  /* box-shadow: ${({ isCurrent }) => (isCurrent ? '2px 0px 20px 0px #efefef' : 'none')}; */
`;

export const OptionalInput = styled.input`
  width: 534px;

  color: #000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  padding: 7px 12px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fbfbfb;

  outline: none;

  &::placeholder {
    color: #3f3f3f;
  }
`;

export const OptionalTitle = styled.div<{ greyOptionaltext?: boolean }>`
  display: flex;
  gap: 7px;
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;

  span {
    color: ${({ greyOptionaltext }) => (greyOptionaltext ? '#636363' : '#cc4900')};
    font-family: 'Roboto Flex';
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
`;

export const ContainerTitleDetails = styled('div')``;

export const ContainerLink = styled('p')``;

export const TitleLink = styled.h3`
  color: #0944da;

  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const TitleMiniSectionOptionalWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > span {
    color: #b1b1b1;
    font-family: 'Roboto Flex';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
  }
`;

export const TitleMiniSectionOptionalValuation = styled.h2`
  color: ${COLORS.black};
  font-weight: ${WEIGHTS.normal};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  line-height: ${SIZES.m};
  width: 125px;
`;

export const ContMemoOptionalDetails = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  & > div {
    padding-bottom: 20px;
    border-bottom: solid 1px #f0f0f0;
  }
`;

export const TitleSector = styled.h3<ITitleOD>`
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  line-height: 20px;
  width: ${({ isDetails }) => (isDetails ? '116px' : '155px')};
  text-align: start;
  margin-bottom: ${({ isDetails }) => (isDetails ? '10px' : 0)};
`;

export const Input = styled.input`
  width: 600px;

  border: none;
  outline: none;
  padding: 4px 5px;
  color: ${COLORS.black};
  background: transparent;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-weight: ${WEIGHTS.light};
  line-height: 24px;
  &::placeholder {
    opacity: 1;
    color: #696969;
  }
  :focus-visible {
    outline: 0;
  }
`;

export const TitleDetails = styled('h3')<ITitleOD>`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.l};
  font-weight: ${WEIGHTS.normal};
  line-height: 20px;
  letter-spacing: 0.32px;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  height: 34px;
  border-radius: 20px;
  padding: 7px 15px 7px 15px;
  color: ${COLORS.white};
  background-color: ${COLORS.black};
  border: none;
  cursor: pointer;
`;

// export const OptionalTitleContainer = styled(SummaryTitleContainer)``;
export const OptionalTitleContainer = styled.div`
  height: 26px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
`;

export const ContMemoRadioOptional = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WrapperLink = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadDocumentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 530px;
`;

export const UploadDocumentContainer = styled.div<{ isCurrent: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UploadDocumentButton = styled.button<{ isFileForProcessing?: boolean; isSubmitPage?: boolean }>`
  color: #696969;
  ${({ isFileForProcessing }) => isFileForProcessing && 'padding: 0;color: #000;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  padding: 4px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;

  ${({ isSubmitPage }) => isSubmitPage && 'font-size: 15px;'}
`;

export const SelectedDocumentText = styled(UploadDocumentButton)<{ isSubmitPage?: boolean }>`
  color: ${COLORS.black};
  cursor: auto;
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isSubmitPage }) => isSubmitPage && 'font-size: 15px;'}
`;

export const DeleteUploadedDocumentButton = styled(UploadDocumentButton)`
  display: flex;
  align-items: center;
  padding-left: 0px;
  color: #8e8e8e;
`;
