import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { PostButtonColor } from './post-button.type';

export const PostButtonCommonStyle = css`
  cursor: pointer;
  border: none;
  padding: 7px 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:disabled {
    cursor: auto;
    color: #ccc;
    background: rgba(237, 237, 237, 0.50);

  }
`;

export const PostButton = styled.button<{ color: PostButtonColor; borderRadius?: number }>`
  ${PostButtonCommonStyle}
  background: #494949;
  color: #fff;
  ${({ color }) => (color === 'bear' ? 'background: #E4D7F3; color: #5400BD;' : '')}
  ${({ color }) => (color === 'bull' ? 'background: rgba(87, 206, 199, 0.31); color: #006F68;' : '')}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px`}
`;

export const ButtonImage = styled(Image as AnyStyledComponent)`
  width: 20px;
  height: 20px;
`;
