import React from 'react';
import * as Styled from '../information-block-defalut.styled';

export const RealInformationBlock = () => (
  <Styled.InformationBlockWrapper>
    <Styled.InformationBlockTopic>
      <h3>What is Mode Real</h3>
      <p>Real accounts are the real profiles of users</p>
    </Styled.InformationBlockTopic>
    <Styled.InformationBlockTopic>
      <h3>Zero-Trust Approach to your Identity</h3>
      <p>
        We designed Mode Anon so that nobody, <strong>not even us</strong>, can ever identify the owner of any anonymous account
      </p>
      <p>
        The connection between your real account and anonymous account is end-to-end encrypted by a password you will create on the right
      </p>
      <p>We do not store your password anywhere or at any time, but you can recover it via your email address</p>
      <p>
        Even during this current process of creating your real account via your anonymous account, we do not establish any identifiable
        links between the accounts, ensuring complete anonymity at all times
      </p>
      <p>We never log your IP address in either Mode Real or Mode Anon to keep each account clearly isolated</p>
    </Styled.InformationBlockTopic>
  </Styled.InformationBlockWrapper>
);
