import styled from 'styled-components';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const NewKeywordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: ${FAMILIES.robotoFlex};
  & > div {
    & > h5 {
      color: #3a3a3a;
      font-size: ${SIZES.m};
      font-style: normal;
      font-weight: ${WEIGHTS.normal};
      line-height: ${SIZES.xxl};
    }
    & > p {
      padding: 5px 0px 3px 0px;
      color: #5a5a5a;
      font-size: ${SIZES.s};
      font-style: normal;
      font-weight: ${WEIGHTS.light};
      line-height: ${SIZES.s};
    }
  }
  & > button {
    font-family: ${FAMILIES.robotoFlex};
    color: #7900a4;
    font-size: ${SIZES.m};
    font-style: normal;
    padding: 10px 0px;
    font-weight: ${WEIGHTS.light};
    line-height: ${SIZES.m};
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: none;
    background-color: transparent;
    &:disabled {
      color: rgba(121, 0, 164, 0.2);
    }
  }
  & > p {
    color: #df0000;
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.xxl};
  }
`;

export const KeywordAndErrorWrapper = styled.div<{ isHasValue: boolean }>`
  display: flex;
  gap: 10px;
  & > input {
    font-family: ${FAMILIES.robotoFlex};
    color: ${COLORS.black};
    width: 100%;
    ${({ isHasValue }) => isHasValue && 'width: 199px;'}
    border: none;
    outline: none;
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.medium};
    line-height: ${SIZES.xxl};
    letter-spacing: 2.1px;
    background-color: transparent;
    &::placeholder {
      color: ${COLORS.black};
      letter-spacing: 0.42px;
      opacity: 1;
    }
    :focus-visible {
      outline: 0;
    }
  }
  & > span {
    color: #df0000;
    font-family: ${FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: ${WEIGHTS.light};
    line-height: 12px;
    padding: 5px 0 3px 0;
  }
`;
