import styled from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { MentionInputComponent } from '../post/components/mention-input';
import { NewPostBasicInfoComponent } from '../post/components/post-basic-info';
import { ModalComponent } from '../modal';
import { PostButtonCommonStyle } from '../modal/components/post-button';
import { FAMILIES } from '@/theme/fonts.const';

export const NewMusingModalWrapper = styled(ModalComponent)`
  padding: 30px 50px 18px;
  min-width: 602px;
  max-width: 602px;
  min-height: calc(100vh - 30px);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
`;

export const ModalTitle = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #000;
  margin-bottom: 30px;
`;

export const PostBasicInfo = styled(NewPostBasicInfoComponent)`
  margin-bottom: 14px;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 40px;
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const CloseButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const InputArea = styled(MentionInputComponent)`
  & > div:first-child {
    line-height: 22px;
    min-height: 22px;
    font-size: ${FONTS.SIZES.m};
  }
`;

export const TextAndImageWrapper = styled.div<{ isCoverage?: boolean }>`
  margin-bottom: 11px;
  margin-top: ${({ isCoverage }) => (isCoverage ? '5px' : 0)};
  margin-left: ${({ isCoverage }) => (isCoverage ? '10px' : 0)};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PostActionsDivider = styled.div`
  width: 540px;
  margin-left: -40px;
  height: 1px;
  background: linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 94%, #fff 94.05%);
  margin-bottom: 20px;
`;

export const PostActionsReplyDivider = styled.div<{ isCoverage?: boolean }>`
  width: 540px;
  margin-left: -80px;
  height: 1px;
  background: linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 94%, #fff 94.05%);
  margin-bottom: 20px;

  ${({ isCoverage }) =>
    isCoverage &&
    `
    width: 500px;
    margin-left: -30px;
    `}
`;

export const PostActionsContainer = styled.div<{ isCoverage?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ isCoverage }) => (isCoverage ? '20px' : '17px')};
`;

export const AttachImage = styled(Image)<{ isCoverage?: boolean }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: ${({ isCoverage }) => (isCoverage ? '10px' : 0)};
`;

export const CloseImage = styled(Image)`
  cursor: pointer;
  align-self: center;
  width: 10px;
  height: 10px;
`;

export const ModalActionsContainer = styled.div<{ mt?: number; gap?: number }>`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : '10px')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '15px')};
`;

export const AddReplyButton = styled.button<{ isFirst: boolean }>`
  ${PostButtonCommonStyle}
  height: 34px;
  padding: 7px 11px;
  font-weight: ${FONTS.WEIGHTS.medium};
  background: #f0f0f0;
  border: 1px solid #e1e1e1;
  color: #000;
  justify-content: center;
  border-radius: 8px;
  &:disabled {
    padding: 7px 12px;
    border: none;
  }
`;

export const AddReplyButtonNew = styled.button<{ isFirst: boolean }>`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  min-width: 103px;
  height: 40px;
  display: flex;
  white-space: nowrap;
  padding: 11px 11px 11px 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
  border-radius: 15px;
  border: 1px solid #e1e1e1;
  background: #f0f0f0;
  &:disabled {
    color: #ccc;
    border: none;
  }
`;

export const AddReplyButtonImage = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const PrivatePostButton = styled.button<{ isPrivate: boolean }>`
  border: none;
  cursor: pointer;
  display: flex;
  padding: 7px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ isPrivate }) => (isPrivate ? '#7900A4' : '#0086a4')};
  background: ${({ isPrivate }) => (isPrivate ? 'rgba(121, 0, 164, 0.10)' : 'rgba(0, 134, 164, 0.1)')};
`;

export const PrivatePostButtonImage = styled(Image)`
  display: flex;
  padding: 3px;
  width: 20px;
  height: 20px;
`;

export const RepliesContainer = styled.div`
  padding-left: 40px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const NewPostReplyContainer = styled.div<{ isCoverageList?: boolean; isFirst?: boolean }>`
  ${({ isCoverageList, isFirst }) => isCoverageList && !isFirst && 'margin-top: -4px'}
`;
