export const getRangeOfYearsStart = (counter: number, date?: Date | string | null) => {
  const n = 10;
  let from = new Date().getFullYear() - n * counter;
  const years = [];
  const dateLocal = typeof date === 'string' ? new Date(date) : date;
  const minYear = 1920;

  if (dateLocal) {
    from = dateLocal.getFullYear() - n * counter;
    const to = from - n;
    while (from > to) {
      if (from >= minYear) {
        years.push(from);
      }
      from -= 1;
    }
  } else {
    const to = from - n;
    while (from > to) {
      if (from >= minYear) {
        years.push(from);
      }
      from -= 1;
    }
  }

  return years;
};

export const getRangeOfYearsStartPoll = () => {
  const n = 2;
  const today = new Date();
  let todayYear = today.getFullYear();
  const to = todayYear + n;
  const years: number[] = [];

  while (to >= todayYear) {
    years.push(todayYear);
    todayYear += 1;
  }

  return years;
};
