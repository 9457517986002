import React, { useEffect } from 'react';
import Link from 'next/link';
import { Stack } from '@mui/material';
import UserConnectionButton from './components/user-connection-button';
import { BUTTON_TEXT } from './conts/likes-claps-reposts.conts';
import { useCreateFollow, useCreateConnection, useUpdateProfileInfoInActionsModal } from '@/common/hooks';
import { IPostStatResponseItem } from '@/common/types/post.type';
import * as Styled from './likes-claps-praises-modal.styled';
import PrivateUser from '@/styles/icons/private-user-logo.webp';

interface IProps {
  item: IPostStatResponseItem['data'][0];
  postId: string;
  currentId: string;
  isFolowClick: boolean;
  setFollowClick: (item: string) => void;
  deleteFollowClick: (item: string) => void;
  isConnectClick: boolean;
  isAnon: boolean;
  setConnectClick: (item: string) => void;
  deleteConnectClick: (item: string) => void;
  index: number;
}

export const LikeClapPraiseComponent = ({
  item,
  postId,
  currentId,
  isFolowClick,
  setFollowClick,
  deleteFollowClick,
  isConnectClick,
  isAnon,
  setConnectClick,
  deleteConnectClick,
  index
}: IProps) => {
  const {
    id,
    userId,
    imageUrl,
    fullName,
    vertical,
    role,
    follower,
    following,
    followingRequestSent,
    followingRequestIgnored,
    connected,
    connectionRequestSent,
    connectionRequestIgnored,
    isImageHidden
  } = item;

  const { mutateAsync: updateItem, isLoading: isUpdateLoading } = useUpdateProfileInfoInActionsModal(postId);

  const onSuccess = () => {
    updateItem(id);
  };

  const { mutate: followById, isLoading: isFollowCreating } = useCreateFollow(onSuccess);
  const { mutate: connectById, isLoading: isConnectionCreating } = useCreateConnection(onSuccess);

  useEffect(() => {
    if (!isFollowCreating && !isUpdateLoading) deleteFollowClick(id);
    if (isFollowCreating) setFollowClick(id);
    if (!isConnectionCreating && !isUpdateLoading) deleteConnectClick(id);
    if (isConnectionCreating) setConnectClick(id);
  }, [isFollowCreating, isUpdateLoading]);

  const getUserFollowingState = (): string => {
    switch (true) {
      case connected:
        return BUTTON_TEXT.MUTUAL_FOLLOWERS;
      case following && follower:
        return BUTTON_TEXT.MUTUAL_FOLLOWERS;
      case followingRequestSent:
        return BUTTON_TEXT.FOLLOW_SENT;
      case following && !follower && !followingRequestSent:
        return BUTTON_TEXT.FOLLOWING;
      case follower:
        return BUTTON_TEXT.FOLLOW_BACK;
      default:
        return BUTTON_TEXT.FOLLOW;
    }
  };

  const getUserConnectionState = (): string => {
    switch (true) {
      case connected:
        return BUTTON_TEXT.CONNECTED;
      case !connected && connectionRequestSent:
        return BUTTON_TEXT.CONNECTION_SENT;
      default:
        return BUTTON_TEXT.CONNECT;
    }
  };

  return (
    <Styled.ActionUser key={id} index={!!index}>
      <Styled.UserInfo>
        <Link href={`profile?id=${id}`}>
          <div>
            {!isImageHidden ? (
              <Styled.ImageWrapper imageUrl={imageUrl} borderRadius="30px" />
            ) : (
              <Styled.HiddenImage>
                <img src={PrivateUser.src} alt={fullName} />
              </Styled.HiddenImage>
            )}
            <Stack gap="4px">
              <Styled.Username>{fullName}</Styled.Username>
              <Styled.Role>
                {vertical}
                {role && ` ${role}`}
              </Styled.Role>
            </Stack>
          </div>
        </Link>
        {currentId !== userId && (
          <Styled.UserConnectionButtons>
            <UserConnectionButton
              active={!isFolowClick && !following && !followingRequestSent && !followingRequestIgnored}
              disabled={followingRequestIgnored || followingRequestSent}
              onClick={() => followById({ targetId: userId })}
            >
              {getUserFollowingState()}
            </UserConnectionButton>
            {!isAnon && (
              <UserConnectionButton
                active={!isConnectClick && !connected && !connectionRequestSent && !connectionRequestIgnored}
                disabled={connectionRequestIgnored || connectionRequestSent}
                onClick={() => connectById({ targetId: userId })}
              >
                {getUserConnectionState()}
              </UserConnectionButton>
            )}
          </Styled.UserConnectionButtons>
        )}
      </Styled.UserInfo>
    </Styled.ActionUser>
  );
};
