import styled from 'styled-components';
import { InputLabel, InputText } from '../ui/common-styles';

export const KeywordInputWrapper = styled.div`
  display: flex;
  max-width: 568px;
  flex-direction: column;
  gap: 10px;
`;

interface IKeywordInputInfoProps {
  $isKeywordCheck?: boolean;
}

export const KeywordInputInfo = styled.div<IKeywordInputInfoProps>`
  ${InputLabel}
  ${({ $isKeywordCheck }) =>
    $isKeywordCheck &&
    `display: grid;
     grid-template-columns: 130px 155px;
     gap: 40px;
     span {
      cursor:pointer;
     }`}
`;

export const KeywordInput = styled.input`
  ${InputText}
`;
