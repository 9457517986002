import styled, { css } from 'styled-components';

export const RightColumnWrapper = styled.div`
  width: fit-content;
  padding: 88px 80px 88px 50px;
  height: 100vh;
  background-color: #fbfbfb;

  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const RightColumnStyle = css`
  width: fit-content;
  padding: 88px 80px 88px 50px;
  height: 100vh;
  // background-color: #fbfbfb;

  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
