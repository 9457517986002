import styled from 'styled-components';
import { FONTS } from '@/theme';

export const SwingsTitle = styled.div<{ isQuote?: boolean }>`
  display: inline;
  align-items: start;
  color: #000;
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 1px;
  white-space: pre-wrap;
`;

export const HighlightedTitle = styled.p`
  display: inline;
  font-weight: ${FONTS.WEIGHTS.semiBold};
`;

export const SwingsSubtitle = styled.span<{ isQuote?: boolean }>`
  color: #0018f1;
  ${({ isQuote }) => isQuote && 'color: rgba(0, 24, 241, 0.70);'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: nowrap;
  cursor: pointer;
`;
