/* eslint-disable import/no-cycle */
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { clapService } from '../services/clap.service';
import { setPostStatistics, updateAdorationPost } from '../utils/post-statistics';
import { IPostStatResponseItem } from '../types/post.type';
import { useUser } from './use-user';

export const useClap = (customCallback?: () => void) => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (postId: string) => clapService.clap(postId),
    onSuccess: (data) => {
      if (customCallback) {
        customCallback();
        return;
      }

      if (!data) return;
      setPostStatistics(data.statistic, queryClient);
      updateAdorationPost(data.post, queryClient, user?.profile?.id);
    }
  });
};

export const useAllClapsOnPost = (postId: string, enabled: boolean) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery(
    [QUERY_KEYS.POST_CLAPS, postId],
    () => queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_CLAPS, postId]),
    { enabled }
  );
  return {
    allData: posts,
    setAllData: (data: IPostStatResponseItem['data'], newPostId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.POST_CLAPS, postId ?? newPostId], data);
      queryClient.invalidateQueries([QUERY_KEYS.POST_CLAPS, postId ?? newPostId]);
    },
    setNewData: (data: IPostStatResponseItem['data'], newPostId: string, isBefore?: boolean) => {
      const old = queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_CLAPS, postId ?? newPostId]);
      const filtered = (old ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newData = [...(isBefore ? data : []), ...(filtered ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.POST_CLAPS, postId ?? newPostId], newData);
      queryClient.invalidateQueries([QUERY_KEYS.POST_CLAPS, postId ?? newPostId]);
    },
    setUpdateData: (data: IPostStatResponseItem['data'][0], newId: string) => {
      const oldData = queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_CLAPS, newId]);
      const newData = (oldData ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([QUERY_KEYS.POST_CLAPS, newId], newData);
      queryClient.invalidateQueries([QUERY_KEYS.POST_CLAPS, newId]);
    }
  };
};

export const useClapsByPostId = (id: string, take: number) => {
  const { setAllData, setNewData } = useAllClapsOnPost(id, false);
  const {
    data: pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch
  } = useInfiniteQuery([QUERY_KEYS.POST_CLAPS, id, take], ({ pageParam = 0 }) => clapService.getPostClaps(id, take, pageParam + 1), {
    enabled: false,
    getNextPageParam(lastPage, allPages) {
      if (allPages.length * Number(take) >= (Number(lastPage?.count) ?? 0)) return;
      return allPages.length;
    },
    onSuccess: (data) => {
      if (!data) return;
      const pageNumber = data.pages.length;
      const lastPage = data.pages[pageNumber - 1];
      if (!lastPage) return;
      const { data: clapsData } = lastPage;
      if (pageNumber === 1) {
        setAllData(clapsData, id);
        return;
      }
      setNewData(clapsData, id);
    }
  });

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch,
    count: Number(pages?.pages?.at(0)?.count) || 0,
    metaData: pages?.pages[pages?.pages && pages.pages.length > 0 ? pages.pages.length - 1 : 0]?.metaData
  };
};
