import React from 'react';
import { IdeaHorizon } from '@/common/types';
import * as Styled from './post-idea.styled';
import { horizonText } from '@/common/consts/idea.const';

interface IPostIdeaComponentProps {
  text: string;
  postText?: string;
  horizon: IdeaHorizon;
  reasons: string[];
  priority: number;
  isPostTile?: boolean;
  isQuotePost?: boolean;
  isFaded?: boolean;
  isAnon?: boolean;
  onClickByPostText?: () => void;
}

export const PostIdeaComponent = ({
  text,
  postText,
  horizon,
  reasons,
  priority,
  isPostTile,
  isQuotePost,
  isFaded,
  isAnon,
  onClickByPostText
}: IPostIdeaComponentProps) => {
  const onClick = () => {
    if (onClickByPostText) {
      onClickByPostText();
    }
  };

  const postTextForOutput = postText?.startsWith('Idea ') && (postText?.length ?? 0) > 5 ? postText.slice(5) : '';

  return (
    <Styled.PostIdeaWrapper>
      <Styled.IdeaTextAndHorizon>
        {isPostTile && (
          <Styled.IdeaTextTitle isClicable={!!onClickByPostText} onClick={onClick} isQuote={isQuotePost} isFaded={isFaded}>
            coverage
          </Styled.IdeaTextTitle>
        )}
        <Styled.IdeaText
          isHasHirazen={horizon !== IdeaHorizon.SKIP}
          isClicable={!!onClickByPostText}
          onClick={onClick}
          priority={priority}
          isQuote={isQuotePost}
          isFaded={isFaded}
        >
          {text}
        </Styled.IdeaText>
        {horizon !== IdeaHorizon.SKIP && <Styled.IdeaDot />}
        {horizon !== IdeaHorizon.SKIP && (
          <Styled.IdeaHorizon onClick={onClick} isQuote={isQuotePost} isFaded={isFaded}>
            {`${horizonText[horizon]} month view`}
          </Styled.IdeaHorizon>
        )}
      </Styled.IdeaTextAndHorizon>
      {!!reasons?.length && (
        <Styled.ReasonsList>
          {reasons?.map((reason) => (
            <Styled.ReasonItem isQuote={false} isFaded={isFaded} key={reason} priority={priority}>
              {reason}
            </Styled.ReasonItem>
          ))}
        </Styled.ReasonsList>
      )}
      {postTextForOutput && (
        <Styled.IdeaOutputText isAnon={!!isAnon} isFaded={isFaded}>
          {postTextForOutput}
        </Styled.IdeaOutputText>
      )}
    </Styled.PostIdeaWrapper>
  );
};
