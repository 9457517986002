import styled from 'styled-components';
import Image from 'next/image';
import { MentionInputComponent } from '@/common/components/post/components/mention-input';
import { NewPostBasicInfoComponent } from '@/common/components/post/components/post-basic-info';
import { COLORS, FONTS } from '@/theme';

export const InputWithImage = styled.div<{ isNotEditable: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  ${({ isNotEditable }) => isNotEditable && 'gap: 0;'}
`;

export const InputIsNotEditable = styled.div`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
  white-space: pre-wrap;
`;

export const PostBasicInfo = styled(NewPostBasicInfoComponent)`
  margin-bottom: 8px;
`;

export const MainInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
`;

export const ReviewInput = styled(MentionInputComponent)`
  width: 100%;
`;

export const PostActionsContainer = styled.div<{ haveReplie?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  ${({ haveReplie }) =>
    haveReplie &&
    `
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        width: 470px;
        height: 1px;
        background-color: #F5F5F5;
      }
    `}
`;

export const AttachImage = styled(Image)`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
