import styled from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES, FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { ModalComponent } from '@/common/components/modal';
import { FormType } from '@/common/types/anon.types';

export const StyledModal = styled(ModalComponent)`
  padding-top: 40px;
  width: 100vw;
  display: flex;
  align-items: center;

  flex-direction: column;
  height: 100vh;
  gap: 20px;
`;

const modalBaseStyles = `
  max-width: 662px;
  background: ${COLORS.anonActiveModalInformationBackground};
  border: 1px solid #eaeaea;
  border-radius: ${SIZES.xxl};
  display: flex;
  flex-direction: column;
  padding: 25px 36px 0px 36px;
`;

export const ModalAnonOnboardingWrapper = styled.div`
  ${modalBaseStyles}
  border: none;
  padding-bottom: 25px;
`;

export const ModalWrapper = styled.div<{formType?: string}>`
    max-width: 550px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: ${({ formType }) => (formType ? '20px 30px' : '20px 45px 15px 45px')};
    box-shadow: 0px 3px 8px 3px rgba(237, 237, 237, 0.90);
`;

export const ModalDivider = styled.div<{formType?: FormType}>`
    width: ${({ formType }) => (formType ? '460px' : '490px')};
    height: 1px;
    background: #EEE;
    margin: 25px 0;
`

export const AnonymityActionsOptions = styled.div`
 display: flex;
 gap: 10px;
 align-items: center;
 padding-top: 4px;
 padding-bottom: 10px;
`

export const AnonymityActionsOption = styled.div`
    color: #90009C;
    font-family: "Roboto Flex";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
`

export const AnonymityActionsOptionText = styled.div`
    color: #000;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; 
`

export const ConnectoAccountModalWrapper = styled.div`
  display: flex;
  max-width: 1100px;
  gap: 40px 20px;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const AnonymityActionsRequireText = styled.div`
    color: #6C6C6C;
    margin-top: 4px;
    font-family: "Roboto Flex";
    font-size: 12px;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-left: 76px;
`

export const FormContainer = styled.div`
  max-width: 662px;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

interface IInputsContainerProps {
  $isFullRounded: boolean;
  $isRealModal?: boolean;
  $isKeywordRecovery?: boolean;
}

export const InputsContainer = styled.div<IInputsContainerProps>`
  border-radius: ${({ $isFullRounded }) => ($isFullRounded ? `${SIZES.xxl}` : `${SIZES.xxl} ${SIZES.xxl} 0 0`)};
  background: ${COLORS.anonActiveModalEmailInputBackground};
  padding: 25px 42px 25px 30px;
  ${({ $isKeywordRecovery }) => $isKeywordRecovery && 'padding-bottom:20px;'}

  display: flex;
  flex-direction: column;
  gap: 25px;

  > *:not(:first-child):not(:nth-child(2)) {
    margin-top: 5px;
  }

  h3 {
    color: ${({ $isRealModal }) => ($isRealModal ? COLORS.realActivateModalTextColorPrimary : COLORS.anonActiveModalEmailTextColorPrimary)};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.l};
    font-style: normal;
    font-weight: ${WEIGHTS.semiBold};
    line-height: ${SIZES.l};
    letter-spacing: 0.48px;
  }
`;

export const EmailVerification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const AnonymityActionsBlockContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 5px;

   button {
    margin-left: 60px;
   }
`;
