import React, { useEffect, useMemo, useState } from 'react';

import Image from 'next/image';
import EmailVerifyIcon from '@styles/icons/anon-activate-modal-verify-email-icon.webp';
import EmailVerifyIconDisabled from '@styles/icons/anon-activate-modal-verify-email-icon-disabled.webp';
import VerifyButtonArrow from '@styles/icons/switch-to-anon-verify-button-arrow.webp';
import VerifyButtonArrowDisabled from '@styles/icons/switch-to-anon-verify-button-arrow-disabled.webp';
import CreateAnonIcon from '@styles/icons/anon-activate-modal-create-icon.webp';

import * as Styled from '../modal-default.styled';
import { KeywordAndErrorWrapper, NewKeywordWrapper } from './connect-real-to-anon.styled';

import { ERROR_MESSAGES } from '../../anon.consts';

import { useAnonLinkReal, useAnonVerifyConfirm, useAnonVerifySend } from '@/common/hooks/use-anon';
import { CloseButton, SubmitButton } from '../../ui';
import { ConnectRealToAnonInformationBlockComponent, EmailInputComponent, VerifcationCodeInputComponent } from '../../index';

import { getConnectRealToAnonButtonDisplayCondition, getIsEmailValid, getIsKeywordValid } from '../../../helpers/validation.helper';
import { SlideType } from '@/common/types/modal.type';
import { CheckKeywordInput } from '../../check-keyword-input/check-keyword-input.component';
import { useAnonSignIn, useResetAnonKeyword } from '@/common/hooks/use-auth';

interface IConnectRealToAnonModalProps {
  open: boolean;
  closeHandler: () => void;
}

export const ConnectRealToAnonModal = ({ open, closeHandler }: IConnectRealToAnonModalProps) => {
  // states
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [verifyCodeError, setVerifyCodeError] = useState<string>('');

  const [keyword, setKeyword] = useState<string>('');
  const [keywordError, setKeywordError] = useState<string>('');

  const [firstKeyword, setFirstKeyword] = useState<string>('');
  const [secondKeyword, setSecondKeyword] = useState<string>('');

  const [resetKeywordStatus, setResetKeywordStatus] = useState<boolean>(false);

  // queries
  const {
    data: verifyCodeSentStatus,
    mutateAsync: emailCharactersVerifySend,
    isLoading: charactersSendIsLoading,
    isError: charactersSendIsError,
    reset: emailVerifySendReset
  } = useAnonVerifySend();

  const {
    data: verifyCodeConfirmStatus,
    mutateAsync: emailCharactersConfirmSend,
    isLoading: charactersConfirmIsLoading,
    isError: charactersConfirmIsError,
    reset: emailVerifyConfirmReset
  } = useAnonVerifyConfirm();

  const {
    data: linkAnonToRealStatus,
    mutateAsync: linkAnonToRealSend,
    isError: linkAnonToRealIsError,
    isLoading: linkAnonToRealIsLoading,
    reset: linkAnonToRealSendReset
  } = useAnonLinkReal();

  const { mutateAsync: anonSingIn } = useAnonSignIn(() => {});

  const { mutateAsync: resetAnonKeyword, isLoading: isResetingAnonKeyword } = useResetAnonKeyword(
    () => {
      closeHandler();
    },
    () => {}
  );

  // functions

  const sendEmailWithVerifycationCode = (): void => {
    const isEmailValid = getIsEmailValid(email);
    if (isEmailValid) {
      emailCharactersVerifySend({ email });

      return;
    }
    setEmailError(ERROR_MESSAGES.INVALID_ENTRY);
  };

  const sendVerificationCode = (code: string): void => {
    emailCharactersConfirmSend({ email: email.trim(), code });
  };

  const sendLinkToReal = () => {
    linkAnonToRealSend({ keyword });
  };

  const resetComponent = (): void => {
    setEmail('');
    setEmailError('');
    setKeyword('');
    setKeywordError('');
    setFirstKeyword('');
    setSecondKeyword('');
    setResetKeywordStatus(false);
    emailVerifySendReset();
    emailVerifyConfirmReset();
    linkAnonToRealSendReset();
  };

  // renders effects
  useEffect(() => {
    resetComponent();
  }, [open]);

  useEffect(() => {
    if (charactersSendIsError) {
      setEmailError(ERROR_MESSAGES.ANON_WRONG_EMAIL);
    }
  }, [charactersSendIsLoading]);

  useEffect(() => {
    const keywordDelayTimer = setTimeout(() => {
      const isKeywordValid = getIsKeywordValid(keyword);
      if (!isKeywordValid) {
        setKeywordError(ERROR_MESSAGES.INVALID_ENTRY);
      }
    }, 500);

    return () => clearTimeout(keywordDelayTimer);
  }, [keyword]);

  useEffect(() => {
    if (linkAnonToRealIsError) {
      setKeywordError(ERROR_MESSAGES.INVALID_ENTRY);
    }
  }, [linkAnonToRealIsLoading]);

  useEffect(() => {
    if (charactersConfirmIsError) {
      setVerifyCodeError(ERROR_MESSAGES.WRONG_VERIFY_CODE_EMAIL);
    }
  }, [charactersConfirmIsLoading]);

  // memoization

  const isKeywordMatch = useMemo(
    () => getIsKeywordValid(firstKeyword, true) && getIsKeywordValid(secondKeyword),
    [firstKeyword, secondKeyword]
  );
  const isFirstKeywordMatch = useMemo(() => getIsKeywordValid(firstKeyword, true), [firstKeyword]);

  const isConfirmNewKeywordDisabled = useMemo(
    () => !isKeywordMatch || firstKeyword !== secondKeyword || isResetingAnonKeyword,
    [firstKeyword, secondKeyword, isKeywordMatch, isResetingAnonKeyword]
  );

  // hadlers for events
  const handleEmailKeyDownVerifyEmailInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      sendEmailWithVerifycationCode();
    }
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
    setEmailError('');
    setVerifyCodeError('');
  };

  const handelOtpFinish = (code: string): void => {
    sendVerificationCode(code);
    setVerifyCodeError('');
  };

  const hadleKeywordInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyword(event.target.value);
    setKeywordError('');
  };

  const handleVerifyEmailErrorClick = (): void => {
    setEmail('');
    setEmailError('');
    emailVerifySendReset();
    emailVerifyConfirmReset();
  };

  const handleResetPassword = (): void => {
    setResetKeywordStatus(true);
  };

  const handleConfirmResetkeywordClick = () => {
    if (isConfirmNewKeywordDisabled) return;
    resetAnonKeyword({ email, keyword: firstKeyword, keywordConfirm: secondKeyword });
  };

  const handleKeywordFinish = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      sendLinkToReal();
    }
  };

  const handleSuccessfullLinkedClick = () => {
    anonSingIn(keyword);
    closeHandler();
  };
  return (
    <Styled.StyledModal
      open={open}
      closeHandler={closeHandler}
      isCenter
      slideType={SlideType.BOTTOM}
      openTimeout={800}
      closeTimeout={600}
      hideChildBackground
      hideChildeShadow
      hideBackdrop
      hideBorder
      childTopPostion={0}
      isUserModeSwitchModal
    >
      <Styled.ConnectoAccountModalWrapper>
        <ConnectRealToAnonInformationBlockComponent />
        <Styled.FormContainer>
          <Styled.InputsContainer
            $isFullRounded={(!!verifyCodeSentStatus && !verifyCodeConfirmStatus) || resetKeywordStatus}
            $isKeywordRecovery={resetKeywordStatus}
          >
            <h3>Establish End-to-End Encrypted Link between Accounts</h3>
            <Styled.EmailVerification>
              <input hidden autoComplete="username" value={email} />
              <EmailInputComponent
                isSpanPurple={!verifyCodeSentStatus && !verifyCodeConfirmStatus}
                disabled={!!verifyCodeSentStatus}
                onChange={handleEmailInput}
                onKeyDown={handleEmailKeyDownVerifyEmailInput}
                error={{ errorText: emailError, isError: !!emailError }}
                autoFocus
                value={email}
                isLinkModal
              />
              {!!verifyCodeSentStatus && (
                <VerifcationCodeInputComponent
                  disabled={!!verifyCodeConfirmStatus}
                  onFinish={handelOtpFinish}
                  onErrorClick={handleVerifyEmailErrorClick}
                  error={{ errorText: verifyCodeError, isError: !!verifyCodeError }}
                  clickable
                />
              )}
              {resetKeywordStatus && (
                <NewKeywordWrapper>
                  <div>
                    <h5>Create a New Password</h5>
                    <p>8 - 20 characters</p>
                    <p>Allowed: numbers, letters, certain specials ( ! , £ , $ , # )</p>
                  </div>
                  <KeywordAndErrorWrapper isHasValue={!!firstKeyword}>
                    <input
                      placeholder="type new password here"
                      type="password"
                      value={firstKeyword}
                      onChange={(e) => setFirstKeyword(e.target.value)}
                      autoComplete="new-password"
                      onKeyDown={(e) => e.key === 'Enter' && handleConfirmResetkeywordClick()}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                    {!!firstKeyword && !isFirstKeywordMatch && <span>Invalid entry</span>}
                  </KeywordAndErrorWrapper>

                  <KeywordAndErrorWrapper isHasValue={!!secondKeyword}>
                    <input
                      placeholder="type new password again to confirm"
                      type="password"
                      value={secondKeyword}
                      onKeyDown={(e) => e.key === 'Enter' && handleConfirmResetkeywordClick()}
                      onChange={(e) => setSecondKeyword(e.target.value)}
                    />
                    {!!secondKeyword && firstKeyword !== secondKeyword && <span>Passwords do not match</span>}
                  </KeywordAndErrorWrapper>
                  <button disabled={isConfirmNewKeywordDisabled} onClick={handleConfirmResetkeywordClick} type="button">
                    <span>confirm new password</span>
                    <Image
                      src={isConfirmNewKeywordDisabled ? VerifyButtonArrowDisabled : VerifyButtonArrow}
                      width={10}
                      height={10}
                      alt="confirm new keyword"
                    />
                  </button>
                </NewKeywordWrapper>
              )}
            </Styled.EmailVerification>
            {!!verifyCodeConfirmStatus && !resetKeywordStatus && (
              <CheckKeywordInput
                onChange={hadleKeywordInput}
                onKeyDown={handleKeywordFinish}
                onReset={handleResetPassword}
                error={{ errorText: keywordError, isError: keywordError.length > 0 }}
              />
            )}
          </Styled.InputsContainer>

          {!verifyCodeSentStatus && !resetKeywordStatus && (
            <SubmitButton isActive={getIsEmailValid(email)} onClick={() => sendEmailWithVerifycationCode()}>
              Verify email
              <Image src={getIsEmailValid(email) ? EmailVerifyIcon : EmailVerifyIconDisabled} width={14} height={14} alt="Verify email" />
            </SubmitButton>
          )}

          {!!verifyCodeConfirmStatus && !resetKeywordStatus && !linkAnonToRealStatus && (
            <SubmitButton isActive={getConnectRealToAnonButtonDisplayCondition(email, keyword)} onClick={() => sendLinkToReal()}>
              Confirm
              <Image
                src={getIsKeywordValid(keyword, true) ? EmailVerifyIcon : EmailVerifyIconDisabled}
                width={14}
                height={14}
                alt="Confirm"
              />
            </SubmitButton>
          )}
          {!!linkAnonToRealStatus && (
            <SubmitButton isLinked isActive onClick={handleSuccessfullLinkedClick}>
              Your accounts are successfully and anonymously linked via end-to-end encryption
              <Image src={CreateAnonIcon} width={14} height={14} alt="Account linked" />
            </SubmitButton>
          )}
        </Styled.FormContainer>
        <CloseButton closeHandler={closeHandler} />
      </Styled.ConnectoAccountModalWrapper>
    </Styled.StyledModal>
  );
};
