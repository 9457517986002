import { EMAIL_MATCH, KEYWORD_MATCH, USERNAME_MATCH } from '../../auth/auth.const';

export const getIsEmailValid = (email: string): boolean => !!email && EMAIL_MATCH.test(email.trim());

export const getIsUsernameValid = (username: string, useReturnValueForSubmitButton: boolean = false): boolean => {
  if (useReturnValueForSubmitButton) {
    return USERNAME_MATCH.test(username);
  }
  return username.length === 0 || USERNAME_MATCH.test(username);
};

export const getIsKeywordValid = (keyword: string, useReturnValueForSubmitButton: boolean = false): boolean => {
  if (useReturnValueForSubmitButton) {
    return KEYWORD_MATCH.test(keyword);
  }
  return keyword.length === 0 || KEYWORD_MATCH.test(keyword);
};

export const getIsKeywordCkeckValid = (keyword: string, keywordCheck: string): boolean => keyword.trim() === keywordCheck.trim();

export const getCheckAllFieldFilled = (...args: string[]): boolean => args.every((arg) => !!arg);

export const getCreateAnonButtonDisplayCondition = (email: string, username: string, keyword: string, keywordCheck: string): boolean => {
  switch (true) {
    case !getIsEmailValid(email):
      return false;
    case !getIsUsernameValid(username, true):
      return false;
    case !getIsKeywordValid(keyword, true):
      return false;
    case !getIsKeywordCkeckValid(keyword, keywordCheck):
      return false;
    default:
      return true;
  }
};

export const getCreateRealButtonDisplayCondition = (email: string, keyword: string, keywordCheck: string): boolean => {
  switch (true) {
    case !getIsEmailValid(email):
      return false;
    case !getIsKeywordValid(keyword, true):
      return false;
    case !getIsKeywordCkeckValid(keyword, keywordCheck):
      return false;
    default:
      return true;
  }
};

export const getConnectRealToAnonButtonDisplayCondition = (email: string, keyword: string): boolean => {
  switch (true) {
    case !getIsEmailValid(email):
      return false;
    case !getIsKeywordValid(keyword, true):
      return false;
    default:
      return true;
  }
};
