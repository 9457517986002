import { ConvictionLevel, Direction, Disclaimer, PositionSize, Research, TimeHorizon, TitleEnum } from '../types/memo.type';

export const horizonText = {
  [TimeHorizon.MONTHS_0_6]: '0 - 6 months',
  [TimeHorizon.MONTHS_6_12]: '6 - 12 months',
  [TimeHorizon.MONTHS_12_24]: '12 - 24 months',
  [TimeHorizon.MONTHS_24_PLUS]: '24+ months'
};

export const directionText = {
  [Direction.LONG]: 'Long',
  [Direction.SHORT]: 'Short'
};

export const researchProgress = {
  [Research.LOWER_50]: '< 50%',
  [Research.BETWEEN_50_75]: '< 75%',
  [Research.OVER_75]: '< 95%'
};

export const researchProgressOnPreviewPage = {
  [Research.LOWER_50]: '<50%',
  [Research.BETWEEN_50_75]: '<75%',
  [Research.OVER_75]: '<95%'
};

export const directionOptions = [
  {
    id: Direction.LONG,
    include: false
  },
  {
    id: Direction.SHORT,
    include: false
  }
];

export const categoryOptions = [
  { id: 'Equity', include: false }
  // { id: 2, label: 'IG Credit' },
  // { id: 3, label: 'Levered Loan' },
  // { id: 4, label: 'HY Credit' },
  // { id: 5, label: 'Distressed' },
  // { id: 6, label: 'EM Credit' },
  // { id: 7, label: 'Early VC' },
  // { id: 8, label: 'Growth VC' }
];

export const marketCapOptions = [
  { id: '< 500m', min: 0, max: 500000000 },
  { id: '0.5-2b', min: 500000000, max: 2000000000 },
  { id: '2-10b', min: 2000000000, max: 10000000000 },
  { id: '10-50b', min: 10000000000, max: 50000000000 },
  { id: '50-100b', min: 50000000000, max: 100000000000 },
  { id: '> 100b', min: 100000000000, max: Number.MAX_SAFE_INTEGER }
];

export const adtvCapOptions = [
  { id: '< 10m', min: 0, max: 10000000 },
  { id: '10-50m', min: 10000000, max: 50000000 },
  { id: '50-100m', min: 50000000, max: 100000000 },
  { id: '100-500m', min: 100000000, max: 500000000 },
  { id: '> 500m', min: 500000000, max: Number.MAX_SAFE_INTEGER }
];

export const ABOUT = ['Company Description', 'Security Details', 'Financial Statements', 'Unit Economics'];

export const OPORTUNITY = ['Underappreciation', 'Catalyst 1', 'Catalyst 2', 'Catalyst 3'];

export const QUALITY = ['Moat', 'Pricing Power', 'Margins', 'ROIC'];

export const GROWTH = ['Revenue Growth', 'Margin Growth', 'Per Share Growth'];

export const STEWATDSHIP = ['Capital Allocation', 'Leadership', 'Ownership'];

export const VALUATION = ['Valuation Standalone', 'Valuation vs Peers'];

export const RISKS = [
  'Competition',
  'Disruption',
  'Execution',
  'Regulation',
  'Trends',
  'AI',
  'Supply Chain',
  'Customers',
  'M&A',
  'Leverage',
  'Macro',
  'FX',
  'China',
  'Other'
];

export const PRE_MORTEM = ['Inverse Catalyst 1', 'Inverse Catalyst 2', 'Inverse Catalyst 3'];

export const THESIS_SECTIONS_NAMES = [
  'Opportunity Summary',
  'About the Company',
  'Financial Expectations',
  'Valuation Expectations',
  'Business Quality',
  'Risks',
  'Stakeholders'
];

export const INDEX_MAP_THESIS_SECTIONS_NAMES: { [key: string]: number } = {};
THESIS_SECTIONS_NAMES.forEach((item, index) => {
  INDEX_MAP_THESIS_SECTIONS_NAMES[item] = index;
});

export const regionsObj = [
  { sortId: 1, name: 'North America', id: '3957f7e7-efa4-4321-b790-ce9ec3ba0803' },
  { sortId: 2, name: 'Europe', id: '454efdd2-2931-441b-9907-e191ad1789dd' },
  { sortId: 3, name: 'Australasia', id: '8a303331-0ceb-46f8-9c55-cfcfd804db26' },
  { sortId: 4, name: 'Japan', id: '5ecd7b3c-c2e9-41c7-9dce-9bbef43e4140' },
  { sortId: 5, name: 'Korea', id: '3c1aa62d-f7d3-438f-ad3b-d896490910d4' },
  { sortId: 6, name: 'China', id: 'ca2c0add-1f3e-4b46-a6eb-a50642b7d704' },
  { sortId: 7, name: 'India', id: '66d0d8de-15a1-4226-94aa-97f51c0334f8' },
  { sortId: 8, name: 'South + South East Asia', id: 'de4e8a62-4ab9-48dd-8a3a-621b82171913' },
  { sortId: 9, name: 'Latin America', id: 'ae61fa9d-150d-4a39-8d45-b5ad1a903dfb' },
  { sortId: 10, name: 'Middle East', id: 'd6651dc7-6f02-4d9c-ad91-2a61154b7a06' },
  { sortId: 11, name: 'Africa', id: '6a07f93d-24f4-4f6f-ba10-6ad214638516' }
];

export const sectorsObj = [
  { sortId: 1, name: 'consumer', id: '15f65a6f-4357-4994-a015-e3185f976bec' },
  { sortId: 2, name: 'technology', id: '10b32d28-cfca-4150-927a-8dc4b18db1a1' },
  { sortId: 3, name: 'telecoms + media', id: '752de7b9-04c6-4ba7-af02-a99adc58a957' },
  { sortId: 4, name: 'financials', id: '8f73d929-2d7f-4bad-a8d4-386b263576c8' },
  { sortId: 5, name: 'healthcare', id: '7fcbe61e-2f83-417f-a3a9-928bb165e90e' },
  { sortId: 6, name: 'industrials', id: '6340341d-979b-4532-8c59-718a153c83a4' },
  { sortId: 7, name: 'materials', id: 'b5010c2c-3798-429e-ae95-76850f75d74f' },
  { sortId: 8, name: 'energy + utilities', id: '3746ec85-be1d-44f4-ac2a-9f6c506f5c5d' },
  { sortId: 9, name: 'real estate', id: 'fb54e86c-6e1a-4054-8536-d4b2b1d8c139' }
];

export const positionSizeObj = {
  [PositionSize.SMALL]: 'Small',
  [PositionSize.MEDIUM]: 'Medium',
  [PositionSize.LARGE]: 'Large'
};

export const convictionLevelObj = {
  [ConvictionLevel.LOW]: 'Low',
  [ConvictionLevel.MEDIUM]: 'Medium',
  [ConvictionLevel.HIGH]: 'High'
};

export const disclaimerObj = {
  [Disclaimer.LONG]: 'Long',
  [Disclaimer.SHORT]: 'Short',
  [Disclaimer.NONE]: 'None'
};

export const themesButton = [
  {
    id: 1,
    name: 'Value'
  },
  {
    id: 2,
    name: 'SOTP'
  },
  {
    id: 4,
    name: 'GARP'
  },
  {
    id: 5,
    name: 'Growth'
  },
  {
    id: 6,
    name: 'Quality'
  },
  {
    id: 7,
    name: 'Underappreciated'
  },
  {
    id: 8,
    name: 'Misunderstood'
  },
  {
    id: 9,
    name: 'Transition'
  },
  {
    id: 10,
    name: 'Activism'
  }
];

export const LIMIT = 20;
export const LIMIT_DRAFTS = 10;

export const MaxSizeToThesis: {
  [key: string]: {
    length: number;
    string: string;
  };
} = {
  [TitleEnum.SUMMARY]: {
    length: 2000,
    string: '2'
  },
  [TitleEnum.BEAR_CASE]: {
    length: 1000,
    string: '1'
  },
  [TitleEnum.RISKS]: {
    length: 4000,
    string: '4'
  },
  [TitleEnum.EXIT_VALUATION]: {
    length: 4000,
    string: '4'
  },
  default: {
    length: 4000,
    string: '4'
  }
};

export const NO_MARKET_VALUE_TEXT = 'n/a';
