import React from 'react';
import * as Styled from './keyword-input.styled';
import { InputError } from '../modals/activate-anon-modal/activate-anon-modal.types';
import { InputContainer, Error } from '../ui/common-styles';

interface IKeywordInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  error: {
    keyword: InputError['error'];
    keywordMatch: InputError['error'];
  };
}

export const KeywordInputComponent = ({ onChange, onChangeCheck, onKeyDown, autoFocus, error }: IKeywordInputProps) => (
  <Styled.KeywordInputWrapper>
    <Styled.KeywordInputInfo>
      <p>Create a Password</p>
      <span>8 - 20 characters</span>
      <span>Allowed: numbers, letters, certain specials ( ! , £ , $ , # )</span>
    </Styled.KeywordInputInfo>
    <InputContainer>
      <Styled.KeywordInput placeholder="type here" onChange={onChange} type="password" autoFocus={autoFocus} autoComplete="new-password" />
      {error.keyword.isError && <Error>{error.keyword.errorText}</Error>}
    </InputContainer>
    <InputContainer>
      <Styled.KeywordInput placeholder="type again to confirm" onChange={onChangeCheck} onKeyDown={(e) => onKeyDown(e)} type="password" />
      {error.keywordMatch.isError && <Error>{error.keywordMatch.errorText}</Error>}
    </InputContainer>
  </Styled.KeywordInputWrapper>
);
