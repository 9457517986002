/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import * as Styled from '../keyword-input/keyword-input.styled';
import { InputError } from '../modals/activate-anon-modal/activate-anon-modal.types';
import { InputContainer, Error } from '../ui/common-styles';

interface IKeywordInputProps extends InputError {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  autoFocus?: boolean;
}

export const CheckKeywordInput = ({ onChange, onKeyDown, onReset, autoFocus, error }: IKeywordInputProps) => (
  <Styled.KeywordInputWrapper>
    <Styled.KeywordInputInfo $isKeywordCheck>
      <p>Enter your Password</p>
      <span onClick={onReset}>Click here to reset password</span>
    </Styled.KeywordInputInfo>
    <InputContainer $isCheckKeyword>
      <Styled.KeywordInput
        placeholder="type here"
        onChange={onChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
        type="password"
        autoFocus={autoFocus}
      />
      {error.isError && <Error>{error.errorText}</Error>}
    </InputContainer>
  </Styled.KeywordInputWrapper>
);
