import { useEffect } from 'react';

export const useDebounceEffect = (effect: () => void | (() => void), deps: any[], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [...deps, delay]);
};
