import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const Container = styled.div`
  display: flex;
  justify-content: start;
  width: fit-content;
  gap: 4px;
  border-radius: 10px;
`;

export const InputField = styled.input<{ isFirst?: boolean; isLast?: boolean }>`
  width: 52px;
  height: 46px;
  text-align: center;
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 20px;
  border: none;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.6px;
  border-radius: 3px;
  ${({ isFirst }) => isFirst && 'border-radius: 10px 3px 3px 10px;'}
  ${({ isLast }) => isLast && 'border-radius: 3px 10px 10px 3px;'}
  background: ${COLORS.white};
  outline: none;
  &:focus {
    outline: none;
  }
`;
