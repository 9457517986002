import React, { ReactNode } from 'react';
import { Slide } from '@mui/material';
import * as Styled from './modal.styled';
import { COLORS } from '@/theme';
import { MODAL_SLIDE_TIMEOUT, MODAL_SLIDE_TIMEOUT_CLOSE } from '@/common/consts';
import { useScrollX } from '@/common/hooks/use-scroll';
import { SlideType } from '@/common/types/modal.type';
import { getIsAnonUser } from '@/common/hooks';

interface IModalComponent {
  open: boolean;
  closeHandler: () => void;
  children: ReactNode;
  className?: string;
  isLeft?: boolean;
  isCenter?: boolean;
  hideChildBackground?: boolean;
  hideChildeShadow?: boolean;
  hideBackdrop?: boolean;
  hideBorder?: boolean;
  childTopPostion?: number;
  slideType?: SlideType;
  isScrollDisabled?: boolean;
  openTimeout?: number;
  closeTimeout?: number;
  isUserModeSwitchModal?: boolean;
  isCreateModal?: boolean;
  isTopLeft?: boolean;
}

export const ModalComponent = ({
  open,
  closeHandler,
  children,
  className,
  isLeft,
  isCenter,
  hideChildBackground,
  hideChildeShadow,
  hideBackdrop,
  hideBorder,
  childTopPostion,
  slideType,
  isScrollDisabled,
  openTimeout,
  closeTimeout,
  isUserModeSwitchModal,
  isTopLeft,
  isCreateModal
}: IModalComponent) => {
  const { data: scrollX } = useScrollX();

  const isAnon = getIsAnonUser();

  return (
    <Styled.ModalWrapper
      open={open}
      closeAfterTransition
      onClose={closeHandler}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: hideBackdrop ? 'transparent' : isCreateModal ? COLORS.modalBackdropColor : COLORS.defaultBackdropColor
          }
        }
      }}
      scrollX={scrollX || 0}
      isLeft={isLeft}
      isCenter={isCenter}
      onDrop={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      isCreateModal={isCreateModal}
      isTopLeft={isTopLeft}
    >
      <Slide
        direction={slideType || 'left'}
        in={open}
        timeout={open ? (openTimeout ?? MODAL_SLIDE_TIMEOUT) : (closeTimeout ?? MODAL_SLIDE_TIMEOUT_CLOSE)}
      >
        <Styled.ChildrenWrapper
          isAnon={isAnon}
          hideBorder={hideBorder}
          className={className}
          isScrollDisabled={isScrollDisabled}
          hideChildBackground={hideChildBackground}
          hideChildeShadow={hideChildeShadow}
          isCenter={isCenter}
          isLeft={isLeft}
          isTopLeft={isTopLeft}
          childTopPostion={childTopPostion}
          isUserModeSwitchModal={isUserModeSwitchModal}
        >
          {children as any}
        </Styled.ChildrenWrapper>
      </Slide>
    </Styled.ModalWrapper>
  );
};
