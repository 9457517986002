import React, { ReactNode } from 'react';
import * as Styled from './section-wrapper.styled';

interface IProps {
  children: ReactNode;
  sectionNumber?: number;
  sectionTitle: string;
  optional?: boolean;
}

const TOTAL_SECTIONS = 4;

export const SectionWrapperComponent = ({ children, sectionNumber, sectionTitle, optional }: IProps) => (
  <Styled.SectionWrapper>
    <Styled.TitleWrapper>
      <Styled.TitleText>{sectionTitle}</Styled.TitleText>
      {optional && <Styled.DescriptionText>optional</Styled.DescriptionText>}
      {sectionNumber && <Styled.Dot>•</Styled.Dot>}
      {sectionNumber && <Styled.DescriptionText>{`${sectionNumber} of ${TOTAL_SECTIONS}`}</Styled.DescriptionText>}
    </Styled.TitleWrapper>
    {children as any}
  </Styled.SectionWrapper>
);
