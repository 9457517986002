import {
  IAnswerConnectionRequestPayload,
  IAnswerFollowRequestPayload,
  IConnectionsResponse,
  ITargetIdPayload
} from '../types/connection.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class ConnectionService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async createConnection(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/connect', data);
  }

  async createFollow(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/follow', data);
  }

  async answerConnectionRequest(data: IAnswerConnectionRequestPayload) {
    return this.post<IConnectionsResponse, IAnswerConnectionRequestPayload>('connection/answer-connection-request', data);
  }

  async answerFollowRequest(data: IAnswerFollowRequestPayload) {
    return this.post<IConnectionsResponse, IAnswerFollowRequestPayload>('connection/answer-follow-request', data);
  }

  async cancelConnectionRequest(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/cancel-connection-request', data);
  }

  async cancelFollowRequest(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/cancel-follow-request', data);
  }

  async disconnect(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/disconnect', data);
  }

  async unfollow(data: ITargetIdPayload) {
    return this.post<IConnectionsResponse, ITargetIdPayload>('connection/unfollow', data);
  }
}

export const connectionService = new ConnectionService();
