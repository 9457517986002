import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 50px 0;
`;

export const LogoText = styled.p<{ isAnon?: boolean }>`
  margin-top: 2px;
  color: #6b6b6b;
  ${({ isAnon }) => isAnon && 'color: #C9C9C9;'}
  text-align: center;
  font-family: ${FAMILIES.rubik};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  line-height: ${SIZES.m};
`;

export const LogoImage = styled(Image as AnyStyledComponent)`
  width: 18px;
  height: 18px;
`;
