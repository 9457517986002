import React, { ReactNode, forwardRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import * as Styled from './page-wrapper.styled';
import { Loader } from '../loader';
import { Sidebar } from '../sidebar';
import { useSetScrollX } from '@/common/hooks/use-scroll';
import { getIsAnonUser, getIsNonFinanceUser, useAuth, useAuthProfile, useGetModalOpen, useSidebarWidth } from '@/common/hooks';
import { FooterComponent } from '../footer';

interface IPageWrapperComponentProps {
  children: ReactNode;
  isLoading: boolean;
  isSidebarHidden?: boolean;
  isFooterHidden?: boolean;
  isVerticalBarsHidden?: boolean;
  sidebarActive: string;
  isSearch?: boolean;
  isIdeaHome?: boolean;
  routePage?: string;
  sidebarProfileHandler?: () => void;
  disableScroll?: boolean;
  contentLeftMargin?: string;
}

export const PageWrapperComponent = forwardRef<HTMLDivElement, IPageWrapperComponentProps>(
  (
    {
      children,
      isLoading,
      isIdeaHome,
      sidebarActive,
      isFooterHidden,
      isSidebarHidden,
      isVerticalBarsHidden,
      sidebarProfileHandler,
      disableScroll = true,
      isSearch,
      routePage,
      contentLeftMargin
    }: IPageWrapperComponentProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const sidebarWidth = useSidebarWidth();
    const [scrollX, setScrollX] = useState(0);
    const { mutate: mutateScrollX } = useSetScrollX();
    const { user, isUserLoading } = useAuth();
    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { target } = event;
      const scrollXPosition = (target as HTMLButtonElement).scrollLeft;
      setScrollX(scrollXPosition || 0);
    };
    const { data: isModalOpen } = useGetModalOpen();

    useEffect(() => {
      mutateScrollX(scrollX);
    }, [scrollX]);

    const duration = 225;

    const { isLoading: isAuthProfileLoading } = useAuthProfile();

    const isAnonUser = getIsAnonUser();
    const isNonFinanceUser = getIsNonFinanceUser();

    return (
      <Styled.PageWrapper
        ref={ref}
        onScroll={handleScroll}
        disableScroll={disableScroll}
        onDrop={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        isAnon={isAnonUser}
      >
        {!isVerticalBarsHidden && (
          <Styled.LeftVerticalBar data-id="sidebar">
            {/* <div>
              <ScrollAnimationComponent duration={duration} isAnon={isAnonUser} text="Verona" isLeft />
            </div> */}
            {!isSidebarHidden && (
              <Sidebar
                active={sidebarActive}
                isAnon={isAnonUser}
                isNonFinanceUser={isNonFinanceUser}
                profileHandler={sidebarProfileHandler}
              />
            )}
          </Styled.LeftVerticalBar>
        )}
        <Styled.ContentWrapper sidebarWidth={sidebarWidth!} contentLeftMargin={contentLeftMargin} routePage={routePage}>
          {isModalOpen && <Styled.BlurBlockRightMain />}
          <AnimatePresence>
            {isLoading || isAuthProfileLoading || isUserLoading ? (
              <Styled.LoaderWrapper isPageLoading>
                <Loader />
              </Styled.LoaderWrapper>
            ) : (
              <motion.div
                transition={{ ease: 'backInOut', delay: 0.3, duration: 1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Styled.ChildrenWrapper isSearch={!!isSearch} isIdeaHome={isIdeaHome} disableScroll={disableScroll}>
                  {children as any}
                </Styled.ChildrenWrapper>
              </motion.div>
            )}
          </AnimatePresence>
        </Styled.ContentWrapper>
        {!isVerticalBarsHidden && (
          <Styled.RightVerticalBar>
            {isModalOpen && <Styled.BlurBlockRightBar />}
            {!isFooterHidden && !!user && <FooterComponent user={user} isAnon={isAnonUser} />}
            {/* <div>
              <ScrollAnimationComponent duration={0} isAnon={isAnonUser} text="Verified" isLeft={false} />
            </div> */}
          </Styled.RightVerticalBar>
        )}
      </Styled.PageWrapper>
    );
  }
);
