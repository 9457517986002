import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import {
  IAnswerConnectionRequestPayload,
  IAnswerFollowRequestPayload,
  IAssociationResponse,
  IConnectionsResponse,
  ITargetIdPayload
} from '../types/connection.type';
import { connectionService } from '../services';

export const setCachedConnection = (
  queryClient: QueryClient,
  sourceId: string,
  targetId: string,
  connection?: IAssociationResponse | null
) => {
  queryClient.setQueryData<IAssociationResponse | null>([QUERY_KEYS.CACHED_CONNECTION, sourceId, targetId], connection || null);
  queryClient.invalidateQueries([QUERY_KEYS.CACHED_CONNECTION, sourceId, targetId]);
};

export const setCachedConnections = (queryClient: QueryClient, response: IConnectionsResponse) => {
  if (response.connection) {
    setCachedConnection(queryClient, response.connection.sourceUserId, response.connection.targetUserId, response.connection);
  }

  if (response.targetConnection) {
    setCachedConnection(
      queryClient,
      response.targetConnection.sourceUserId,
      response.targetConnection.targetUserId,
      response.targetConnection
    );
  }
};

export const useCachedConnection = (sourceId: string, targetId: string) => {
  const queryClient = useQueryClient();
  return useQuery([QUERY_KEYS.CACHED_CONNECTION, sourceId, targetId], () =>
    queryClient.getQueryData<IAssociationResponse | null>([QUERY_KEYS.CACHED_CONNECTION, sourceId, targetId])
  );
};

export const useCreateConnection = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.createConnection(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
        if (onSuccess) onSuccess();
      }
    }
  });
};

export const useCreateFollow = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.createFollow(payload),
    onSuccess: (data) => {
      if (!data) return;
      setCachedConnections(queryClient, data);
      if (onSuccess) onSuccess();
    }
  });
};

export const useAnswerConnectionRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: IAnswerConnectionRequestPayload) => connectionService.answerConnectionRequest(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};

export const useAnswerFollowRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: IAnswerFollowRequestPayload) => connectionService.answerFollowRequest(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};

export const useCancelConnectionRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.cancelConnectionRequest(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};

export const useCancelFollowRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.cancelFollowRequest(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};

export const useDisconnect = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.disconnect(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};

export const useUnfollow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CONNECTION],
    mutationFn: (payload: ITargetIdPayload) => connectionService.unfollow(payload),
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, data);
      }
    }
  });
};
