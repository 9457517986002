import styled, { AnyStyledComponent, keyframes } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS, SPACES } from '@theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { CodeInputComponent, CodeInputLandingComponent } from './components/code-input';
import { LeftColumnComponent } from '../page-wrapper/components/left-column';

export const AuthWrapper = styled.div`
  display: flex;
  border: none;
  background: radial-gradient(118.6% 141.42% at 0% 0%, #005889 0%, #001c2c 81.77%);
  font-family: ${FAMILIES.robotoFlex};
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1450px) {
    align-items: normal;
  }
`;

export const AuthContainer = styled.div`
  min-height: 1400px;
  display: flex;
  flex-direction: column;
`;

export const AuthLogoWrapper = styled.div`
  flex-direction: row;
  display: flex;
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.medium};
  align-items: flex-end;
  gap: 10px;
  height: 40px;
  padding: 3px 0px;
`;

export const AuthLogo = styled.div`
  color: rgba(255, 255, 255, 0.15);
  text-align: center;
  font-family: Roboto Mono;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -4px;
  filter: blur(1.5px);
  margin-bottom: -1px;
`;

export const AuthVersion = styled.div`
  color: rgba(255, 255, 255, 0.2);
  text-align: center;
  font-family: Roboto Mono;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -2.5px;
  opacity: 0.5;
  filter: blur(1.5px);
`;

export const AuthForm = styled.form`
  margin-top: 80px;
  font-size: 16px;
  line-height: 16px;
  width: 350px;
  height: 400px;
`;

export const InputWrapper = styled.div`
  background: linear-gradient(102.25deg, rgba(247, 247, 247, 0.06) 0%, rgba(238, 238, 238, 0.12) 100%);
  height: 45px;
  width: 350px;
  border-radius: 5px;
`;

export const AuthInput = styled.input`
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }
  background-color: ${COLORS.authInputBackground};
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: ${COLORS.authInputTextColor};
  text-align: center;
  letter-spacing: ${SPACES.mediumLetterSpacing};
  font-family: ${FAMILIES.robotoFlex};
  outline: none;
  font-weight: ${FONTS.WEIGHTS.light};

  ::placeholder {
    color: ${COLORS.authInputTextColor};
  }
  :focus-visible {
    outline: 0;
  }
`;

export const AuthButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthButton = styled.button`
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  height: 46px;
  border: none;
  border-radius: 5px;
  background: ${COLORS.onboardBackgroundColor};
  color: ${COLORS.black};
  letter-spacing: ${SPACES.mediumLetterSpacing};
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.medium};
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(1.1px);
    box-shadow: 0px 4px 4px -5px #b9b9b9;
  }
`;

export const AuthInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const AuthErrorMessage = styled.div<{ isPasswordError?: boolean }>`
  color: ${COLORS.white};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${({ isPasswordError }) => (isPasswordError ? FONTS.WEIGHTS.light : FONTS.WEIGHTS.normal)};
  line-height: 22px;
  text-align: center;
  font-size: 14px;
`;

export const AuthInfoMessage = styled.div`
  color: ${COLORS.white};
  letter-spacing: ${SPACES.mediumLetterSpacing};
  line-height: 18px;
  text-align: center;
`;

export const ForgotPassword = styled.div`
  margin: ${SPACES.xxxs} auto;
  color: ${COLORS.authTypeColor};
  font-family: ${FAMILIES.robotoFlex};
  font-weight: ${WEIGHTS.normal};
  font-size: ${SIZES.m};
  cursor: pointer;
`;

export const AuthInfoWrapper = styled.div`
  position: relative;
  margin-top: 70px;
`;

export const AuthInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: absolute;
  left: -50px;
  width: 450px;
`;

export const AuthLogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 976px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 3px 0px;
`;

export const AuthLogoImage = styled(Image)`
  width: 40px;
  height: 40px;
`;

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

export const AuthTypeButtonWrapper = styled.div`
  display: flex;
  animation: ${appearAnimation} 0.5s ease;
  width: 372px;
  transform: translateX(-11px);

  & > button:first-child {
    margin-right: 21px;
  }
`;

export const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${appearAnimation} 0.5s ease;
`;

export const AuthTypeButton = styled.button`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.medium};
  width: 100%;
  height: 56px;
  letter-spacing: ${SPACES.mediumLetterSpacing};
  color: ${COLORS.authTypeColor};
  border-radius: 30px;
  cursor: pointer;
  background: ${COLORS.onboardBackgroundColor};
  border: none;
  box-shadow: 1px 1px 8px -3px #eeeeee;
`;

export const AuthTypeBackWrapper = styled.div`
  height: 46px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;

export const AuthBackArrowImageWrapper = styled.div`
  height: 100%;
  width: 36px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AuthBackTitle = styled.div`
  font-weight: ${FONTS.WEIGHTS.medium};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${COLORS.authTypeColor};
  letter-spacing: ${SPACES.mediumLetterSpacing};
  font-size: 16px;
`;

export const AuthInfoPasswordFreeLogin = styled.div`
  font-size: 14px;
  color: #c2c2c2;
  text-align: center;
`;

export const InputBlankSpace = styled.div`
  height: 45px;
  margin-bottom: 10px;
`;

export const PasswordlessContainer = styled.div<{ isLowGap?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: ${({ isLowGap }) => (isLowGap ? '30' : '60')}px;
`;

export const EmailInputContainer = styled.div`
  position: relative;
  width: 380px;
  height: 46px;
`;

export const EmailInput = styled.input<{ isFilled: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  padding: 16px 80px 16px 20px;
  border-radius: 10px;
  background: rgba(246, 246, 246, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 27, 68, 0.4);
  color: #fff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    opacity: 1;
    font-family: ${FAMILIES.robotoFlex};
    font-size: 14px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 1.4px;
  }

  ${({ isFilled }) =>
    isFilled &&
    'color: rgba(255, 255, 255, 0.75); background: rgba(246, 246, 246, 0.05); box-shadow: 0px 0px 10px 0px rgba(0, 27, 68, 0.1);'}

  &:disabled {
    background: rgba(246, 246, 246, 0.05);
    box-shadow: 0px 0px 10px 0px rgba(0, 27, 68, 0.1);
    color: rgba(255, 255, 255, 0.75);
  }
`;

export const EmailInputButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: transparent;
  border-radius: 0px 20px 20px 0px;
  width: 52px;
  height: 100%;
  cursor: pointer;
`;

export const EmailInputIcon = styled(Image as AnyStyledComponent)`
  width: 20px;
  height: 24px;
`;

export const EnterCodeTitle = styled.div`
  position: absolute;
  top: -32px;
  left: 471px;
  color: #fff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.42px;
`;

export const EnterCodeComponent = styled(CodeInputComponent as AnyStyledComponent)``;

export const PasswordlessErrorText = styled.div`
  white-space: pre-wrap;
  color: #fff;
  text-align: center;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.42px;
`;

export const ChangeLoginButton = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 5;
`;

export const BodyContainer = styled.div`
  margin-top: 180px;
  min-width: 1220px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1450px) {
    margin-left: 110px;
  }
`;

export const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-diraction: row;
  align-items: center;
  margin-top: 495px;
  width: 100%;
`;

export const TextInfoContainer = styled.div<{ isNeedMargin: boolean }>`
  display: flex;
  width: 783px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const InfoText = styled.span<{ opacity?: number }>`
  color: rgba(255, 255, 255, 0.5);
  ${({ opacity }) => opacity && `color: rgba(255, 255, 255, ${opacity});`}
  font-family: Roboto Mono;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
`;

export const OnboardWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
  background: ${COLORS.authWrapperBackground};
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  /* overflow: auto; */
`;

export const OnboardContainer = styled.div`
  width: 1340px;
  height: 1200px;

  padding-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const MiniLogoIconStab = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  background: rgba(107, 107, 107, 0.5);
`;

export const TitleIconStab = styled.div`
  width: 126px;
  height: 30px;
  display: flex;
  background: rgba(107, 107, 107, 0.5);
`;

export const FormContainer = styled.div`
  margin-top: 60px;
  margin-right: auto;
`;

export const RecoverBackButton = styled.button`
  margin-top: 30px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 30px;
  color: #5a5a5a;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const OnboardEmailInputContainer = styled.div`
  position: relative;
  min-width: 650px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OnboardEmailInputWrapper = styled.div<{ gap?: number; column?: boolean }>`
  position: relative;
  min-width: 650px;
  max-width: 650px;
  height: 66px;
  display: flex;
  align-items: center;

  ${({ column }) => column && 'flex-direction: column; align-items: flex-start'}
  ${({ gap }) => gap && `${gap}px`}
`;

export const RecoverPasswordDescription = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #5a5a5a;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const RecoverPasswordError = styled.p`
  margin-top: 30px;
  color: #ab0000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

interface IOnboardEmailInputProps {
  isWarning?: boolean;
}
export const OnboardEmailInput = styled.input<IOnboardEmailInputProps>`
  position: relative;
  display: flex;
  width: 650px;
  height: 66px;
  padding: 15px 5px 15px 25px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 35px;
  border: 2px solid #6d6d6d;
  background: #fafafa;

  color: #000;

  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: 1;

  &::placeholder {
    color: #999;

    font-family: ${FAMILIES.robotoFlex};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    opacity: 1;
  }

  &[disabled] {
    border: none;
    padding: 17px 7px 17px 27px;
    background: rgba(65, 187, 165, 0.1);
    color: #41bba5;
  }

  :focus-visible {
    outline: 0;
  }
  display: flex;
  align-items: center;
`;

export const PasswordInput = styled(OnboardEmailInput)`
  letter-spacing: 3.6px;
  color: #000;
  font-weight: ${WEIGHTS.medium};

  &::placeholder {
    color: #5e5e5e;
    font-family: ${FAMILIES.robotoFlex};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    opacity: 1;
    letter-spacing: 0;
  }
`;

interface IOnboardEmailInputButtonProps {
  isDone?: boolean;
  isWarning?: boolean;
  hideSignInButton?: boolean;
  isLinkMethod?: boolean;
}

export const OnboardEmailInputButton = styled.button<IOnboardEmailInputButtonProps>`
  position: absolute;
  top: 5px;
  right: 5px;

  justify-content: center;
  align-items: center;

  color: #fff;

  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  width: 132px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  display: flex;
  padding: 17px 20px 17px 25px;
  align-items: center;
  height: 56px;
  gap: 10px;
  border-radius: 35px;
  background: #41bba5;

  cursor: pointer;

  white-space: nowrap;
  border: 0;
  ${({ hideSignInButton }) => hideSignInButton && 'display: none'}
`;

export const EmailJoinButton = styled.button<IOnboardEmailInputButtonProps>`
  position: absolute;
  top: 0;
  right: -243px;
  width: 233px;
  height: 66px;

  justify-content: center;
  align-items: center;

  color: #5c5c5c;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  display: flex;
  padding: 17px 20px 17px 25px;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border-radius: 35px;
  border: 1px solid #e0e0e0;
  background: #f5f5f5;

  cursor: pointer;

  white-space: nowrap;
  ${({ isLinkMethod }) => isLinkMethod && 'display: none'}
`;

interface IAuthErrorMessageProps {
  clickable?: boolean;
  isWarning?: boolean;
}

export const OnboardAuthErrorMessage = styled.span<IAuthErrorMessageProps>`
  ${({ clickable }) => clickable && 'cursor:pointer;'}
  margin-top: ${({ isWarning }) => (isWarning ? '0' : '20px')};

  color: ${({ isWarning }) => (isWarning ? COLORS.authEmailWarningTextColor : COLORS.authEmailInputErrorColor)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const OnboardAuthErrorEmailMessage = styled(OnboardAuthErrorMessage)`
  position: absolute;
  top: 75px;
  left: 25px;
  white-space: nowrap;
`;

export const RecoverPasswordButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0018f1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const RecoverPasswordDescriptionAfterClickOnReset = styled(RecoverPasswordButton)`
  cursor: auto;
`;

export const AuthPageDescription = styled.p`
  color: #939393;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const AuthPageDescriptionByEmail = styled.p`
  font-family: ${FAMILIES.robotoFlex};
  color: #5e5e5e;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const OtpCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  align-items: center;
`;

export const OtpCodeTitle = styled.p`
  color: ${COLORS.black};
  font-weight: ${WEIGHTS.extra_light};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  line-height: ${SIZES.m};
  letter-spacing: 0.03em;

  opacity: 0.6;
`;

export const OtpCodeComponent = styled(CodeInputLandingComponent as AnyStyledComponent)``;

export const WarningEmail = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  p {
    color: ${COLORS.black};

    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.l};
    font-style: normal;
    font-weight: ${WEIGHTS.light};
    line-height: ${SIZES.l};
    letter-spacing: 1.6px;
  }
`;

export const JoinButton = styled.button`
  display: inline-flex;
  align-self: flex-start;
  min-height: 66px;
  color: #fff;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 140px;
  padding: 21px 30px;
  align-items: center;
  min-width: 223px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 35px;
  border: 2px solid #d44d01;
  background: #d44d01;
  cursor: pointer;
  white-space: nowrap;
`;

export const JoinButtonIcon = styled(Image)`
  width: 22px;
  height: 22px;
`;
