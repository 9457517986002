import styled, { css } from 'styled-components';
import { COLORS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const InputLabel = css`
  display: grid;
  grid-template-columns: 120px 90px 300px;
  gap: 30px;
  align-items: center;

  p {
    color: ${COLORS.anonActiveModalEmailSubTextColorPrimary};

    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.xxl};
  }
  span {
    color: ${COLORS.darkerGray};

    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.s};
    font-style: normal;
    font-weight: ${WEIGHTS.light};
    line-height: ${SIZES.s};
  }
`;

export const InputText = css`
  background: transparent;
  border: 0;
  :focus-visible {
    outline: 0;
  }

  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: ${SIZES.xxl};
  letter-spacing: 2.1px;

  ::placeholder {
    opacity: 1;
    color: ${COLORS.black};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.medium};
    line-height: ${SIZES.xxl};
    letter-spacing: 0.42px;
  }
`;

interface IInputContainerProps {
  $isCheckKeyword?: boolean;
}

export const InputContainer = styled.div<IInputContainerProps>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ $isCheckKeyword }) => ($isCheckKeyword ? '160px 80px' : '270px 300px')};
  ${({ $isCheckKeyword }) => $isCheckKeyword && 'gap:10px'};
`;

interface IErrorProps {
  $clickable?: boolean;
  $isRealModal?: boolean;
  $isAnonModal?: boolean;
  $isConnectModal?: boolean;
}

export const Error = styled.p<IErrorProps>`
  color: ${COLORS.anonActiveModalInputErrorText} !important;
  font-family: ${FAMILIES.robotoFlex};
 // font-size: ${({ $isAnonModal }) => ($isAnonModal ? SIZES.m : SIZES.s)};
 font-size: ${SIZES.s};
  font-style: normal;
 // font-weight: ${({ $isConnectModal }) => ($isConnectModal ? WEIGHTS.normal : WEIGHTS.light)};
 font-weight: ${WEIGHTS.light};
 // line-height: ${({ $isAnonModal }) => ($isAnonModal ? SIZES.xxl : SIZES.s)};
 line-height: ${SIZES.s};
  margin-left: 10px;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'auto')};
  margin-top: ${({ $isRealModal }) => ($isRealModal ? '20px' : '0')};

  a {
    cursor: pointer;
  }
`;