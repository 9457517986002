import React from 'react';
import { useRouter } from 'next/router';
import { IPost, IStatistic, ISubPost } from '@/common/types/post.type';
import { PostComponent } from '@/common/components/post';
import { RepostBodyWrapper } from '@/common/components/post/post.styled';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { RepostTileComponent } from './repost-tile.component';

interface IProps {
  post: IPost;
  isAnon?: boolean;
  parrentPost: ISubPost;
  onClickByChildPost: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  statistic?: IStatistic;
  userProfileId?: string;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  quotePostHandler: (postId: string) => void;
  onClickPost?: () => void;
  closeModal?: () => void;
  isTreadOpen?: boolean;
  isInModal?: boolean;
}

export const RepostComponent = ({
  post,
  isAnon,
  parrentPost,
  onClickByChildPost,
  statistic,
  userProfileId,
  openReply,
  quotePostHandler,
  onClickPost,
  closeModal,
  isTreadOpen,
  isInModal
}: IProps) => {
  const { push } = useRouter();
  const onClickByFullName = () => {
    push(`${ROUTER_KEYS.PROFILE}?id=${post.profileId}`);
    if (closeModal) closeModal();
  };
  const handleClickByMemoSectionRepost = (e: any) => {
    if (!parrentPost?.memoSection) return;
    e.stopPropagation();
    push(`${ROUTER_KEYS.MEMO_PUBLISH}/${parrentPost.memoSection.memoId}`);
  };

  const isLast = (statistic?.replyCount || parrentPost.replyCount) === 0;

  const onClickByPost = (e: any) =>
    onClickPost
      ? onClickPost()
      : !parrentPost.memoSection
      ? !parrentPost.isHidden && onClickByChildPost()
      : handleClickByMemoSectionRepost(e);

  return (
    <RepostBodyWrapper>
      <RepostTileComponent
        fullName={post?.profile?.fullName ?? post.newsFeed?.newsSource?.fullName}
        date={typeof post.createdAt === 'string' ? new Date(post.createdAt) : post.createdAt}
        onClickByFullName={onClickByFullName}
      />
      <PostComponent
        isAnon={isAnon}
        post={parrentPost}
        postStatus={{ isInOwnTread: true, isRepost: true, isTreadOpen, isLast, isInModal }}
        userProfileId={userProfileId}
        onClickByPost={onClickByPost}
        key={parrentPost?.id}
        quotePostHandler={quotePostHandler}
        closeModal={closeModal}
        openReply={openReply}
      />
    </RepostBodyWrapper>
  );
};
