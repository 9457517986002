import { month } from '../components/employer/employer.const';

export const getRangeOfMonthsEnd = (dateStart?: Date, dateEnd?: Date) => {
  if (!dateEnd || !dateStart) {
    return month;
  }

  const dateEndLocal = typeof dateEnd === 'string' ? new Date(dateEnd) : dateEnd;

  const dateCurrent = new Date();

  if (dateEndLocal.getFullYear() === dateCurrent.getFullYear() && !dateStart) {
    return month.filter((_, index) => index <= dateCurrent?.getMonth());
  }

  const dateStartLocal = typeof dateStart === 'string' ? new Date(dateStart) : dateStart;

  if (dateStartLocal && dateEndLocal && dateStartLocal?.getFullYear() === dateEndLocal?.getFullYear() && dateEndLocal?.getDate() !== 6) {
    return month.filter((_, index) => index >= dateStartLocal.getMonth() && index <= dateEndLocal.getMonth());
  }

  if (dateStartLocal && dateStartLocal.getFullYear() === dateEndLocal.getFullYear()) {
    return month.filter((_, index) => index >= dateStartLocal.getMonth());
  }

  if (dateEndLocal.getFullYear() === dateCurrent.getFullYear()) {
    return month.filter((_, index) => index <= dateCurrent?.getMonth());
  }

  return month;
};
