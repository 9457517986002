import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { userService } from '@services';
import { QUERY_KEYS, ROUTER_KEYS } from '@/common/consts/app-keys.const';

export const useUpdateSetupStep = () => {
  const queryClient = useQueryClient();
  const { push } = useRouter();

  return useMutation({
    mutationKey: [QUERY_KEYS.USER],
    mutationFn: (step: number) => userService.updateSetupStep(step),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.USER, data);
    },
    onError: () => {
      push(ROUTER_KEYS.ONBOARDING);
    }
  });
};
