import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { CaseType, Direction, IMemoSection } from '@/common/types/memo.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import * as Styled from './display-memo-section.styled';
import { FlexComponent } from '@/common/components/flex';
import { getSectionText, getSectionTitle } from './model/helpers';
import { IdeaModalComponent } from '@/common/components/memo/components/idea-modal';
import arrow from '@/styles/icons/arrow-top-right.svg';

export interface IDisplayMemoSectionComponent {
  memoSection: IMemoSection;
  isQuote: boolean | undefined;
}

export const DisplayMemoSectionComponent = ({ memoSection, isQuote }: IDisplayMemoSectionComponent) => {
  const router = useRouter();
  const isNotificationPage = router.pathname.includes(ROUTER_KEYS.NOTIFICATIONS);
  const [openIdeaModal, setOpenIdeaModal] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const updateQueryParams = (memoId: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          idea: memoId
        }
      },
      undefined,
      { shallow: true }
    );
  };

  const clearQuery = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {}
      },
      undefined,
      { shallow: true }
    );
  };

  const handleCloseModal = useCallback(() => {
    setOpenIdeaModal(false);
    setTimeout(() => setIsModalVisible(false), 1000);
    clearQuery();
  }, [clearQuery]);

  const handleClickModal = () => {
    setOpenIdeaModal(true);
    setIsModalVisible(true);
    updateQueryParams(memoSection?.memoId!);
  };

  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isQuote) return;
    e.stopPropagation();
    router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${memoSection.memoId}`);
  };

  useEffect(() => {
    if (router.query.idea) {
      clearQuery();
    }
  }, []);

  if (isNotificationPage || memoSection.type !== CaseType.BASE_CASE) {
    return (
      <Styled.MemoWrapper isQuote={isQuote}>
        <div>
          <Styled.MemoTitle style={{ width: '46px' }}>idea</Styled.MemoTitle>
          <Styled.LinkOnMemo isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote} onClick={handleClick}>
            {memoSection?.title.split('  ')[0]} &#160;{memoSection?.title.split('  ')[1]}
          </Styled.LinkOnMemo>
        </div>
        <FlexComponent gap="10px">
          <div>
            <Styled.MemoTitle>{getSectionTitle(memoSection)}</Styled.MemoTitle>
            <Styled.MemoText isQuote={isQuote}>{getSectionText(memoSection)}</Styled.MemoText>
          </div>
          {!!memoSection.risk && (
            <div>
              <Styled.MemoTitle style={{ width: '76px' }}>risk / reward</Styled.MemoTitle>
              <Styled.MemoText isQuote={isQuote}>{memoSection.risk}x</Styled.MemoText>
            </div>
          )}
          <Styled.Link onClick={handleClick}>
            <Styled.Arrow src={arrow.src} alt="arrow-top" />
            read
          </Styled.Link>
        </FlexComponent>
      </Styled.MemoWrapper>
    );
  }

  return (
    <>
      <Styled.MemoWrapper isQuote={isQuote} onClick={handleClickModal}>
        <Styled.ClickBlock onClick={handleClickModal} />
        <div>
          <Styled.MemoTitle style={{ width: '46px' }}>idea</Styled.MemoTitle>
          <Styled.LinkOnMemo isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote}>
            {memoSection?.title.split('  ')[0]} &#160;{memoSection?.title.split('  ')[1]}
          </Styled.LinkOnMemo>
        </div>
        <FlexComponent gap="10px">
          <div>
            <Styled.MemoTitle>{getSectionTitle(memoSection)}</Styled.MemoTitle>
            <Styled.MemoText isQuote={isQuote}>{getSectionText(memoSection)}</Styled.MemoText>
          </div>
          {!!memoSection.risk && (
            <div>
              <Styled.MemoTitle style={{ width: '76px' }}>risk / reward</Styled.MemoTitle>
              <Styled.MemoText isQuote={isQuote}>{memoSection.risk}x</Styled.MemoText>
            </div>
          )}
          <Styled.Link>
            <Styled.Arrow src={arrow.src} alt="arrow-top" />
            read
          </Styled.Link>
        </FlexComponent>
      </Styled.MemoWrapper>
      {(openIdeaModal || isModalVisible) && (
        <IdeaModalComponent withoutPagination item={memoSection?.memo!} open={openIdeaModal} closeHandler={handleCloseModal} />
      )}
    </>
  );
};
