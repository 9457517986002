import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';

export const OptionalTitle = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const OptionalText = styled.div`
  color: #c86000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
