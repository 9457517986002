import styled from 'styled-components';
import { InputLabel, InputText } from '../ui/common-styles';
import { SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const UsernameInputWrapper = styled.div`
  display: flex;
  max-width: 568px;
  flex-direction: column;
  gap: 10px;
`;

interface IUsernameInputInfoProps {
  $isOnPage?: boolean;
  $isOnboarding?: boolean;
}

export const UsernameInputInfo = styled.div<IUsernameInputInfoProps>`
  ${InputLabel}
  grid-template-columns: 120px 90px 320px;
  ${({ $isOnboarding }) =>
    $isOnboarding &&
    `
    span {
      color: ${COLORS.headTextColorInModal}; 
      font-weight: ${WEIGHTS.extra_light}; 
      line-height: ${SIZES.xxl};
    }
    `}
  ${({ $isOnPage }) =>
    $isOnPage &&
    `grid-template-columns: 65px 90px 320px;
    gap: 10px;
    span:first-child {
      font-weight: ${WEIGHTS.light};
    }
    `}
`;

export const UsernameInput = styled.input<IUsernameInputInfoProps>`
  ${InputText}
  letter-spacing: 0.42px;
  ${({ $isOnboarding }) =>
    $isOnboarding &&
    `font-weight: ${WEIGHTS.light};

    ::placeholder {
      font-weight: ${WEIGHTS.light};
    }`}
`;
