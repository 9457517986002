import React from 'react';
import { OtpInputComponent } from '../ui';
import { InputError } from '../modals/activate-anon-modal/activate-anon-modal.types';
import * as Styled from './verification-code-input.styled';
import { Error } from '../ui/common-styles';

interface IVerifcationCodeInputProps extends InputError {
  disabled: boolean;
  onFinish: (code: string) => void;
  isRealModal?: boolean;
  onErrorClick?: () => void;
  clickable?: boolean;
}

export const VerifcationCodeInputComponent = ({
  disabled,
  onFinish,
  error,
  isRealModal,
  onErrorClick,
  clickable
}: IVerifcationCodeInputProps) => (
  <Styled.OtpInputContainer>
    <p>Enter the code you receive to the above email address</p>
    <OtpInputComponent onFinish={onFinish} disabled={disabled} />
    {error.isError && (
      <Error
        $isRealModal={isRealModal}
        $clickable={isRealModal || clickable}
        onClick={() => (onErrorClick !== undefined ? onErrorClick() : null)}
      >
        {error.errorText}
      </Error>
    )}
  </Styled.OtpInputContainer>
);
