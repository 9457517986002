import styled from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES, FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { InputText } from '../ui/common-styles';

interface IEmailVerificationInfoTextProps {
  $isSpanPurple: boolean;
  $isRealModal?: boolean;
}

export const EmailVerificationInfoText = styled.div<IEmailVerificationInfoTextProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 590px;
  p {
    color: ${COLORS.anonActiveModalEmailSubTextColorPrimary};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.xxl};
  }
  span {
    color: ${({ $isSpanPurple, $isRealModal }) => {
      if ($isRealModal) {
        return $isSpanPurple ? `${COLORS.realActiveModalEmailSubTextColorSecondary}` : `${COLORS.anonActiveModalEmailSubTextColorGrey}`;
      }
      return $isSpanPurple ? `${COLORS.anonActiveModalEmailSubTextColorSecondary}` : `${COLORS.anonActiveModalEmailSubTextColorGrey}`;
    }};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.s};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.s};

    padding-bottom: 3px;
  }
`;

export const EmailVerificationInput = styled.input`
  ${InputText}
  letter-spacing: 0.42px;
  max-width: 260px;
  margin-rigth: 10px;
`;

export const EmailVerifcationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
