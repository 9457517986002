import React from 'react';
import { DateOutput, DateOutputWrapper, DateOutputWrapperWithPadding, DotOutput } from '../post-stats/post-stats.styled';
import { month } from '@/common/components/onboard/components/employer/employer.const';

interface IProps {
  date: Date;
  isQuote?: boolean;
  isRepost?: boolean;
  fontSize?: string;
}

export const DateTimeOutput = ({ date, isQuote, isRepost, fontSize = '10px' }: IProps) => {
  if (!date) return <div />;
  const currentDate = new Date().getTime();
  const diffInMinutes = Math.floor((currentDate - date.getTime()) / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  let result = '';

  if (diffInDays <= 14) {
    result = `${diffInDays}d`;
  }
  if (diffInHours < 24) {
    result = `${diffInHours}h`;
  }
  if (diffInMinutes < 60) {
    result = `${diffInMinutes}m`;
  }
  if (diffInMinutes < 0) {
    result = '0m';
  }
  const save = (
    <DateOutputWrapper>
      <DateOutput fontSize={fontSize} isRepost={isRepost} isQuote={isQuote}>
        {date.getDate()} {month[date.getMonth()]} {date.getFullYear()}
      </DateOutput>
      <DotOutput isRepost={isRepost} isQuote={isQuote}>
        •
      </DotOutput>
      <DateOutput fontSize={fontSize} isRepost={isRepost} isQuote={isQuote}>
        {date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()}:
        {date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()}
      </DateOutput>
    </DateOutputWrapper>
  );

  if (result.length > 0) {
    return (
      <DateOutputWrapperWithPadding>
        <DateOutput fontSize={fontSize} isRepost={isRepost} isQuote={isQuote}>
          {result}
        </DateOutput>
        {diffInHours > 24 && (
          <>
            <DotOutput isRepost={isRepost} isQuote={isQuote}>
              •
            </DotOutput>
            {save}
          </>
        )}
      </DateOutputWrapperWithPadding>
    );
  }

  return save;
};
