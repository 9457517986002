import React, { useEffect, useMemo, useState } from 'react';
import { ILinkPreview } from '@/common/types/post.type';
import { useDebounce } from '@/common/hooks';
import { useLinkPreview } from '@/common/hooks/use-link-preview';
import { ReviewLinkPreview } from '../display-review/review-link-preview.component';

interface LinkPreviewFroNewPostProps {
  text: string;
}

export const LinkPreviewForNewPost = ({ text }: LinkPreviewFroNewPostProps) => {
  const [linksWithLinkPreview, setLinkWithLinkPreview] = useState<{ link: string; preview: undefined | null | ILinkPreview }[]>([]);
  const deboundedText = useDebounce<string>(text, 500);

  const setNewLinkPreview = (data: ILinkPreview | null, url: string) => {
    setLinkWithLinkPreview((prev) => {
      const findLinkPreview = prev.find((it) => it.link === url);
      if (!findLinkPreview) return prev;
      const indexFindElement = prev.indexOf(findLinkPreview);
      return [...prev.slice(0, indexFindElement), { link: url, preview: data }, ...prev.slice(indexFindElement + 1)];
    });
  };

  const { mutateAsync } = useLinkPreview(setNewLinkPreview);
  useEffect(() => {
    setLinkWithLinkPreview((prev) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const links = deboundedText.match(urlRegex) || [];

      if (!links.length) return [];
      const dataForSet: { link: string; preview: undefined | null | ILinkPreview }[] = [];

      if (prev.length && prev.length >= links.length) {
        for (let i = 0; i < prev.length; i += 1) {
          if (prev[i].link === links[i]) {
            dataForSet.push(prev[i]);
          }
        }
      }

      if (prev.length && prev.length < links.length) {
        for (let i = 0; i < prev.length; i += 1) {
          if (prev[i].link === links[i]) {
            dataForSet.push(prev[i]);
          }
        }

        for (let i = prev.length - 1; i < links.length; i += 1) {
          dataForSet.push({ link: links[i], preview: undefined });
        }
      }

      if (!prev.length) {
        links.forEach((it) => {
          dataForSet.push({ link: it, preview: undefined });
        });
      }

      return dataForSet;
    });
  }, [deboundedText]);

  useEffect(() => {
    for (let i = 0; i < linksWithLinkPreview.length; i += 1) {
      const currentElement = linksWithLinkPreview[i];
      if (i === 0 && currentElement.preview === undefined) {
        mutateAsync(currentElement.link);
      }
      if (i > 0 && currentElement.preview === undefined && linksWithLinkPreview[i - 1].preview === null) {
        mutateAsync(currentElement.link);
      }
    }
  }, [linksWithLinkPreview]);

  const linkIntoView = useMemo(() => {
    let findLink;
    for (let i = 0; i < linksWithLinkPreview.length; i += 1) {
      if (linksWithLinkPreview[i].preview !== undefined && linksWithLinkPreview[i].preview !== null) {
        findLink = linksWithLinkPreview[i].preview;
        break;
      }
    }
    return findLink;
  }, [linksWithLinkPreview]);

  return <> {linkIntoView && <ReviewLinkPreview data={linkIntoView} />}</>;
};
