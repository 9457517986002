/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  Filler
} from 'chart.js';
import ViewDetailsArrow from '@styles/icons/view-details-debate-arrow.webp';
import ViewDetailsArrowDisabled from '@styles/icons/view-details-debate-arrow-disabled.webp';
import { Line } from 'react-chartjs-2';
import { Stack, Tooltip as MuiTooltip } from '@mui/material';
import Image from 'next/image';
import { SentimentType } from '@/common/types/question.type';
import * as Styled from './poll-graph.styled';
import { COLORS } from '@/theme';
import { IPollPosts } from '@/common/types/post.type';
import { PollTextComponent } from '../poll-text';
import { calculateChartPoints, getUnitText } from './lib/helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LineController, Filler);

interface IProps {
  item: IPollPosts;
  highlight?: string;
  isFinal?: boolean;
  isClickedOnChangeEntry?: boolean;
  onClickedOnChangeEntry?: () => void;
  isNewAnswerDisabled?: boolean;
}

const NOT_ENOUGH_ANSWERS_TEXT = 'insufficient estimates';

export const PollGraphComponent: React.FC<IProps> = ({
  item,
  isFinal,
  highlight,
  isClickedOnChangeEntry,
  onClickedOnChangeEntry,
  isNewAnswerDisabled
}) => {
  if (!item) return <span />;
  const [chartKey, setChartKey] = useState(0);
  const { ownAnswer, ownAnswerPosition, actualAnswer, finalConsensus } = item;
  const answers = [item.answers[0], ...item.answers];
  const lastAnswer = finalConsensus?.toFixed(1);

  const unitText = getUnitText(item.unit);
  const labels = answers.map((_, index) => `Vote ${index + 1}`);

  useEffect(() => {
    setChartKey((prevKey) => prevKey + 1);
  }, [ownAnswer, ownAnswerPosition]);

  const dataMin = Math.min(...answers);
  const dataMax = Math.max(...answers);
  const minY = dataMin * 0.97;
  const maxY = dataMax * 1.03;
  const containerHeight = 160;
  const dotHeight = 1;
  const gap = 5;

  const dotsCount = Math.floor(containerHeight / (dotHeight + gap)) + 1;
  const isAnswersMoreThan10 = item.totalAnswers >= 10;
  const isResultMoreThanFinal = actualAnswer !== null && Number(actualAnswer) <= Number(lastAnswer);

  const consensusText = !isFinal
    ? isAnswersMoreThan10
      ? `${lastAnswer} ${unitText}`
      : 'awaiting more estimates'
    : isAnswersMoreThan10
    ? `${lastAnswer} ${unitText}`
    : NOT_ENOUGH_ANSWERS_TEXT;

  const isNotEnough = consensusText === NOT_ENOUGH_ANSWERS_TEXT;
  return (
    <Styled.SentimentSwingsItemWrapper>
      <PollTextComponent poll={item} highlight={highlight} />
      <Styled.SwingsChartWrapper>
        <Styled.BoxContainer>
          <Stack gap={isFinal ? (isNotEnough ? '14px' : '21px') : isAnswersMoreThan10 ? '64px' : '46px'} p="13px 0 0 10px">
            <Stack gap="2px">
              <Styled.CurrentConsensusValue>{consensusText}</Styled.CurrentConsensusValue>
              <Styled.EstimateText>{isFinal ? 'final consensus' : 'current consensus'}</Styled.EstimateText>
            </Stack>
            {isFinal && (
              <Stack gap="2px">
                <Styled.FinalTextValue resultColor={isResultMoreThanFinal}>
                  {actualAnswer ? `${actualAnswer?.toFixed(1)} ${unitText}` : 'tbu'}
                </Styled.FinalTextValue>
                <Styled.FinalText resultColor={isResultMoreThanFinal}>actual result</Styled.FinalText>
              </Stack>
            )}
            {ownAnswer && (
              <Stack gap="2px" marginTop="auto">
                <Styled.EstimateValue>
                  {ownAnswer.toFixed(1)} {unitText}
                </Styled.EstimateValue>
                <Styled.EstimateText>your estimate</Styled.EstimateText>
              </Stack>
            )}
          </Stack>
          {!isFinal && (
            <Styled.DetailsViewRowWrapper>
              <Styled.CustomTooltip
                title={isNewAnswerDisabled ? 'change your estimate 30 days after your prior submission' : ''}
                disableHoverListener={!isNewAnswerDisabled}
                placement="bottom-start"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-2, -12]
                      }
                    }
                  ]
                }}
              >
                <button type="button" disabled={isNewAnswerDisabled} onClick={onClickedOnChangeEntry}>
                  <Image
                    src={isNewAnswerDisabled ? ViewDetailsArrowDisabled : ViewDetailsArrow}
                    alt="View Details Arrow"
                    width={6}
                    height={6}
                  />
                  change estimate
                </button>
              </Styled.CustomTooltip>
            </Styled.DetailsViewRowWrapper>
          )}
        </Styled.BoxContainer>
        <Styled.ChartLineWrapper>
          <Stack gap="5px" position="absolute" height="160px">
            {Array.from({ length: dotsCount }).map((_, index) => (
              <Styled.Dot key={index} />
            ))}
          </Stack>
          <Line
            key={chartKey}
            style={{
              width: '100%',
              height: '100%'
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  right: 1
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false
                },
                tooltip: {
                  enabled: false
                }
              },
              scales: {
                x: {
                  display: false
                },
                y: {
                  display: false,
                  suggestedMin: minY,
                  suggestedMax: maxY
                }
              },
              elements: {
                point: {
                  radius: 0
                },
                line: {
                  borderWidth: 2,
                  tension: 0.4
                }
              }
            }}
            data={{
              labels,
              datasets: [
                {
                  data: answers,
                  borderColor(context) {
                    const { chart } = context;
                    const { ctx, chartArea } = chart;
                    if (chartArea) {
                      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                      gradient.addColorStop(0, COLORS.sentimentSwingLineGradientFrom);
                      gradient.addColorStop(1, COLORS.sentimentSwingLineGradientTo);
                      return COLORS.sentimentSwingLineNewColor;
                    }
                    return COLORS.sentimentSwingLineNewColor;
                  },
                  backgroundColor(context) {
                    const { chart } = context;
                    const { ctx, chartArea, scales } = chart;
                    if (!chartArea) {
                      return '';
                    }
                    const yScale = scales.y;
                    const gradient = ctx.createLinearGradient(
                      0,
                      yScale.getPixelForValue(yScale.min),
                      0,
                      yScale.getPixelForValue(yScale.max)
                    );
                    gradient.addColorStop(0, 'rgba(0, 182, 222, 0)');
                    gradient.addColorStop(1, 'rgba(0, 182, 222, 0.15)');
                    return gradient;
                  },
                  fill: 'start',
                  spanGaps: true,
                  cubicInterpolationMode: 'monotone'
                }
              ]
            }}
          />
        </Styled.ChartLineWrapper>
      </Styled.SwingsChartWrapper>
    </Styled.SentimentSwingsItemWrapper>
  );
};
