import React, { ChangeEvent, useRef, useState } from 'react';
import AttachPhoto from '@styles/icons/reply-repost-attach-photo.webp';
import CloseBlack from '@styles/icons/modal-close-black.webp';
import CloseGrey from '@styles/icons/modal-close-grey.webp';
import { INewPostReply } from './new-musing-modal.types';
import * as Styled from './new-musing-modal.styled';
import { POST } from '@/common/consts';
import { getBase64 } from '../onboard/helpers';
import { pasteBuffer } from '@/common/utils/copy-past-in-input';
import { ImagePreviewComponent } from '../post/components/image-preview';
import { LinkPreviewForNewPost } from '../post/components/link-preview-for-new-post';
import { handleDrop } from '@/common/utils/on-drop-in-input';
import { OnDropErrorEnum } from '@/common/types/profile.type';
import { ModalErrorOnDragAndDropComponent } from '../modal-error-on-drag-and-drop';

interface INewPostReplyComponentProps {
  reply: INewPostReply;
  setReplies: React.Dispatch<React.SetStateAction<INewPostReply[]>>;
  setIsMoreThanMaxLength: (it: boolean) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  setIsScrollDisabled?: (it: boolean) => void;
  fullName: string;
  role?: string;
  vertical: string;
  imageUrl?: string;
  profileId?: string;
  isLast: boolean;
  isCoverage?: boolean;
  isCoverageList?: boolean;
  isFirst?: boolean;
}

export const NewPostReplyComponent = ({
  reply,
  isCoverage,
  isCoverageList,
  setReplies,
  setIsMoreThanMaxLength,
  onKeyDown,
  setIsScrollDisabled,
  fullName,
  imageUrl,
  profileId,
  role,
  vertical,
  isLast,
  isFirst
}: INewPostReplyComponentProps) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);

  const onChangeText = (value: string) => {
    setReplies((prev) => prev.map((curr) => (curr.id === reply.id ? { ...reply, text: value } : curr)));
  };

  const deleteReply = () => {
    setReplies((prev) => prev.filter((curr) => curr.id !== reply.id));
  };

  const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img = await getBase64(e.target.files[0]);
      setReplies((prev) => prev.map((curr) => (curr.id === reply.id ? { ...reply, image: img } : curr)));
      e.target.value = '';
    }
  };

  const setImage = (item: string | ArrayBuffer | null) => {
    setReplies((prev) => prev.map((curr) => (curr.id === reply.id ? { ...reply, image: item } : curr)));
  };

  const onPast = (e: React.ClipboardEvent<HTMLDivElement>) => {
    pasteBuffer(e, setImage);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e, setImage, setOnDropError);
  };

  return (
    <Styled.NewPostReplyContainer isCoverageList={isCoverageList} isFirst={isFirst}>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      {!isCoverage && (
        <Styled.PostBasicInfo fullName={fullName} role={role || ''} vertical={vertical} imageUrl={imageUrl} profileId={profileId} />
      )}
      <Styled.TextAndImageWrapper isCoverage={isCoverage}>
        <Styled.InputArea
          isInModal
          setIsMoreThanMaxLength={setIsMoreThanMaxLength}
          placeholder="Type here .."
          onChange={onChangeText}
          value={reply.text}
          maxLength={POST.MAX_LENGTH}
          onPaste={onPast}
          onDrop={onDrop}
          onKeyDown={onKeyDown}
          onShowDropdown={() => setIsScrollDisabled?.(true)}
          onHideDropdown={() => setIsScrollDisabled?.(false)}
          autoFocus
        />
        <LinkPreviewForNewPost text={reply.text} />
        {reply.image && (
          <ImagePreviewComponent
            img={typeof reply.image === 'string' ? reply.image : URL.createObjectURL(new Blob([reply.image]))}
            deleteHandler={() => setImage(null)}
          />
        )}
      </Styled.TextAndImageWrapper>
      <Styled.PostActionsContainer isCoverage={isCoverage}>
        <Styled.AttachImage
          isCoverage={isCoverage}
          src={AttachPhoto}
          alt="attach"
          onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
        />
        <input ref={uploadInputRef} accept="image/*" onChange={uploadPhoto} style={{ display: 'none' }} type="file" />
        <Styled.CloseImage src={isLast ? CloseBlack : CloseGrey} alt="attach" onClick={deleteReply} />
      </Styled.PostActionsContainer>
      {!isLast && <Styled.PostActionsReplyDivider isCoverage />}
    </Styled.NewPostReplyContainer>
  );
};
