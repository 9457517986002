import { useState, useEffect } from 'react';

export type OS = 'Windows' | 'MacOS' | 'Other' | null;

export const useDetectOS = (): OS => {
  const [os, setOS] = useState<OS>(null);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('win')) {
      setOS('Windows');
    } else if (userAgent.includes('mac')) {
      setOS('MacOS');
    } else {
      setOS('Other');
    }
  }, []);

  return os;
};
