import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ReplyModalComponent } from './reply-modal.component';
import { useAuthProfile } from '@/common/hooks';

interface IReplyModalWrapperProps {
  isInNotifications?: boolean;
  onClose?: () => void;
}

export const ReplyModalWrapper = ({ isInNotifications, onClose }: IReplyModalWrapperProps) => {
  const [postIdInModal, setPostIdInModal] = useState<{ id: string; searchId: string; isReplyOpen?: boolean } | null>(null);
  const [profileId, setProfileId] = useState<string | undefined>(undefined);
  const [quoteModalHistory, setQuoteModalHistory] = useState<string[]>([]);
  const { query } = useRouter();
  const { id } = query;
  const { data: userProfileId } = useAuthProfile();

  useEffect(() => {
    if (userProfileId) {
      setProfileId(userProfileId);
    }
  }, [userProfileId]);

  useEffect(() => {
    if (id) {
      setProfileId(id.toString());
    } else if (userProfileId) {
      setProfileId(userProfileId);
    }
  }, [id]);

  const quotePostClick = (newPostId: string, searchId: string, isReplyOpen?: boolean) => {
    setPostIdInModal({ id: newPostId, searchId, isReplyOpen });
  };
  return {
    openReplyModal: quotePostClick,
    componentToView: (
      <ReplyModalComponent
        open={!!postIdInModal}
        isTreadOpen={!!isInNotifications}
        profileId={profileId}
        originalPostId={postIdInModal?.id!}
        searchPostId={postIdInModal?.searchId!}
        isReplyOpen={postIdInModal?.isReplyOpen}
        userProfileId={userProfileId!}
        openModal={(newPostId, searchId) => setPostIdInModal({ id: newPostId, searchId })}
        closeModal={() => {
          setPostIdInModal(null);
          onClose?.();
        }}
        quoteModalHistory={quoteModalHistory}
        setQuoteModalHistory={setQuoteModalHistory}
      />
    )
  };
};
