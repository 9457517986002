import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const Container = styled.div<{ isAuth?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 4px;
  box-shadow: ${({ isAuth }) => (isAuth ? 'none' : '0px 0px 15px 0px rgba(0, 27, 68, 0.7)')};
  border-radius: 10px;
`;

export const InputField = styled.input<{ isFirst?: boolean; isLast?: boolean; isAuth?: boolean }>`
  :focus-visible {
    outline: 0;
  }
  width: 60px;
  height: 46px;
  text-align: center;
  color: ${COLORS.white};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 20px;
  border: none;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.6px;
  border-radius: 3px;
  ${({ isFirst }) => isFirst && 'border-radius: 10px 3px 3px 10px;'}
  ${({ isLast }) => isLast && 'border-radius: 3px 10px 10px 3px;'}
  background: ${({ isAuth }) => (isAuth ? COLORS.authEmailInputBackground : 'rgba(246, 246, 246, 0.3)')};

  ${({ isAuth }) =>
    isAuth &&
    `
    width: 65px;
    height: 54px;
    text-align: center;
    color: ${COLORS.black};
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.xxl};
    border: none;
    font-weight: ${FONTS.WEIGHTS.extra_light};
    line-height: ${FONTS.SIZES.xxl};
    letter-spacing: 0.6px;
`}
`;
