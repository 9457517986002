import styled from 'styled-components';
import { PostDescription, PostImageScreensaver, PostTitle } from '../newsfeed-view/newsfeed-view.styled';
import { FONTS, COLORS } from '@/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.article`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 411px;
`;

export const Title = styled(PostTitle)`
  margin: 0;
  margin-bottom: 0;
  max-height: 40px;
`;

export const Description = styled(PostDescription)``;

export const Image = styled.img`
  height: 84px;
  width: 84px;
  object-fit: cover;
  border-radius: 7px;
  overflow: hidden;
`;

export const Screensaver = styled(PostImageScreensaver)`
  width: 84px;
  height: 84px;
  padding: 15px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  min-height: 22px;
  & > p {
    display: flex;
    padding: 0px 10px;
    height: 22px;
    word-wrap: nowrap;
    align-items: center;
    color: #707070;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    background-color: ${COLORS.newsfeedTags};
    border-radius: 8px;
    border: 1px solid ${COLORS.newsfeedTagsBorder};
  }
`;

export const SourceIcon = styled.img`
  border-radius: 3px;
  object-fit: cover;
`;
