import styled from 'styled-components';

export const FooterWrapper = styled.div<{ isAnon: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: fit-content;
  height: fit-content;
  padding: 20px 8px 40px 8px;
  position: absolute;
  top: 0;
  left: 27px;
  background: rgba(245, 245, 245, 0.75);
  ${({ isAnon }) => isAnon && 'background: rgba(49, 49, 49, 0.50);'}
  backdrop-filter: blur(3px);
  z-index: 12;
  width: 60px;
`;
