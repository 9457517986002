import React from 'react';
import AddIcon from '@styles/icons/add-insight-icon.webp';
import { Stack } from '@mui/material';
import Image from 'next/image';
import { NewMusingInputsComponent } from '@/common/components/new-musing-modal/new-mussing-inputs.component';
import { INewPostReply } from '@/common/components/new-musing-modal/new-musing-modal.types';
import { useAuthProfile, useCreatePost } from '@/common/hooks';
import * as Styled from './post.styled';
import { IconContainer } from '../poll/poll.styled';
import { ModalHeaderText, ModalTitle, TitleContainer } from '../coverage/coverage.styled';

interface INewMusingModalComponentProps {
  open: boolean;
  closeHandler: () => void;
  setIsScrollDisabled: (it: boolean) => void;
}

export const PostComponent = ({ open, closeHandler, setIsScrollDisabled }: INewMusingModalComponentProps) => {
  const { data: profileId } = useAuthProfile();

  const { mutateAsync: createPost, isLoading, isSuccess, reset } = useCreatePost(profileId ?? undefined);

  const createClick = (text: string, image: ArrayBuffer | string | null, replies: INewPostReply[], isPrivate: boolean) => {
    if (text || image) {
      createPost({ text, image, replies, isPrivate });
    }
  };
  return (
    <div>
      <TitleContainer>
        <IconContainer>
          <Image src={AddIcon} width={12} height={12} alt="add-post" />
        </IconContainer>
        <Stack gap="10px">
          <ModalTitle>Add post</ModalTitle>
          <ModalHeaderText>Create a thread of multiple posts by adding a reply</ModalHeaderText>
        </Stack>
      </TitleContainer>
      <Styled.NewMusingInputsComponentContainer>
        <NewMusingInputsComponent
          isCreateModal
          closeHandler={closeHandler}
          createClick={createClick}
          isLoading={isLoading}
          setIsScrollDisabled={(it) => setIsScrollDisabled(it)}
          isSuccess={isSuccess}
          reset={reset}
          open={open}
        />
      </Styled.NewMusingInputsComponentContainer>
    </div>
  );
};
