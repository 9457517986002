import { CaseType, IMemo, IMemoSection, IMemoThesis, TitleEnum } from '@/common/types/memo.type';

export const titleOrder: { [key in TitleEnum]: number } = {
  [TitleEnum.SUMMARY]: 1,
  [TitleEnum.EXIT_VALUATION]: 2,
  [TitleEnum.RISKS]: 3,
  [TitleEnum.BEAR_CASE]: 4,
  [TitleEnum.MOAT]: 5,
  [TitleEnum.STAKEHOLDERS]: 6,
  [TitleEnum.BUSINESS_MODEL]: 7
};

export const getAdditionalInfo = ({
  title,
  priceTarget,
  bearCasePriceTarget
}: {
  title: string;
  priceTarget: number;
  bearCasePriceTarget?: number;
}): {
  method: string;
  type: string;
  priceTarget?: number;
} => {
  if (title === TitleEnum.SUMMARY) {
    return { method: CaseType.BASE_CASE, type: CaseType.BASE_CASE, priceTarget };
  }
  if (title === TitleEnum.BEAR_CASE) {
    return { method: CaseType.BEAR_CASE, type: CaseType.BEAR_CASE, priceTarget: bearCasePriceTarget };
  }

  return { method: CaseType.THESIS, type: CaseType.THESIS };
};

export const sortTitles = (titles: TitleEnum[]): TitleEnum[] => titles?.slice().sort((a, b) => (titleOrder[a] || 0) - (titleOrder[b] || 0));

export const getBaseFields = ({ getValues, memo }: { getValues: (name: string) => any; memo?: IMemo | void }) => ({
  direction: memo?.direction || getValues('direction')!,
  security: memo?.security || getValues('company.id'),
  priceTarget: memo?.priceTarget || getValues('priceTarget'),
  bearPriceTarget: memo?.bearPriceTarget || getValues('bearCasePriceTarget'),
  disclaimer: memo?.disclaimer || getValues('disclaimer'),
  documentUrl: memo?.documentUrl || getValues('documentUrl'),
  documentName: memo?.documentName || getValues('documentName'),
  link: memo?.link || getValues('link'),
  author: memo?.author || getValues('author'),
  isAnon: memo?.isAnon || getValues('isAnon'),
});

export const getMemoSections = ({
  getValues,
  sections,
  bearCasePriceTarget
}: {
  getValues: (name: string) => any;
  sections: IMemoThesis[];
  bearCasePriceTarget?: number;
}): IMemoSection[] =>
  sections
    .filter((section) => {
      const hasText = section?.text?.length > 0;
      const isBearCaseWithPrice = section.title === TitleEnum.BEAR_CASE && bearCasePriceTarget;
      return hasText || isBearCaseWithPrice;
    })
    .map(({ sectionName, text, title, imageUrls }) => ({
      title,
      text,
      imageUrls,
      sectionName,
      ...getAdditionalInfo({ title, priceTarget: getValues('priceTarget'), bearCasePriceTarget: getValues('bearCasePriceTarget') || 0 })
    }));

export function removeFirstRepeatedWord(companyName: string): { id: string; name: string } {
  const words = companyName.split(' ');
  const uniqueWords = new Set<string>();
  const result: string[] = [];

  for (const word of words) {
    if (uniqueWords.has(word)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    uniqueWords.add(word);
    result.push(word);
  }

  return { id: result[0], name: result.slice(1, result.length).join(' ') };
}
