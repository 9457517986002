import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useAllLikesOnPost, useLikesByPostId } from '@/common/hooks/use-like';
import * as Styled from './likes-claps-praises-modal.styled';
import { COLORS } from '@/theme';
import { IMAGE_ALT, TAKE_STATS_MODAL } from './conts/likes-claps-reposts.conts';
import { useRepostsByPostId, useAllRepostsOnPost, useUser } from '@/common/hooks';
import PaginationArrow from '@/styles/icons/blue-bottom-arrow.webp';
import { useAllClapsOnPost, useClapsByPostId } from '@/common/hooks/use-clap';
import { LikeClapPraiseComponent } from './like-claps-praises-item.component';
import CloseIcon from '@/styles/icons/close-modal-pointer.png';

interface ILikesClapsPraisesModalComponentProps {
  postId: string;
  open: boolean;
  closeHandler: () => void;
}

export const LikesClapsPraisesModalComponent = ({ postId, open, closeHandler }: ILikesClapsPraisesModalComponentProps) => {
  const user = useUser();
  const currentId = user && user.id;

  const [followingClicks, setFollowingClicks] = useState<string[]>([]);
  const [connectionClicks, setConnectionClicks] = useState<string[]>([]);

  const { allData: likes } = useAllLikesOnPost(postId, open);
  const {
    metaData: likesMetaData,
    count: likesCount,
    isListLoading: likesLoading,
    refetchList: refetchLikes,
    isFetchingNextPageList: isFetchingNextPageListLikes,
    fetchNextPageList: fetchNextPageListLikes
  } = useLikesByPostId(postId, TAKE_STATS_MODAL);

  const { allData: claps } = useAllClapsOnPost(postId, open);
  const {
    metaData: clapsMetaData,
    count: clapsCount,
    isListLoading: clapsLoading,
    refetchList: refetchClaps,
    isFetchingNextPageList: isFetchingNextPageListClaps,
    fetchNextPageList: fetchNextPageListClaps
  } = useClapsByPostId(postId, TAKE_STATS_MODAL);

  const { allData: reposts } = useAllRepostsOnPost(postId, open);
  const {
    metaData: reopstsMetaData,
    count: repostsCount,
    isListLoading: repostsLoading,
    refetchList: refetchReposts,
    isFetchingNextPageList: isFetchingNextPageListReposts,
    fetchNextPageList: fetchNextPageListReposts
  } = useRepostsByPostId(postId, TAKE_STATS_MODAL);

  useEffect(() => {
    if (open) {
      refetchLikes();
      refetchClaps();
      refetchReposts();
    }
  }, [open]);

  const reactions = [
    {
      name: 'Likes',
      data: likes,
      metaData: likesMetaData,
      count: likesCount,
      loading: likesLoading,
      refetch: refetchLikes,
      pagination: () => !isFetchingNextPageListLikes && fetchNextPageListLikes()
    },
    {
      name: 'Claps',
      data: claps,
      metaData: clapsMetaData,
      count: clapsCount,
      loading: clapsLoading,
      refetch: refetchClaps,
      pagination: () => !isFetchingNextPageListClaps && fetchNextPageListClaps()
    },
    {
      name: 'Re-Posts',
      data: reposts,
      metaData: reopstsMetaData,
      count: repostsCount,
      loading: repostsLoading,
      refetch: refetchReposts,
      pagination: () => !isFetchingNextPageListReposts && fetchNextPageListReposts()
    }
  ];

  return (
    <Styled.LikesClapsPraisesModalWrapper open={open} closeHandler={closeHandler} childTopPostion={15} isCreateModal>
      <Styled.CloseButton onClick={closeHandler}>
        <Styled.CloseImage src={CloseIcon} alt="close-icon" />
        close
      </Styled.CloseButton>
      {reactions.map(
        ({ name, data, metaData, count, loading, pagination }) =>
          count! > 0 && (
            <Styled.ActionSection key={name}>
              {!loading ? (
                <Styled.ActionCount>
                  <p>{`${count} ${data && ((count ?? 0) <= 1 ? name.slice(0, -1) : name)}`}</p>
                </Styled.ActionCount>
              ) : (
                <Styled.ActionLoader color={COLORS.dimGray} />
              )}
              <Styled.ActionUsersSection>
                {!loading ? (
                  data?.map((item, index) => (
                    <LikeClapPraiseComponent
                      index={index}
                      isFolowClick={followingClicks.includes(item.id)}
                      setFollowClick={(it) => setFollowingClicks((prev) => [...prev, it])}
                      deleteFollowClick={(it) => setFollowingClicks((prev) => prev.filter((st) => st !== it))}
                      isConnectClick={connectionClicks.includes(item.id)}
                      setConnectClick={(it) => setConnectionClicks((prev) => [...prev, it])}
                      deleteConnectClick={(it) => setConnectionClicks((prev) => prev.filter((st) => st !== it))}
                      currentId={currentId ?? ''}
                      isAnon={item.isAnon || (!!user && user.isAnon)}
                      postId={postId}
                      item={item}
                    />
                  ))
                ) : (
                  <Styled.ActionLoader color={COLORS.dimGray} />
                )}
              </Styled.ActionUsersSection>
              {metaData?.nextPage && (
                <Styled.PaginationContainer
                  onClick={() => {
                    pagination();
                  }}
                >
                  <Image src={PaginationArrow} width={14} height={14} alt={IMAGE_ALT.PAGINATION_ARROW} />
                  <p>{`view more ${name.slice(0).toLocaleLowerCase() + name.slice(1, 0)}`}</p>
                </Styled.PaginationContainer>
              )}
            </Styled.ActionSection>
          )
      )}
    </Styled.LikesClapsPraisesModalWrapper>
  );
};
