import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { QUERY_KEYS, ROUTER_KEYS } from '../consts/app-keys.const';
import { memoService } from '../services/memo.service';
import { IMemo } from '../types/memo.type';
import { setPostStatistics } from '../utils/post-statistics';
import { useMemoLocalStorage } from './use-memo';
import { useTreadByPostId } from './use-post';

export function useMemosFromUser(id: string) {
  return useQuery([QUERY_KEYS.MEMOS_CURRENT_USER, id], () => memoService.getMemosCurrentUser(id), {
    enabled: !!id
  });
}

export function useMemosOutherUser(id: string, direction: string, category: string) {
  return useQuery([QUERY_KEYS.MEMOS_OUTHER_USER, id], () => memoService.getMemosOtherUser(id, direction, category), {
    enabled: !!id
  });
}

export function usePublishMemo(isNotFileSubmit?: boolean) {
  const queryClient = useQueryClient();
  const { currentMemo } = useMemoLocalStorage();
  const router = useRouter();
  return useMutation((memo: IMemo) => memoService.publishedMemo(memo.id, memo), {
    onSuccess: (data) => {
      if (isNotFileSubmit) {
        router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${currentMemo.id}`);
        queryClient.invalidateQueries(QUERY_KEYS.PUBLISHED_MEMO);
      }
      if (!data) return;
      queryClient.invalidateQueries([QUERY_KEYS.MEMOS_ON_PROFILE, data.profileId]);
    }
  });
}

export function useProcessingSecondStepOfMemo() {
  const queryClient = useQueryClient();
  const { currentMemo } = useMemoLocalStorage();
  const router = useRouter();
  return useMutation(({ id, document }: { id: string; document: File }) => memoService.processSecondStepMemoProcessing(id, document), {
    onSuccess: () => {
      setTimeout(() => {
        router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${currentMemo.id}`);
        queryClient.invalidateQueries(QUERY_KEYS.PUBLISHED_MEMO);
      }, 300);
    }
  });
}

export function useMemosById(id: string | undefined) {
  const client = useQueryClient();
  return useQuery([QUERY_KEYS.MEMOS_BY_ID, id], () => memoService.getMemosById(id as string), {
    onSuccess: (data) => {
      if (!data) return;
      client.setQueryData(QUERY_KEYS.MEMOS_BY_ID_DATA, data);
      client.invalidateQueries([QUERY_KEYS.MEMOS_BY_ID_DATA]);
    },
    enabled: !!id,
    keepPreviousData: false,
    cacheTime: 0
  });
}

export const useMemoDataById = () => {
  const queryClient = useQueryClient();
  return useQuery([QUERY_KEYS.MEMOS_BY_ID_DATA], () => queryClient.getQueryData<IMemo | null>(QUERY_KEYS.MEMOS_BY_ID_DATA));
};

export function useMemosPostId(id: string | undefined, open?: boolean) {
  const queryClient = useQueryClient();
  const { setTread } = useTreadByPostId();
  return useQuery(
    [QUERY_KEYS.MEMOS_POST_BY_ID, id],
    async () => {
      const response = await memoService.getMemosPostById(id as string);

      if (!response) return response;

      await Promise.all(
        response.memosSection.map(async (item) => {
          await setPostStatistics(item, queryClient);
          await Promise.all(
            item.tread.replies.map(async (it) => {
              await setPostStatistics(it, queryClient);
            })
          );
          await setTread(item.tread, item.id);
        })
      );
      await Promise.all(
        response.summary.map(async (item) => {
          await setPostStatistics(item, queryClient);
          await Promise.all(
            item.tread.replies.map(async (it) => {
              await setPostStatistics(it, queryClient);
            })
          );
          await setTread(item.tread, item.id);
        })
      );
      return response;
    },
    {
      enabled: !!id && open
    }
  );
}
