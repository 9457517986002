import { useRouter } from 'next/router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { ideasService } from '../services/ideas.service';
import { CreateIdeaDTO, UpdateIdeaDTO } from '../types';
import { IPost } from '../types/post.type';
import { setPostStatistics } from '../utils/post-statistics';
import { useAllHomePosts, useAllProfilePosts, useTreadByPostId } from './use-post';

export const useProfileIdeas = (profileId: string) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId], () => ideasService.getIdeas(profileId), {
    enabled: !!profileId,
    onSuccess: (ideasData) => {
      if (!ideasData) return;
      const data = ideasData.ideas.map((item) => ({ ...item.postStats, id: item.post }));
      data.forEach((item) => {
        setPostStatistics(item as IPost, queryClient);
      });
    }
  });
};

export const useIdeaByPostId = () =>
  useMutation({
    mutationFn: (postId: string) => ideasService.getIdeaByPostId(postId)
  });

export const useCreateIdea = (callback?: () => void) => {
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts();
  const { setNewPosts: setNewHomePostsFollowing } = useAllHomePosts(false);
  const { setNewPosts: setNewHomePosts } = useAllHomePosts(true);
  const { setTread } = useTreadByPostId();

  return useMutation({
    mutationKey: [QUERY_KEYS.IDEAS],
    mutationFn: (payload: CreateIdeaDTO) => ideasService.createIdea(payload),
    onSuccess: (data) => {
      if (callback) {
        callback();
      }

      if (!data) return;
      const { post, profileId } = data;
      setNewPosts([post], profileId, true);
      setNewHomePostsFollowing([post], profileId, true);
      setNewHomePosts([post], profileId, true);

      setTread(post.tread, post.id);
      post.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });

      queryClient.invalidateQueries([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId]);

      setPostStatistics(post, queryClient);
    }
  });
};

export const useUpdateIdea = (callback?: () => void) => {
  const queryClient = useQueryClient();
  const { pathname } = useRouter();

  return useMutation({
    mutationKey: [QUERY_KEYS.IDEAS],
    mutationFn: (payload: UpdateIdeaDTO) => ideasService.updateIdea(payload),
    onSuccess: (data) => {
      if (callback) {
        callback();
      }

      if (!data) return;
      const { post, profileId } = data;

      queryClient.invalidateQueries([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId]);

      setPostStatistics(post, queryClient);

      if (pathname === '/profile') {
        const profilePosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_DATA]);
        if (!profilePosts) {
          return;
        }
        const filteredPosts = profilePosts.filter((item) => item.id !== post.id);
        queryClient.setQueryData([QUERY_KEYS.POST_DATA], [post, ...filteredPosts]);
      }
    }
  });
};

export const useDeleteIdea = (callback?: () => void) => {
  const queryClient = useQueryClient();
  const { deletePost } = useAllProfilePosts();
  const { deletePost: deletePostOnHomeFollowing } = useAllHomePosts(false);
  const { deletePost: deletePostOnHome } = useAllHomePosts(true);

  return useMutation({
    mutationKey: [QUERY_KEYS.IDEAS],
    mutationFn: (id: string) => ideasService.deleteIdea(id),
    onSuccess: (data) => {
      if (callback) {
        callback();
      }

      if (!data) return;
      const { deletedPosts, profileId } = data;

      queryClient.invalidateQueries([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId]);

      deletedPosts.forEach((it) => {
        deletePost(it, profileId);
        deletePostOnHomeFollowing(it, profileId);
        deletePostOnHome(it, profileId);
      });
    }
  });
};

export const useLatestIdeas = () => {
  const queryClient = useQueryClient();
  return useQuery([QUERY_KEYS.LATEST_IDEAS], () => ideasService.getLatestIdeas(), {
    onSuccess: (data) => {
      if (!data) return;
      data.map((idea) => setPostStatistics(idea.postStats, queryClient));
    }
  });
};
