import React, { useEffect, useState } from 'react';
import * as Styled from './modal-error-on-drag-and-drop.styled';
import { SlideType } from '@/common/types/modal.type';
import { ModalComponent } from '../modal/modal.component';
import { OnDropErrorEnum, OnDropErrorMessages } from '@/common/types/profile.type';

interface IProps {
  open: boolean;
  closeHandler: () => void;
  type: null | OnDropErrorEnum;
}

export const ModalErrorOnDragAndDropComponent = ({ open, closeHandler, type }: IProps) => {
  const [outputObject, setOutputObject] = useState<null | { description: string; title: string }>(null);

  const close = () => {
    closeHandler();
    setTimeout(() => {
      setOutputObject(null);
    }, 800);
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => close(), 3800);
    }
  }, [open]);

  useEffect(() => {
    if (open && type !== null) {
      setOutputObject(OnDropErrorMessages[type]);
    }
  }, [open, type]);
  return (
    <ModalComponent
      childTopPostion={0}
      hideBackdrop
      hideChildBackground
      hideChildeShadow
      slideType={SlideType.BOTTOM}
      open={open}
      closeHandler={close}
      isCenter
      hideBorder
    >
      <Styled.ErrorWrapper>
        <Styled.ErrorTextWrapper>
          {!!outputObject && (
            <>
              <p>{outputObject.title}</p>
              <span>{outputObject.description}</span>
            </>
          )}
        </Styled.ErrorTextWrapper>
      </Styled.ErrorWrapper>
    </ModalComponent>
  );
};
