import React, { useState } from 'react';
import { NewQuotePostModal } from './new-quote-modal.component';
import { useAuthProfile } from '@/common/hooks';

export const quotePostModalWrapper = () => {
  const [postIdInModal, setPostIdInModal] = useState<string | null>(null);
  const [quotePostHistory, setQuotePostHistory] = useState<string[]>([]);

  const { data: userProfileId } = useAuthProfile();

  const quotePostHandler = (newPostId: string) => {
    setPostIdInModal(newPostId);
  };

  return {
    quotePostHandler,
    componentToView: !!postIdInModal && !!userProfileId && (
      <NewQuotePostModal
        open={!!postIdInModal}
        postId={postIdInModal}
        userProfileId={userProfileId}
        openModal={(newPostId) => setPostIdInModal(newPostId)}
        closeModal={() => setPostIdInModal(null)}
        quotePostHistory={quotePostHistory}
        setQuotePostHistory={setQuotePostHistory}
      />
    )
  };
};
