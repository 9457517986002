import React from 'react';
import * as Styled from './username-input.styled';
import { Error, InputContainer } from '../ui/common-styles';
import { InputError } from '../modals/activate-anon-modal/activate-anon-modal.types';

interface IUsernameInputComponent extends InputError {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  outputValue?: string;
  autoFocus?: boolean;
  isOnboarding?: boolean;
}

export const UsernameInputComponent = ({ onChange, value, outputValue, autoFocus, isOnboarding, error }: IUsernameInputComponent) => (
  <Styled.UsernameInputWrapper>
    <Styled.UsernameInputInfo $isOnPage={!!value} $isOnboarding={isOnboarding}>
      {value ? <span>Username</span> : <p>Create a Username</p>}
      <span>5 - 25 characters</span>
      <span>Allowed: numbers, letters, dashes; spaces are not allowed</span>
    </Styled.UsernameInputInfo>
    <InputContainer>
      <Styled.UsernameInput
        id="anon-username-input"
        placeholder="type here"
        onChange={onChange}
        value={outputValue ?? value}
        disabled={!!value && !onChange}
        autoFocus={autoFocus}
        $isOnboarding={!!isOnboarding}
      />
      {error.isError && <Error>{error.errorText}</Error>}
    </InputContainer>
  </Styled.UsernameInputWrapper>
);
