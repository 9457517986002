import styled from 'styled-components';
import Image from 'next/image';
import { COLORS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const ReservedUsernameInputWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
 // border-radius: 20px;
  background: ${COLORS.white};
`;

export const WarninIcon = styled(Image)`
 width: 14px;
 height: 14px;
 position: absolute;
 left: -38px;
`

export const ReservedUsernameInputIcon = styled(Image)`
  margin-left: -7px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: ${COLORS.black};
    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.light};
    line-height: ${SIZES.xl};
    letter-spacing: 0.42px;
  }
`;

export const FormContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 435px;
  position: relative;

  div {
    display: flex;
    align-items: center;
    gap: 7px;
    p {
      font-family: ${FAMILIES.robotoFlex};
      font-size: ${SIZES.m};
      font-style: normal;
      font-weight: ${WEIGHTS.medium};
      line-height: ${SIZES.xxl};
      letter-spacing: 0.42px;
      color: #d10000;
    }
  }

  span {
    color: #000;
    font-family: "Roboto Flex";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
  }
`;

export const CodeOtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CodeOtpInputWrapper = styled.div`
  div {
    input {
      background-color: #f6f6f6;
    }
  }
`;
