import styled, { css } from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES, FAMILIES, WEIGHTS } from '@/theme/fonts.const';

interface ISubmitButtonProps {
  $isActive: boolean;
  $isPrimary?: boolean;
  $isRealModal?: boolean;
  $isLinked?: boolean;
}
const CreatingRealAccountButtonEnabled = css`
  border: 0;
  background: ${COLORS.realActiveModalInputButtonPrimaryBackground};
  color: ${COLORS.white};
`;
const CreatingRealAccountButtonDisabled = css`
  border: 0;
  background: ${COLORS.realActivateModalInputButtonPrimaryBackgroundDisabled};
  color: ${COLORS.realActivateModalInputButtonPrimaryDisabled};
`;

const VerificationRealAccountButtonEnabled = css`
  border: ${COLORS.realActiveModalInputButtonBorder};
  background: ${COLORS.realActiveModalInputButtonBackground};
  color: ${COLORS.realActivateModalTextColorPrimary};
`;
const VerificationRealAccountButtonDisabled = css`
  border: 1px solid ${COLORS.realActiveModalInputButtonBackgroundDisabled};
  background: ${COLORS.realActiveModalInputButtonBackgroundDisabled};
  color: ${COLORS.realActiveModalInputButtonTextColorDisabled};
`;

const CreatingButtonEnabled = css`
  border: ${COLORS.anonActiveModalInputButtonPrimaryBackground};
  background: ${COLORS.anonActiveModalInputButtonPrimaryBackground};
  color: ${COLORS.white};
`;
const CreatingButtonDisabled = css`
  border: ${COLORS.anonActiveModalInputButtonPrimaryBackgroundDisabled};
  background: ${COLORS.anonActiveModalInputButtonPrimaryBackgroundDisabled};

  color: ${COLORS.anonActiveModalInputButtonPrimaryTextColorDisabled};
`;

const VerificationButtonEnabled = css`
  border: ${COLORS.anonActiveModalInputButtonBorder};
  background: ${COLORS.anonActiveModalInputButtonBackground};

  color: ${COLORS.anonActiveModalEmailTextColorPrimary};
`;
const VerificationButtonDisabled = css`
  border: ${COLORS.anonActiveModalInputButtonBorderDisabled};
  background: ${COLORS.anonActiveModalInputButtonBackgroundDisabled};

  color: ${COLORS.anonActiveModalInputButtonTextColorDisabled};
`;

const LinkedButton = css`
  border: 0;
  background: ${COLORS.linkModalSuccessfulLinkedButtonBackground};
  color: ${COLORS.white};
`;
export const SubmitButton = styled.button<ISubmitButtonProps>`
  ${({ $isPrimary, $isActive, $isRealModal, $isLinked }) => {
    if ($isLinked) {
      return LinkedButton;
    }
    if ($isRealModal) {
      if ($isPrimary) {
        return $isActive ? CreatingRealAccountButtonEnabled : CreatingRealAccountButtonDisabled;
      }
      return $isActive ? VerificationRealAccountButtonEnabled : VerificationRealAccountButtonDisabled;
    }
    if ($isPrimary) {
      return $isActive ? CreatingButtonEnabled : CreatingButtonDisabled;
    }
    return $isActive ? VerificationButtonEnabled : VerificationButtonDisabled;
  }}

  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'auto')};

  padding: 20px 30px;
  border-radius: 0px 0px ${SIZES.xxl} ${SIZES.xxl};

  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  line-height: ${SIZES.m};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
