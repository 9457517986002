import { useMutation, useQueryClient } from 'react-query';
import { postService } from '../services';
import { useAllProfilePosts, useAllHomePosts, useTreadByPostId } from './use-post';
import { setPostStatistics } from '../utils/post-statistics';
import { IReviewCreatePayload } from '../types/review.type';

export const useCreateReview = (id?: string) => {
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts(id);
  const { setNewPosts: setNewPostsOnHomeFollowing } = useAllHomePosts(false, id);
  const { setNewPosts: setNewPostsOnHome } = useAllHomePosts(true, id);
  const { setTread } = useTreadByPostId();

  return useMutation({
    mutationFn: (data: IReviewCreatePayload) => postService.createReview(data),
    onSuccess: (data) => {
      if (!data) return;

      setTread(data.tread, data.id);
      data.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);
      setNewPosts([data], data.profileId, true);
      setNewPostsOnHomeFollowing([data], data.profileId, true);
      setNewPostsOnHome([data], data.profileId, true);
    }
  });
};
