import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';

export const Container = styled.div<{ isAuth?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 4px;
  box-shadow: ${({ isAuth }) => (isAuth ? 'none' : '0px 0px 15px 0px rgba(0, 27, 68, 0.7)')};
  border-radius: 10px;
`;

export const InputField = styled.input<{ isFirst?: boolean; width?:number; isLast?: boolean; isAuth?: boolean }>`
  :focus-visible {
    outline: 0;
  }

  border-radius: 35px;
  border: 2px solid #6d6d6d;
  width: ${({width}) => width ? `${width}px` : '650px'};
  height: 66px;
  padding: 15px 5px 15px 25px;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  color: #000;

  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 12.6px;

  &::placeholder {
    color: #5e5e5e;
    letter-spacing: 0px;
  }
`;
