import React from 'react';
import SendIconWhite from '@styles/icons/send-icon-white.svg';
import SendIconGrey from '@styles/icons/send-icon-grey.svg';
import SendIconTeal from '@styles/icons/send-icon-teal.svg';
import SendIconPurple from '@styles/icons/send-icon-purple.svg';
import NotActiveQuote from '@styles/icons/not-active-quote-action.webp';
import ActiveQuote from '@styles/icons/active-quote-action.webp';
import * as Styled from './post-button.styled';
import { COLORS } from '@/theme';
import { Loader } from '@/common/components/loader';
import { PostButtonColor } from './post-button.type';

interface IPostButtonComponentProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  text?: string;
  onClick?: () => void;
  color?: PostButtonColor;
  isInNewQuote?: boolean;
  borderRadius?: number;
}

export const PostButtonComponent = ({
  isLoading,
  isDisabled,
  text,
  onClick,
  color,
  isInNewQuote,
  borderRadius
}: IPostButtonComponentProps) => (
  <Styled.PostButton onClick={onClick} disabled={isDisabled} color={color || 'default'} borderRadius={borderRadius}>
    {!isLoading && !isInNewQuote && (
      <Styled.ButtonImage
        src={isDisabled ? SendIconGrey : color === 'bear' ? SendIconPurple : color === 'bull' ? SendIconTeal : SendIconWhite}
        alt="send"
      />
    )}
    {!isLoading && isInNewQuote && <Styled.ButtonImage src={isDisabled ? NotActiveQuote : ActiveQuote} alt="send" />}
    {isLoading && <Loader width="20" height="20" borderSize="2" color={COLORS.white} />} {text || 'post'}
  </Styled.PostButton>
);
