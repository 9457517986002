// eslint-disable-next-line import/no-cycle
import { IPostPayload, IStatistic } from './post.type';
import { IProfile } from './profile.type';

export enum IdeaHorizon {
  SKIP = 'SKIP',
  MONTHS_0_6 = 'MONTHS_0_6',
  MONTHS_6_12 = 'MONTHS_6_12',
  MONTHS_12_24 = 'MONTHS_12_24',
  MONTHS_24_PLUS = 'MONTHS_24_PLUS'
}

export type IdeaDTO = {
  id: string;
  priority: number | null;
  text: string;
  postText?: string;
  reasons: string[];
  horizon: IdeaHorizon;
  post: string;
  createdAt: string;
  profileId: string;
  questionId: string;
  postStats: IStatistic;
};

export type CreateIdeaDTO = {
  priority: number | null;
  isPrivate: boolean;
  text?: string;
  image: string | ArrayBuffer | null;
  replies?: IPostPayload[];
  questionId: string;
  reasons: string[];
  horizonSelect: IdeaHorizon;
  type: string;
};

export type UpdateIdeaDTO = {
  priority: number | null;
  ideaId: string;
  reasons: string[];
  horizonSelect: IdeaHorizon;
};

export type LatestIdeaDTO = {
  id: string;
  profile: IProfile;
  profileId: string;
  text: string;
  priority: number | null;
  reasons: string[];
  horizon: IdeaHorizon;
  post?: string;
  answer: number;
  createdAt: string;
  questionId: string;
  vertical?: string;
  role?: string;
  isImageHidden?: boolean;
  postStats: IStatistic;
};
