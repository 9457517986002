import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import ArrowBottom from '@styles/icons/conversation-blue-bottom-arrow.webp';
import { useRouter } from 'next/router';
import * as Styled from './conversation-tread.styled';
import { PostComponent } from '@/common/components/post';
import { useDeletePost, useFetchNextRepliesInTreadTread, useFetchPrevRepliesInTreadTread, useTreadByPostId } from '@/common/hooks';
import { IPostWithPostOrOriginalPost, ISubPost, PostType } from '@/common/types/post.type';
import { ShowMorePrev } from './show-more-prev.component';
import { Loader } from '@/common/components/loader';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IProps {
  openReply: (postItem?: IPostWithPostOrOriginalPost, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isClickOnPost: boolean;
  isTreanding?: boolean;
  isMemoPost?: boolean;
  postId: string;
  profileId?: string;
  isIdeaModal?: boolean;
  userProfileId?: string | undefined;
  isInModal?: boolean;
  isPublish?: boolean;
  replies?: IPostWithPostOrOriginalPost[];
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  memosSection?: any;
  dividerWidth?: number;
  isDefaultStats?: boolean;
  parentPost?: ISubPost;
}

export const ConversationTreadComponent = ({
  openReply,
  isClickOnPost,
  isTreanding,
  profileId,
  postId,
  userProfileId,
  isInModal,
  replies,
  isMemoPost,
  isIdeaModal,
  isPublish,
  memosSection,
  dividerWidth,
  isDefaultStats,
  parentPost,
  quotePostHandler,
  closeModal
}: IProps) => {
  const router = useRouter();
  const isHome = router.pathname.includes(ROUTER_KEYS.HOME);
  const isProfile = router.pathname.includes(ROUTER_KEYS.PROFILE);
  const isMemoPostHomeOrProfile = memosSection && (isProfile || isHome);

  const { mutateAsync: deletePost } = useDeletePost({ postId, profileId });
  const { hasPrev, hasNext, countNextReplies, prevFollowingUserImages, countPrevFollowingUserImages } = useTreadByPostId(postId);
  const { mutateAsync: loadPrevReplies, isLoading: isPrevLoading } = useFetchPrevRepliesInTreadTread(postId);
  const { mutateAsync: loadNextReplies, isLoading: isNextLoading } = useFetchNextRepliesInTreadTread(postId);

  const onClickToLoadPrevReplies = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = replies?.filter((it) => !it.isSearched && !it.isNew)?.[0]?.id;
    if (!id) return;
    loadPrevReplies(id);
  };

  const onClickToLoadNextReplies = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = replies?.filter((it) => !it.isSearched && !it.isNew)?.at(-1)?.id;
    if (!id) return;
    loadNextReplies(id);
  };

  const stopPropagation = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <AnimatePresence>
      {isClickOnPost && replies?.length && (
        <motion.div
          transition={{ ease: 'backInOut', delay: isIdeaModal ? 0 : 0.2, duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={undefined}
        >
          <Styled.ConversationWrapper isInModal={isInModal}>
            {hasPrev && !isIdeaModal && (
              <Styled.ReplyDivider
                isMemoPostHomeOrProfile={isMemoPostHomeOrProfile}
                dividerWidth={dividerWidth}
                isPublish={isPublish}
                isFirst
                isMemoPost={!!memosSection || isMemoPost}
                isIdeaModal={isIdeaModal}
              />
            )}
            {!hasPrev && isPublish && !isIdeaModal && (
              <Styled.ReplyDivider
                dividerWidth={dividerWidth}
                isPublish={isPublish}
                isFirst
                isMemoPost={!!memosSection || isMemoPost}
                isIdeaModal={isIdeaModal}
              />
            )}
            <div style={{ marginLeft: isPublish && !isIdeaModal ? '40px' : undefined }}>
              {hasPrev && !isPrevLoading && (
                <ShowMorePrev
                  noPadding={(isPublish || isIdeaModal) && !isHome}
                  userImages={prevFollowingUserImages ?? []}
                  countUserImages={countPrevFollowingUserImages ?? 0}
                  onClick={onClickToLoadPrevReplies}
                />
              )}
              {hasPrev && isPrevLoading && (
                <Styled.LoaderWrapper>
                  <Loader height="30" width="30" />
                </Styled.LoaderWrapper>
              )}
              {replies.map((it, index) => {
                if (it.isNew) return <div key={`empty${isTreanding && '-treanding'}-post-${it.id}`} />;
                const isFirstReply = index === 0;
                return (
                  <div key={`wrapper-post${isTreanding && '-treanding'}-divider-${it.id}`}>
                    <Styled.ReplyDivider
                      // isPublish={isPublish}
                      dividerWidth={dividerWidth}
                      isFirstMemoReply={(!!memosSection || isMemoPost) && isFirstReply && isPublish && !hasPrev}
                      isIdeaModal={isIdeaModal}
                      isMemoPost={!!memosSection || isMemoPost}
                      isMemoPostHomeOrProfile={isMemoPostHomeOrProfile}
                      key={`post${isTreanding && '-treanding'}-divider-${it.id}`}
                      isFirst={!hasPrev && index === 0}
                    />
                    <PostComponent
                      parentPost={parentPost}
                      userProfileId={userProfileId}
                      isDefaultStats={isDefaultStats}
                      postStatus={{
                        isInOwnTread: false,
                        isOwnReply: true,
                        isLast: it.replyCount === 0,
                        isInModal,
                        isTreadOpen: isClickOnPost,
                        isConversation: true
                      }}
                      isIdeaModal={isIdeaModal}
                      closeModal={closeModal}
                      post={it}
                      onClickByPost={stopPropagation}
                      repliedPost={it.post}
                      key={`conversation${isTreanding && '-treanding'}-post-${it.id}`}
                      deletePost={deletePost}
                      openReply={(e) => openReply(it, e)}
                      quotePostHandler={quotePostHandler}
                      postType={PostType.Reply}
                    />
                  </div>
                );
              })}
              {hasNext && <Styled.ReplyDivider />}
              {hasNext && !isNextLoading && (
                <Styled.ShowMoreWrapper onClick={onClickToLoadNextReplies}>
                  <Image src={ArrowBottom} alt="Show More" width={30} height={34} style={{ padding: '10px 8px 10px 8px' }} />
                  <div>
                    <p>
                      Show {countNextReplies} more {(countNextReplies ?? 0) > 1 ? 'replies' : 'reply'}
                    </p>
                  </div>
                </Styled.ShowMoreWrapper>
              )}
              {replies.map((it) => {
                if (!it.isNew) return <div key={`empty-new${isTreanding && '-treanding'}-post-${it.id}`} />;
                return (
                  <div key={`wrapper-new-post${isTreanding && '-treanding'}-divider-${it.id}`}>
                    <Styled.ReplyDivider
                      dividerWidth={dividerWidth}
                      isMemoPost={!!memosSection || isMemoPost}
                      // isPublish={isPublish}
                      isIdeaModal={isIdeaModal}
                      key={`new-post${isTreanding && '-treanding'}-divider-${it.id}`}
                    />
                    <PostComponent
                      isDefaultStats={isDefaultStats}
                      onClickByPost={stopPropagation}
                      userProfileId={userProfileId}
                      postStatus={{
                        isInOwnTread: false,
                        isOwnReply: true,
                        isLast: it.replyCount === 0,
                        isInModal,
                        isTreadOpen: isClickOnPost,
                        isConversation: true
                      }}
                      post={it}
                      isIdeaModal={isIdeaModal}
                      repliedPost={it.post}
                      key={`new-conversation${isTreanding && '-treanding'}-post-${it.id}`}
                      deletePost={deletePost}
                      openReply={(e) => openReply(it, e)}
                      quotePostHandler={quotePostHandler}
                      postType={PostType.Reply}
                    />
                  </div>
                );
              })}
              {hasNext && isNextLoading && (
                <Styled.LoaderWrapper>
                  <Loader height="32" width="32" />
                </Styled.LoaderWrapper>
              )}
            </div>
          </Styled.ConversationWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
