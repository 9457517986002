import { IPost } from '../types/post.type';

export const updatePostQuestion = (oldPosts: IPost[], data: IPost) =>
  oldPosts.map((it) => {
    if (data?.question && it?.question && it.question.id === data.question.id) {
      return {
        ...it,
        question: {
          ...it.question,
          ...data.question
        }
      };
    }
    if (it.post && data?.question && it.post?.question && it.post.question.id === data.question.id) {
      return {
        ...it,
        post: {
          ...it.post,
          question: {
            ...it.post.question,
            ...data.question
          }
        }
      };
    }
    return it;
  });
