const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export function formatDate(inputDate: string | Date, shortMonth: boolean | undefined = false, backDate: boolean | undefined = false) {
  const date = new Date(inputDate);

  const day = backDate ? date.getDate() - 1 : date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  let monthName = months[month];

  if (shortMonth) {
    monthName = months[month]?.substring(0, 3);
  }

  const formattedDate = `${day} ${monthName} ${year}`;
  return formattedDate;
}

export function formatDateYearMonth(inputDate: string | Date, shortMonth: boolean = false) {
  const date = new Date(inputDate);
  const month = date.getMonth();
  const year = date.getFullYear();

  let monthName = months[month];

  if (shortMonth) {
    monthName = monthName.substring(0, 3);
  }

  const formattedDate = `${year} ${monthName}`;
  return formattedDate;
}

export function isToday(date: Date) {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}
