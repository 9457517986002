import styled from 'styled-components';
import { COLORS } from '@/theme';
import { SIZES, FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const OtpInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: ${COLORS.anonActiveModalEmailSubTextColorPrimary};

    font-family: ${FAMILIES.robotoFlex};
    font-size: ${SIZES.m};
    font-style: normal;
    font-weight: ${WEIGHTS.normal};
    line-height: ${SIZES.xxl};
  }

  p:last-child {
    margin-top: 20px;
  }
`;
