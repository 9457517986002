import axios from 'axios';
import { IGNORE_ERRORS } from '../consts/ignore-errors.conts';

const axiosInstance = axios.create({
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status !== 401) {
      const serverErrorMessage = error.response?.data?.errorMessage;
      const isErrorMustBeIgnored = IGNORE_ERRORS.some((ignore_error) => {
        const isEnpointIgnored = error.response?.config?.url?.includes(ignore_error.endpoint);
        const isMessageIgnored = serverErrorMessage === ignore_error.message;

        return isEnpointIgnored || isMessageIgnored;
      });
      if (isErrorMustBeIgnored) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  }
);

export { axiosInstance };
