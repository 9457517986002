import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';

export const UnitText = styled.p`
  color: #343434;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

export const Item = styled.button<{ isDisabled?: boolean; isActive: boolean }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: ${({ isActive, isDisabled }) => (isDisabled ? '#F2F2F2' : isActive ? '#EFF1FF' : '#F2F2F2')};
  color: ${({ isActive, isDisabled }) => (isDisabled ? '#000' : isActive ? '#0016D8' : '#000')};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
`;
